import React from "react";

function TableHeaders(){
  return(
    <div className="wcl-listing-header">
      <div className="wcl-40">Product</div>
      <div className="text-center wcl-10">Wastage</div>
      <div className="text-center wcl-40">Reasons</div>
      <div className="text-center wcl-10">Units</div>
    </div>
  )
}

export default TableHeaders