import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import { OperationsService } from '../../../_services/operations'
import { openNotificationWithIcon } from '../../../_modules/notification';

const operations_service = new OperationsService()

const ModalReason = ({ onRowReject, handleOnRowReject, rowId, getParentListing, rowData }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)

  const onCreate = () => {
    let value = form.getFieldValue()
    const isEmpty = Object.keys(value).length === 0 ? '' : false;
    if (isEmpty !== '' && value.comment !== '') {
      setLoading(true)
      const payload = {
        request_id: rowId,
        status: "rejected",
        comment: value.comment
      }
      operations_service.reject_pending_req(payload).subscribe((r) => {
        getParentListing()
        setLoading(false)
        openNotificationWithIcon('success', 'Rejected Successfully')
        handleOnRowReject(false)
      }, ((err) => {
        console.log("err", err)
        setLoading(false)
        openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
      }))
    }
  }

  return (
    <Modal
      visible={onRowReject}
      // title="View SKU"
      okText="Create"
      width="40%"
      cancelText="Cancel"
      // centered
      className="frz-modal-no-footer custom-padding"
      onCancel={() => handleOnRowReject(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      footer={[]}>
      <div className="frz-fw-700 frz-bm-20">
        Reason for Rejection
      </div>
      <Form
        id="myForm"
        form={form}
        layout="vertical"
        name="form">
        <div>
          State your reason for Rejection
        </div>
        <Form.Item
          name="comment"
          // label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter reason for rejection!',
            },
          ]}>
          <Input.TextArea style={{ height: 140 }} />
        </Form.Item>
        <div className="frz-dis-flex frz-float-right-jc">
          <Button form="myForm" key="submit" onClick={onCreate} loading={loading} className="frz-bg-red" htmlType="submit" type="primary">
            Reject
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalReason