import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Table, InputNumber, Spin } from 'antd';
import { AttendanceService } from '../../_services/attendance';
import { openNotificationWithIcon } from '../../_modules/notification';
import { LoadingOutlined } from '@ant-design/icons';

const attendance_service = new AttendanceService()
// let otpAtt = true;
// let imgAtt = true;

let video
let canvas
let stream
var interval

const ModalAttandance = ({ showAttModal, showModalLog, rowData, handleCameraError, otpAtt, imgAtt,
    cameraErrorThrown, eventType, getUsers, node_code }) => {
    const [form] = Form.useForm();
    const [imgAttValidation, setImgAttValidation] = useState(false)
    const [imageUrl, setImageUrl] = useState(false)
    const [isOtpVerified, setIsOtpVerified] = useState(false)
    const [otp, setOtp] = useState(false)
    const [forceLogout, setForceLogout] = useState(false)
    const [APImsg, setAPImsg] = useState(false)
    const [loading, setLoading] = useState(false)
    const [finish, setFinish] = useState(false)
    const [disable, setDisable] = useState(true)
    const [timeLeft, setTimeLeft] = useState(5);
    const [inError, setInError] = useState(false)

    useEffect(() => {
        if (!disable) {

            if (timeLeft === 0) {
                console.log("TIME LEFT IS 0");
                setTimeLeft(null)
                captureImage()
            }
            // exit early when we reach 0
            if (!timeLeft) return;

            // save intervalId to clear the interval when the
            // component re-renders
            const intervalId = setInterval(() => {

                setTimeLeft(timeLeft - 1);
            }, 1000);

            // clear interval on re-render to avoid memory leaks
            return () => clearInterval(intervalId);
            // add timeLeft as a dependency to re-rerun the effect
            // when we update it
        }
    }, [timeLeft, disable]);

    const onCreate = (value) => {
        if (forceLogout) {
            const payload = {
                mobile_number: rowData.mobile_number,
                event_type: eventType,
                is_forced: forceLogout ? true : false,
                profile_image: imageUrl,
                image_attendance: imgAtt,
                otp_attendance: otpAtt,
                node_code: node_code
            }
            attendance_service.capture_attendance(payload).subscribe((r) => {
                setAPImsg(r.response.data)
                setFinish(true)
                setLoading(false)
                setForceLogout(false)
            }, ((err) => {
                setLoading(false)
                if (err.response.data) {
                    setAPImsg(err.response.data)
                    setForceLogout(true)
                } else {
                    setInError(err.response.error)
                    openNotificationWithIcon("error", err.response.error)
                }
                // openNotificationWithIcon("error", err.response.data)
                console.log("err", err)
            }))
            return
        }
        if (otpAtt && imgAtt) {
            if (isOtpVerified) {
                submitAttendance()
            } else {
                setOtp(value.otp)
                //verify attendace
                verifyOtp(value.otp)
            }
        } else if (imgAtt && !otpAtt) {
            submitAttendance()
        } else if (otpAtt && !imgAtt) {
            if (isOtpVerified) {
                submitAttendance()
            } else {
                setOtp(value.otp)
                verifyOtp(value.otp)
            }
            //verify attendace
            // verifyOtp(value.otp)
        }
    }

    useEffect(() => {
        if (imageUrl) {
            submitAttendance()
        }
    }, [imageUrl])

    const submitAttendance = () => {
        if (imgAtt && !imageUrl) {
            openNotificationWithIcon("error", "Please click and upload your picture")
            return
        }
        setLoading(true)
        const payload = {
            mobile_number: rowData.mobile_number,
            event_type: eventType,
            is_forced: forceLogout ? true : false,
            profile_image: imageUrl,
            image_attendance: imgAtt,
            otp_attendance: otpAtt,
            node_code: node_code
        }
        attendance_service.capture_attendance(payload).subscribe((r) => {
            setAPImsg(r.response.data)
            setFinish(true)
            setLoading(false)
            setForceLogout(false)
        }, ((err) => {
            setLoading(false)
            if (err.response.data) {
                setAPImsg(err.response.data)
                setForceLogout(true)
            } else {
                setInError(err.response.error)
                openNotificationWithIcon("error", err.response.error)
            }
            // openNotificationWithIcon("error", err.response.data)
            console.log("err", err)
        }))
    }

    const verifyOtp = (otp) => {
        setLoading(true)
        const payload = {
            mobile_number: rowData.mobile_number,
            otp: parseInt(otp)
        }
        attendance_service.verify_otp_login(payload).subscribe((r) => {
            setLoading(false)
            if (imgAtt) {
                setImgAttValidation(true)
                openCamera()
                setIsOtpVerified(true)
            } else {
                submitAttendance()
            }
        }, ((err) => {
            setLoading(false)
            openNotificationWithIcon("error", err?.response?.errors ? err?.response?.errors : 'API error')
            console.log("err", err)
        }))
    }

    useEffect(() => {
        if (otpAtt) {
            generateOtp()
        } else if (imgAtt) {
            openCamera()
        }
        return () => {
            if (video && stream) {
                video.pause();
                video.src = "";
                stream.getTracks()[0].stop()
            }
        }
    }, [])

    useEffect(() => {
        if ((otpAtt && imgAtt && isOtpVerified) || (imgAtt && !otpAtt)) {
            navigator.mediaDevices.getUserMedia({ video: true }).then((value) => {

            }).catch((val) => {
                handleCameraError(true)
                handleOnCancel()
                openNotificationWithIcon('error', 'Please Allow Camera Access From Browser Settings')
            })
        }

    }, [isOtpVerified])

    const generateOtp = () => {
        const payload = {
            mobile_number: rowData.mobile_number
        }
        attendance_service.generate_otp_login(payload).subscribe((r) => {

        }, ((err) => {
            console.log("err", err)
        }))
    }

    const handleOnCancel = () => {
        if (video && stream) {
            video.pause();
            video.src = "";
            stream.getTracks()[0].stop()
        }
        showModalLog(false)
        if (finish) {
            getUsers()
        }
    }

    const openCamera = async () => {
        video = document.querySelector("#video");
        canvas = document.querySelector("#canvas");
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        video.srcObject = stream
        setDisable(false)
    }

    const captureImage = () => {
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        let image_data_url = canvas.toDataURL('image/jpeg');
        setImageUrl(image_data_url)
        // data url of the image
    }


    return (
        <Modal
            visible={showAttModal}
            closable={false}
            title=""
            okButtonProps={{
                loading: loading, hidden: !forceLogout && (finish ||
                    (otpAtt && imgAtt && isOtpVerified) || (!otpAtt && imgAtt)
                )
            }}
            maskClosable={false}
            okText={forceLogout ? "Yes, Logout" : "Submit"}
            cancelText={finish ? "Ok" : "Cancel"}
            onCancel={() => handleOnCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}>
            {APImsg ?
                <div className="frz-fw-600">
                    {APImsg}
                </div>
                :
                otpAtt &&
                    !imgAttValidation ?
                    <div className="frz-dis-flex frz-hrz-center frz-vt-center">
                        <Form
                            style={{ marginTop: "30px" }}
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            initialValues={{
                                modifier: 'public',
                            }}>
                            <div className="frz-bm-5">
                                Enter OTP
                            </div>
                            <Form.Item
                                name="otp"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter OTP!',
                                    },
                                ]}>
                                <Input style={{ width: "300px" }} placeholder="Enter OTP" />
                            </Form.Item>
                        </Form>
                    </div>
                    :
                    <div style={{ height: !disable ? "auto" : 200 }}>
                        <div className={!disable ? "" : "frz-dis-none"}>
                            {timeLeft ?
                                <div className="frz-taking-pic frz-dis-flex frz-hrz-center">
                                    <div>
                                        Taking your picture in
                                        <div className="frz-time frz-dis-flex frz-hrz-center">{timeLeft}</div>
                                    </div>
                                </div>
                                : null}
                            <div className={imageUrl ? "frz-w-100-percent" : "frz-dis-none"}>
                                <canvas className='frz-w-100-percent' id="canvas" width="260" height="180"></canvas>
                                <div>
                                    {inError ?
                                        <div className="frz-in-error frz-dis-flex frz-hrz-center">
                                            Failed: {inError}
                                        </div>
                                        :
                                        <span className="frz-dis-flex frz-hrz-center">Processing <LoadingOutlined style={{ marginLeft: 10, fontSize: 24 }} spin /></span>
                                    }
                                </div>
                            </div>
                            <div className={!imageUrl ? "" : "frz-dis-none"}>
                                <video id="video" autoPlay className='frz-w-100-percent'></video>
                            </div>

                            {/* <div className='text-center frz-tp-10'>
                <Button type="primary" onClick={captureImage} disabled={disable}>Take Employee Photo</Button>
              </div> */}
                            {/* <div className="block"><b>Employee Photo : </b>
                <span style={{ paddingLeft: 10 }}>
                  <canvas id="canvas" width="260" height="180"></canvas>
                </span>
              </div> */}
                        </div>

                        <div className="position-center">
                            <div className="spin-center"><Spin spinning={disable} tip="...Loading" /></div>
                        </div>
                    </div>

            }
        </Modal>
    )
}

export default ModalAttandance