import { TOGGLE_LOGIN } from "../_constants"

export function toggleLogin(value) {
  return {
    type: TOGGLE_LOGIN,
    payload: {
      value
    },
  };
}

export function getPreLoadData(value, type) {
  return {
    type: type,
    payload: {
      value
    },
  };
}

export function setSTO(value,type){
  return{
    type: type,
    payload: {
      value
    }
  }
}
