import React, { useState, useEffect } from "react";
import '../../static/css/wastage_closing.css';
import WCMain from "../../components/wastage_closing/wc_main";
import WCCreate from '../../components/wastage_closing/wc_create';
import {WastageClosingService} from '../../_services/wastage_closing';

const wc_service = new WastageClosingService()

function WastageClosing(){
  const [wcScreen, setWCScreen] = useState('wc_main')
  const [currentNode, setCurrentNode] = useState(null)
  const [currentTab, setCurrentTab] = useState('wastage')
  const [reasons, setReasons] = useState(false)
  const [dashboardData, setDashboardData] = useState(false)
  const [currentMainTab, setCurrentMainTab] = useState('node_procurement')

  useEffect(() => {
    getReasons()
  },[])

  function getReasons() {
    wc_service.get_reasons().subscribe((r) => {
      setReasons(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  return(
    <div className="font-quicksand layer2">
      {wcScreen === 'wc_main' ?
        <WCMain setWCScreen={setWCScreen} currentNode={currentNode} setCurrentNode={setCurrentNode}
          setCurrentTab={setCurrentTab} currentTab={currentTab} reasons={reasons}
          setDashboardData={setDashboardData} dashboardData={dashboardData} setCurrentMainTab={setCurrentMainTab}
          currentMainTab={currentMainTab}/>
      :
        <WCCreate setWCScreen={setWCScreen} wcScreen={wcScreen} currentNode={currentNode} reasons={reasons}
          dashboardData={dashboardData} currentMainTab={currentMainTab}/>
      }
    </div>
  )
}

export default WastageClosing