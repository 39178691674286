import React from "react";

function TableHeaders(){
  return(
    <div className="wcl-listing-header">
      <div className="wcl-60">Product</div>
      <div className="text-center wcl-20">Closing</div>
      <div className="text-center wcl-20">Inventory Units</div>
    </div>
  )
}

export default TableHeaders