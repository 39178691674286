import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZUpload from '../../../_controls/FRZUpload';
import FRZInput from '../../../_controls/FRZInput';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const UploadBills = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [billType] = useState([{id: 'invoice_report', name: 'Invoice'}, {id:'grn_report', name: 'Grn'}])
  const [imageState, setImageState] = useState({})
  const [originalFileDetails, setOriginalFileDetails] = useState({ name: "", contentType: "" })

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setImageState({})
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    const payload = { ...values, original_filename: originalFileDetails.name,
                                 content_type: originalFileDetails.contentType,
                                 image_base: imageState.content_type,
                                 po_ids: [data.id]}
    setLoading(true)
    modalSubmit(payload)
  }

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOriginalFileDetail = (name, type) => {
    setOriginalFileDetails({ name: name, contentType: type })
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={billType} name="doc_type" isRequired={true}
              label="Bill Type" placeholder="Select Bill Type" value="id" option="name"
              isShowSearch={true} width={"100%"} />
            
            <FRZInput name="bill_ref_no" isRequired={true} label="Bill Ref No" placeholder="Bill Ref No" />

            <FRZUpload name="content_type" className="avatar-uploader" isRequired={true}
              customRequest={handleClick} imgWidth={"100%"} label={"Upload Bill"} listType="picture-card" setOnChangeType={"content_type"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} showFileName={true} handleOriginalFileDetail={handleOriginalFileDetail} />

          </Form>
        </Modal>
        :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default UploadBills
