import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Spin, Row, Col, Tooltip } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { OperationsService } from '../../../_services/operations';
import FRZNamingBlock from '../../../_controls/FRZNamingBlock';
import { openNotificationWithIcon } from '../../../_modules/notification';

const ops_service = new OperationsService()

const AddProductModal = forwardRef((props, ref) => {
  const { modalSubmit, roles } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [poData, setPoData] = useState(false)
  const [id, setId] = useState(false)
  const [id2, setId2] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [skuData, setSkuData] = useState(false)
  const [conversionValue, setConversionValue] = useState(false)
  const [totalValue, setTotalValue] = useState(false)
  const [packedSize, setPackedSize] = useState(false)
  const [conversionFactor, setConversionFactor] = useState(false)
  const [throwError, setThrowError] = useState(false)
  const [throwErrorMultiple, setThrowErrorMultiple] = useState(false)
  const time = "08:07:33"

  useEffect(() => {
    if (data && title === 'Edit Product') {
      setId(data.po_id)
    }
    if (data && title === 'Add Product') {
      setId(data.id)
    }
    if (data && title === 'Edit Graded Qty') {
      let total = ((data.graded_quantity / data.conversion_factor) * (10 / 100))
      // console.log("total00", total)
      setConversionFactor(data.conversion_factor)
      setConversionValue(total)
      setTotalValue(data.graded_quantity / data.conversion_factor)
      setPackedSize(data.packed_qty)
      form.setFieldsValue({ ...data })
      setId(data.po_id)
      setId2(data.id)
    }
    if ((data && title == "Edit Product") || isClose) {
      form.setFieldsValue({ ...data })
    }
    if (isClose) {
      setIsClose(false)
    }
    if (title === 'Edit Product' && props.type === 'PO' && poData) {
      setSkuData(poData.find(x => x.sku === data.sku))
    }
  }, [data])

  useEffect(() => {
    if (props.type === 'PO') {
      getPoProducts()
    } else {
      setPoData(true)
    }
  }, [])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setTitle(value)
      setData(data)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function getPoProducts() {
    ops_service.get_po_products(props.po_id).subscribe((r) => {
      let tempData = r.response.data.map(v => ({ ...v, name_sku: v.name + " - (" + v.sku + ")" }))
      setPoData(tempData)
    },
      (err) => {
        console.log(err)
      })
  }

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setSkuData(false)
  }

  const handleOnSubmit = (values) => {
    if (data?.pcs_qty_required) {
      if (!packedSize) {
        openNotificationWithIcon("error", "Pack Qty cannot be 0 or empty. Please Enter correct value of qty in pcs")
        return
      }
      values.packed_qty = packedSize
    }
    if (values.excess_qty < 0 || values.graded_quantity < 0 || values.packed_qty < 0) {
      openNotificationWithIcon("error", "Values cannot be less than 0")
      return
    }
    setLoading(true)
    modalSubmit(values, id, id2)
  }

  const handleSkuData = (e) => {
    if (props.type === 'PO') {
      setSkuData(poData.find(x => x.sku === e))
    }
  }

  const onChangePackSize = (value) => {
    let tempForm = form.getFieldValue()
    if (data.pcs_qty_required) {
      if (value > (totalValue + (totalValue / 2)) || value < (totalValue - (totalValue / 2))) {
        setThrowError(true)
      } else {
        setThrowError(false)
      }
    } else {
      if (tempForm.packed_qty > (totalValue + (totalValue / 2)) || tempForm.packed_qty < (totalValue - (totalValue / 2))) {
        setThrowError(true)
      } else {
        setThrowError(false)
      }
    }

    setPackedSize(value)
  }

  useEffect(() => {
    if (packedSize) {
      if (packedSize > (totalValue + (totalValue / 2)) || packedSize < (totalValue - (totalValue / 2))) {
        setThrowError(true)
      } else {
        setThrowError(false)
      }
    }
  }, [packedSize])

  const onChangePcs = (value) => {
    if (value % data?.unit_qty_in_pack == 0 && value !== 0) {
      setPackedSize(value / data?.unit_qty_in_pack)
      setThrowErrorMultiple(false)
    } else {
      setPackedSize(null)
      setThrowErrorMultiple("Please enter in multiples of " + data?.unit_qty_in_pack)
    }
  }

  const onChangeGradedQty = (value) => {
    // console.log("totalValue", totalValue)
    let tempForm = form.getFieldValue()

    // console.log("tempForm", tempForm.packed_qty)
    let temp = value / conversionFactor
    let total = ((temp) * 10 / 100)
    // console.log("temp curr", temp)
    if (data.pcs_qty_required) {
      if (value > (totalValue + (totalValue / 2)) || value < (totalValue - (totalValue / 2))) {
        setThrowError(true)
      } else {
        setThrowError(false)
      }
    } else {

      if (tempForm.packed_qty > (temp + (temp / 2)) || (tempForm.packed_qty < (temp - (temp / 2)))) {
        setThrowError(true)
      } else {
        setThrowError(false)
      }
    }

    // console.log("temp", temp)
    // console.log("total", total)
    setTotalValue(temp)
    setConversionValue(total)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading, disabled: data.unit_type ? throwError : false }}
          destroyOnClose={true}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {

              });
          }}
          onCancel={handleCancelModal}>
          {poData ?
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form2">

              {title === 'Edit Graded Qty' ?
                <>
                  <div className='text-center frz-bp-20 grn-text'>
                    Graded quantity and Packed quantity can be updated till
                    <div className='grn-timer'>{data.qty_time_remain}</div>
                  </div>
                  <FRZInputNumber name="graded_quantity" isRequired={true} isDisabled={!data?.qty_editable && title === 'Edit Graded Qty'}
                    label="Graded Qty" placeholder="Graded Qty" width={200} addAfter={true} addAfterValue={data.procurement_unit} setOnChange={onChangeGradedQty} />

                  <FRZInputNumber isDisabled={true} name="pack_size" isRequired={false}
                    label="Pack Size" placeholder="Pack Size" width={200} />

                  {data?.pcs_qty_required ?
                    <>
                      <FRZInputNumber name="qty_in_pcs" isRequired
                        label="Qty In Pcs" placeholder="Packed Qty" width={200} addAfter={true} addAfterValue={'pcs'}
                        setOnChange={onChangePcs} isDisabled={!data?.qty_editable} />
                      {throwErrorMultiple ?
                        <div className='frz-pos-rel'>
                          <div className='frz-error-grn'>{throwErrorMultiple}</div>
                        </div>
                        : null}
                      {console.log("packedSize in rentutn", packedSize)}
                      <FRZNamingBlock label={'Packed Qty'} value={packedSize} />
                    </>
                    :
                    <FRZInputNumber isThrowError={data.unit_type ? throwError : false} minvalue={data.unit_type ? form.getFieldValue().graded_quantity == 0 ? null : 1 : null} name="packed_qty" isRequired={data.unit_type}
                      label="Packed Qty" placeholder="Packed Qty" width={200} addAfter={true} addAfterValue={data.inventory_unit}
                      setOnChange={onChangePackSize} isDisabled={!data?.qty_editable} />
                  }

                  {/* {console.log("conversionValue0000", conversionValue)}
                  {console.log("totalValue", totalValue)} */}
                  {data.unit_type ?
                    <div className={((totalValue + conversionValue) < packedSize || (totalValue - conversionValue) > packedSize) ? "frz-pos-rel frz-bm-40" : ""} >
                      {((totalValue + conversionValue) < packedSize || (totalValue - conversionValue) > packedSize) ?
                        <div className="frz-warning-grn">
                          {/* (total + (total * 5 / 100) < formValue.formData[name].transfer_qty || formValue.formData[name].transfer_qty < total - (total * 5 / 100))) { */}
                          {/* {console.log("totalValue + conversionValue", totalValue + conversionValue)} */}
                          {(totalValue + conversionValue) > packedSize ?
                            "Packed qty is less than the expected packaging qty (please verify the data mentioned)" :
                            "Packed qty is more than the expected packaging qty (please verify the data mentioned)"
                          }
                        </div>
                        : null}
                    </div>
                    : null}
                  <FRZInputNumber name="excess_qty" isRequired={false} isDisabled={!data?.qty_editable || data.pcs_qty_required}
                    label="Excess Qty" placeholder="Excess Qty" width={200} addAfter={true} addAfterValue={data.inventory_unit} />
                </>
                :
                <>
                  {/* {console.log("object", props.products)} */}
                  <FRZSelect DropDownData={props.type === 'PO' ? poData : props.products} name="sku" isRequired={true}
                    label="SKU Name" placeholder="Select SKU Name" value="sku" option="name_sku"
                    isShowSearch={true} width={300} selectDisabled={title === 'Edit Product'}
                    breakOption={true} setOnChange={handleSkuData} />

                  {props.type === 'PO' && skuData ?
                    <>
                      <FRZNamingBlock label={'Forecast Qty'} value={skuData?.forecast_qty} />
                      <FRZNamingBlock label={'PO Qty'} value={skuData?.po_qty} />
                    </>
                    : null}

                  <FRZInputNumber name="basic_unit_price" isRequired={false}
                    label="Basic Unit Price" placeholder="Basic Unit Price" width={200} />

                  <Tooltip placement="bottom" title={'GRN done for item'} visible={!data?.po_qty_editable && title === 'Edit Product'}>
                    <FRZInputNumber name="qty" isRequired={true} isDisabled={!data?.po_qty_editable && title === 'Edit Product'}
                      label="Quantity" placeholder="Quantity" width={200} addAfter={true} addAfterValue={skuData ? skuData.procurement_unit : false} />
                  </Tooltip>
                </>
              }
            </Form>
            :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>
          }
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form2">
        </Form>
      }
    </div>
  )
})

export default AddProductModal
