import React, { useState, useEffect } from 'react'
import { Row, Col, Spin } from 'antd'
import SidePanel from '../../../components/operations/approvalsettings/sidepanel'
import DetailPanel from '../../../components/operations/approvalsettings/detailpanel'
import { OperationsService } from "../../../_services/operations";
import { openNotificationWithIcon } from '../../../_modules/notification';

const operation_service = new OperationsService();

const ApprovalSettings = () => {

  const [nodeData, setNodeData] = useState(false)
  const [nodeType, setNodeType] = useState('CC')
  const [activeItem, setActiveItem] = useState(false)
  const [listingData, setListingData] = useState(false)
  const [adminList, setAdminList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getNodeListing()
  }, [nodeType])

  useEffect(() => {
    if (activeItem) {
      getListingData()
      getAdminlist()
    }
  }, [activeItem])

  const getAdminlist = () => {
    operation_service.get_admin_list().subscribe((r) => {
      let temp = r.response.data
      temp.map((data) => {
        return Object.assign(data, { name: data.first_name + " " + data.last_name })
      })
      setAdminList(temp)
    }, (err) => {
      console.log("err", err)
    })
  }

  const getListingData = () => {
    setListingData(false)
    const payload = {
      nodeId: activeItem
    }
    operation_service.get_approval_setting(payload).subscribe((r) => {
      setListingData(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const updateApprovalSettings = (data, id) => {
    setLoading(true)
    const payload = {
      ...data,
      id: id
    }
    operation_service.update_approval_settings(payload).subscribe((r) => {
      setLoading(false)
      openNotificationWithIcon("success", "Settings updated")
      let tempdata = listingData
      tempdata.filter((itm) => itm.id === r.response.data.id).map((item) => {
        Object.assign(item, r.response.data)
      })
      setListingData([...tempdata])
      // console.log("listingdata", listingData)
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      console.log("err", err)
    }))
  }


  const handleNodeType = (value) => {
    setNodeType(value)
    setListingData(false)
  }

  const handleActiveItem = (value) => {
    setActiveItem(value)
  }

  const getNodeListing = () => {
    setNodeData(false)
    setActiveItem(false)
    const payload = {
      nodeType: nodeType
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodeData(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  return (
    <div>
      {/* {nodeData ?
        nodeData.length ? */}
      <Row>
        <Col span={6}>
          <SidePanel nodeData={nodeData} handleNodeType={handleNodeType} nodeType={nodeType}
            handleActiveItem={handleActiveItem} activeItem={activeItem} listingData={listingData} />
        </Col>
        {adminList && adminList.length ?
          <Col span={18}>
            <DetailPanel activeItem={activeItem} listingData={listingData} adminList={adminList}
              updateApprovalSettings={updateApprovalSettings} loading={loading} />
          </Col>
          : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
      </Row>
      {/* :
          <div className="no-data">No Data Available</div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>} */}
    </div>
  )
}

export default ApprovalSettings