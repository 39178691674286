export const formItemLayout = {
    labelCol: {
      xs: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 17 },
    },
  };

export const tailLayout = {
    wrapperCol: {
        span: 17,
    },
};