import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Table } from 'antd';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';

const ModalUpdateSTO = ({ updateStoModal, setUpdateStoModal, getSto }) => {
  const [form] = Form.useForm();

  const [updateType, setUpdateType] = useState("")
  const [bothUpload, setBothUpload] = useState(false)



  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['planning', 'planning_head', 'super_admin', 'admin', 'developer', 'developer_admin'].includes(data)) {
        setBothUpload(true)
        setUpdateType("expected_update")
      }
    })
  }, [])

  const handleStoFormat = (value) => {
    setUpdateType(value.target.value)
  }

  return (
    <Modal
      visible={updateStoModal}
      title=""
      okText="Create"
      okButtonProps={{ hidden: true }}
      width="50%"
      cancelText="Cancel"
      onCancel={() => setUpdateStoModal(false)}>
      <div className="frz-dis-flex frz-vt-center">
        <Radio.Group className="frz-dis-flex-imp frz-vt-center" value={updateType} onChange={handleStoFormat}>
          <Radio value={""}>Transfer QTY Update</Radio>
          <Radio disabled={!bothUpload} value={"expected_update"}>Expected QTY Update</Radio>
        </Radio.Group>

        <FrzDynamicUpload customName="Update" name={'STO'} url={'admin_dashboard/procurement/upload_sto_update_sheet?created_by='
          + `${JSON.parse(localStorage.getItem('auth')).id}` + "&update_type=" + `${updateType}`}
          uploadFunc={'upload_zapper_put'} callBack={getSto} />
      </div>
    </Modal>
  )
}

export default ModalUpdateSTO