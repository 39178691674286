import React from 'react';
import Login from '../components/login'

function loginRequired(Wrapped) {
   return function (props) {
      return (
        <Login>
          <Wrapped {...props} />
        </Login>
      )
   }
}

export default loginRequired
