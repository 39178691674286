import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Row, Col, Input, InputNumber, Spin, Radio } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import { WastageService } from '../../../_services/wastage';

const wastage_service = new WastageService()

const EditRtvWastageModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [disable, setDisable] = useState(true)
  const [noDataFound, setNoDataFound] = useState(false)
  const [formListData, setFormListData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {
    if ((data && formListData && title == "Edit RTV Wastage")) {
      form.setFieldsValue({ data: formListData })
    } else {
      setDisable(false)
      setFormListData(false)
      setNoDataFound(false)
    }
  }, [data, formListData])

  useEffect(() => {
    if (data && title == "Edit RTV Wastage") {
      getRTVWastageData(data.id)
    }
  }, [data])

  function handleOnSubmit(values) {
    const payload = values.data.map((e) => {
      return (
        {
          id: e.stock_loss_item_id,
          unit_price: e?.unit_price ?? ""
        }
      )
    })
    const finalPayload = {
      "stock_loss_id": data.id,
      "items": payload
    }
    setLoading(true)
    modalSubmit(finalPayload)
  }


  function getRTVWastageData(id) {
    setIsLoading(true)
    setDisable(true)
    const payload = { nodeId: id }
    wastage_service.get_wastage_product_list(payload).subscribe((r) => {
      setIsLoading(false)
      if (r.response.success = "success") {
        if (r.response.data.length > 0) {
          setFormListData(r.response.data)
          setNoDataFound(false)
        } else {
          setNoDataFound(true)
          setDisable(false)
        }
      }
    },
      (err) => {
        console.log(err)
        setIsLoading(false)
        setDisable(false)
      })
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>

          {!isLoading ?
            <div>
              {formListData &&
                <Form
                  form={form}
                  {...formItemLayout}
                  layout="horizontal"
                  name="data">
                  <>
                    <Row className="frz-mt-20">
                      <Col span={8}>
                        SKU
                      </Col>

                      <Col span={8}>
                        RTV Wastage Qty
                        </Col>

                      <Col span={8}>
                        Unit Price
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="frz-division"></div>
                      </Col>
                    </Row>

                    <Form.List name="data">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div key={key} style={{ marginBottom: 8 }}>
                              <Row>
                                <Col span={8}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    fieldKey={[fieldKey, 'name']}>
                                    <Input placeholder="First Name" style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>


                                <Col span={8}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'wastage_qty']}
                                    fieldKey={[fieldKey, 'wastage_qty']}>
                                    <InputNumber style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                <Col span={8}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unit_price']}
                                    fieldKey={[fieldKey, 'unit_price']}
                                    rules={[{ required: false }]}>
                                    <InputNumber placeholder="Unit Price" style={{ width: "100%" }} min={0} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </>
                </Form>}
            </div> :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>}
          {noDataFound && <div className="frz-fw-600 frz-font-24 frz-dis-flex frz-hrz-center">No data found</div>}
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default EditRtvWastageModal

