export const calculateTime = (expiryTimestamp) => {
  let remainingTime = new Date(expiryTimestamp).getTime() - new Date().getTime()
  var hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  return makeMeTwoDigits(hours) + ':' + makeMeTwoDigits(minutes) + ':' + makeMeTwoDigits(seconds)
}

function makeMeTwoDigits(n) {
  return (n < 10 ? "0" : "") + n;
}