import { Http } from '../_modules/http'

export class DownloadService {

  http = new Http()

  zapper_report(minurl, payload) {
    const url = process.env.REACT_APP_AWS_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  alloc_prod_report(minurl, payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stos/export_allocation"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  export_alloc_prod_report(minurl, payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stos/export_allocation_product_list"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  zapper_report_only_city(minurl, payload) {
    const url = process.env.REACT_APP_AWS_URL + minurl + '?by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  zapper_report_without_global_city(minurl, payload) {
    const url = process.env.REACT_APP_AWS_URL + minurl + payload
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  zapper_job_report(id) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/get_job_details?id=' + id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  sto_transfer_data_download(minurl, payload) {
    const url = process.env.REACT_APP_AWS_URL + minurl + payload
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  download_sto(minurl) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + minurl;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  zapper_report_v2(minurl, payload) {
    const url = process.env.REACT_APP_AWS3_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  fraazo_report_auth(minurl, payload) {
    const url = process.env.REACT_APP_AUTH_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  download_reports_job_fraazo(id){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/downloads/get_report_update?id=' + id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }


}
