import { DownloadOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Row ,Button, Space, Dropdown, Menu, Popconfirm, Popover} from "antd";
import React,{ useEffect, useState} from "react";
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";
const dummyHeaders=[
    {title:'STO #',width:1},
    {title:'Source',width:6},
    {title:'Destination',width:6},
    {title:'STO Movement Type',width:3},
    {title:'Exp.Delivery Date',width:2},
    {title:'Total SKU',width:2},
    {title:'Status',width:2},
    {title:'',width:2}
];
const colorWise={
  "Assigned":"#656565",
  "Material allocated":"#781DA4",
  "Shipment assigned":"brown",
  "ready_to_load":"#FF8A00",
  "Loading":"#D613AB",
  "Allocated":"#D613AB",
  "Ready to dispatch":"#439701",
  "Dispatched":"#1378D6",
  "Delivered":"#06CC1A",
  "Cancelled":"red"
}
const ops_service = new OperationsService()
const ManualTable =({currTab,data,setShowCreate,setScreen,setSto_id,setSpinEnable,getSto,setStoData})=>{
  const [show,setShow]=useState(false)
  const [id,setId]=useState(null)
  const [loading, setLoading] = useState(false)
  const [irnLoading, setIrnLoading] = useState(false)
  const [currentId, setCurrentId] = useState(false)  
  const [showAddProduct,setShowAddProduct]=useState(false);
  const [stoId,setStoId]=useState(false)
  // console.log('CurrTab',currTab)
  const handleShowAddProduct=(id)=>{
    setStoId(id)
    setShowAddProduct(true)
  }
  const handleOnRetry = (id, isIRN) => {
    setCurrentId(id)
    const payload = {
      id: id
    }
    if (!isIRN) {
      setLoading(true)
      ops_service.sto_retry_ewaybill(payload).subscribe((r) => {
        setLoading(false)
        setCurrentId(false)
        getSto()
      }, ((err) => {
        setLoading(false)
        setCurrentId(false)
        console.log("err", err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }))
    } else {
      setIrnLoading(true)
      ops_service.sto_irn_report(payload).subscribe((r) => {
        setIrnLoading(false)
        setCurrentId(false)
        getSto()
      }, ((err) => {
        setIrnLoading(false)
        setCurrentId(false)
        console.log("err", err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }))
    }
  }
  function cancelSTO(id) {
    const payload = {
      id: id
    }
    setSpinEnable(true)
    setStoData([])
    ops_service.cancel_sto(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'STO Cancelled Successfully')
      getSto()
    },
      (err) => {
        console.log(err)
        getSto()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }
  return(
        <>
        <div className="sto-headers">
            <Row>
                {dummyHeaders.map((item,i)=>{
                    return(<Col key={i} span={item?.width}>{item?.title}</Col>)
                })}
            </Row>
        </div>
        <div className="sto-data-cols">
                {data.map((item,i)=>{
                    return(
                            <Row key={i}>
                                <Col span={1}>{item?.order_number}</Col>
                                <Col span={6}>{item?.source_node}</Col>
                                <Col span={6}>{item?.destination_node}</Col>
                                <Col span={3}>{item?.category_sub_category}</Col>
                                <Col span={2}>{item?.expected_delivery_date}</Col>
                                <Col span={2}>
                                  <div className='frz-dis-flex frz-fd-c'>
                                  {item?.total_sku}
                                  {item?.status!=='Dispatched' && currTab==='sto_inward'?<a style={{textDecoration: 'underline'}} onClick={()=>{setShowCreate(true);setSto_id(item?.id);setScreen('ViewDetails')}}>View Details</a>:null}
                                  {currTab==='sto_outward'?<><a style={{textDecoration: 'underline'}} onClick={()=>{setShowCreate(true);setSto_id(item?.id);setScreen('ViewDetails')}}>View Details</a></>:null}
                                  </div>
                                  </Col>
                                <Col span={2} style={{color:colorWise[item?.status]}}>{item?.status}</Col>
                                <Col span={2}>{currTab==='sto_inward' ?<div className='start-inward-btn'><Button type='primary' disabled={item?.status!=='Delivered'?false:true} onClick={()=>{setShowCreate(true);setSto_id(item?.id);setScreen('inwardSTO')}}>Start Inward</Button></div>:currTab==='sto_outward'?
                                                                  <Dropdown placement='bottomRight' arrow  overlay={
                                                                    <Menu style={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
                                                                      <Button type="primary"  className="frz-m-10" disabled={item?.is_editable ? false :true}  onClick={()=>{setShowCreate(true);setScreen('EditSTO');setSto_id(item?.id)}} icon={<EditOutlined />} >Edit</Button>        
                                                                      {/* <Button type="primary" className="frz-m-10">Generate E-Way Bill</Button> */}
                                                                      {/* <Button type='primary' className='frz-m-10' onClick={()=>handleShowAddProduct(item?.id)} icon={<PlusCircleOutlined />} disabled={(item?.status==='Material allocated'||item?.status==='Assigned') ? false:true}>Add Product</Button> */}
                                                                      <Dropdown className='frz-m-10' placement='topRight' arrow overlay={
                                                                        <Menu>
                                                                          <Menu.Item>
                                                                      <span>
                                                                          {!item.bill_url ?
                                                                            item.bill_error_reason ?
                                                                              <Popover content={<div className="frz-m-15">
                                                                                <div className="frz-p-2">
                                                                                  {item.bill_error_reason == null ? " " : item.bill_error_reason}
                                                                                </div>
                                                                              </div>} trigger="hover">
                                                                                <Button loading={loading && currentId === item.id} type="primary" onClick={() => handleOnRetry(item.id)}>
                                                                                  Retry
                                                                                </Button>
                                                                              </Popover>
                                                                              :
                                                                              <Button
                                                                                disabled={item.status !== "ready_to_dispatch" &&
                                                                                  item.status !== "dispatched" &&
                                                                                  item.status !== "delivered"}
                                                                                loading={loading && currentId === item.id} type="primary" onClick={() => handleOnRetry(item.id)}>
                                                                                E-Way Bill
                                                                              </Button>
                                                                            : null
                                                                          }
                                                                      </span>
                                                                          </Menu.Item>
                                                                          <Menu.Item>
                                                                            <Button type="primary" disabled={item?.status !== "ready_to_dispatch" && item?.status !== "dispatched" && item?.status !== "delivered" && !item?.irn_invoice_url} loading={irnLoading && currentId === item?.id} onClick={() => handleOnRetry(item?.id, true)} >IRN</Button>
                                                                          </Menu.Item>
                                                                        </Menu>
                                                                      }>
                                                                      <Button type='primary'>Generate</Button>
                                                                      </Dropdown> 
                                                                                       
                                                                      <Dropdown className="frz-m-10" overlay={<Menu>
                                                                        <Menu.Item
                                                                          disabled={!item?.bill_url && (item?.status !== "ready_to_dispatch" ||
                                                                            item?.status !== "dispatched" || item?.status !== "delivered")}>
                                                                          <a target="_blank" rel="noopener noreferrer" href={item?.bill_url}>
                                                                            Eway bill
                                                                          </a>

                                                                        </Menu.Item>

                                                                        <Menu.Item disabled={!item?.irn_invoice_url}>
                                                                          <a target="_blank" rel="noopener noreferrer" href={item?.irn_invoice_url}>
                                                                            IRN
                                                                          </a>
                                                                        </Menu.Item>
                                                                        <Menu.Item disabled={!item?.tax_invoice_url}>
                                                                          <a target="_blank" rel="noopener noreferrer" href={item?.tax_invoice_url}>
                                                                            Tax Invoice
                                                                          </a>
                                                                        </Menu.Item>
                                                                      </Menu>} placement="topRight" arrow>
                                                                        <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
                                                                      </Dropdown>
                                                                      <Popconfirm
                                                                        title="Are you sure you want to cancel this sto?"
                                                                        onConfirm={() => cancelSTO(item?.id)}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        placement="rightTop"
                                                                        disabled={item?.status === "cancelled" || item?.status === "delivered"|| item?.source_node_type === 'DS'}
                                                                      >
                                                                        <Button type="primary" className='frz-m-10' ghost disabled={item?.status?.toLowerCase() === "cancelled" || item?.status?.toLowerCase() === "delivered" || item?.status?.toLowerCase() === "dispatched" || (item?.source_node_type === 'DS') }>
                                                                          Cancel
                                                                        </Button>
                                                                      </Popconfirm>
                                                                    </Menu>
                                                                  }>

                                                                    <span className='edit-delete-sto' >...</span>
                                                                  </Dropdown>
                                :null}</Col>
                            </Row>
                    )
                })}
        </div>
        {/* {showAddProduct?<ShowAddProductModal show={showAddProduct} hide={setShowAddProduct} id={stoId} getSto={getSto}/>:null} */}
        </>
    )
}
export default ManualTable