import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Select, Spin } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import moment from 'moment';
import FRZSelect from '../../../_controls/FRZSelect';
import { OperationsService } from '../../../_services/operations';
import { openNotificationWithIcon } from '../../../_modules/notification';
import { DropTarget } from 'react-dnd';
const ForcastListingModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)
  const [skuData, setSkuData] = useState(false)
  useEffect(() => {
    if ((data && title == "Edit")) {
      form.setFieldsValue({ ...data, delivery_date: data && moment(data.delivery_date), sku: data.name+"-"+data.sku })
      setId(data.id)
    } else {
      setId(false)
    }
  }, [data])

  function handleOnSubmit(values) {
      const payload = { ...values,delivery_date: moment(values.delivery_date).format("YYYY-MM-DD") }
      // modalSubmit(payload, id)
      console.log('payload',payload)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));
  const handleSkuData = (e) => {
    setSkuData(props.products.find(x => x.sku === e))
  }


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
    form.resetFields()
  }
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title === 'Edit' ? title + ' - ' + data.city_name : title + ' - ' + localStorage.getItem('city') ?? 'All Cities'}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={props.nodes} name="node_id" isRequired={true}
              label="Node Name" placeholder="Select Node Name" value="id" option="name"
              isShowSearch={true} width={"100%"} selectDisabled={title == "Edit"} />


            <FRZSelect DropDownData={props.products} name="sku" isRequired={true}
              setOnChange={handleSkuData}
              label="SKU Name" placeholder="Select SKU Name" value="sku" option="name_sku"
              isShowSearch={true} width={"100%"} selectDisabled={title == "Edit"}
              breakOption={true} />
              
            <FRZInputNumber name="qty" isRequired={true}
              label="Quantity" placeholder="Quantity" width={200}
              addAfter={true} addAfterValue={skuData.procurement_unit} />

            <FRZInputNumber name="forecast_qty" isRequired={true} isDisabled={title === 'Edit'}
              label="Forecast Qty" placeholder="Forecast Quantity" width={200}
              addAfter={true} addAfterValue={skuData.procurement_unit} />


            <FRZInputNumber name="target_price" isRequired={false}
              label="Target Price" placeholder="Target Price" width={200} />

            <FRZDatepicker placeholder="Select Delivery Date" label="Delivery Date" isRequired={true}
              name="delivery_date" isDisabled={title == "Edit"} />

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default ForcastListingModal
