import { useState } from "react"

let dummyData=[
    {title:"Draft",value:''},
    {title:"Material Allocated",value:''},
    {title:"Shipment Ready",value:''},
    {title:"Ready for Dispatch",value:''},
    {title:"Dispatched",value:''},
    {title:"Inward in progress",value:''},
    {title:"Delivered",value:''},
]
const colorWise={
        "assigned":"#656565",
        "material_allocated":"#781DA4",
        "shipment_assigned":"brown",
        "ready_to_load":"#FF8A00",
        "loading":"#D613AB",
        "allocated":"orange",
        "ready_to_dispatch":"#439701",
        "dispatched":"#1378D6",
        "delivered":"#06CC1A",
        "cancelled":"red"
    }
const StatusTab=({data})=>{
    const [status,setStatus]=useState(null)
    if(data){
        let temp=[]
        for (const [key, value] of Object.entries(data)){
            temp.push({'name':key ,'value':value})
        }
        dummyData=[...temp]
    }
    const convertPascal=(str)=>{
        let res=str?.charAt(0).toUpperCase() + str?.slice(1);
        return res;
    }
    return(
      <div className="frz-dis-flex frz-h-60 sto-status-tab">
          {dummyData.map((item,i)=>{
              return(
                  <div className="frz-dis-flex" key={i}>
                     <div className="frz-dis-flex frz-fd-c  frz-vertical-center">
                          <div className="frz-mb-5 frz-font-14" style={{color:colorWise[item.name]}}>{convertPascal(item?.name?.replaceAll('_'," "))}</div>
                          <div><b className="frz-font-18">{item.value}</b></div>
                     </div>
                      {i+1<=dummyData.length-1 ?<div className="vertical-line frz-lm-20"></div>:null}
                  </div>
              )
          })}
      </div>
    )
}
export default StatusTab