import { useEffect, useRef } from "react";
import * as d3 from "d3";
const BarChart = ({ chartData, type }) => {
  const svgRef = useRef(null);
  useEffect(() => {
    const width = 600;
    const height = 270;
    const margin = { left: 40, top: 30, right: 0, bottom: 50 };
    const getRatio = (side) => (margin[side] / width) * 100 + "%";

    const marginRatio = {
      left: getRatio("left"),
      top: getRatio("top"),
      right: getRatio("right"),
      bottom: getRatio("bottom")
    };
    const svg = d3
      .select(svgRef.current)
      .style(
        "padding",
        marginRatio.top +
          " " +
          marginRatio.right +
          " " +
          marginRatio.bottom +
          " " +
          "14%" +
          " "
      )
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr(
        "viewBox",
        "0 0 " +
          (width + margin.left + margin.right) +
          " " +
          (height + margin.top + margin.bottom)
      )
      .style("overflow", "visible");
    var xScale = d3.scaleBand().range([0, width]).padding(chartData.length > 30 ? 0.5 : 0.5),
      yScale = d3.scaleLinear().range([height, 0]);

    xScale.domain(
      chartData.map(function (d) {
        return d.count_range;
      })
    );
    yScale.domain([
      0,
      d3.max(chartData, (d) => d.count) === 0 ? 0 : d3.max(chartData, (d) => d.count) + 0.5
    ]);
    const indicesBetweenPoints = chartData.length > 12 ? Math.round(chartData.length / 12) : 1
    const xAxis = d3.axisBottom(xScale).tickValues(
      chartData.map((d, i) =>
          i % indicesBetweenPoints == 0 ? d.count_range : undefined
        )
        .filter(item => item) 
    )
    const yAxis = d3.axisLeft(yScale).ticks(4)
    // .tickSize(-width)
    .scale(yScale.nice())
    //ticks(chartData.length);

    // grid lines
    // gridlines in x axis function
    function make_x_gridlines() {
      return d3.axisBottom(xScale).ticks(chartData.length);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(yScale).ticks(4)
      // .tickSize(-width)
      .scale(yScale.nice())
      //.ticks(chartData.length);
    }
    // add the X gridlines
    svg
      .append("g")
      .attr("class", "grid")
      .style("color", "#F0F0F0")
      .attr("transform", "translate(0," + height + ")")
      .call(make_x_gridlines().tickSize(-height).tickFormat(""));

    // // add the Y gridlines
    svg
      .append("g")
      .attr("class", "grid")
      .style("color", "#F0F0F0")
      .call(make_y_gridlines().tickSize(-width).tickFormat(""));
    // svg.append("g").call(xAxis).attr("transform", `translate(0,${height})`);
    svg.append("g")
      // .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)
      .style("color", "#F0F0F0")
      .selectAll("text")  
          .style("font-size", '12px')
          .style("text-anchor", "end")
          .style("color", "#A3A3A3")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-20)" );

    svg.append("g").call(yAxis).style("color", "#F0F0F0")
    .selectAll("text")
    .style("color", "#A3A3A3");
    //barss

    svg
      .selectAll(".bar")
      .data(chartData)
      .enter()
      .append("rect")
      .attr("x", (v, i) => xScale(v.count_range))
      .attr("y", (v, i) => yScale(v.count))
      .attr("fill", "#F4AB3C")
      .attr("width", xScale.bandwidth())
      .attr("y", function (d) {
        return yScale(0);
      })
      .transition()
      .attr("y", function (d) {
        return yScale(d.count);
      })
      .attr("height", (val, i) => height - yScale(val.count))
      // .on('mouseover', onMouseOver)
      // .on('mouseout', onMouseOut)
    // text on bars
    svg
      .selectAll(".bar")
      .data(chartData)
      .enter()
      .append("text")
      .attr("class", "tooltip-cls")
      // .text(function (d, i) {
      //   return d.count;
      // })
      .attr("x", (v, i) => xScale(v.count_range))
      .attr("y", (v, i) => yScale(v.count))
      .attr("dy", "-1em")
      .attr("transform", "translate(22,-0)")
      .attr("font-family", "Roboto")
      .style("background-color", "#FFFFFF")
      .style("box-shadow", "0px 4px 4px rgba(165, 165, 165, 0.25)")
      .attr("font-size", "10px")
      .attr("margin", "50px")
      .attr("border", "1px solid black")
      .attr("text-anchor", "middle");

    let line = d3
      .line()
      .curve(d3.curveBumpX)
      .x((d, i) => xScale(d.count_range))
      .y((d, i) => yScale(d.count))

    function onMouseOver(event, data) {
      const xBar = parseFloat(event.x - xScale.bandwidth() - 10);
      const yBar = parseFloat(event.y + data?.count/2);

      const label = type === 'daily' ? 'Date' : type

      let xBarVal = xBar
      let yBarVal = yBar < 245 ? 245 : yBar > 490 ? 490 : yBar
      if (type === "week" && xBar > 1100) {
        xBarVal = 1000
      } else if ((type === 'daily' && xBar > 1200) || (type === 'month' && xBar > 1250)) {
        xBarVal = 1250
      } else {
        xBarVal = xBar
      }
      d3.select('#tooltip')
        .style('left', xBarVal + 'px')
        .style('top', yBarVal + 'px')
        .style('opacity', 0.7)
        .select('#count').html("<span>" + label + ": " + data?.count_range + "<br />Count: " + data?.count +"</span>")
    }

    function onMouseOut() {
      d3.select('#tooltip').style('opacity', 0)
    }

    svg
      .append("path")
      .datum(chartData)
      .attr("fill", "none")
      .attr("stroke", "#4CA89A")
      .attr("stroke-width", 1.8)
      // .attr("stroke-linejoin", "round")
      .attr("transform", "translate(" + xScale.bandwidth()/2 + "," + 0 + ")")
      .attr("d", line);

    //title of graph
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      // .text("Bar Graph");
    //dots

    //circle
    svg
      .append("g")
      .selectAll("dot")
      .data(chartData)
      .enter()
      .append("circle")
      .attr("class", "myCircle")
      .attr("cx", function (d, i) {
        return xScale(d.count_range);
      })
      .attr("cy", function (d) {
        return yScale(d.count);
      })
      .attr("r", 2)
      .attr("stroke", "#69b3a2")
      .attr("stroke-width", 0)
      .attr("fill", "#69b3a2")
      .attr("transform", "translate(" + xScale.bandwidth()/2 + "," + 0 + ")")
      .on('mouseover', onMouseOver)
      .on('mouseout', onMouseOut);

      
    // X label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + 60)
      .attr("text-anchor", "middle")
      .style("font-family", "Helvetica")
      .style("font-size", 12)
      // .text("X - AXIS");

    // Y label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", height / 2 - 90)
      .attr("y", 20)
      .attr("transform", "translate(-50," + height / 2 + ")rotate(-90)")
      .style("font-family", "Helvetica")
      .style("font-size", 12)
      // .text("Y - AXIS");

      svg.selectAll('rect')
        .on('mouseover', onMouseOver)
        .on('mouseout', onMouseOut)

  }, []);

  return (
    <>
      <div className="App">
        <div id="tooltip" className="tooltip">
            <span id="count" />
        </div>
        <svg ref={svgRef}></svg>
      </div>
    </>
  );
};
export default BarChart;
