import React from "react";
import {Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

function TableHeaders(props){
  return(
    <div className="wcl-listing-header-filter">
      <div className="wcl-40">Product 
        <Input placeholder="Search Product" prefix={<SearchOutlined />} className="frz-w-300px frz-lm-20"
               onChange={props.filterData}/></div>
      <div className="text-center wcl-15">Closing Stock</div>
      <div className="text-center wcl-15">Corrected Closing Stock</div>
      <div className="text-center wcl-15">Expected Closing Stock</div>
      <div className="text-center wcl-15">Approval Status</div>
    </div>
  )
}

export default TableHeaders