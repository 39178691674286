import React, { useState, useImperativeHandle, forwardRef } from 'react'
import styledmap from '../../../static/json/map.json';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Polygon } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '90%',
};

const MapTracking = forwardRef((props, ref) => {
  const { supplierMap } = props

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCmijT_PoZ6_j28v0Of37JWKu7ACqMolmY"
  })

  // const [map, setMap] = useState(null)

  const [state, setState] = useState({
    showInfoWindow: false,
    selectedPlace: {},
    hoverId: false,
    lastLat: undefined,
    lastLng: undefined,
    polygonData: undefined
  })
  const { showInfoWindow, selectedPlace, preRender, lastLat, lastLng, polygonData } = state

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: styledmap,
  }

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  useImperativeHandle(ref, () => ({
    onActiveSupplier
  }));

  const onMouseoverMarker = (data) => {
    if (data.latitude) {
      setState((prevState) => ({ ...prevState, showInfoWindow: true, selectedPlace: data, lastLat: data.latitude, lastLng: data.longitude }))
    } else {
      setState({ showInfoWindow: false, selectedPlace: {} })
    }
  }

  const onActiveSupplier = (data) => {
    onMouseoverMarker(data)
    if (data.fencing_data && data.fencing_data.length > 0) {
      setState((prevState) => ({ ...prevState, polygonData: data.fencing_data }))
    } else {
      setState({ polygonData: undefined })
    }
  }

  const windowHasClosed = () => {
    setState({ showInfoWindow: false, selectedPlace: {} })
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      styles={styledmap}
      center={{
        lat: lastLat ? parseFloat(lastLat) : 19.0948,
        lng: lastLng ? parseFloat(lastLng) : 74.7480
      }}
      zoom={polygonData ? 14 : 7}
      options={options}
    // onLoad={onLoad}
    // onUnmount={onUnmount}
    >
      {supplierMap.map((location) => (
        <>
          <Marker key={location.id} position={location}
            // icon='/static/images/marker.png'
            options={{
              size: (26, 26)
            }}
            onMouseOver={() => onMouseoverMarker(location)}
            position={{
              lat: parseFloat(location.latitude),
              lng: parseFloat(location.longitude)
            }} />
          {showInfoWindow ?
            <InfoWindow onCloseClick={windowHasClosed}
              position={{
                lat: parseFloat(selectedPlace.latitude),
                lng: parseFloat(selectedPlace.longitude)
              }}
              options={{
                pixelOffset: ({ width: 0, height: -20 })
              }}>
              <div>
                <div><b>Name: </b>{selectedPlace.name}</div>
                <div><b>Contact: </b>{selectedPlace.contact_number}</div>
                <div><b>Gender: </b>{selectedPlace.gender}</div>
                <div><b>Cultivation Land: </b>{selectedPlace.cultivation_land}</div>
                <div><b>Land Holding: </b>{selectedPlace.land_holding}</div>
              </div>
            </InfoWindow>
            : null}
          {polygonData ?
            <Polygon
              paths={polygonData}
            />
            : null
          }
        </>
      ))}
      <></>
    </GoogleMap>
  ) : <></>
})
export default MapTracking
