import React, {useState, useEffect, useRef} from 'react';
import {Table, Spin, Card, Icon, Tooltip, Button} from 'antd';
import {productColumns} from '../../antd/columns/operations/category';
import {OperationsService} from '../../../_services/operations';
import AddProductModal from './product_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import { EditOutlined } from '@ant-design/icons';

const ops_service = new OperationsService()

function NestedPoTable(props) {
  const modalRefProducts = useRef(null);
  const [nestedOrder, setNestedOrder] = useState(false)
  const [columns, setColumns] = useState([])
  const [operations, setOperations] = useState(false)
  const [fetchColm, setFetchColm] = useState(false) 

  useEffect(() => {
    getDetail()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','category','category_admin','cc_admin','procurement_admin','procurement'].includes(data)){
        setOperations(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if(fetchColm){
      setFetchColm(false)
      getColumns()
    }
  })

  function getDetail(){
    const payload = {
      id: props.data.id,
    }
    ops_service.get_po_line_items(payload).subscribe((r) => {
      let productsList = r.response.data.map(v => ({...v, is_editable: props.data.is_editable, po_id: props.data.id}))
      setNestedOrder(productsList)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    productColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
          return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_editable || !operations}
                         onClick={() => showModalProducts(record, 'Edit Product')}>
                 </Button>
      }
    })
    setColumns(columns)
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  function modalSubmitProduct(payloadData, id){
    const payload = {...payloadData, purchase_order_id: id}
    ops_service.po_products(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getDetail()
      modalRefProducts.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRefProducts.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  return(
    <div>
      {nestedOrder ?
        <div>
          <Button type="primary" className="frz-bm-20" onClick={() => showModalProducts(props.data, 'Add Product')}
                  disabled={!props.data.is_editable || !operations}>
            Add Products
          </Button>
          {nestedOrder.length > 0 ?
            <Card className="nested-table">
              <Table columns={columns} pagination={false} rowKey="id" dataSource={nestedOrder}/>
            </Card>
           :
            <div className="no-data-nested">No PO Products Found</div>
          }
          <AddProductModal modalSubmit={modalSubmitProduct} ref={modalRefProducts} type={'PO'} po_id={props.data.id}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default NestedPoTable;
