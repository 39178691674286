import React, { useState, useEffect } from "react";
import { Spin, Tabs } from 'antd';
import {WastageClosingService} from '../../../_services/wastage_closing';
import Wastage from './wastage';
import Closing from './closing';
import PartialClosing from './partial_closing';
import greentick from '../../../static/images/green-tick.svg';
import { openNotificationWithIcon } from "../../../_modules/notification";

const wc_service = new WastageClosingService()
const { TabPane } = Tabs;

function WCDashboard(props){
  const [apiError, setApiError] = useState(false)
  const [jobLoading, setJobLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [checkDone, setCheckDone] = useState(false)

  useEffect(() => {
    getDashboardData()
  },[props.currentNode])

  useEffect(() => {
    getPastWCLStatus()
    !props.currentDate && props.currentTab === 'closing' && props.setCurrentTab('wastage')
  },[props.currentDate])

  function getPastWCLStatus(){
    wc_service.get_check_done(props.currentNode, props.currentDate ?? '').subscribe((r) => {
      setCheckDone(r.response.data)
    },
    (err) => {
      console.log(err)
      setApiError(true)
    })
  }

  function getDashboardData(){
    props.setDashboardData(false)
    wc_service.get_dashboard_data(props.currentNode).subscribe((r) => {
      props.setDashboardData(r.response.data)
    },
    (err) => {
      console.log(err)
      setApiError(true)
    })
  }

  function getJobDownloads(url, loadingType){
    if(loadingType === 'csv_wastage_sheet' || loadingType === 'csv_closing_sheet'){
      setExportLoading(loadingType)
    }else{
      setJobLoading(loadingType)
    }
    wc_service.get_base_download(url).subscribe((r) => {
      var myVar = setInterval(() => {
        wc_service.get_job_download(r.response.data).subscribe(res => {
            if(res.response.response.status === 'success'){
                clearInterval(myVar)
                window.open(res.response.response.url, '_blank')
                openNotificationWithIcon('success','Downloaded Successfully')
                if(loadingType === 'csv_wastage_sheet' || loadingType === 'csv_closing_sheet'){
                  setExportLoading(false)
                }else{
                  setJobLoading(false)
                }
            }
          }, (err) => {
              if(loadingType === 'csv_wastage_sheet' || loadingType === 'csv_closing_sheet'){
                setExportLoading(false)
              }else{
                setJobLoading(false)
              }
              clearInterval(myVar)
              openNotificationWithIcon('error','Download Failed')
          })
      }, 6000);
    }, (err) => {
        if(loadingType === 'csv_wastage_sheet' || loadingType === 'csv_closing_sheet'){
          setExportLoading(false)
        }else{
          setJobLoading(false)
        }
        openNotificationWithIcon('error','Download Failed')
    })
  }

  function sendEmail(url, type){
    setEmailLoading(type)
    wc_service.send_email(url).subscribe(res => {
      setEmailLoading(false)
      openNotificationWithIcon('success','Email Sent Successfully')
    },
    (err) => {
      setEmailLoading(false)
      openNotificationWithIcon('error','API Error')
    })
  }

  return(
    <div>
      {props.dashboardData ? 
        <div className="wc-main-container">
          <Tabs type="card" className="wc-main-tabs" onChange={(e) =>  {props.setCurrentTab(e)}}
            activeKey={props.currentTab}>
            <TabPane key="wastage"
              tab={<span>
                {props.dashboardData.wastage.current_transaction_count === props.dashboardData.wastage.transaction_limit || checkDone?.wastage ? 
                  <div className="wcl-align-center wcl-tab-green">
                    <img src={greentick} className="frz-rm-8" width={15} height={15}/> Wastage - Completed
                  </div>
                : 'Wastage'}
              </span>}>
              <Wastage data={props.dashboardData.wastage} wc_service={wc_service} currentNode={props.currentNode} 
                       currentDate={props.currentDate} setWCScreen={props.setWCScreen} emailLoading={emailLoading}
                       jobLoading={jobLoading} getJobDownloads={getJobDownloads} sendEmail={sendEmail}
                       exportLoading={exportLoading} reasons={props.reasons}
                       />
            </TabPane>
            <TabPane key="closing" 
              disabled={props.dashboardData.wastage.current_transaction_count !== props.dashboardData.wastage.transaction_limit && !props.currentDate}
              tab={<span>
                {props.dashboardData.closing.current_transaction_count === props.dashboardData.closing.transaction_limit ||  checkDone?.closing ? 
                  <div className="wcl-align-center wcl-tab-green">
                    <img src={greentick} className="frz-rm-8" width={15} height={15}/> Closing - Completed
                  </div>
                : 'Closing'}
              </span>}>
              <Closing data={props.dashboardData.closing} wc_service={wc_service} currentNode={props.currentNode} 
                       currentDate={props.currentDate} setWCScreen={props.setWCScreen} emailLoading={emailLoading}
                       jobLoading={jobLoading} getJobDownloads={getJobDownloads} sendEmail={sendEmail}
                       exportLoading={exportLoading} allowCratesClosing={props.dashboardData.crates_closing_created}
                       allowPackagingClosing={props.dashboardData.packaging_closing_created}
                       setCurrentMainTab={props.setCurrentMainTab} currentMainTab={props.currentMainTab}/>
            </TabPane>
            <TabPane key="inventory_correction" tab={'Inventory Correction'}
              disabled={!props.allowSystemAccess}>
              <PartialClosing data={props.dashboardData.partial_closing} wc_service={wc_service} currentNode={props.currentNode} 
                       currentDate={props.currentDate} setWCScreen={props.setWCScreen} emailLoading={emailLoading}
                       jobLoading={jobLoading} getJobDownloads={getJobDownloads} sendEmail={sendEmail}
                       exportLoading={exportLoading} currentProcType={props.currentProcType}/>
            </TabPane>
          </Tabs>
        </div>
      : 
        (apiError ? 
          <div className="horizontal-vertical-center frz-font-20">
            <b>Backend API System Down</b>
          </div>
        :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        )
      }
    </div>
  )
}

export default WCDashboard