import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Spin, Select, Empty } from 'antd';
import { openNotificationWithIcon } from '../../../../_modules/notification';
import { OperationsService } from '../../../../_services/operations';
import { CheckOutlined } from '@ant-design/icons';

const {Option}=Select;
const ops_service = new OperationsService()

let timer;

const AddSkuModal = ({type,crateData,setCrateData,show,hide,node,handleAddSku,selectedData,setSelectedData}) => {
  const [spinEnable, setSpinEnable]=useState(false);
  const [uploadedData,setUploadedData]=useState(type==='crate'?[...crateData]:[...selectedData]);
  const [selectedSKU,setSelectedSKU]=useState({});
  const [dropDownData,setDropDownData]=useState([])
  const handleOk = () => {
    hide(false);
  };
  // console.log('crateData',crateData);
  useEffect(()=>{
    if(type==='crate'){
      setSelectedData([])
      const payload={
                node_id:node
            }
            ops_service.get_crate_list(payload).subscribe((r) => {
                setDropDownData(r.response.data)
                setSpinEnable(false)
            },
            (err) => {
            console.log(err)
            setSpinEnable(false)
            })
    } else{
      setCrateData([])
    }
  },[])
  const handleCancel = () => {
    setSelectedData([...uploadedData])
    setCrateData([...uploadedData])
    hide(false);
  };

  const handleRemove=(sku)=>{
   if(type==='sku') 
   {
      let temp=[...selectedData];
      let filteredData=temp.filter((item)=>{
        return item.sku!==sku;
      })
      setSelectedData(filteredData)
    }else{
      let temp=[...crateData];
      let filteredData=temp.filter((item)=>{
        return item.sku!==sku;
      })
      setCrateData(filteredData)
    }
  }
  const debounce = (cb, d) => {
    //let timer;
    return function (...args) {
      setSpinEnable(true)
      setDropDownData([])
        clearInterval(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, d);
    };
  };
  const handleChange=debounce((e)=>{
      let value=e;
      if(value.length>=3){
        const payload = { id: node ,by_name:value,procurement_type:localStorage.getItem('procurementtype')}
        ops_service.get_sto_skus(payload).subscribe((r) => {
        setSpinEnable(false)
        setDropDownData(r.response.data)
      },
        (err) => {
          setSpinEnable(false)
          console.log(err)
        }) 
      }
  },1000)
  const getUnique=(data)=>{
    let distinct={};
    let unique=[]
    for(let i=0;i<data.length;i++){
      if(!distinct[data[i].sku]){
        unique.push(data[i])
        distinct[data[i].sku]=1
      }
    }
    return unique;
  }
  const checkedExisted=(sku)=>{
    let flag=false;
    for(let i=0;i<selectedData?.length;i++){
      if(selectedData[i].sku===sku){
        flag=true;
      }
    }
    return flag;
  }
  const handleSelection=(a,b)=>{
    // console.log(a,b)
    let temp;
    if(crateData || selectedData){
      temp=type==='sku'?[...selectedData]:[...crateData]
    }
      if(type==='sku'){
        setSelectedData(getUnique([...temp,b]))
      } else{
        setCrateData(getUnique([...temp,b]))
      }
    // }
  }

  return (
    <><div className='sku-modal-container'>
      <Modal className='add-sku-modal-container' title="Products"   visible={show} onOk={handleOk} onCancel={handleCancel}>
        <div>
          <div className="frz-dis-flex sku-modal-input">
            <div className='search-bar-sku'>
               <Select placeholder={type==='crate'?'Please Select Crate':'Please Search Sku'} showSearch 
                          showArrow={false}  filterOption={false} className="add-sku-container"
                          onSearch={type==='sku'?handleChange:''} onChange={(e, f) => handleSelection(e, f)}
                          notFoundContent={null}>
                          {dropDownData?.length>0 ? dropDownData?.map(data => {
                            return <Option expected_transfer_qty={null} disabled={checkedExisted(data.sku)} value={data.id} error="true" key={data.id} sku={data.sku} inventory_unit={data.inventory_unit} image_url={data.image_url} name={data.name} available_qty={data.available_qty} product_current_stock={data.product_current_stock}>
                              <div className="frz-dis-flex frz-justify-sb">
                                <div><img src={data.image_url} alt={data.image_url} width='30px' heigth='30px'/>  {data.name} ({data.sku})</div> 
                                {checkedExisted(data.sku)?<div><CheckOutlined style={{color:'black',fontWeight:'700',fontSize:'20px'}} /></div>:<div></div>}
                                </div>
                              </Option>
                          }):(spinEnable ?<div className="spin-center add-sku-loader"><Spin tip="...Loading" /></div>:null)}
                        </Select>
            </div>
            {selectedData?.length ?<div className="frz-dis-flex sku-selection-container">
               <div className='selection-count'>{selectedData?.length < 9 ? `0${selectedData?.length}`:selectedData?.length}</div>
               <div>Selected</div>
            </div>:null}
            {crateData?.length ?<div className="frz-dis-flex sku-selection-container">
               <div className='selection-count'>{crateData.length < 9 ? `0${crateData?.length}`:crateData?.length}</div>
               <div>Selected</div>
            </div>:null}
          </div>
          <div className='sku-modal-data-container'>
          {(type==='sku' ? selectedData?.length:crateData?.length) ? 
          (type==='sku' ?selectedData:crateData).map((data,i)=>(
              <div className='sku-data-row'  key={i}>
                <div> <img src={data?.image_url} alt={data?.image_url} width='30px' heigth='30px'/>  {data.name} ({data.sku}) </div>
                <div><Button type='danger' onClick={()=>handleRemove(data.sku)}>Remove</Button></div>
              </div>
          )):
          (spinEnable ? null:<p className="frz-fw-700 frz-font-22 frz-ml-25p frz-mt-15p">Please add SKU / Crate by searching</p>)}
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default AddSkuModal;