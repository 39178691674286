import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect} from 'react'
import { Modal, Table, Spin, Button } from 'antd';
import { CrateService } from '../../../_services/crate';
import { crateTypeColumns } from '../../antd/columns/operations/crate';
import AddCrateType from './add_crate_type';
import { EditOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';

const crate_service = new CrateService()

const ManageCrateType = forwardRef((props, ref) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [listingData, setListingData] = useState(false)

  useEffect(() => {
    crateTypeColumns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />}
                       onClick={(e) => addCrateType('Edit Crate Type', record)}>
               </Button>
      }
    })
  }, [])

  useImperativeHandle(ref, () => ({

    showModal() {
      setVisible(true)
      setData(true)
      getListing()
    },

  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
  }

  function getListing(){
    crate_service.get_crate_types().subscribe((r) => {
      setListingData(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function addCrateType(title, data){
    modalRef.current.showModal(title, data)
  }

  function modalSubmit(values, id){
    const payload = {
      id: id,
      body: values
    }
    if(id){
      crate_service.edit_crate_type(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        modalRef.current.handleCancel()
        listingData[listingData.findIndex(x => x.id === r.response.data.id)] = r.response.data
        setListingData([...listingData])
      },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
    }else{
      crate_service.add_crate_type(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        modalRef.current.handleCancel()
        listingData.push(r.response.data)
        setListingData([...listingData])
      },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
    }
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title="Manage Crate Type"
          onCancel={handleCancelModal}
          width='85%'
          className="order fl-right"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}>
          <div className="order-modal-container">
            {listingData ?
              <div>
                <div className="frz-add-crate">
                  <div className="frz-crate-btn-grey" onClick={() => addCrateType('Add New Crates', true)}>
                    Add New Crate Type
                  </div>
                </div>
                {listingData.length > 0 ? 
                <div>
                  <Table dataSource={listingData} columns={crateTypeColumns} 
                    pagination={false} scroll={{ y: 'calc(100vh - 212px)' }}
                    rowKey={'id'}/>
                </div>
                :
                <div className="no-data">No Data Available</div>}
                <AddCrateType modalSubmit={modalSubmit} ref={modalRef} crateProducts={props.crateProducts}/>
              </div>
            :
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            }
          </div>
        </Modal>
      : null}
    </div>
  )
})

export default ManageCrateType

