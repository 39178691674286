import React from 'react';
import { render } from 'react-dom';
import './static/index.css';
import { createStore } from 'redux';
import rootReducer from './_reducers';
import Root from './root';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
    light: `${window.location.origin}/style/index-default.css`,
    dark: `${window.location.origin}/style/index-dark.css`,
};

const store = createStore(rootReducer)
let preferredTheme = localStorage.getItem('themeChoice') === null ? "light" : localStorage.getItem('themeChoice');

render(
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={preferredTheme}>
        <Root store={store} />
    </ThemeSwitcherProvider>, document.getElementById('root'))
 