import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Spin, Pagination, Table, Button, Divider, Popover, Image } from 'antd';
import { CrateService } from '../../../_services/crate';
import { crateColumns } from '../../../components/antd/columns/operations/crate';
import ManageCrateType from '../../../components/operations/crate/manage_crate_type';
import AddNewCrate from '../../../components/operations/crate/add_new_crate';
import { OperationsService } from "../../../_services/operations";
import { openNotificationWithIcon } from '../../../_modules/notification';
import ModalTransitDetail from "../../../components/operations/crate/modaltransitdetail";
import ModalCrateDetail from "../../../components/operations/crate/modalcratedetail";
import FrzDynamicDownload from "../../../_controls/FRZDynamicDownload";
import CrateMgmtModal from "../../../components/operations/crate/crate_mgmt_modal";

const crate_service = new CrateService()
const operation_service = new OperationsService();
const roles=JSON.parse(localStorage.getItem('roles'));
function CrateManagement() {
  const modalRef = useRef(null);
  const modalRefCrate = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [disable, setDisable] = useState(true)
  const [total, setTotal] = useState(false)
  const [spinEnable, setSpinEnable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [activeSet, setActiveSet] = useState('DS')
  const [column, setColumn] = useState(false);
  const [nodes, setNodes] = useState(false)
  const [crateProducts, setCrateProducts] = useState(false)
  const [tranistDetailModal, setTranistDetailModal] = useState(false);
  const [crateDetailModal, setCrateDetailModal] = useState(false);
  const [nodeCode, setNodeCode] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [manageCrateTypeAccess, setManageCrateTypeAccess] = useState(false);
  const [createCrateAccess, setCreateCrateAccess] = useState(false);
  const [showCrateModal,setShowCrateModal]=useState(false)
  const [accessControl,setAccessControl] = useState(false)
  const [dashboardData,setDashboardData]=useState(false)
  useEffect(() => {
    if (spinEnable) {
      getListing()
      getColumns()
    }
  }, [spinEnable])

  useEffect(() => {
    getNodes()
    getCrateProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['super_admin', 'admin', 'cc_admin', 'developer', 'procurement_admin'].includes(data)) {
        setCreateCrateAccess(true)
      }
    })

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'category', 'category_admin'].includes(data)) {
        setManageCrateTypeAccess(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer', 'super_admin'].includes(data)) {
        setAccessControl(true)
      }
    })
  }, [])

  const handleTransitModal = (value, nodecode, rowdata) => {
    if (!value) {
      setNodeCode(false)
      setRowData(false)
      setTranistDetailModal(value)
    } else {
      setRowData(rowdata)
      setTranistDetailModal(value)
      setNodeCode(nodecode)
    }
  }

  const handleCrateModal = (value, nodecode, rowdata) => {
    if (!value) {
      setNodeCode(false)
      setRowData(false)
      setCrateDetailModal(value)
    } else {
      setRowData(rowdata)
      setCrateDetailModal(value)
      setNodeCode(nodecode)
    }
  }
  const getColumns = () => {
    let tempCol = []
    crateColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {/* <Button style={{ marginBottom: 8 }} onClick={() => handleTransitModal(true, record.node_code, record)}>
              View Transit Details
            </Button> */}

            {/* <Button onClick={() => handleCrateModal(true, record.node_code, record)}>
              View Crate Details
            </Button> */}
          </div>
        )
      }
    })
    setColumn(tempCol)
  }

  function getListing() {
    const payload = {
      page: current,
      node_type: activeSet
    }
    crate_service.get_crate_listing(payload).subscribe((r) => {
      setSpinEnable(false)
      setTotal(r.response.meta.total_pages)
      setListingData(r.response.data)
      setDashboardData(r.response.dashboard_data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getNodes() {
    const payload = {
      nodeType: `["CC","DS"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCrateProducts() {
    crate_service.get_crate_products().subscribe((r) => {
      setCrateProducts(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function pagination(page) {
    setCurrent(page)
    updateActiveSet(activeSet)
  }

  function updateActiveSet(type) {
    setActiveSet(type)
    setSpinEnable(true)
    setListingData([])
  }
  function showCrate(val) {
    if (val === 'type') {
      modalRef.current.showModal()
    } else {
      modalRefCrate.current.showModal()
    }
  }

  function modalSubmit(payload) {
    crate_service.add_new_crate(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      modalRefCrate.current.handleCancel()
      pagination(1)
    },
      (err) => {
        console.log(err)
        modalRefCrate.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  const handleManageCrate=()=>{
    setShowCrateModal(true)
  }

  const content = (data, parentType) => {
    return (
      <div className="frz-p-8">
        {data?.map((item, index) => {
          return (
            <Row key={index} gutter={[15, 15]}>
              <Col span={10}>
                <Image src={item.crate_image} className="frz-img-sm frz-img-boder" />
              </Col>
              <Col span={14}>
                <div className="frz-label frz-font-13">
                  {item.crate_name}
                </div>
                <div>
                  {item[parentType]}
                </div>
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }

  return (
    <div className="layer">
      {listingData && nodes && crateProducts ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <div className="frz-dis-flex frz-justify-sb">
                  <div className="frz-dis-flex frz-m-10">
                    <div className={`${"frz-crate-tab"} ${spinEnable ? "frz-disable-pointer frz-crate-disable" : ""} ${activeSet === 'DS' ? "crate-active" : ''}`}
                      onClick={() => spinEnable ? "" : updateActiveSet('DS')}>
                      Darkstores
                    </div>
                    <div className={`${"frz-crate-tab"} ${spinEnable ? "frz-disable-pointer frz-crate-disable" : ""} ${activeSet === 'CC' ? "crate-active" : ''}`}
                      onClick={() => spinEnable ? "" : updateActiveSet('CC')}>
                      Collection Center
                    </div>
                    <div className={`${"frz-crate-tab"} ${spinEnable ? "frz-disable-pointer frz-crate-disable" : ""} ${activeSet === 'WH' ? "crate-active" : ''}`}
                      onClick={() => spinEnable ? "" : updateActiveSet('WH')}>
                      Warehouse
                    </div>
                  </div>
                  <div className="frz-dis-flex  crte-mgmnt-btn">
                    {/* <Button className='frz-rm-5' type='primary'>Download Report</Button> */}
                    <FrzDynamicDownload name={'Crate Report'} fileName={'Crate Report'}
                    url={'crate_dashboard/crate_report?'}
                    payload={''}
                    type={'job'}
                    reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                    <Button className='frz-m-10' disabled={!accessControl} onClick={handleManageCrate} type='primary'>Manage Crates</Button>
                  </div>
                  {/* <div className="frz-dis-flex frz-justify-end">
                    {manageCrateTypeAccess ?
                      <div className="frz-dis-flex frz-m-10">
                        <div className="frz-crate-btn-grey" onClick={() => showCrate('type')}>
                          Manage Crate Type
                        </div>
                      </div> : null}

                    {createCrateAccess ?
                      <div className="frz-dis-flex frz-vt-center">
                        <FrzDynamicUpload name={'Bulk Crate'} url={'crate_dashboard/upload_crates_sheet'}
                          uploadFunc={'upload_zapper_post'} callBack={getListing} />
                      </div>
                      : null}
                    <FrzDynamicDownload name={'Crate Report'} fileName={'Crate'} url={'admin_dashboard/procurement/crates_report'}
                      type={'job'} reportFunc={'zapper_report_only_city'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  </div> */}
                </div>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card className='crte-main-container' style={{marginTop:'10px'}}>
              <Col span={24}>
                <div className="frz-dis-flex frz-justify-sb ">
                    <div className='frz-dis-flex frz-m-10 frz-tm-5'>
                      {
                        dashboardData?Object.keys(dashboardData[activeSet]).map((filter,i)=>{
                          return(
                            <div key={i} className='frz-dis-flex'>
                              <Popover content={content(dashboardData[activeSet][filter]?.types, filter.toString())} placement="bottom">
                                <div className='frz-dis-flex frz-justify-center frz-align-center frz-fd-c frz-fw-500'>
                                  <div className={'frz-text-transform-capitalize'+" " + `${activeSet}`+'-color'}>{activeSet + ' ' + filter.toString().replaceAll('_'," ")}</div>
                                  <div>{dashboardData[activeSet][filter].count}</div>
                                </div>
                              </Popover>
                              {i<2?<div className='divider-box'>
                                <Divider type='vertical'/>
                              </div>:null}
                            </div>
                          )
                        }):null
                      }
                    </div>
                    <div className='frz-dis-flex'>
                    {
                        dashboardData?Object.keys(dashboardData['total_data']).map((filter,i)=>{
                          return(
                            <div key={i} className='frz-dis-flex'>
                              <Popover content={content(dashboardData[activeSet][filter]?.types, filter.toString())} placement="bottom">
                                <div className='frz-dis-flex frz-justify-center frz-align-center frz-fd-c frz-fw-500'>
                                  <div className={'frz-text-transform-capitalize'+" " + `${'total-data'}`+'-color'}>{'Total ' + filter.toString().replaceAll('_'," ")}</div>
                                  <div>{dashboardData['total_data'][filter].count}</div>
                                </div>
                              </Popover>
                              {i<2?<div className='divider-box'>
                                <Divider type='vertical'/>
                              </div>:null}
                            </div>
                          )
                        }):null
                      }
                    </div>
                </div>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card className="frz-card-top-11">
                  <Table dataSource={listingData} columns={column}
                    pagination={false} scroll={{ y: 'calc(100vh - 274px)' }}
                    rowKey={'node_code'} />
                </Card>
                <Pagination className="frz-tm-10" current={current}
                  pageSize={20} total={total*20} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ManageCrateType ref={modalRef} crateProducts={crateProducts} />
          <AddNewCrate ref={modalRefCrate} nodes={nodes} modalSubmit={modalSubmit} />
          {tranistDetailModal ?
            <ModalTransitDetail tranistDetailModal={tranistDetailModal} handleTransitModal={handleTransitModal}
              nodeCode={nodeCode} rowData={rowData} />
            : null}
          {crateDetailModal ?
            <ModalCrateDetail crateDetailModal={crateDetailModal} handleCrateModal={handleCrateModal}
              nodeCode={nodeCode} rowData={rowData} />
            : null}
            {showCrateModal?<CrateMgmtModal hide={setShowCrateModal} show={showCrateModal}/>:null}
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default CrateManagement