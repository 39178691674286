import React from "react";
import { Card, DatePicker } from 'antd';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import moment from "moment";

function MainTab(props){

  function onChangeDate(date, dateString) {
    props.setCurrentDate(dateString)
  }

  function disabledDate(current) {
    // Can select days last 2 months
    return current && (current < moment().subtract(61, "day") || current > moment().add(-1, "day"));
  }
    
  return(
    <Card>
      <FrzDynamicSearch DropDownData={props.nodes}
        placeholder="Select Nodes" value="code" option="name"
        isShowSearch={true} width={200} setOnChange={(e) => props.setCurrentNode(e)}
        type="valueByNode" margin={10} defValue={props.currentNode}/>
      {props.currentNode ? 
        <DatePicker placeholder="Today" className="frz-w-150 frz-lm-10"
          onChange={onChangeDate} disabledDate={disabledDate} />
      : null}
    </Card>
  )
}

export default MainTab