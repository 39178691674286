import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Input } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const RequestApprovalModal = forwardRef((props, ref) => {

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOnSubmit(values) {
    setLoading(true)
    props.modalSubmit(values)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Request"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            layout="vertical"
            name="form">

            <Form.Item
              label="State your reason for delay"
              name="comment"
              rules={[{ required: true, message: 'Please state your reason for delay!' }]}
            >
              <TextArea rows={4} placeholder='Reason'/>
            </Form.Item>

          </Form>
        </Modal>
        : 
        <Form
          form={form}
          layout="vertical"
          name="form">
        </Form>}
    </div>
  )
})

export default RequestApprovalModal
