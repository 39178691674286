import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Select } from 'antd';

const { Option } = Select;

const AddSkuModal = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({sku_id: props.selectedSkus})
  },[data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

  }));

  function handleOnSubmit(values) {
    props.modalSubmit(values.sku_id)
    handleCancelModal()
  }

  function handleCancelModal() {
    setVisible(false)
    setData(false)
    form.resetFields()
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Add SKU"
          width={'40%'}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            layout="vertical"
            name="form">

            <Form.Item
              label="Add SKU's"
              name="sku_id"
              rules={[{ required: true, message: "Please add SKU's" }]}
            >
              <Select mode="multiple" placeholder="Select SKU's"
                showSearch optionFilterProp="children" className='add-sku-select'
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {data.map((val, index) => {
                  return(
                    <Option key={index} value={val.id} name={val.name}>
                      <img src={val.image_url} width={30} height={30} className='select-dropdown-img'/>{val.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

          </Form>
        </Modal>
        : 
        <Form
          form={form}
          layout="vertical"
          name="form">
        </Form>}
    </div>
  )
})

export default AddSkuModal
