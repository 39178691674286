import { Http } from '../../_modules/http'

export class FulfilmentService {

  http = new Http()

  get_dashboard_stats(payload){
    const url = process.env.REACT_APP_AWS3_URL +
      '/v2/stats_dashboard/fulfilment/dashboard_data?by_delivery_start_date=' + payload.by_delivery_start_date 
      + "&by_delivery_end_date=" + payload.by_delivery_end_date
      + "&by_product="+payload.by_product
      + "&by_category_code="+ payload.by_category_code
      + "&by_sub_category_code=" + payload.by_sub_category_code
      + '&by_node=' + `[${payload.by_node}]`
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
      let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_fulfilment_value(payload){
    const url = process.env.REACT_APP_AWS3_URL +
      '/v2/stats_dashboard/fulfilment/fulfilment_value?by_delivery_start_date=' + payload.by_delivery_start_date 
      + "&by_delivery_end_date=" + payload.by_delivery_end_date
      + "&by_product="+payload.by_product
      + "&by_category_code="+ payload.by_category_code
      + "&by_sub_category_code=" + payload.by_sub_category_code
      + '&by_node=' + `[${payload.by_node}]`
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&range_type=' + payload.range_type
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
      let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_rejections(payload){
    const url = process.env.REACT_APP_AWS3_URL +
      '/v2/stats_dashboard/fulfilment/rejections_data?by_delivery_start_date=' + payload.by_delivery_start_date 
      + "&by_delivery_end_date=" + payload.by_delivery_end_date
      + "&by_product="+payload.by_product
      + "&by_category_code="+ payload.by_category_code
      + "&by_sub_category_code=" + payload.by_sub_category_code
      + '&by_node=' + `[${payload.by_node}]`
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&range_type=' + payload.range_type
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
      let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_order_fulfilment(payload) {
    const url = process.env.REACT_APP_AWS3_URL +
    '/v2/stats_dashboard/fulfilment/order_fulfilment_rate?by_delivery_start_date=' + payload.by_delivery_start_date 
    + "&by_delivery_end_date=" + payload.by_delivery_end_date
    + "&by_product="+payload.by_product
    + "&by_category_code="+ payload.by_category_code
    + "&by_sub_category_code=" + payload.by_sub_category_code
    + '&by_node=' + `[${payload.by_node}]`
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&range_type=' + payload.range_type
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
  return this.http.get(url, headers)
  }

  get_allocation(payload){
    const url = process.env.REACT_APP_AWS3_URL +
      '/v2/stats_dashboard/fulfilment/indent_and_availability_for_allocation?by_delivery_start_date=' + payload.by_delivery_start_date 
      + "&by_delivery_end_date=" + payload.by_delivery_end_date
      + "&by_product="+payload.by_product
      + "&by_category_code="+ payload.by_category_code
      + "&by_sub_category_code=" + payload.by_sub_category_code
      + '&by_node=' + `[${payload.by_node}]`
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&range_type=' + payload.range_type
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
      let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_rejection_by_reason(payload) {
    const url = process.env.REACT_APP_AWS3_URL +
    '/v2/stats_dashboard/fulfilment/rejection_by_reason?by_delivery_start_date=' + payload.by_delivery_start_date 
    + "&by_delivery_end_date=" + payload.by_delivery_end_date
    + "&by_product="+payload.by_product
    + "&by_category_code="+ payload.by_category_code
    + "&by_sub_category_code=" + payload.by_sub_category_code
    + "&by_count=" + payload.by_count
    + "&by_count_order=" + payload.by_count_order
    + "&by_percentage=" + payload.by_percentage
    + "&by_percentage_order=" + payload.by_percentage_order
    + "&product_name_search=" + payload.product_name_search
    + "&page=" + payload.page
    + '&by_node=' + `[${payload.by_node}]`
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
  return this.http.get(url, headers)
  }

  get_category() {
    const url = process.env.REACT_APP_AWS3_URL + '/api/v1/admin/get_product_categories'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_sub_category() {
    const url = process.env.REACT_APP_AWS3_URL + '/api/v1/admin/get_product_sub_categories'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_product() {
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/products/sku_list'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }


}
