import React, { useEffect, useState, useRef, createRef } from "react";
import FrzDynamicSearch from "../../_controls/FrzDynamicSearch";
import { Row, Col, Card, Spin, DatePicker, Table, Button, Input, Select, Switch } from 'antd'
import { OperationsService } from '../../_services/operations';
import moment from 'moment';
import FRZInput from "../../_controls/FRZInput";
import { allocationDetailList, allocationDetailNestedList, allocationList } from '../../components/antd/columns/operations/allocation'
import { ReloadOutlined } from '@ant-design/icons';
import FrzDynamicDownload from "../../_controls/FRZDynamicDownload";

const ops_service = new OperationsService()

// {{rails-new}}v2/admin_dashboard_v2/stos/allocation_products_list/KC01


const statusFilter = [
  { name: "Ready", color: "#5dc6ad", value: "ready" },
  { name: "Not Ready", color: "#faad14", value: "not_ready" },
  { name: "Allocated", color: "#626262", value: "allocated" }]
const allocationFilter = [
  { name: "current stock", value: "current_stock" },
  { name: "available qty", value: "available_qty" },
  { name: "yet to receive", value: "yet_to_receive" }
]

// stored data to show
const initialExternalData = {
  nodes: false
}

//api filters
const initialFilters = {
  selectedNode: null,
  selectedDate: null
}

//local filter
const initialInternalFilters = {
  isSearch: false,
  nameSKU: null,
  selectedStatus: null,
}

const initialState = {
  spinner: false,
  err: false
}



function STOAllocation() {
  const [externalData, setExternalData] = useState(initialExternalData)
  const [disable, setDisable] = useState(true)
  const [filteredList, setFilteredList] = useState(false)
  const [internalFilters, setInternalFilters] = useState(initialInternalFilters)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)
  const [tableData, setTableData] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState(false)
  const [nodeDisable, setNodeDisable] = useState(false)
  const [allocationId, setAllocationId] = useState(false)
  const [allocationData, setAllocationData] = useState(false)
  const [showAllocation, setShowAllocation] = useState(false)
  const [reportUrl, setReportUrl] = useState(false)
  const [allocationType, setAllocationType] = useState('current_stock')

  const onSwitchChange = (value) => {
    setAllocationType(value)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys)
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: (record.status === 'allocated'),
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // useEffect(() => {
  //   console.log("selectedKeys", selectedKeys)
  // }, [selectedKeys])

  useEffect(() => {
    getNodes()
  }, [])

  useEffect(() => {
    if (filters.selectedDate && filters.selectedNode) {
      setNodeDisable(true)
      getproductList()
    }
  }, [filters, filters.selectedNode])

  useEffect(() => {
    if (allocationId) {
      const payload = {
        id: allocationId
      }
      var myVar = setInterval(() => {
        ops_service.alloc_prod_report_detail(payload).subscribe((r) => {
          setShowAllocation(true)
          if (r.response.data.length) {
            clearInterval(myVar)
            setAllocationData(r.response.data)
          }
        }, ((err) => {
          console.error("err", err)
        }))
      }, 6000)
      ops_service.alloc_prod_report_detail(payload).subscribe((r) => {
        setShowAllocation(true)
        if (r.response.data.length) {
          clearInterval(myVar)
          setAllocationData(r.response.data)
        }
      }, ((err) => {
        console.error("err", err)
      }))
    }
  }, [allocationId])

  const getproductList = () => {
    const payload = {
      node_code: filters.selectedNode
    }
    ops_service.get_alloc_prod_list(payload).subscribe((r) => {
      setTableData(r.response.data)
      setFilteredList(r.response.data)
    }, ((err) => {
      console.log(err)
    }))
  }

  const getNodes = () => {
    const payload = {
      nodeType: `["WH"]`
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, nodes: r.response.data }))
    },
      (err) => {
        console.log(err)
      })
  }


  function onChangeFilters(value, type, filterType) {
    setDisable(false)
    if (filterType === 'internalFilter') {
      if (internalFilters[type] === value) {
        // console.log("againn") //reset
      }
      setInternalFilters(prevState => ({ ...prevState, [type]: value, isSearch: true }))
    } else {
      setFilters(prevState => ({ ...prevState, [type]: value }))
      setInternalFilters(initialInternalFilters)
      filteredList.length === 0 && setFilteredList(false)
    }
  }


  function disabledDate(current) {
    return current && (current < moment().subtract(1, "day") || current > moment().add(1, "day"));
  }

  useEffect(() => {
    if (internalFilters) {
      localFilter()
    }
  }, [internalFilters])

  const localFilter = () => {
    internalFilters.isSearch && tableData &&
      setFilteredList(tableData?.filter(x =>
        (internalFilters.selectedStatus ? (x.status == internalFilters.selectedStatus.toLowerCase()) : true)
        &&
        (internalFilters.nameSKU ? (x.product_name.toLowerCase().includes(internalFilters.nameSKU.toLowerCase())) : true)
        // &&
        // (internalFilters.selectedStatus ? (x.status == internalFilters.selectedStatus) : true)
      ))
  }

  const reset = () => {
    setDisable(true)
    initialInternalFilters.isSearch = true
    setInternalFilters(initialInternalFilters)
    localFilter()
    setReportUrl(false)
  }

  const resetNode = () => {
    setTableData(false)
    setFilteredList(false)
    setFilters(initialFilters)
    setNodeDisable(false)
    //---
    resetIndividualState()
  }

  const resetIndividualState = () => {
    setShowAllocation(false)
    setSelectedKeys(false)
    setAllocationData(false)
    setAllocationId(false)
    setReportUrl(false)
    setAllocationType('current_stock')
  }

  function expandedRow(record) {
    return <>
      <Card className="nested-table">
        <Table columns={allocationDetailNestedList()} pagination={false} rowKey="product_sku" dataSource={record.darkstore_products} />
      </Card>
    </>
  }

  const handleOnFinish = () => {
    reset()
    window.open(reportUrl, '_blank')
    //---
    resetIndividualState()
  }

  return (
    <div>
      {externalData.nodes ?
        <div className="layer">
          <Row>
            <Col span={24}>
              <Card>
                <DatePicker value={filters.selectedDate} onChange={(e, f) => onChangeFilters(e, 'selectedDate')} className="frz-w-200 frz-m-10"
                  placeholder="Select Date" disabled={nodeDisable} disabledDate={disabledDate} />

                <Select value={filters.selectedNode} disabled={nodeDisable} style={{ width: 180 }}
                  onChange={(e) => onChangeFilters(e, 'selectedNode')}
                  placeholder="Select Warehouse">
                  {externalData.nodes.map((itm, index) => {
                    return (
                      <Select.Option key={index} value={itm.code}>{itm.name}</Select.Option>
                    )
                  })}
                </Select>
                {/* <FrzDynamicSearch DropDownData={externalData.nodes} placeholder="Select Warehouse" value="code" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChangeFilters(e, 'selectedNode')}
                  selectDisabled={nodeDisable} customClass="frz-m-8" /> */}

                <Button disabled={!nodeDisable} onClick={resetNode} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Card>
            </Col>
          </Row>

          {/* main content */}
          {/* no filter then msg to select filter  */}
          {filters.selectedDate && filters.selectedNode ?
            <Card className="frz-tm-20">
              <div>
                {/* internal filters */}
                <div className="frz-dis-flex frz-jc-sb">
                  {!showAllocation ? <div className="frz-dis-flex frz-vt-center frz-bm-20">
                    {/* <FRZInput value={internalFilters.nameSKU} noFormMargin name={'sku'} placeholder="Search for sku" width={200} setOnChange={(e) => onChangeFilters(e.target.value, 'nameSKU', 'internalFilter')} /> */}
                    <Input placeholder="Search SKU Name" allowClear
                      onChange={(e) => onChangeFilters(e.target.value, 'nameSKU', 'internalFilter')}
                      value={internalFilters.nameSKU} className="frz-w-200 frz-m-8" />

                    <div className="frz-dis-flex frz-vt-center">
                      {/* status */}
                      {statusFilter.map((itm) => {
                        return <div onClick={() => onChangeFilters(itm.value, 'selectedStatus', 'internalFilter')}
                          className={`${"frz-lm-10 frz-filter-btn frz-hrz-center frz-dis-flex frz-vt-center"} 
                        ${itm.value === internalFilters.selectedStatus && itm.value == 'ready' && 'frz-active-filter frz-border-ready'}
                        ${itm.value === internalFilters.selectedStatus && itm.value == 'not_ready' && 'frz-active-filter frz-border-not-ready'}
                        ${itm.value === internalFilters.selectedStatus && itm.value == 'allocated' && 'frz-active-filter frz-border-allocated'}  
                        `}>
                          <div style={{ height: 10, width: 10, backgroundColor: itm.color, borderRadius: '50%', marginRight: 4 }}></div>
                          {itm.name}
                        </div>
                      })}

                      <Button disabled={disable} onClick={reset} type="primary"
                        className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                    </div>

                    <div className="frz-dis-flex">
                      <div className="frz-rp-10 frz-vt-center frz-dis-flex">
                        Allocation of -
                        {/* <b>{!allocationType ? "Current" : "Available"}</b> */}
                      </div>
                      <div>
                        {/* <Switch value={allocationType} onChange={onSwitchChange} /> */}
                        <Select value={allocationType} style={{ width: 180 }}
                          onChange={(e) => setAllocationType(e)}>
                          {allocationFilter.map((itm, index) => {
                            return (
                              <Select.Option key={index} value={itm.value}>{itm.name}</Select.Option>
                            )
                          })}
                        </Select>
                      </div>
                    </div>

                  </div>
                    : <div className="frz-dis-flex frz-vt-center frz-fw-600">{!reportUrl ? "Allocating the following SKU's...." : "Following SKU's has been allocated"}</div>}

                  <div>

                    {/* <Button type="ghost">
                      Download report
                    </Button> */}
                    <FrzDynamicDownload name={'Export CSV'} fileName={'Allocate report'}
                      url={`/v2/admin_dashboard_v2/stos/export_allocation_product_list/${filters.selectedNode}?`}
                      onlyName
                      payload={{
                        by_product_ids: selectedKeys,
                        node_code: filters.selectedNode,
                        start_date: filters.selectedDate,
                        end_date: filters.selectedDate,
                        allocation_of: allocationType
                      }}
                      isDisabled={!selectedKeys.length}
                      type={'job'}
                      reportFunc={'export_alloc_prod_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />

                    {!showAllocation ?
                      <FrzDynamicDownload name={selectedKeys ? `Allocate ${selectedKeys.length} items` : 'Allocate'} fileName={'Allocate report'}
                        url={'/v2/admin_dashboard_v2/stos/export_allocation'}
                        setAllocationId={(value) => setAllocationId(value)}
                        setReportUrl={(value) => setReportUrl(value)}
                        onlyName
                        payload={{
                          by_product_ids: selectedKeys,
                          node_code: filters.selectedNode,
                          start_date: filters.selectedDate,
                          end_date: filters.selectedDate,
                          allocation_of: allocationType
                        }}
                        isDisabled={!selectedKeys.length}
                        type={'job'}
                        reportFunc={'alloc_prod_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                      :
                      <Button onClick={handleOnFinish} loading={!reportUrl} type="primary" className="frz-lm-20 frz-bm-10 frz-tm-10 frz-rm-10">
                        Download & Finish
                      </Button>
                    }
                    {/* <Button onClick={handleOnAllocate} disabled={!selectedKeys.length} type="primary" className="frz-lm-20">
                      Allocate
                    </Button> */}
                  </div>
                </div>
                {!showAllocation ?
                  <div className={filteredList.length ? "" : "frz-dis-none"}>
                    <Table
                      columns={allocationList()}
                      pagination={false}
                      rowKey="product_id"
                      dataSource={filteredList}
                      rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                      }}
                    />
                  </div>
                  :
                  allocationData ?
                    <Table
                      columns={allocationDetailList()}
                      pagination={false}
                      rowKey="product_sku"
                      dataSource={allocationData}
                      defaultExpandedRowKeys={'LCOR21'}
                      expandedRowRender={expandedRow}
                    />
                    : null
                }
              </div>
            </Card>
            :
            <div className="frz-dis-flex frz-hrz-center">
              <div className="no-data">Please select filter</div>
            </div>}
          {!filteredList.length && filters.selectedDate && filters.selectedNode && < div className="frz-dis-flex frz-hrz-center">
            <div className="no-data">No data on selected filters</div>
          </div>}

        </div>
        : <div className="spin-center"><Spin tip="...Loading" /></div>
      }
    </div >
  )
}

export default STOAllocation