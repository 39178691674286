import React, {useState, useEffect} from 'react';
import {Row, Col, Select} from 'antd';
import {FulfilmentService} from '../../../_services/procurementDashboard/fulfilment';
import { RightCircleTwoTone } from '@ant-design/icons';

const { Option } = Select;

const fulfilment_service = new FulfilmentService()

const CategoryModule = (props) => {
  const {setModalFilters, modalFilters, setModalDisplayFilter} = props;
  const [categoryData, setCategoryData] = useState({category: [], subCategory: [], product: []})
  const [displayData, setDisplayData] = useState({subCat: [], prod: []})

  useEffect(() => {
    getCategory()
    getSubCategory()
    getProduct()
  }, [])
  
  const getCategory = () => {
    fulfilment_service.get_category().subscribe((r) => {
      setCategoryData((prev) => ({...prev, category: r.response.data}))
    }, (err) => {
      console.log(err?.response)
    })
  }

  const getSubCategory = () => {
    fulfilment_service.get_sub_category().subscribe((r) => {
      setCategoryData((prev) => ({...prev, subCategory: r.response.data}))
      setDisplayData((prev) => ({...prev, subCat: r.response.data}))
    }, (err) => {
      console.log(err?.response)
    })
  }

  const getProduct = () => {
    fulfilment_service.get_product().subscribe((r) => {
      setCategoryData((prev) => ({...prev, product: r.response.data}))
      setDisplayData((prev) => ({...prev, prod: r.response.data}))
    }, (err) => {
      console.log(err?.response)
    })
  }

  const onCategoryChange = (e, f) => {
    setModalFilters((prev) => ({...prev, by_category_code: e}))
    setModalFilters((prev) => ({...prev, by_sub_category_code: ''}))
    let temp = categoryData?.subCategory?.filter(item => item.parent_code === e)
    setDisplayData((prev) => ({...prev, subCat: temp}))
    setModalDisplayFilter((prev) => ({...prev, by_category_code: f}))
  }

  const onSubCategoryChange = (e, f) => {
    setModalFilters((prev) => ({...prev, by_sub_category_code: e}))
    setModalFilters((prev) => ({...prev, by_product: ''}))
    let temp = categoryData?.product?.filter(item => item.category_name === f.children)
    setDisplayData((prev) => ({...prev, prod: temp}))
    setModalDisplayFilter((prev) => ({...prev, by_sub_category_code: f}))
  }

  const onProductChange = (e, f) => {
    setModalFilters((prev) => ({...prev, by_product: e}))
    setModalDisplayFilter((prev) => ({...prev, by_product: f}))
  }

  return (
    <>
      <Row>
        <Col span={8}>Category</Col>
        <Col span={8}>Sub Category</Col>
        <Col span={8}>Product</Col>
      </Row>
      <Row>
        <Col span={8}>
          <Select showSearch style={{ width: 200 }} onChange={onCategoryChange} defaultValue={modalFilters?.by_category_code || undefined} placeholder="Select Category"
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
            value={modalFilters?.by_category_code || undefined} >
            {categoryData?.category.length > 0 && categoryData?.category?.map((item) => <Option value={item.code} key={item.id}>{item.name}</Option>)}
          </Select>
        </Col>
        <Col span={8}>
          <Select showSearch style={{ width: 200 }} defaultValue={modalFilters?.by_sub_category_code || undefined} placeholder="Select Sub Category"
            value={modalFilters?.by_sub_category_code || undefined} onChange={onSubCategoryChange} 
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}>
            {displayData?.subCat?.length > 0 && displayData?.subCat?.map((item) => <Option value={item.code} key={item.id}>{item.name}</Option>)}
          </Select>
        </Col>
        <Col span={8}>
          <Select showSearch style={{ width: 200 }} defaultValue={modalFilters?.by_product || undefined} placeholder="Select Product"
            value={modalFilters?.by_product || undefined} onChange={onProductChange}
            filterOption={(input, option) => option.props.children[1].toLowerCase().includes(input.toLowerCase())}>
            {displayData?.prod.length > 0 && displayData?.prod?.map((item) => <Option value={item.sku} key={item.id}>
              <img src={item?.image_url} className="product-img" />
              {item.name}
            </Option>)}
          </Select>
        </Col>
      </Row>
    </>
  )
}

export default CategoryModule