import React, { useState } from 'react'
import { Form, Upload } from 'antd'
import { tailLayout } from '../_modules/controllayout'

const FRZUpload = ({ name, label, listType, customRequest, className, showUploadList, isDisabled,
  imgWidth, uploadButton, setOnChangeType, isRequired, handleOnChangeImage, imageState, showFileName, handleOriginalFileDetail }) => {

  const [fileName, setFileName] = useState("")

  const onChange = (e) => {
    handleChangeImage(e, setOnChangeType)
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChangeImage(info, type) {
    setFileName(info.file.name)
    if (handleOriginalFileDetail) {
      handleOriginalFileDetail(info.file.name, info.file.type)
    }
    if (info.file.status === 'uploading') {
      // setImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, item => handleOnChangeImage(type, item))
    }
  };

  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      extra={showFileName ? fileName : ""}
      rules={isRequired ? [
        {
          required: true,
          message: `Please ${label}!`,
        },
      ]:[]}
    >
      <Upload onChange={onChange} className={className} showUploadList={showUploadList} disabled={isDisabled}
        name="avatar" action="/upload.do" listType={listType} customRequest={customRequest}>
        {imageState[name] ? <img src={imageState[name]} alt="avatar" style={{ width: imgWidth }} /> : uploadButton}
      </Upload>
    </Form.Item>
  )
}

export default FRZUpload
