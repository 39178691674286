import {Select, Spin, Tabs } from 'antd';
import React, {useState} from 'react';
import '../../static/css/sto_layout.css';
import StoInward from '../../components/sto_list/sto_main/sto_inward';
import StoOutward from '../../components/sto_list/sto_main/sto_outward';
import CreateSto from '../../components/sto_list/sto_main/sto_outward/CreateSto';
import STOShipment from '../operations/stoshipment/sto_shipment';
import CreateStoShipment from '../../components/operations/sto/createstoshipment';
const { TabPane } = Tabs;
const StoList =()=>{
    const [layout,setLayout]=useState(true);
    const [apiError, setApiError] = useState(false);
    const [spinEnable, setSpinEnable]= useState(false)
    const [showCreate,setShowCreate]=useState(false)
    const [showStoShipment,setShowStoShipment]=useState(false)
    const [curr,setCurr]=useState('STO-Outward');
    const [screen,setScreen]=useState(false)
    const [sto_id,setSto_id]=useState(null);
    const [hideTab,setHideTab]=useState(false);
    const handleCreateSto=(value)=>{
      setSpinEnable(true)
      setShowCreate(value)
      setSpinEnable(false)
    }
    const [currentTab, setCurrentTab] = useState("1");

    function changeTab(key) {
      setCurrentTab(key);
    }

    return(
        <div>
          {layout && !showCreate && !showStoShipment ? 
            <div className="sto-main-container">
              <Tabs type="card" defaultActiveKey='1' className={'sto-main-tabs'}  onChange={changeTab}>
                <TabPane key="1"
                disabled={hideTab?true:false}
                  tab={<span>
                    {'STO - Outward'}
                  </span>}>
                </TabPane>
                <TabPane key="2" 
                  disabled={''}
                  tab={<span>
                    {'Shipment'}
                  </span>}>
                </TabPane>
                <TabPane key="3" 
                  disabled={hideTab?true:false}
                  tab={<span>
                    {'STO -  Inward'}
                  </span>}>
                </TabPane>
              </Tabs>
              {currentTab === '1' && <StoOutward  setSto_id={setSto_id} setScreen={setScreen} setSpinEnable={setSpinEnable} spinEnable={spinEnable} showCreate={showCreate} setShowCreate={setShowCreate} />}
              {currentTab === '2' && <STOShipment hide={hideTab} setHide={setHideTab} setShowStoShipment={setShowStoShipment} showStoShipment={showStoShipment}  />}
              {currentTab === '3' && <StoInward  setSto_id={setSto_id} setScreen={setScreen} showCreate={showCreate} setShowCreate={setShowCreate} setSpinEnable={setSpinEnable} spinEnable={spinEnable}/>}
            </div>
          : 
          (
            showCreate  ? <div className='create-sto-main-container'>
              <CreateSto screen={screen} show={showCreate} hide={handleCreateSto} sto_id={sto_id}/>
            </div>:
            apiError &&!spinEnable? 
            <div className="horizontal-vertical-center frz-font-20">
              <b>Backend API System Down</b>
            </div>
          :
            (!showCreate && !apiError && !spinEnable && showStoShipment ?<><CreateStoShipment setShowStoShipment={setShowStoShipment} /> </>:<div className="spin-center">
              <Spin tip="...Loading" />
            </div>)
          )
        }
      </div>
    )
}
export default StoList