import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Spin } from 'antd';

const CreateClosingModal = forwardRef((props, ref) => {

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          width={'40%'}
          footer={null}
          onCancel={handleCancelModal}>
          <div>
            <div className='wcl-create-modal-container'>
              <div className='wcl-create-modal-text'>
                Before you proceed further, <br /> Please make sure to print today’s sheet,
                fill in <br /> details on paper and report on system here
              </div>
              <Spin spinning={props.jobLoading === 'print_closing'} size="small">
                <div className='wcl-create-printbtn wcl-align-center'
                  onClick={() => { props.getJobDownloads(`closing_items_pdf_report?node_code=${props.currentNode}&product_category=${props.currentMainTab}`, 'print_closing'); sessionStorage.setItem('closingPrintClicked', true) }}>
                  <img src={props.printImg} /> Print Sheet
                </div>
              </Spin>
              <Spin spinning={props.jobLoading === 'closing_excel'} size="small">
                <div className='wcl-print-not-text'>
                  Printer not working? <span className='wcl-email-text-modal'
                    onClick={() => {props.getJobDownloads(`download_closing_items?node_code=${props.currentNode}&product_category=${props.currentMainTab}`, 'closing_excel'); sessionStorage.setItem('closingPrintClicked', true) }}>
                      Download Sheet
                    </span>
                </div>
              </Spin>
            </div>
            <div className='wcl-create-modal-text frz-tm-20'>
              Completed paper sheet?
            </div>
            <div className='text-center frz-tm-10'>
              <Button onClick={() => props.setWCScreen('create_closing')}
                type="primary" disabled={!sessionStorage.getItem('closingPrintClicked')}>Proceed Now</Button>
            </div>
          </div>
        </Modal>
        : null}
    </div>
  )
})

export default CreateClosingModal
