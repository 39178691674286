import React from "react";
import {Button} from 'antd';
import { EditOutlined } from '@ant-design/icons';

function TableList(props) {

  return (
    <div className="wcl-listing-table-container">
      {props.data.map((item, index) => {
        return (
          <div key={index} className="wcl-align-center wcl-sp-b wcl-listing-table">
            <div className="wcl-align-center wcl-40">
              <img src={item.image_url} className="wcl-listing-img" />
              <div>{item.name} - ({item.sku})</div>
            </div>
            <div className="wcl-align-center wcl-15 text-center">
              {item.category}
            </div>
            <div className="wcl-align-center wcl-10 text-center">
              {item.wastage}
            </div>
            <div className="wcl-align-center wcl-15 text-center">
              {item.closing}
            </div>
            <div className="wcl-align-center wcl-15 text-center color-red">
              {item.deviation}
            </div>
            <div className="wcl-align-center wcl-5 text-center">
              {/* {!props.currentDate ?
                <Button icon={<EditOutlined />} disabled={true}
                  onClick={() => props.editClosing(item)}>
                </Button>
              : null} */}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TableList