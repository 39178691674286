import { Http } from '../_modules/http';
import FRAAZO from '../_modules/http_headers';

export class AttendanceService {

  http = new Http()

  get_attendance_authority(urlParam) {
    const url = process.env.REACT_APP_AUTH_URL + 'nodes/get_node_by_code' + urlParam
    return this.http.get(url, FRAAZO())
  }

  get_attendance(urlParam) {
    const url = process.env.REACT_APP_AUTH_URL + 'event_log?platform=ops' + urlParam
    return this.http.get(url, FRAAZO())
  }

  capture_attendance(payload) {
    const url = process.env.REACT_APP_AUTH_URL + 'event_log?platform=ops'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }
  // get_attendance(payload) {
  //   const url = process.env.REACT_APP_AUTH_URL + 'event_log?darkstore_id=1'
  //   return this.http.get(url, FRAAZO())
  // }

  get_nodes(urlParam) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/list_node' + urlParam
    return this.http.get(url, FRAAZO())
  }

  get_roles() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_roles?platform=ops'
    return this.http.get(url, FRAAZO())
  }

  get_vendors() {
    const url = process.env.REACT_APP_AUTH_URL + 'vendors?platform=ops'
    return this.http.get(url, FRAAZO())
  }

  get_shifts(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/role_based_shifts' + urlParam
    return this.http.get(url, FRAAZO())
  }

  generate_otp(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/user_send_otp'
    return this.http.post(url, payload, FRAAZO())
  }


  generate_otp_login(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/start_validation'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  verify_otp_login(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/verify_otp'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }


  verify_otp(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/user_verify_otp'
    return this.http.post(url, payload, FRAAZO())
  }

  add_user(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/admins'
    return this.http.post(url, payload, FRAAZO())
  }

  update_user(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/update'
    return this.http.put(url, payload, FRAAZO())
  }

  get_node_wise_attendance(payload) {
    const url =
      process.env.REACT_APP_AUTH_URL +
      "daily_attendance_reports/node_wise?date=" + payload.date + "&node_code=" + payload.node_code + "&platform=ops" + "&roles=" + payload.roles + "&download=false" + "&pnp_vender_id=" + payload.pnp_vender_id + "&by_city=" + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_roles() {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/get_roles?platform=ops"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_pnp_vendors() {
    const url =
      process.env.REACT_APP_AUTH_URL + "daily_attendance_reports/get_pnp_vendors"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_attan_table(payload) {
    // const url = process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/daily_attendance_reports/darkstore_specific_day_wise_report?start_date=2022-05-17&end_date=2022-05-18&darkstore_id=1&download=false"
    const url = process.env.REACT_APP_AUTH_URL + `daily_attendance_reports/node_specific_day_wise_report?start_date=` +
      payload.start_date + "&end_date=" + payload.end_date + "&node_code=" + payload.node_code + '&platform=ops' + "&download=" + payload.download_status
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_attan_table_user_wise(payload) {
    // const url = process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/daily_attendance_reports/darkstore_specific_user_wise_report?start_date=2022-05-17&end_date=2022-05-18&darkstore_id=1&download=false"
    const url = process.env.REACT_APP_AUTH_URL + `daily_attendance_reports/node_specific_user_wise_report?start_date=` +
      payload.start_date + "&end_date=" + payload.end_date + "&node_code=" + payload.node_code + "&download=" + payload.download_status + '&platform=ops'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_attan_table_day_wise_nested(payload) {
    // const url = process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/daily_attendance_reports/darkstore_specific_day_and_name_wise_report?date=2022-05-18&darkstore_id=1"
    const url = process.env.REACT_APP_AUTH_URL + `daily_attendance_reports/node_specific_day_and_name_wise_report?date=` +
      payload.date + "&node_code=" + payload.node_code + '&platform=ops'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_attan_table_user_wise_nested(payload) {
    // const url = process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/daily_attendance_reports/darkstore_specific_user_and_day_wise_report?start_date=2022-05-17&end_date=2022-05-18&darkstore_id=1&mobile_number=6366783995"
    const url = process.env.REACT_APP_AUTH_URL + `daily_attendance_reports/node_specific_user_and_day_wise_report?start_date=` +
      payload.start_date + "&end_date=" + payload.end_date + "&node_code=" + payload.node_code + "&mobile_number=" + payload.mobile_number + '&platform=ops'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}
