import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import { Modal, Form, Select, Row, Col, Button, InputNumber, DatePicker, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout, tailLayout } from '../../_modules/controllayout';
import { useSelector } from "react-redux";
import moment from 'moment';
import FRZInput from '../../_controls/FRZInput';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import FRZSelect from '../../_controls/FRZSelect';
import FRZPassword from '../../_controls/FRZPassword';
import { openNotificationWithIcon } from '../../_modules/notification';
import VerifyOTP from './verify_otp';

const { Option } = Select;

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false
}

const initialDropData = {
  shiftData: [],
  vendorData: []
}

const statusData = ['offline', 'available']

const AddUsers = forwardRef((props, ref) => {
  const modalRef = useRef(null);
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)
  const [dropData, setDropData] = useState(initialDropData)
  const [vendorSelected, setVendorSelected] = useState('')
  const [showError, setShowError] = useState(false)
  const [deleteAcess, setDeleteAcess] = useState(false)

  useEffect(() => {
    if (modalState.data && modalState.title === 'Edit Users') {
      roleUpdates(modalState.data.roles, 'Edit', modalState.data.role_vendor_id)
      form.setFieldsValue({ new_roles: modalState.data.roles, email: modalState.data.uid, ...modalState.data })
    }
  }, [modalState.data])

  useEffect(() => {
    return () => {
      handleCancelModal()
    };
  }, []);

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setVendorSelected('')
    setShowError(false)
    setDropData(initialDropData)
    setModalState(initialModal)
  }

  const handleOnSubmit = (values) => {
    if (modalState.title === 'Add User' && !showError) {
      verifyMobile(values)
    } else {
      if (modalState.data.mobile_number === values.mobile_number.toString()) {
        updateUser(values)
      } else {
        verifyMobile(values, modalState.data.mobile_number)
      }
    }
  }

  const verifyMobile = (values, secondary) => {
    const payload = {
      primary_mobile_number: values.mobile_number
    }
    if (secondary) {
      payload.primary_mobile_number = parseInt(secondary)
      payload.secondary_mobile_number = values.mobile_number
    }
    props.attendance_service.generate_otp(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'OTP Sent Successfully')
      modalRef.current.showModal(values, 'Verify OTP', secondary)
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  function addUser(values) {
    setModalState(prevState => ({ ...prevState, loading: true }))
    const payload = {
      admin: { ...values, node_code: props.node_code, status: "offline" }
    }
    if (!payload.admin.email || !payload?.admin?.email.length || payload?.admin?.email == "") {
      payload.admin.email = payload.admin.mobile_number + "@example.com"
    }
    props.attendance_service.add_user(payload).subscribe((r) => {
      props.getTableList()
      openNotificationWithIcon('success', 'User Added Successfully')
      handleCancelModal()
    }, (err) => {
      // setShowError(err?.response?.errors?.[0] ?? 'API Error')
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  const updateUser = (values) => {
    setModalState(prevState => ({ ...prevState, loading: true }))
    const payload = {
      admin: { ...values, node_code: props.node_code }
    }
    props.attendance_service.update_user(payload).subscribe((r) => {
      props.getTableList()
      openNotificationWithIcon('success', 'User Updated Successfully')
      handleCancelModal()
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  function roleUpdates(e, type, vendor) {
    let filteredShift = []
    let filteredVendor = []
    e.map(x => props.shifts.find(y => y[x])?.[x])?.map(z => { z?.map(k => { filteredShift.push(k) }) })
    e.map(x => props.vendors.find(y => y[x])?.[x])?.map(z => { z?.map(k => { filteredVendor.push(k) }) })
    setDropData(prevState => ({ ...prevState, shiftData: filteredShift, vendorData: filteredVendor }))
    if (type !== 'Edit') {
      form.setFieldsValue({ city_wise_shift_id: null, role_vendor_id: null })
      setVendorSelected('')
    } else {
      setVendorSelected(filteredVendor.find(x => x.id === vendor)?.display_name)
    }
  }

  function vendorUpdates(e) {
    setVendorSelected(dropData.vendorData.find(x => x.id === e)?.display_name)
  }

  const confirm = () => {
    const payload = {
      mobile_number: form.getFieldValue().mobile_number
    }
    console.log("payload", payload)
    // attendance_service.delete_user(payload).subscribe((r) => {
    //   this.props.onCancelModal()
    //   this.props.getUsers()
    //   this.handleCancel()
    //   this.openNotificationWithIcon('success', "User deleted")
    // }, ((err) => {
    //   this.openNotificationWithIcon('error', err?.response?.errors ?? 'API Error')
    //   console.log("err", err)
    // }))
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          width={'70%'}
          footer={[
            <div className="frz-dis-flex frz-jc-sb">
              <div className="frz-bm-20">
                {deleteAcess && modalState.title == "Edit User" ?
                  <Popconfirm placement="top" title={"Are you sure you want to delete?"} onConfirm={confirm} okText="Yes" cancelText="No">
                    <Button className="frz-delete-user">
                      Delete User
                    </Button>
                  </Popconfirm>
                  : null}
              </div>
              <div>
                <Button key="back" onClick={handleCancelModal}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" loading={modalState.loading} onClick={() => {
                  form
                    .validateFields()
                    .then(values => {
                      handleOnSubmit(values)
                    })
                    .catch(info => {
                      console.log('Validate Failed:', info);
                    });
                }}>
                  {/* {showError ? 'Update Details & Transfer' : 'Submit'} */}
                  Submit
                </Button>
              </div>
            </div>
          ]}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            {/* {showError ?
              <div className='frz-bm-40 text-center frz-font-16'>{showError}</div>
              : null} */}

            <FRZInput name="first_name" isRequired={true} label="First Name" placeholder="First Name" width={200} />

            <FRZInput name="last_name" isRequired={true} label="Last Name" placeholder="Last Name" width={200} />

            <FRZInput name="email" label="Email" width={200} ofType="email"
              isDisabled={modalState.title === 'Edit Users'} placeholder="Email" />

            <FRZInputNumber isRequired={true} label="Mobile No" name="mobile_number" width={200}
              placeholder="Mobile No" />

            <FRZSelect DropDownData={['grading_person']} selectDisabled={modalState.title == "Edit Users"} name="new_roles" isRequired={true}
              label="Roles" option="data" isDataKey={true} isShowSearch={true}
              // selectDisabled={showError}
              width={"70%"} mode="multiple" setOnChange={roleUpdates} placeholder="Select Roles" />

            <FRZSelect DropDownData={dropData.shiftData} name="city_wise_shift_id" isRequired={true}
              label="Shift" isShowSearch={true} value="id" option="shift_string" width={300}
              selectDisabled={dropData.shiftData.length === 0} placeholder="Select Shift" />

            <FRZSelect DropDownData={dropData.vendorData} name="role_vendor_id" isRequired={true}
              label="Vendor" isShowSearch={true} value="id" option="display_name" width={300}
              selectDisabled={dropData.vendorData.length === 0} placeholder="Select Vendor"
              setOnChange={vendorUpdates} />

            {vendorSelected?.toLowerCase().includes('fraazo') ?
              <FRZInput name="fraazo_employee_id" isRequired={true} label="Employee Code" width={200}
                placeholder="Employee Code" /> : null
            }

            {modalState.title === 'Edit Users' ?
              <FRZPassword isRequired={true} label="Password" name="password" type="password" width={200} />
              : null
            }

            {/* {showError ?
              <div className='frz-bm-40 text-center frz-font-16'>
                Do you want to transfer to <span className='color-orange frz-fw-700'>
                  {props.nodeData.find(x => x.code === props.node_code).name}?</span>
              </div>
              : null} */}

            {/* {modalState.title === 'Edit Users' ?
              <FRZSelect DropDownData={statusData} name="status" isRequired={true}
                label="Status" isShowSearch={true} option="data" isDataKey={true} width={200}
                selectDisabled={showError || (!modalState.data.logged_in_at && props.otpAttendance && props.imageAttendance)}
                placeholder="Select Status"
                help={!modalState.data.logged_in_at && props.otpAttendance && props.imageAttendance ? 'Please Login First' : false} />
              : null} */}
          </Form>
        </Modal>
        : null}
      <VerifyOTP ref={modalRef} attendance_service={props.attendance_service} addUser={addUser} />
    </div>

  )
})

export default AddUsers
