import React, { useState } from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import moment from 'moment';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const downloadOptions = [
  { name: 'Physical SKU Qty Report', url: 'admin_dashboard/phy_sku_qty_report', type: 'zapper_report' },
  { name: 'Sales Forecast Report', url: 'admin_dashboard/node_wise_product_fulfilment_report', type: 'zapper_report' },
  { name: 'STO Crate Report', url: 'admin_dashboard/sto_crates_report', type: 'zapper_report' },
  // { name: 'Wastage Report', url: '/v2/admin_dashboard_v2/stock_losses/export_wastage_report', type: 'zapper_report_v2' },
  // { name: 'Closing Report', url: '/v2/admin_dashboard_v2/stock_losses/export_closing_report', type: 'zapper_report_v2' },
  // { name: 'Inventory Correction Report', url: '/v2/admin_dashboard_v2/stock_losses/export_inventory_correction_report', type: 'zapper_report_v2' }
]

const { Option } = Select;

function SingularDate(props) {
  const [state, setState] = useState({ date: '', dateValue: '', valueByDarkstore: 'all' });
  const [spinEnable, setSpinEnable] = useState(false)
  const [downloadOption, setDownloadOption] = useState(undefined)
  const [reportType, setReportType] = useState(undefined)

  const { date, dateValue, valueByDarkstore } = state

  function onChange(val, f) {
    setReportType(f.type)
    setDownloadOption(val)
  }

  function onChangeDarkstore(value) {
    // console.log(values)
    setState(prevState => ({ ...prevState, valueByDarkstore: value }))
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function disabledDate(current) {
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, dateValue: dateString }))
  }

  return (
    <div className="layer" style={{ paddingTop: 10 }}>
      {!spinEnable ?
        <Row>
          <Card>
            <Col span={24} style={{ marginLeft: 10 }}>
              <h3 style={{ marginTop: 10, marginBottom: 0 }}>
                Physical SKU Qty Report | Sales Forecast Report | STO Crate Report 
                {/* | Wastage Report | Closing Report | Inventory Correction Report */}
              </h3>
              <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                style={{ width: 200 }} disabledDate={disabledDate} />
              {props.nodes ?
                <FrzDynamicSearch DropDownData={props.nodes}
                  isCustomOption={true} customKey={"all"} customOption={"All Nodes"}
                  customValue={"all"} placeholder="Node Name" value="code" option="name"
                  isShowSearch={true} width={300} setOnChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')}
                  type="valueByZapperCode" margin={10} defValue={valueByDarkstore} />
                :
                <FrzDynamicSearch
                  placeholder="Darkstore Name" value="code" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')}
                  type="valueByZapperCode" margin={10} defValue={valueByDarkstore} />
              }
              <Select style={{ width: 300, marginRight: 10 }}
                value={downloadOption}
                placeholder="Select Download Item" onChange={(e, f) => onChange(e, f)}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {downloadOptions.map(data => {
                  return (
                    <Option key={data.url} value={data.url} type={data.type}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
              {/* <Button disabled={!downloadOption || dateValue === ''} onClick={getDownloadList} style={{ width: 100, margin: 10 }}
                type="primary">Download</Button> */}
              <FrzDynamicDownload isDisabled={!downloadOption || dateValue === ''} name={'Report'} fileName={downloadOption} url={`${downloadOption}`}
                payload={'?date=' + `${dateValue ?? ''}` + '&node_code=' + `${valueByDarkstore === 'all' ? '' : valueByDarkstore ?? ''}`} type={'job'}
                reportFunc={reportType} jobReportFunc={'zapper_job_report'} base={'zapper'} />
            </Col>
          </Card>
        </Row>
        :
        <div className="spin-center">
          <Spin tip="...Download in Progress" />
        </div>
      }
    </div>
  )
}

export default SingularDate