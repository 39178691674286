import React, { useEffect, useRef, useState } from 'react'
import { Spin, Button, Card, Row, Col, Pagination, Select, Table, InputNumber } from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import { WastageService } from '../../../_services/wastage';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import { productRetailWastageColumns, retailWastageColumns } from '../../../components/antd/columns/operations/category';
import CreateStockLossApprovalModal from '../../../components/operations/wastage/create_retail_wastage_modal';
import MainTable from '../../../components/antd/table';
import { useSelector } from 'react-redux';

const ops_service = new OperationsService()
const wastage_service = new WastageService()

function RetailWastage() {

  const modalRef = useRef(null);
  const [retailWastageData, setRetailWastageData] = useState(false)
  const [nodes, setNodes] = useState(false)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columnsProducts, setColumnsProducts] = useState([])
  const [products, setProducts] = useState(false)
  const [disable, setDisable] = useState(true)
  const [columns, setColumns] = useState([])
  const [approved] = useState([{ id: true, name: 'True' }, { id: false, name: 'False' }])
  const [spinEnable, setSpinEnable] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [fetchColm, setFetchColm] = useState(false)
  const [state, setState] = useState({
    valueByNode: undefined,
    valueByProducts: undefined,
    createdBy: undefined,
    approvedBy: undefined,
  });
  const { valueByNode, valueByProducts, createdBy, approvedBy } = state


  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getRetailWastage()
    }
  })

  useEffect(() => {
    getProducts()
    getNodes()
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false)
      getColumns()
    }
  })


  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`,
      admin_id: JSON.parse(localStorage.getItem('auth')).id
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProducts() {
    ops_service.get_product_list().subscribe((r) => {
      let tempData = r.response.data.map(v => ({ ...v, name_sku: v.name + " - (" + v.sku + ")" })
      )
      setProducts(tempData)
    },
      (err) => {
        console.log(err)
      })
  }

  function getRetailWastage() {
    setSpinEnable(true)
    setRetailWastageData([])
    const payload = {

      admin_id: JSON.parse(localStorage.getItem('auth')).id,
      by_node: !valueByNode ? '' : valueByNode,
      by_product: valueByProducts ?? '',
      created_by: !createdBy ? '' : createdBy,
      by_is_approved: approvedBy === undefined ? '' : approvedBy === 'True' ? true : false,
      page: current,


    }
    console.log(payload)
    wastage_service.stock_loss_approvals(payload).subscribe((r) => {
      // setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false)
      setRetailWastageData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }


  function showModal(data, type) {
    console.log(data)
    modalRef.current.showModal(type, data)
  }

  function getColumns() {
    retailWastageColumns.map((data) => {
      columns.push(data)
    })

    productRetailWastageColumns.map(data => {
      columnsProducts.push(data)
    })

    setColumnsProducts(columnsProducts)
    setColumns(columns)
    console.log(columnsProducts)
  }

  function modalSubmit(payloadData) {
    console.log(payloadData)
    const payload = { ...payloadData, created_by: JSON.parse(localStorage.getItem('auth')).id, }
    ops_service.create_Stock_loss_Approvals(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      modalRef.current.handleCancel()
      getRetailWastage()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setState({
      valueByNode: undefined,
      valueByProducts: undefined,
      approvedBy: undefined,
    })
    setDisable(true)
    setCurrent(1)
    setRetailWastageData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRetailWastageData([])
    setRenderData(false)
  }

  function expandedRow(record) {
    console.log(record.line_items)
    return <>
      <Card className="nested-table">
        <Table columns={columnsProducts} pagination={false} rowKey="id" dataSource={record.line_items} />
      </Card>
    </>
  }

  return (
    <div className="layer">
      {
        retailWastageData && nodes && products ?
          <div>
            <Row>
              <Card>
                <Col span={24}>
                  <FrzDynamicSearch DropDownData={nodes}
                    placeholder="Select Node" value="id" option="name"
                    isShowSearch={true} width={400} setOnChange={(e) => onChange(e, 'valueByNode')}
                    type="valueByNode" margin={10} defValue={valueByNode} />

                  <FrzDynamicSearch DropDownData={products}
                    placeholder="Select Product" value="sku" option="name_sku"
                    isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByProducts')}
                    type="valueByProducts" margin={10} breakOption={true} defValue={valueByProducts} />

                  <FrzDynamicSearch DropDownData={approved}
                    placeholder="Is Approved" value="name" option="name"
                    isShowSearch={true} width={200} setOnChange={onChange}
                    type="approvedBy" margin={10} defValue={approvedBy} />

                  <Button disabled={disable} onClick={getRetailWastage}
                    className="frz-w-100px frz-m-10" type="primary">Search</Button>
                  <Button disabled={disable} onClick={reset} type="primary"
                    className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />

                </Col>
              </Card>
            </Row>
            <Row>
              <Card>
                <Col span={24}>
                  <Button onClick={() => showModal(true, 'Create Wastage')} className="frz-w-140px frz-m-10" type="primary">
                    Create Wastage
                  </Button>
                </Col>
              </Card>
            </Row>
            {retailWastageData.length > 0 ?
              <Row>
                <Col span={24}>
                  <MainTable dataSource={retailWastageData} columns={columns} expandedRow={expandedRow} />
                  <Pagination className="frz-tm-10 frz-bm-10" current={current}
                    pageSize={50} total={total} onChange={pagination}
                    showSizeChanger={false} />
                </Col>
              </Row>
              : (disable || spinEnable ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">No Data Available</div>)
                :
                <div className="no-data">No Data Available On Selected Filters</div>)
            }
            <CreateStockLossApprovalModal modalSubmit={modalSubmit} ref={modalRef} nodes={nodes}
            />
          </div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
      }

    </div>

  )
}

export default RetailWastage
