import React, { useEffect, useState } from 'react';
import {
  Spin, Button, Card, Row, Col, Tabs, Input, Select,
  Pagination, DatePicker, Table
} from 'antd';
import { AttendanceService } from '../../_services/attendance'
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment'
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';
import { nodewiseData, daywiseData, reportsAttnUserColumns } from '../../components/antd/columns/operations/attendance'
import NestedAttnTable from './nestedattntable';

const dateFormat = 'YYYY-MM-DD';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const attendance_service = new AttendanceService()

const AttendanceReport = () => {
  const [listingData, setListingData] = useState(false)
  const [roles, setRoles] = useState(false)
  const [pnpVendors, setPnpVendors] = useState(false);
  const [spinEnable, setSpinEnable] = useState(false)
  const [currentTab, setCurrentTab] = useState(1);
  const [isReset, setIsReset] = useState(false)
  const [disable, setDisable] = useState(true)
  const [node, setNode] = useState(false)
  const [state, setState] = useState({
    valueByRoles: 'all', valueByPnpVendors: undefined,
    valueBySource: undefined, valueByDeliveryDate: '', date: moment().add(0, "day"),
    start_date: moment().add(0, "day").format('YYYY-MM-DD'), end_date: moment().add(0, "day").format('YYYY-MM-DD'),
    showDateValue: true
  });

  const { valueBySource, valueByDeliveryDate, valueByRoles, date, valueByPnpVendors,
    start_date, end_date, showDateValue } = state

  useEffect(() => {
    getRoles();
    getPnpVendors();
    getNodes()
  }, [])

  useEffect(() => {
    if (!isReset) {
      getListing()
    }
  }, [isReset, currentTab])

  function getNodes() {
    let urlParam = `?by_node_type=["CC","WH"]&by_city=${localStorage.getItem('city') ?? ''}`
      + `&admin_id=${JSON.parse(localStorage.getItem('auth')).id}`
      + `&by_procurement_type=${localStorage.getItem('procurementtype')}`
    attendance_service.get_nodes(urlParam).subscribe((r) => {
      setNode(r.response.data)
    }, (err) => {
      console.log("err", err)
    })
  }


  function getRoles() {
    attendance_service.get_roles().subscribe((r) => {
      let temp = r.response;
      temp.push('all')
      setRoles(temp)
    },
      (err) => {
        console.log(err)
      })
  }

  function getPnpVendors() {
    attendance_service.get_pnp_vendors().subscribe((r) => {
      setPnpVendors(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  const getListing = () => {
    setSpinEnable(true)
    if (currentTab == 1) {
      const payload = {
        node_code: !valueBySource ? '' : valueBySource,
        date: !valueByDeliveryDate ? moment().add(0, "day").format(dateFormat) : valueByDeliveryDate,
        roles: !valueByRoles ? [] : valueByRoles == "all" ? [] : valueByRoles,
        pnp_vender_id: !valueByPnpVendors ? '' : valueByPnpVendors,
        start_date: start_date,
        end_date: end_date,
      }
      attendance_service.get_node_wise_attendance(payload).subscribe((r) => {
        setSpinEnable(false)
        setListingData(r.response.data)
        setIsReset(true)
      }, ((err) => {
        setSpinEnable(false)
        console.error("err", err)
      }))
    } else {
      if (valueBySource) {
        getAttTable(start_date, end_date)
      } else {
        setListingData([])
        setSpinEnable(false)
      }
    }
  }

  const getAttTable = (startDate, endDate) => {
    setListingData(false)
    const payload = {
      start_date: startDate ?? moment().format(dateFormat),
      end_date: endDate ?? moment().format(dateFormat),
      download_status: false,
      node_code: valueBySource ? valueBySource : ""
    }
    if (currentTab == 2) {
      attendance_service.get_attan_table(payload).subscribe((r) => {
        r.response.data.map((itm, index) => {
          Object.assign(itm, { ...itm, id: index })
        })
        setSpinEnable(false)
        setListingData(r.response.data)
      }, ((err) => {
        console.log("err", err)
        setSpinEnable(false)
      }))
    } else {
      attendance_service.get_attan_table_user_wise(payload).subscribe((r) => {
        setSpinEnable(false)
        r.response.data.map((itm, index) => {
          Object.assign(itm, { ...itm, id: index })

        })
        setListingData(r.response.data)
      }, ((err) => {
        setSpinEnable(false)
        console.log("err", err)
      }))
    }
  }

  const changeTab = (key) => {
    setIsReset(false)
    setCurrentTab(key)
  }

  const reset = () => {
    setState({
      valueBySource: undefined, valueByPnpVendors: undefined, showDateValue: true, start_date: moment().add(0, "day").format(dateFormat),
      end_date: moment().add(0, "day").format(dateFormat),
      valueByRoles: "all", valueByDeliveryDate: '', date: moment().add(0, "day")
    })
    setDisable(true)
    setIsReset(false)
    setListingData([])
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDeliveryDate: dateString }))
    setDisable(false)
  }

  function disabledDate(current) {
    return current && current > moment().add(0, "day");
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
  }

  function expandedRow(record) {
    return <NestedAttnTable record={record} valueBySource={valueBySource} tabsAttChanged={currentTab}
      endDate={end_date} startDate={start_date} />
  }

  return (
    <div className="layer">
      <div>
        <div className="ost">
          <Tabs defaultActiveKey="1" onChange={changeTab}>
            <TabPane tab="Node Wise" key="1"></TabPane>
            <TabPane tab="Day Wise" key="2"></TabPane>
            <TabPane tab="User Wise" key="3"></TabPane>
          </Tabs>
        </div>
        {listingData && roles && pnpVendors && node ?
          <div>
            <Row>
              <Card>
                <Col span={24}>
                  {/* {currentTab == 1 ? <Search placeholder="Darkstore" value={valueBySource} width="medium"
                    onChange={(e) => onChange(e, 'valueBySource')} type="valueByDarkstoreCode" /> : null}
                   */}
                  {/* DS */}
                  <Select
                    placeholder="Select Node"
                    showSearch
                    style={{
                      width: 280,
                    }}
                    value={state.valueBySource}
                    onChange={(e) => onChange(e, 'valueBySource')}>
                    {node.map((itm, index) => {
                      return <Option value={itm.code} key={index}>{itm.name}</Option>
                    })}
                  </Select>
                  {currentTab == 1 ?
                    <>
                      {/* roles */}
                      <Select
                        showSearch
                        className='frz-lm-10'
                        placeholder="Role"
                        style={{
                          width: 130,
                        }}
                        value={state.valueByRoles}
                        onChange={(e) => onChange(e, 'valueByRoles')}>
                        {roles.map((itm, index) => {
                          return <Option value={itm} key={index}>{itm}</Option>
                        })}
                      </Select>

                      {/* PNP */}
                      <Select
                        showSearch
                        className='frz-lm-10'
                        placeholder="Vendors"
                        style={{
                          width: 130,
                        }}
                        value={valueByPnpVendors}
                        onChange={(e) => onChange(e, 'valueByPnpVendors')}>
                        {pnpVendors.map((itm, index) => {
                          return <Option value={itm.id} key={index}>{itm.name}</Option>
                        })}
                      </Select>

                      <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                        style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />

                    </>
                    :
                    <RangePicker onChange={onChangeRangePicker} style={{ margin: 10 }}
                      value={showDateValue ? [moment(start_date, dateFormat),
                      moment(end_date, dateFormat)] : ''} format={dateFormat}
                      allowClear={false} disabledDate={disabledDate} />
                  }
                  <Button disabled={disable} onClick={(e) => getListing(currentTab)}
                    style={{ width: 100, margin: 10 }} type="primary">Search</Button>
                  <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                    type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                  {currentTab == 1 ? <span className="fl-right">
                    <FrzDynamicDownload name={'Reports'} fileName={'Reports'} url={'daily_attendance_reports/node_wise'}
                      payload={`?date=${valueByDeliveryDate}&platform=ops&node_code=${valueBySource === undefined ? '' : valueBySource}&roles=${valueByRoles === undefined ? '' : valueByRoles}&pnp_vender_id=${valueByPnpVendors === undefined ? '' : valueByPnpVendors}&download=true`}
                      type={'job'} reportFunc={'fraazo_report_auth'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                  </span> :
                    <span className="fl-right">
                      <FrzDynamicDownload name={'Reports'} fileName={'Report'} url={
                        currentTab == "2" ?
                          `daily_attendance_reports/node_specific_day_wise_report?start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}&node_code=${valueBySource}&download=${true}` + '&platform=ops'
                          : `daily_attendance_reports/node_specific_user_wise_report?start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}&node_code=${valueBySource}&download=${true}` + '&platform=ops'
                      }
                        payload={''} type={'job'} reportFunc={'fraazo_report_auth'} jobReportFunc={'download_reports_job_fraazo'}
                        base={'fraazo'} />
                    </span>}
                </Col>
              </Card>
            </Row>
            {listingData.length ?
              <Row>
                <Col span={24}>
                  <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                      <Col span={24}>
                        {currentTab == 1 ? <Table
                          columns={currentTab == 1 ? nodewiseData() : daywiseData()}
                          dataSource={listingData}
                          pagination={false}
                          scroll={{ y: 'calc(96vh - 325px)' }}
                          rowKey="darkstore_name"
                        />
                          :
                          valueBySource ?
                            <Table
                              columns={currentTab == 2 ? daywiseData() : reportsAttnUserColumns}
                              dataSource={listingData}
                              pagination={false}
                              scroll={{ y: 'calc(96vh - 325px)' }}
                              expandedRowRender={expandedRow}
                              rowKey={"id"}
                            /> : null}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              : (disable || spinEnable || (!valueBySource) ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">{currentTab === 1 || valueBySource ? "No Data Available" : "Please select a node"}</div>)
                :
                <div className="no-data">No Data Available On Selected Filters</div>)
            }
          </div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        }
      </div>

    </div>
  )
}

export default AttendanceReport