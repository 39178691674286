import React, { useState, useEffect } from 'react'
import { Modal, Table, Spin, Button, Collapse, Row, Col, Steps, Popover } from 'antd';
import { CrateService } from '../../../_services/crate';
import { transitDetailColumns } from '../../antd/columns/operations/crate';
import AddCrateType from './add_crate_type';
import { EditOutlined, CaretRightOutlined, DownOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';

const crate_service = new CrateService()
const { Step } = Steps;
const ModalTransitDetail = ({ tranistDetailModal, handleTransitModal, nodeCode, rowData }) => {
  const [listingData, setListingData] = useState(false);
  const { Panel } = Collapse;
  const [column, setColumn] = useState(false);

  useEffect(() => {
    getTransitDetails()
    getColumns()
  }, [])

  const getColumns = () => {
    let tempCol = []
    transitDetailColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push(
      {
        title: 'State',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center',
        render: (record, data) => {
          return (
            !record ?
              <div className="frz-dis-flex frz-hrz-center">
                <div className={record ? "frz-crate-detail-state-occ" : "frz-crate-detail-state-empty"}>
                  {record ? "Occupied" : "Empty"}
                </div>
              </div>
              :

              <div className="frz-dis-flex frz-hrz-center">
                <div className={record ? "frz-crate-detail-state-occ" : "frz-crate-detail-state-empty"}>
                  {record ? "Occupied" : "Empty"}
                </div>
              </div>

            // <Popover placement="bottom" content={() => <div style={{ padding: 12 }}>
            //   <div className="frz-label">
            //     Occupied with:
            //   </div>
            //   <span className="frz-crate-colm">
            //     {data.product_name}
            //   </span>
            //   <span className="frz-lp-5">
            //     -
            //   </span>
            //   <span className="frz-crate-colm frz-lp-5">
            //     {data.weight}
            //   </span>
            //   <span className="frz-crate-colm">
            //     {data.unit}
            //   </span>
            // </div>}>
            //   <div className="frz-dis-flex frz-hrz-center">
            //     <div className={record ? "frz-crate-detail-state-occ" : "frz-crate-detail-state-empty"}>
            //       {record ? "Occupied" : "Empty"}
            //     </div>
            //   </div>
            // </Popover>
          )
        }
      })
    setColumn(tempCol)
  }

  const getTransitDetails = () => {
    const payload = {
      node_code: nodeCode
    }
    crate_service.get_transit_details(payload).subscribe((r) => {
      setListingData(r.response.data)
    }, ((err) => {
      console.log("err", err);
    }))
  }

  const customDot = (dot, { status, index }) => (
    dot
  );


  return (
    <div>
      <Modal
        visible={tranistDetailModal}
        title={rowData ? rowData.node_name : "Transit Detail"}
        onCancel={() => handleTransitModal(false)}
        width='85%'
        className="order fl-right"
        footer={[
          <Button key="back" onClick={() => handleTransitModal(false)}>
            Close
          </Button>
        ]}>
        {listingData ?
          <div className="order-modal-container-no-padding">
            {listingData.length ?
              listingData.map((data, index) => {
                return (
                  <div key={index} className="frz-transit-detail-collapse">
                    <Collapse bordered={false} className="frz-tab-no-border" collapsible="header" accordion expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} className="frz-fw-800" />}>
                      <Panel className="frz-tab-no-margin-padding" header={
                        <>
                          <Row>
                            <Col span={8}>
                              <Steps current={1} progressDot={customDot}>
                                <Step title={
                                  <div className="frz-label frz-font-14">
                                    {data.source_node}
                                  </div>} />
                                <Step title={
                                  <div className="frz-label frz-font-14">
                                    {data.destination_node}
                                  </div>} />
                              </Steps>
                            </Col>
                            <Col span={12}>
                            </Col>
                            <Col span={4}>
                              <Row gutter={[10, 10]}>
                                <Col span={10}>
                                  <div className="frz-occupied-container">
                                    <div className="frz-label">
                                      Occupied
                                    </div>
                                    <div className="frz-fw-700 frz-font-16">
                                      {data.total_occupied_count}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="frz-occupied-container">
                                    <div className="frz-label">
                                      Empty
                                    </div>
                                    <div className="frz-fw-700 frz-font-16">
                                      {data.total_empty_count}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* <div className="frz-available-rider frz-dis-flex frz-vt-center">
                      {props.riderData.filter((obj) => obj.status_id === 2).length}
                    </div> */}
                        </>
                      } key="1">
                        <div style={{ background: "#fff", paddingTop: 5 }}>
                          <Table dataSource={data.crate_details} columns={column}
                            pagination={false} scroll={{ y: 'calc(100vh - 274px)' }}
                            rowKey={'node_code'} />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                )
              })
              :
              <div className="no-data">No Data Available</div>}
          </div>
          :
          <div className="spin-center order-modal-container">
            <Spin tip="...Loading" />
          </div>}
      </Modal>
    </div >
  )
}

export default ModalTransitDetail