import React from "react";
import { InputNumber, Button, Tooltip, Select } from "antd";
import { DeleteOutlined } from '@ant-design/icons'

function ListingTable(props) {

  function checkSkuFilter(value) {
    if (value.toLowerCase().includes(props.searchProduct)) {
      return ''
    }
    return 'wcl-search-filter-none'
  }

  return (
    <div className="wcl-createlisting-table-container p-relative wcl-bottom-border wcl-input">
      {props.data.length > 0 ?
        <div>
          {props.data.map((item, index) => {
            return (
              <div key={index} className={`${"wcl-listing-table"} 
                  ${props.searchProduct ? checkSkuFilter(item.name + item.sku) : ''}`}>
                <div className="wcl-40 wcl-align-center">
                  <img src={item.image_url} className="wcl-listing-img" />
                  <div>{item.name} - ({item.sku})</div>
                </div>
                <div className="wcl-10 wcl-align-center text-center">
                  {props.wcScreen === 'create_wastage' ? item.recorded_wastage_quantity : item.wastage_quantity}
                </div>
                <div className="wcl-15 wcl-align-center text-center">
                  <div className="p-relative">
                    {props.keyname === 'addsku' && item.max_threshold ? 
                      <span className={"wcl-threshold"}>
                        ({item.max_threshold} +)
                      </span> 
                    : null
                    }
                    <InputNumber min={0} size="large" onChange={(e) => props.onChangeWC(e, item, props.keyname)}
                      className={props.enableError && ((props.wcScreen === 'create_wastage' ? 
                      item.wastage_quantity === null : item.closing_quantity === null) || item.errors) ? 'wcl-input-error' : ''}
                      disabled={props.buttonLoading} 
                      value={props.wcScreen === 'create_wastage' ? item.wastage_quantity : item.closing_quantity} />
                    {props.enableError && item.errors ?
                      <div className="wcl-input-error-msg">
                        {item.errors}
                      </div>
                    : null}
                  </div>
                </div>
                {props.wcScreen === 'create_wastage' ? 
                  <div className="wcl-align-center wcl-20 text-center">
                    <div className="p-relative">
                      <Select placeholder="Select Reason" disabled={props.buttonLoading} value={item.reason_id}
                        onChange={(e) => props.onChangeWCReason(e, item, props.keyname)}
                        className={props.enableError && item.wastage_quantity > 0 && item.reason_id === null ? 
                          'wcl-select-error frz-w-200px' : 'frz-w-200px'}>
                        {props.reasons.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.id}>
                              {data.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                      {props.enableError && item.wastage_quantity > 0 && item.reason_id === null ?
                        <div className="wcl-input-error-msg">
                          Please select a reason
                        </div>
                      : null}
                    </div>
                </div>
                : null}
                <div className="wcl-10 wcl-align-center text-center frz-p-relative">
                  {item.unit_changed ? <div className="wcl-unit-changed top-minus-12">Unit Changed</div> : null}
                  {item.unit}
                </div>
                <div className="wcl-5 wcl-align-center text-center">
                  {props.keyname === 'addsku' ?
                    <Tooltip title="Remove SKU">
                      <Button icon={<DeleteOutlined />} danger type="primary" shape="circle" size="small"
                        onClick={() => props.removeSku(item.id)} />
                    </Tooltip>
                    : null}
                </div>
              </div>
            )
          })}
        </div>
        :
        <div className="horizontal-vertical-center frz-font-20">
          <b>No SKU's Available</b>
        </div>
      }
    </div>
  )
}

export default ListingTable