import React from 'react'
import { Form , Input , Space} from 'antd'
import { tailLayout } from '../_modules/controllayout'
import Password from 'antd/lib/input/Password'
const FRZPassword = ({ name, label, isRequired, placeholder, width, isDisabled }) => {

    return (
        <Form.Item
            {...tailLayout}
            name={name}
            label={label}
            rules={[{required:isRequired,message:"Please enter " + label}]}
        >
            <Password placeholder={placeholder ? placeholder : label} style={{width:width}} 
                disabled={isDisabled}/>
        </Form.Item>
    )
}

export default FRZPassword
