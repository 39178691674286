import React, { useState } from "react";
import { Upload, Row, Col, Card, Button, Spin, notification } from 'antd';
import { UploadService } from "../../../_services/uploads";
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const upload_service = new UploadService()

function Uploads(){
  const [uploadFile, setUploadFile] = useState(false)
  const [current, setCurrent] = useState(false)
  const [currentSpin, setCurrentSpin] = useState(false)
  const [fileList, setFileList] = useState([])

  const [zapperData] = useState(
    [
      { 
        name: 'Upload Stock Buffer', 
        url: 'admin_dashboard/procurement/uploads/stock_buffer_pct', 
        isDownload: 'https://sku-zapper.s3.ap-south-1.amazonaws.com/development_sample_upload/buffer_stock_update_report.csv' 
      },
      { 
        name: 'Last STO City Settings', 
        url: 'admin_dashboard/procurement/uploads/product_city_settings', 
        isDownload: 'https://sku-zapper.s3.ap-south-1.amazonaws.com/city_settings_update_sheet/2022-06-28_17%3A13_.csv' 
      },
      { 
        name: 'Upload City Product Tag', 
        url: 'admin_dashboard/procurement/uploads/update_city_product_tags', 
        isDownload: 'https://sku-zapper.s3.ap-south-1.amazonaws.com/city_settings_update_sheet/product_tags/2022_07_01_14_26_48_896_.csv' 
      },
    ]
  )

  function beforeUploadFile(file, url) {
    setCurrent(url)
    setUploadFile(false)
    setFileList([])
    const formData = new FormData();
    formData.append('file', file)
    setFileList([file])
    setUploadFile(formData)
  }

  function afterUpload(url) {
    setCurrentSpin(url)
    upload_service.upload_zapper_put(uploadFile, url).subscribe((r) => {
      setUploadFile(false)
      setFileList([])
      setCurrentSpin(false)
      setCurrent(false)
      openNotificationWithIcon('success', 'Upload File Succesfully')
    }, (err) => {
      setCurrentSpin(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'Upload Failed Please Check Internal File Format')
    })
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function handleRemove(url) {
    setUploadFile(false)
    setFileList([])
    setCurrent(false)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  return(
    <div>
       <Row>
        {zapperData.map((data, index) => {
          return (
            <Col md={8} xs={24} key={index}>
              <Card>
                <Spin spinning={currentSpin == data.url}>
                  <Dragger
                    name='file'
                    fileList={current === data.url ? fileList : []}
                    showUploadList={true}
                    customRequest={handleClick}
                    beforeUpload={(e) => beforeUploadFile(e, data.url)}
                    accept=".csv"
                    onRemove={handleRemove}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{data.name}</p>
                    <p className="ant-upload-hint">
                      Format :- CSV File
                    </p>
                  </Dragger>
                  <div className="frz-dis-tbl">
                    <div className='text-center'>
                      <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} 
                        className="frz-mt-16 frz-w-100px" type="primary">Upload</Button>
                    </div>
                    {data.isDownload ? 
                      <div className='zapper-url'>
                        <a href={data.isDownload}>
                          Download Sample File
                        </a>
                      </div> 
                    : null}
                  </div>
                </Spin>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default Uploads