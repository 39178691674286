import React, { useEffect, forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Table, Row, Col, Tabs, Button, Form } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import FRZUpload from '../../../_controls/FRZUpload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';

const SupplierTypeData = [
  { value: "farmer", option: "Farmer" },
  { value: "supplier", option: "Supplier" }
]

const procurementData = [{ name: "Fnv", value: "fnv" }, { name: "Fmgc", value: "fmcg" }]

const creditCycleTypeData = [
  { value: "by_date_of_transaction", option: "By Date" },
  { value: "by_week_of_transaction", option: "By Week" },
  { value: "none", option: "None" },
]

const payDayData = [
  { value: 2, option: "Tuesday" },
  { value: 5, option: "Friday" },
]

const documentTypeData = [
  { value: "aadhar_card", option: "Aadhar Card" },
  { value: "pan_card", option: "Pan Card" },
  { value: "cancelled_cheque", option: "Cancelled Cheque" },
]

const SupplierEditModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [isBankDetail, setIsBankDetail] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [isBankVerified, setIsBankVerified] = useState(false)
  const [circleDetail, setCircleDetail] = useState(false)
  const [originalFileDetails, setOriginalFileDetails] = useState({ name: "", contentType: "" })
  const [id, setId] = useState(false)
  const [supplierBankDetailId, setSupplierBankDetailId] = useState(false)
  const [supplierType, setSupplierType] = useState(false)
  const [imageState, setImageState] = useState({})


  useEffect(() => {
    if ((data && title == "Edit Suppliers Information")) {
      let fixed_category = []
      data.not_editable_payment_category.map(val => {
        fixed_category.push(val.id)
      })
      form.setFieldsValue({
        ...data,
        is_bank_details: data.supplier_bank_details.length > 0 ? true : false,
        bank_account_no: data.supplier_bank_details.length > 0 ? data.supplier_bank_details[data.supplier_bank_details.length - 1].bank_account_no : "",
        ifsc: data.supplier_bank_details.length > 0 ? data.supplier_bank_details[data.supplier_bank_details.length - 1].ifsc : "",
        bank_name: data.supplier_bank_details.length > 0 ? data.supplier_bank_details[data.supplier_bank_details.length - 1].bank_name : "",
        beneficiary_name: data.supplier_bank_details.length > 0 ? data.supplier_bank_details[data.supplier_bank_details.length - 1].beneficiary_name : "",
        supplier_documents_attributes: data.supplier_documents.length > 0 ? data.supplier_documents[0].document_type : "",
        fixed_category: fixed_category, category_parameters_id: data.payment_category
      })
      setId(data.id)
      resetUploads()
      setCircleDetail(data.credit_cycle_type)
      setSupplierType(data.supplier_type)
      setIsVerified(data.is_verified)
      setIsBankVerified(data.supplier_bank_details.length > 0 ? data.supplier_bank_details[data.supplier_bank_details.length - 1].is_verified : false)
      if (data.supplier_bank_details.length > 0) {
        setIsBankDetail(true)
        setSupplierBankDetailId(data.supplier_bank_details[data.supplier_bank_details.length - 1].id)
      } else {
        setIsBankDetail(false)
      }
    } else {
      setId(false)
    }
  }, [data])


  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const resetUploads = () => {
    setImageState({})
  }


  useImperativeHandle(ref, () => ({

    showEditModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  const handleBankDetail = (value) => {
    setIsBankDetail(value)
  }

  const handleIsVerified = (value) => {
    setIsVerified(value)
  }

  const handleCreditCycle = (value) => {
    setCircleDetail(value)
  }

  const handleOriginalFileDetail = (name, type) => {
    setOriginalFileDetails({ name: name, contentType: type })
  }

  const handleChangeSupplier = (value) => {
    setSupplierType(value)
  }

  function handleOnSubmit(values) {
    const payload = {
      ...values,
      id,
      supplier_bank_details_attributes:
      {
        id: supplierBankDetailId,
        ifsc: values.ifsc,
        bank_account_no: values.bank_account_no,
        bank_name: values.bank_name,
        beneficiary_name: values.beneficiary_name
      },
      supplier_documents_attributes: [{
        document_type: values.document_type,
        original_filename: originalFileDetails.name,
        content_type: originalFileDetails.contentType,
        image_base: imageState.content_type
      }]
    }
    delete payload.fixed_category
    setLoading(true)
    // console.log("payload", payload)
    modalSubmit(payload)
  }

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }


  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'60%'}
          okButtonProps={{ loading: loading }}
          onCancel={handleCancelModal}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true}
              label="Vendor Name" placeholder="Vendor Name" width={200} />

            <FRZInput name="organisation_name" isRequired={true}
              label="Organisation Name" placeholder="Organisation Name" width={200} />

            <FRZInput name="mobile_number" isRequired={true}
              label="Contact Number" placeholder="Contact Number" width={200} />

            <FRZInput name="other_mobile_number" label="Other Number" placeholder="Other Number" width={200} />

            <FRZInput name="email" label="Email" placeholder="Email" width={200} />

            <FRZInput name="shop_no" isRequired={true}
              label="Shop No" placeholder="Shop No" width={200} />

            <FRZInput name="address" isRequired={true}
              label="Address" placeholder="Address" width={200} />

            <FRZSelect DropDownData={props.nodes} name="node_id" isRequired={true}
              label="Select Node" placeholder="Select Node Name" value="id" option="name"
              isShowSearch={true} width={300} />

            <FRZInput name="pan_uid" label="Pan UID" placeholder="Pan UID" width={200} />

            <FRZSelect mode="multiple" DropDownData={data.not_editable_payment_category} name="fixed_category" isRequired={false}
              label="Auto Assigned Supplier Category" placeholder="Select Supplier Category" value="id" option="description"
              isShowSearch={true} width={"100%"} isDisabled={true} />

            <FRZSelect DropDownData={procurementData} name="procurement_type" isRequired={true}
              label="Procurement type" placeholder="Select procurement type" value="value" option="name"
              isShowSearch={true} width={"100%"} />

            {supplierType === 'supplier' ?
              <FRZSelect mode="multiple" DropDownData={props.supplierCategory} name="category_parameters_id" isRequired={true}
                label="Supplier Category" placeholder="Select Supplier Category" value="id" option="description"
                isShowSearch={true} width={"100%"} />
              : null}

            <FRZCustomRadioButon name="supplier_type" label="Supplier Type" isRequired={true}
              optionData={SupplierTypeData} option="option" value="value" setOnChange={handleChangeSupplier} />

            <FRZCustomRadioButon name="credit_cycle_type" label="Credit Cycle Type" disabled={true}
              optionData={creditCycleTypeData} option="option" value="value" setOnChange={handleCreditCycle} />

            {circleDetail === "by_week_of_transaction" &&
              <FRZCustomRadioButon name="pay_day_in_week" label="Pay Day In Week"
                optionData={payDayData} option="option" value="value" disabled={true} />}

            <FRZRadioButton name="is_bank_details" label="Bank Details"
              setOnChange={handleBankDetail} disabled={true} />

            {isBankDetail &&
              <>
                <FRZInput name="bank_account_no" isRequired={true} isDisabled={isBankVerified}
                  label="Account Number" placeholder="Account Number" width={200} />

                <FRZInput name="ifsc" isRequired={true} isDisabled={isBankVerified}
                  label="IFSC" placeholder="IFSC" width={200} />

                <FRZInput name="bank_name" isRequired={true} isDisabled={isBankVerified}
                  label="Bank Name" placeholder="Bank Name" width={200} />

                <FRZInput name="beneficiary_name" isRequired={true} isDisabled={isBankVerified}
                  label="Beneficiary Name" placeholder="Beneficiary Name" width={200} />
              </>
            }

            <FRZCustomRadioButon name="document_type" label="Document Type"
              optionData={documentTypeData} option="option" value="value" />

            {/* <FRZRadioButton name="is_verified" label="Is Verified" isRequired={true}
              setOnChange={handleIsVerified} /> */}

            <FRZUpload name="content_type" className="avatar-uploader"
              customRequest={handleClick} imgWidth={"100%"} label={"Upload"} listType="picture-card" setOnChangeType={"content_type"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage} isDisabled={isVerified}
              imageState={imageState} showFileName={true} handleOriginalFileDetail={handleOriginalFileDetail} />
          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default SupplierEditModal
