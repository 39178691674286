import moment from "moment"
import { handleDateFormat } from "../../../../_modules/date"
import { humanize } from "../../../../_modules/humanize"
import { getStaticTime } from "../../../../_modules/time"
import { Tag } from 'antd'

export const pendingApprovalColumns = [
  {
    title: humanize('requested_on'),
    dataIndex: 'requested_on',
    key: 'requested_on',
    align: 'center',
    width: 120,
    render: (record) => <div>{handleDateFormat(record) + " " + getStaticTime(record)}</div>
  },
  {
    title: 'CC',
    dataIndex: 'node',
    key: 'node',
    align: 'center',
    width: 120
  },
  {
    title: humanize('approval_type'),
    dataIndex: 'approval_type',
    key: 'approval_type',
    align: 'center',
    width: 120
  },
  {
    title: humanize('request_by'),
    dataIndex: 'request_by',
    key: 'request_by',
    align: 'center',
    width: 120
  },
  // {
  //   title: humanize('has_child_requests'),
  //   dataIndex: 'has_child_requests',
  //   key: 'has_child_requests',
  //   align: 'center',
  //   render: (record) => <div>{record ? "True" : "False"}</div>
  // },
  {
    title: humanize('request_reason'),
    dataIndex: 'reason',
    key: 'reason',
    align: 'center',
    width: 300,
    render: (record, data) => <div>
      <div className="frz-align-left">
        {record}
      </div>
      <div>
        {data.request_approvals.map((item) => {
          return (
            <div className={`${item.action_type == "pending" ? "frz-clr-warning" :
              item.action_type == "approved" ? "frz-clr-approved" :
                item.action_type == "rejected" ? "frz-clr-rejected" :
                  item.action_type == "discard" && "frz-clr-discard"} ${'frz-align-left'}`}>
              Level - {item.level} | {humanize(item.action_type)} | {item.name}
            </div>
          )
        })}
      </div>
    </div >
  }
]

export const pendingApprovalTableColumns = [
  {
    title: humanize('name'),
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 120,
  },
  {
    title: humanize('image_url'),
    dataIndex: 'image_url',
    key: 'image_url',
    align: 'center',
    width: 120,
    render: (record) => <img src={record} style={{ width: 60, height: 60 }} />
  },
  {
    title: humanize('fraazo_sku'),
    dataIndex: 'fraazo_sku',
    key: 'fraazo_sku',
    align: 'center',
    width: 120
  },

  {
    title: humanize('inventory_unit'),
    dataIndex: 'inventory_unit',
    key: 'inventory_unit',
    align: 'center',
    width: 120
  },

  {
    title: humanize('current_stock'),
    dataIndex: 'current_stock',
    key: 'current_stock',
    align: 'center',
    width: 120
  },

  {
    title: humanize('closing_stock'),
    dataIndex: 'closing_stock',
    key: 'closing_stock',
    align: 'center',
    width: 120
  },

  {
    title: humanize('expected_closing_stock'),
    dataIndex: 'expected_closing_stock',
    key: 'expected_closing_stock',
    align: 'center',
    width: 120
  },

  {
    title: humanize('corrected_closing_stock'),
    dataIndex: 'corrected_closing_stock',
    key: 'corrected_closing_stock',
    align: 'center',
    width: 120,
    render: (record) => <Tag color="red">{record}</Tag>
  },


  // {
  //   title: humanize('request_status'),
  //   dataIndex: 'request_status',
  //   key: 'request_status',
  //   align: 'center',
  //   width: 120
  // },

  // {
  //   title: humanize('requested_at'),
  //   dataIndex: 'requested_at',
  //   key: 'requested_at',
  //   align: 'center',
  //   width: 120,
  //   render: (record) => <div>{handleDateFormat(record)}</div>
  // },
]
