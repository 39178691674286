import React from 'react'
import { Form, Radio } from 'antd'
import { tailLayout } from '../_modules/controllayout'
const FRZRadioButton = ({ name, label, isRequired, setOnChange, disabled }) => {

  const onchange = (e) => {
    if (setOnChange) {
      setOnChange(e.target.value)
    }
  }

  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      rules={isRequired ? [
        {
          required: true,
          message: `Please input the value of ${label}!`,
        },
      ] : []}>
      <Radio.Group onChange={onchange} disabled={disabled}>
        <Radio value={true}>True</Radio>
        <Radio value={false}>False</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default FRZRadioButton
