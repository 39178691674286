export const WorkBenchColumns = [
  {
    title: "City Name",
    dataIndex: "city_name",
    key: "city_name",
    align: "center",
  },
  {
    title: "Dark Store Name",
    dataIndex: "dark_store_name",
    key: "dark_store_name",
    align: "center",
  },
  {
    title: "SKU Code",
    dataIndex: "sku_code",
    key: "sku_code",
    align: "center",
  },
  {
    title: "SKU Name",
    dataIndex: "sku_name",
    key: "sku_name",
    align: "center",
  },
  {
    title: "Stock Status",
    dataIndex: "stock_status",
    key: "stock_status",
    align: "center",
    render: (record) => {
      return record ? "In stock" : "Out of stock"
    }
  },
  {
    title: "Order Edited",
    dataIndex: "orders_edited",
    key: "orders_edited",
    align: "center"
  },
  {
    title: "Current Inventory",
    dataIndex: "current_inventory",
    key: "current_inventory",
    align: "center",
  },
  {
    title: "Forecast Quantity",
    dataIndex: "forecast_quantity",
    key: "forecast_quantity",
    align: "center",
  },
  {
    title: "Grn Quantity",
    dataIndex: "grn_quantity",
    key: "grn_quantity",
    align: "center",
  },
  {
    title: "Po Quantity",
    dataIndex: "po_quantity",
    key: "po_quantity",
    align: "center",
  },
  {
    title: "Sale Quantity",
    dataIndex: "sale_quantity",
    key: "sale_quantity",
    align: "center",
  },
  {
    title: "STO in Quantity",
    dataIndex: "sto_in_quantity",
    key: "sto_in_quantity",
    align: "center",
  },
  {
    title: "STO Out Quantity",
    dataIndex: "sto_out_quantity",
    key: "sto_out_quantity",
    align: "center",
  },
  {
    title: "Unrealised Quantity",
    dataIndex: "unrealised_quantity",
    key: "unrealised_quantity",
    align: "center",
  },
  // {
  //   title: "Verified",
  //   dataIndex: "",
  //   key: "",
  //   align: "center",
  //   render: (record) => {
  //     return record.is_verified.toString()
  //   }
  // },

]
