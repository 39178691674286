import React, { useState } from 'react';
import { Button, Upload, Tooltip } from 'antd'
import { UploadService } from '../_services/uploads';
import { openNotificationWithIcon } from '../_modules/notification';

const upload_service = new UploadService()

function FrzDynamicUpload({ name, url, uploadFunc, callBack, disabled, customName, checkPoType, modType, preUpload, setScreenLoad, m0 }) {

    const [loading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState(false)
    const [fileList, setFileList] = useState([])

    function afterUpload() {
        setLoading(true)
        if (setScreenLoad) {
            setScreenLoad(true)
        }
        upload_service[uploadFunc](uploadFile, url).subscribe((r) => {
            setUploadFile(false)
            setLoading(false)
            setFileList([])
            openNotificationWithIcon('success', 'Uploaded File Succesfully')
            if (callBack) {
                callBack(r.response.data)
            }
        }, (err) => {
            setLoading(false)
            if (setScreenLoad) {
                setScreenLoad(false)
            }
            openNotificationWithIcon('error', err?.response?.errors[0] ?? 'Upload Failed Please Check Internal File Format')
        })
    }

    const props = {
        onRemove: file => {
            setUploadFile(false)
            setFileList([])
        },
        beforeUpload: file => {
            const formData = new FormData();
            formData.append('file', file)
            setFileList([file])
            setUploadFile(formData)
            return false
        },
        fileList,
    };
    
    return (
        <>
            <Upload {...props} disabled={checkPoType ?
                localStorage.getItem('procurementtype') === null ||
                    localStorage.getItem('procurementtype') === '' ?
                    true : false : false}>
                {fileList.length === 0 ?
                    (checkPoType && (localStorage.getItem('procurementtype') === null ||
                        localStorage.getItem('procurementtype') === '') ?
                        <Tooltip placement="topLeft" title={"Please select a procurement type on top right"}>
                            <Button type="primary" className="frz-dwn" disabled={disabled || checkPoType ?
                                localStorage.getItem('procurementtype') === null ||
                                    localStorage.getItem('procurementtype') === '' ?
                                    true : false : false}>{customName ? customName : "Upload"} {name}</Button>
                        </Tooltip>
                        :
                        <Button type="primary" disabled={disabled} ghost={modType === 'wcl'}
                            className={` ${"frz-dwn"} ${m0 ? 'm0' : ''} ${modType === 'wcl' ? disabled ? 'wcl-ghost' : "wcl-black-button wcl-ghost" : ''}`}>
                            {customName ? customName : "Upload"} {name}
                        </Button>
                    )
                    : null}
            </Upload>
            {fileList.length > 0 ?
                <Button
                    type="primary"
                    onClick={afterUpload}
                    disabled={fileList.length === 0}
                    loading={loading}
                    ghost={modType === 'wcl'}
                    className={`${"frz-dwn"} ${m0 ? 'm0' : ''} ${modType === 'wcl' ? disabled ? 'wcl-ghost' : "wcl-black-button wcl-ghost" : ''}`}
                >
                    Start {name} {customName ? customName : "Upload"}
                </Button>
                : null}
        </>
    );
}

export default FrzDynamicUpload;
