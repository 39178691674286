import { Checkbox, Col, Divider, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import { openNotificationWithIcon } from '../../../_modules/notification'
import { GeneralService } from "../../../_services/general"
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch"
import { OperationsService } from "../../../_services/operations"

const general_service = new GeneralService()
const operation_service = new OperationsService()

const initState = {
  statesData: false,
}

const initialSpinner = {
  stateSpinner: false,
  nodeSpinner: false
}

const CCFilterComponent = ({ setModalFilters, modalFilters, setCCState, ccState, setModalDisplayFilter, modalDisplayFilter }) => {
  const [state, setState] = useState(initState)
  const [spinners, SetSpinner] = useState(initialSpinner)

  useEffect(() => {
    getState();
  }, [])

  useEffect(() => {
    if (modalDisplayFilter?.stateId) {
      getNodeListing()
    }
  }, [modalDisplayFilter?.stateId])

  const getState = () => {
    SetSpinner((prev) => ({ ...prev, stateSpinner: true }))
    general_service.get_state().subscribe((r) => {
      setState((prev) => ({ ...prev, statesData: r.response.data }))
      SetSpinner((prev) => ({ ...prev, stateSpinner: false }))
    },
      (err) => {
        console.log(err)
        SetSpinner((prev) => ({ ...prev, stateSpinner: false }))
        openNotificationWithIcon('error', 'Backend API Error')
      })
  }

  const getNodeListing = () => {
    SetSpinner((prev) => ({ ...prev, nodeSpinner: true }))
    const payload = {
      nodeType: `["CC","WH"]`,
      by_state: modalDisplayFilter.stateId ? modalDisplayFilter.stateId : ''
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      let temp = r?.response?.data.map((item) => {
        let exist = modalFilters?.by_node?.find(id => id === item.id)
        item.isChecked = exist ? true : false;
        return item;
      })
      setCCState(temp)
      setModalDisplayFilter((prev) => ({ ...prev, by_node: temp }))
      SetSpinner((prev) => ({ ...prev, nodeSpinner: false }))
    },
      (err) => {
        console.log(err)
        SetSpinner((prev) => ({ ...prev, nodeSpinner: false }))
      })
  }

  const onChange = (e) => {
    setModalDisplayFilter((prev) => ({ ...prev, stateId: e }))
  }

  return (
    <>
      {!spinners.stateSpinner ?
        <div>
          <FrzDynamicSearch DropDownData={state.statesData ? state.statesData : null}
            placeholder="Select State" value="id" option="name"
            isShowSearch={true} width={140} setOnChange={onChange}
            type="" defValue={modalDisplayFilter.stateId ? modalDisplayFilter.stateId : null} />
          <div>
            <Divider />
            {modalDisplayFilter.stateId ? <div>
              <Checkbox.Group
                style={{
                  width: '100%',
                }}
                defaultValue={modalFilters?.by_node}
                onChange={(e) => setModalFilters((prev) => ({ ...prev, by_node: e }))}>
                {ccState?.length > 0 ? <Row>
                  {ccState?.map((item) => {
                    return (
                      <Col span={8} key={item?.id}>
                        <Checkbox value={item?.id} key={item.id} checked={true}>{item.name}</Checkbox>
                      </Col>
                    )
                  })}
                </Row>
                  : <div className='CC-no-data graph-color-display-flex'>No-Data Selected State</div>}
              </Checkbox.Group>
            </div> : <div className="CC-no-data">Please Select A State</div>}
          </div>
        </div> :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </>
  )
}

export default CCFilterComponent