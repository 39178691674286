import { Http } from '../_modules/http'

export class CrateService {

  http = new Http()

  get_crate_listing(payload) {
    // const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crate_stats?criteria=node_wise'
    //   + "&page=" + `${payload.page}` + "&node_type=" + `${payload.node_type}`
    //   + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
    //   + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    //   + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    // https://api.staging.zapper.fraazo.com/v2/stats_dashboard/crates?by_node_type=DS&by_city=MUM
    const url = process.env.REACT_APP_AWS3_URL + `/v2/stats_dashboard/crates?page=${payload.page}&by_node_type=${payload.node_type}&by_city=${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_crate_types() {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crate_types'
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  edit_crate_type(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crate_types/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  add_crate_type(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crate_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload.body, headers)
  }

  add_new_crate(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crates'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_transit_details(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/view_transit_details?node_code=' + payload.node_code
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_crate_details(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/view_crate_details?node_code=' + payload.node_code
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_crate_products() {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/crate_products'
      + '?by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}