import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Spin } from 'antd';
import { OperationsService } from '../../../_services/operations'
import MainTable from '../../../components/antd/table';
import { poPaymentDetailColumn } from '../../../components/antd/columns/operations/category';

const opr_service = new OperationsService()

const ViewDetails = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [detailData, setDetailData] = useState(([]))
  const [data, setData] = useState(false)

  const getDetailData = () => {
    const payload = {
      id: data.id
    }
    opr_service.get_po_payment_list(payload).subscribe((r) => {
      setDetailData(r.response.data)
    }, (err) => {
      console.log("err")
    })
  }

  useEffect(() => {
    if ((data && (title == "View Details"))) {
      getDetailData(data.id)
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showDetailModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    setVisible(false)
    setLoading(false)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          width={'80%'}
          okButtonProps={{ style:{display:"none"}}}
          onCancel={handleCancelModal}>
          {detailData ?
            <MainTable dataSource={detailData} columns={poPaymentDetailColumn} />
            : <div className="spin-center">
              <Spin tip="...Loading" />
            </div>}
        </Modal>
        :
        null}
    </div>
  )
})

export default ViewDetails