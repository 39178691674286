import { Button, DatePicker, Divider, Input, InputNumber, Modal, Radio, Slider, Tabs } from "antd"
import moment from "moment";
import { useEffect, useState } from "react";
import FilterCCSelection from "./FilterCCSelection";
const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';
const SupplierFilterModal = ({ handleApplyFilters, ccData, setCcData, handleClear, show, hide, state, setState }) => {
  const [values, setValues] = useState({
    start_date: undefined, end_date: undefined, by_supplier_type: '', showDate: false,
    by_node: undefined, by_holding_start: 0, by_holding_end: 0, by_credit_cycle: ''
  })
  useEffect(() => {
    setValues((prev) => ({ ...prev, ...state }))
  }, [])
  const handleOk = () => {
    hide(false);
  };
  const handleChange = (type, value) => {
    if (type === 'rangePicker') {
      setValues((prev) => ({ ...prev, by_holding_start: value[0], by_holding_end: value[1] }))
    } else {
      setValues((prev) => ({ ...prev, [type]: value }))
    }
  }
  function handleDatePicker(value, dateString) {
    setValues(prevState => ({ ...prevState, showDate: true, start_date: dateString[0], end_date: dateString[1] }))
  }
  const handleCancel = () => {
    hide(false);
  };
  const handleSubmit = () => {
    setState((prev) => ({ ...prev, ...values }))
    handleApplyFilters(state)
  }
  function disabledDate(current) {
    return current && (current < moment().subtract(365, "day") || current > moment().add(0, "day"));
  }
  return (
    <>
      <Modal className='supplier-mgmt-filter-modal' footer={false} title="Filters" visible={show} onOk={handleOk} onCancel={handleCancel}>
        <Tabs tabPosition={'left'}>
          <TabPane tab="Date" key="1">
            <div className='frz-fw-500'>Select Start date and End date</div>
            <Divider />
            <DatePicker.RangePicker value={values.showDate ? [moment(values.start_date, dateFormat),
            moment(values.end_date, dateFormat)] : ''} allowClear={false} format={dateFormat}
              onChange={handleDatePicker}
              disabledDate={disabledDate}
            ></DatePicker.RangePicker>
          </TabPane>
          <TabPane tab="Type" key="2">
            <div className='frz-fw-500'>Type of Supplier</div>
            <Divider />
            <div className='supplier-type-select-container'>
              <Radio.Group buttonStyle="solid" value={values.by_supplier_type} onChange={(e) => handleChange("by_supplier_type", e.target.value)}>
                <Radio.Button value="farmer">Farmer</Radio.Button>
                <Radio.Button value="supplier">Vendor</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </div>
          </TabPane>
          <TabPane tab="CC" key="3">
            <FilterCCSelection ccData={ccData} setCcData={setCcData} handleChange={handleChange} parentState={state} />
          </TabPane>
          <TabPane tab="Land Holding" key="4">
            <div className="frz-dis-flex land-holding-container">
              <div>
                <div>From</div>
                <div>
                  <InputNumber style={{ width: '150px', marginRight: '10px' }} onChange={(e) => setValues((prev) => ({ ...prev, by_holding_start: e }))} value={values.by_holding_start} />
                  <p>In Acres</p>
                </div>
              </div>
              <div>
                <div>To</div>
                <div>
                  <InputNumber style={{ width: '150px' }} onChange={(e) => setValues((prev) => ({ ...prev, by_holding_end: e }))} value={values.by_holding_end} />
                  <p>In Acres</p>
                </div>
              </div>
            </div>
            <Divider />
            <div className='frz-mt-50'>
              <Slider onChange={(e) => handleChange("rangePicker", e)}
                max={500}
                range={{
                  draggableTrack: true,
                }}
                value={[values.by_holding_start, values.by_holding_end]}
              />
              <p className='fl-right'>In Acres</p>
            </div>
          </TabPane>
          <TabPane tab="Credit Cycle" key="5">
            <div className='frz-fw-500'>Credit Cycle</div>
            <Divider />
            <div>
              <Radio.Group buttonStyle="solid" onChange={(e) => handleChange("by_credit_cycle", e.target.value)} value={values.by_credit_cycle}>
                <Radio.Button value="by_date_of_transaction">By Date</Radio.Button>
                <Radio.Button value="by_week_of_transaction">By Week</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </div>
          </TabPane>
        </Tabs>
        <div className='frz-m-20 frz-ml-17p frz-mt-10p'>
          <Button type='primary' className='apply-filter-btn' onClick={handleSubmit}>Apply Filters</Button>
          <Button className='frz-lm-5 apply-filter-btn' type='danger' ghost onClick={handleClear}>Clear</Button>
        </div>
      </Modal></>
  )
}
export default SupplierFilterModal