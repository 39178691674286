import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form, InputNumber, Select, Row, Col, Button, Spin, Radio } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZInput from '../../../_controls/FRZInput';
import moment from 'moment';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { tailLayout } from '../../../_modules/controllayout';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZTimePicker from '../../../_controls/FRZTimePicker';
import { OperationsService } from '../../../_services/operations';
import FRZRadioButton from '../../../_controls/FRZRadioButton';

const ops_service = new OperationsService()
const { Option } = Select;

const CreateStoModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [skuData, setSkuData] = useState(false)
  const [isSelected, setIsSelected] = useState([]);
  const [disable, setDisable] = useState(true)
  const [breakOption, setBreakOption] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [node, setNode] = useState(false)
  const [isFieldRequired, setIsFieldRequired] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsSelected([])
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsSelected([])
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {

    if (values.items === undefined || values.items.length === 0) {
      openNotificationWithIcon('error', "Please fetch and add at least one sku")
      ref.stopLoading()
    } else {
      values.items.map((data) => {
        Object.assign(data, { ...data, transfer_unit_type: "po_unit" })
      })
      const payload = {
        ...values, transfer_date: moment(values.transfer_date).format("YYYY-MM-DD"),
        sto_format_type: "old",
        expected_arrival_time: values.expected_arrival_time ? moment(values.expected_arrival_time).format("HH:mm") : ""
      }
      setLoading(true)
      modalSubmit(payload)
    }
  }

  function disabledDate(current) {
    return current && (current < moment().subtract(1, "day") || current > moment().add(10, "day"));
  }

  const handleChange = (e) => {
    let res = form.getFieldValue("items")
    setIsSelected(res)
  };

  function fetchSkus() {
    setIsLoading(true)
    setDisable(true)
    const payload = { id: node }
    ops_service.get_sto_skus(payload).subscribe((r) => {
      setIsLoading(false)
      setSkuData(r.response.data)
    },
      (err) => {
        console.log(err)
        setIsLoading(false)
        setDisable(false)
      })
  }

  const onChangeNode = (value) => {
    setNode(value)
    setSkuData(false)
    setDisable(false)
  }

  const onChangeForm = () => {
    let data = form.getFieldValue()
    if (data.vehicle_number?.length || data.transport_provider?.length) {
      setIsFieldRequired(true)
    } else {
      setIsFieldRequired(false)
    }
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'80%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form"
            initialValues={{ sto_format_type: "old" }}
            onChange={() => onChangeForm()}>

            <FRZSelect DropDownData={props.nodes} name="source_node_id" isRequired={true}
              label="Source Name" placeholder="Select Source Name" value="id" option="name"
              isShowSearch={true} width={"40%"} setOnChange={onChangeNode} />

            <FRZSelect selectDisabled={!node} DropDownData={node ? props.destinations.filter((x) => x?.id != node) : []} name="destination_node_id" isRequired={true}
              label="Destination" placeholder="Select Destination" value="id" option="name"
              isShowSearch={true} width={"40%"} />

            <FRZDatepicker placeholder="Select Delivery Date" label="Delivery Date" isRequired={true}
              name="transfer_date" isDisabled={false} disabledDate={disabledDate} />

            {/* <FRZInputNumber isRequired={false} label="Driver Mobile No" name="driver_mobile_number" width={200} />

            <FRZTimePicker isRequired={false} label="Extended Arrival Time" name="expected_arrival_time" />

            <FRZInput name="vehicle_number" label="Vehicle No" isRequired={isFieldRequired}
              placeholder="Vehicle No" width={200} />

            <FRZInput name="transport_provider" label="Transport Provider" isRequired={isFieldRequired}
              placeholder="Transport Provider" width={200} />

            <FRZInput name="transporter_gstin" label="Transporter GSTIN" isRequired={false}
              placeholder="Transporter GSTIN" width={200} /> */}

            {/* <Form.Item
              {...tailLayout}
              name={"sto_format_type"}
              label={"STO Format Type"}
              rules={[
                {
                  required: true,
                  message: `Please input the value of STO Format Type!`,
                },
              ]}>
              <Radio.Group disabled>
                <Radio value={"new"}>New</Radio>
                <Radio value={"old"}>Old</Radio>
              </Radio.Group>
            </Form.Item> */}

            {/* <FRZRadioButton name="is_verified" label="Is Active" isRequired={true} /> */}

            <div className="text-center frz-bm-20">
              <Button onClick={fetchSkus} disabled={disable || node === undefined}
                className="frz-w-100px frz-m-10" type="primary">Fetch SKU's</Button>
            </div>

            {skuData ?
              <div>
                <div className="sto-modal-border"></div>
                <Row className="frz-bm-20 text-center">
                  <Col offset={8} span={3}><b>Current Stock</b></Col>
                  <Col span={3}><b>Inventory Unit</b></Col>
                  <Col span={3}><b>Available Qty</b></Col>
                </Row>
                <Form.List name="items" key={Math.random(1, 100)}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={index} className="create-sto-border">
                          <Row gutter={41} key={index} >
                            <Col span={8}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights}>
                                {() => (
                                  <Form.Item
                                    {...field}
                                    {...tailLayout}
                                    name={[field.name, 'sku']}
                                    fieldKey={[field.fieldKey, 'sku']}
                                    rules={[{ required: true, message: 'Missing sku' }]}>

                                    <Select className="frz-width-220p" onChange={handleChange}
                                      placeholder="Select SKU"
                                      showSearch
                                      optionFilterProp="children"
                                    // filterOption={(input, option) =>
                                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                    // filterOption={(input, option) =>
                                    //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                      {skuData.map(item => (
                                        <Option disabled={isSelected?.map(e => e?.sku).includes(item.sku)}
                                          className={breakOption ? "frz-line-break" : ""}
                                          key={item.sku} value={item.sku} >
                                          {item.name} - ( {item.sku} )
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <div className="text-center">
                                {isSelected.map((data, i) => (
                                  i === index ?
                                    (JSON?.parse(skuData.find(x => x?.sku === data?.sku)?.product_current_stock))
                                    : null
                                ))}
                              </div>
                            </Col>
                            <Col span={3}>
                              <div className="text-center">
                                {isSelected.map((data, i) => (
                                  i === index ?
                                    <div>
                                      {skuData.find(x => x.sku === data.sku).unit_changed ? <div className="wcl-unit-changed top-minus-30">Unit Changed</div> : null}
                                      {skuData.find(x => x.sku === data.sku).inventory_unit}
                                    </div>
                                    : null
                                ))}
                              </div>
                            </Col>
                            <Col span={3}>
                              <div className="text-center">
                                {isSelected.map((data, i) => (
                                  i === index ?
                                    skuData.find(x => x.sku === data.sku).available_qty
                                    : null
                                ))}
                              </div>
                            </Col>

                            <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'transfer_unit_qty']}
                                fieldKey={[field.fieldKey, 'transfer_unit_qty']}
                                rules={[{ required: true, message: 'Missing Qty' }]}>
                                <InputNumber placeholder="Transfer Qty" required={true} name="transfer_unit_qty"
                                  className="frz-width-110p" />
                              </Form.Item>
                            </Col>

                            {/* <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'unit_price']}
                                fieldKey={[field.fieldKey, 'unit_price']}
                                rules={[{ required: true, message: 'Missing Price' }]}>
                                <InputNumber placeholder="Unit Price" required={true} name="unit_price" 
                                  className="frz-width-110p" />
                              </Form.Item>
                            </Col> */}

                            {/* <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'transfer_unit_type']}
                                fieldKey={[field.fieldKey, 'transfer_unit_type']}
                                rules={[{ required: true, message: 'Missing Unit type' }]}>

                                <Select className="frz-width-110p" onChange={handleChange}
                                  placeholder="Select Unit type">
                                  <Option key='po_unit' value='po_unit'>PO Unit</Option>
                                  <Option key='packets' value='packets'>Packets</Option>
                                  <Option key='crates' value='crates'>Crates</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}

                            <Col>
                              <Button className="frz-lm-20 frz-p-0 frz-width-36p frz-tp-2" type="primary"
                                icon={<DeleteOutlined />}
                                shape="round" onClick={() => {
                                  remove(field.name)
                                  let response = form.getFieldValue("items")
                                  setIsSelected(response)
                                }}>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <Button className="frz-width-104p" type="primary" onClick={() => add()} block
                        icon={<PlusCircleOutlined />}>
                        Add SKU
                      </Button>
                    </>
                  )}
                </Form.List>
              </div>
              :
              isLoading ?
                <div className="spin-center">
                  <Spin tip="...Loading" />
                </div>
                : null}
          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div >
  )
})

export default CreateStoModal
