import React, { useState, useEffect, useRef } from "react";
import {calculateTime} from '../../common/calculate_time';
import {timeFormat} from '../../common/time_format';
import { Button, Spin, Tabs } from 'antd';
import email from '../../../../static/images/email.svg';
import print from '../../../../static/images/print.svg';
import download from '../../../../static/images/download.svg';
import AdhocWastageModal from './adhoc_wastage_modal/modal';
import WastageTable from './table';
import {openNotificationWithIcon} from '../../../../_modules/notification';
import TimeScreen from './timescreen';
import CreateWastageModal from './create_wastage_modal';

const { TabPane } = Tabs;
var interval

function Wastage(props){
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const [timer, setTimer] = useState(false)
  const [allowFinalWastage, setAllowFinalWastage] = useState(false)
  const [allowAdhocWastage, setAllowAdhocWastage] = useState(false)
  const [allowRequest, setAllowRequest] = useState(false)
  const [productData, setProductData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('adhoc')
  const [wastageData, setWastageData] = useState(false)
  const [showTimeScreen, setShowTimeScreen] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  
  var expiryTimestamp
  var date = new Date()

  useEffect(() => {
    if(props.data.current_transaction_count !== props.data.transaction_limit){
      setTime()
    }else{
      setCurrentTab('final')
      setAllowAdhocWastage(true)
    }
  },[])

  function setTime() {
    var startTime = timeFormat(props.data.cutoff_starts_in)
    var endTime = timeFormat(props.data.cutoff_ends_in)
    var approvalEndTime = timeFormat(props.data.approval_ends_in)
    if(new Date(startTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = startTime
      setAllowAdhocWastage(true)
      getTimer()
    }
    else if(new Date(endTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = endTime
      setAllowFinalWastage(true)
      setCurrentTab('daily')
      setAllowAdhocWastage(false)
      getTimer()
    }else if(new Date(approvalEndTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = approvalEndTime
      setAllowRequest(true)
      setCurrentTab('daily')
      setAllowFinalWastage(false)
      getTimer()
    }else{
      setAllowRequest(false)
      setShowTimeScreen(true)
    }
  }

  function getTimer() {
    interval = setInterval(() => {
      setTimer(calculateTime(expiryTimestamp))
    }, 1000)
  }

  useEffect(() => {
    if (timer === '00:00:00') {
      clearInterval(interval)
      setTime()
    }
  }, [timer])

  function addWastage(){
    setLoading('adhoc')
    props.wc_service.get_product_data_create_partial_wastage(props.currentNode).subscribe((r) => {
      setLoading(false)
      let newRes = r.response.data.map(x => ({...x, wastage_quantity: null, errors: null, reason_id: null}))
      setProductData(newRes)
      modalRef.current.showModal('Create Adhoc Wastage', newRes)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error', 'Backend API Error')
      setLoading(false)
    })
  }

  useEffect(() => {
    getWastageData()
  },[currentTab, props.currentDate])

  function getWastageData(){
    setWastageData(false)
    const payload = {
      node_code: props.currentNode,
      is_partial_wastage: currentTab === 'adhoc' || currentTab === 'final',
      is_daily_wastage: currentTab === 'daily' || currentTab === 'final',
      by_date: props.currentDate ?? ''
    }
    props.wc_service.get_wastage_data(payload).subscribe((r) => {
      setWastageData(r.response.data)
      setAllowEdit(r.response.meta.can_edit)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error', 'Backend API Error')
      setWastageData([])
    })
  }

  function createWC() {
    modalRef2.current.showModal('Create Wastage', true)
  }

  return(
    <div>
      {timer ? 
        <div className="wastage-timer">
          {allowAdhocWastage ? 'Final wastage starts in ' : allowFinalWastage ? 
            'Time left to complete final wastage ' : allowRequest ? 'Time left for wastage request ' 
            : "Time exhuasted for today's wastage approval"}
          <span className="time frz-lp-10">{timer}</span>
        </div>
      : null}
      <div className="wc-main-screen-container wastage-tabs">
        <Tabs type="card" className="wc-main-tabs" onChange={(e) =>  {setCurrentTab(e);setWastageData(false)}}
              activeKey={currentTab}>
          <TabPane tab="Adhoc Wastage" key="adhoc" disabled={!wastageData}>
            <WastageTable data={wastageData} wc_service={props.wc_service} currentTab={currentTab} allowEdit={allowEdit}
              currentDate={props.currentDate} currentNode={props.currentNode} getWastageData={getWastageData}/>
          </TabPane>
          <TabPane tab="Daily Wastage" key="daily" disabled={!wastageData}>
            {props.data.current_transaction_count !== props.data.transaction_limit && !props.currentDate ?
              <TimeScreen allowRequest={allowRequest} data={props.data} showTimeScreen={showTimeScreen}
                timer={timer} wc_service={props.wc_service} currentNode={props.currentNode}
                getJobDownloads={props.getJobDownloads} jobLoading={props.jobLoading}/>
            :   
              <WastageTable data={wastageData}/>
            }
          </TabPane>
          <TabPane tab="Final Wastage" key="final" disabled={!wastageData}>
            <WastageTable data={wastageData}/>
          </TabPane>
        </Tabs>
        <div className="wastage-btn-container">
          <Spin size="small" spinning={props.emailLoading === 'email_wastage_sheet'}>
            <div className="email-print-export"
              onClick={() => props.sendEmail(`${'wastage_items_pdf_report?node_code='+props.currentNode}`, 'email_wastage_sheet')}>
              <img src={email} width={18} height={16} /> Email Sheet
            </div>
          </Spin>
          {props.jobLoading === 'print_closing' ? 
            <Spin size="small">
              <div className="email-print-export">
                <img src={print} width={18} height={16} /> Print Sheet
              </div>
            </Spin>
          : 
            <div className="email-print-export" 
              onClick={() => props.getJobDownloads(`wastage_items_pdf_report?node_code=${props.currentNode}`, 'print_closing')}>
              <img src={print} width={18} height={16} /> Print Sheet
            </div>
          }
          <Spin size="small" spinning={props.exportLoading === 'csv_wastage_sheet'}>
            <div className="email-print-export"
              onClick={() => props.getJobDownloads(`export_wastage_report?node_code=${props.currentNode}` + 
              `&is_partial_wastage=${currentTab === 'adhoc' || currentTab === 'final'}` +
              `&is_daily_wastage=${currentTab === 'daily' || currentTab === 'final'}` +
              `&date=${props.currentDate ?? ''}`, 'csv_wastage_sheet')}>
              <img src={download} width={18} height={16} /> Export to CSV
            </div>
          </Spin>
          <div>
            <Button type="primary" className={`${allowAdhocWastage ? "add-wastage" : ""}`} onClick={addWastage}
                    loading={loading === 'adhoc'} disabled={!allowAdhocWastage}>
              Add Adhoc Wastage
            </Button>
          </div>
          <div>
            <Button type="primary" disabled={!allowFinalWastage || !props.data.grn_done || !props.data.sto_done} onClick={createWC}>
              Create Final Wastage
            </Button>
          </div>
        </div>
      </div>
      <AdhocWastageModal productData={productData} wc_service={props.wc_service} reasons={props.reasons}
        ref={modalRef} currentNode={props.currentNode} getWastageData={getWastageData}/>
      <CreateWastageModal ref={modalRef2} printImg={print} jobLoading={props.jobLoading} 
                     getJobDownloads={props.getJobDownloads}
                     setWCScreen={props.setWCScreen} currentNode={props.currentNode}/>
    </div>

  )
}

export default Wastage