import React, { useEffect, useState, useRef } from "react";
import { AttendanceService } from '../../_services/attendance';
import { Row, Card, Col, Button, Table, Spin, Input } from "antd";
import { attendanceColumns } from '../../components/attendance/columns';
import AddUsers from "../../components/attendance/add_users";
import FrzDynamicSearch from "../../_controls/FrzDynamicSearch";
import { openNotificationWithIcon } from "../../_modules/notification";
import ModalAttandance from "../../components/attendance/modalattendace";
import { ReloadOutlined } from '@ant-design/icons';

const attendance_service = new AttendanceService()

const initialFilters = {
  selectedNode: null,
}

const initialInternalFilters = {
  isSearch: false,
  nameMobile: null,
  selectedRole: null,
  selectedStatus: null,
  selectedLog: 'all'
}

const initialState = {
  spinner: false,
  err: false
}

const initialExternalData = {
  nodes: false,
  roles: false,
  vendors: false,
  shifts: false,
  imageAttendance: false,
  otpAttendance: false,
  fetchComplete: false
}

const statusFilter = ["available", "busy", "offline"]
const logFilter = ["login", "logout", "all"]

function Attendance() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [filteredList, setFilteredList] = useState(false)
  const [externalData, setExternalData] = useState(initialExternalData)
  const [filters, setFilters] = useState(initialFilters)
  const [internalFilters, setInternalFilters] = useState(initialInternalFilters)
  const [state, setState] = useState(initialState)
  const [eventType, setEventType] = useState(false)
  const [cameraErrorThrown, setCameraErrorThrown] = useState(false)
  const [showAttModal, setShowAttModal] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [disable, setDisable] = useState(true)

  useEffect(() => {
    if (localStorage.getItem('city')) {
      getNodes()
      getRoles()
      getVendors()
      getShifts()
    }
  }, [])

  function getNodes() {
    let urlParam = `?by_node_type=["CC","WH"]&by_city=${localStorage.getItem('city') ?? ''}`
      + `&admin_id=${JSON.parse(localStorage.getItem('auth')).id}`
      + `&by_procurement_type=${localStorage.getItem('procurementtype')}`
    attendance_service.get_nodes(urlParam).subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, nodes: r.response.data }))
    }, (err) => {
      setState(prevState => ({ ...prevState, err: true }))
      setExternalData(prevState => ({ ...prevState, nodes: [] }))
    })
  }

  function getRoles() {
    attendance_service.get_roles().subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, roles: r.response }))
    }, (err) => {
      setState(prevState => ({ ...prevState, err: true }))
      setExternalData(prevState => ({ ...prevState, roles: [] }))
    })
  }

  function getVendors() {
    attendance_service.get_vendors().subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, vendors: r.response.data }))
    }, (err) => {
      setState(prevState => ({ ...prevState, err: true }))
      setExternalData(prevState => ({ ...prevState, vendors: [] }))
    })
  }

  function getShifts() {
    let urlParam = `?city_code=${localStorage.getItem('city') ?? ''}&platform=ops`
    attendance_service.get_shifts(urlParam).subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, shifts: r.response.data }))
    }, (err) => {
      setState(prevState => ({ ...prevState, err: true }))
      setExternalData(prevState => ({ ...prevState, shifts: [] }))
    })
  }

  useEffect(() => {
    if (filters.selectedNode) {
      getTableList()
      getAttendanceAuthority()
    }
  }, [filters])

  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `&node_code=${filters.selectedNode}`
    attendance_service.get_attendance(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false }))
      setTableData(r.response.data)
      setFilteredList(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
      setFilteredList([])
    })
  }

  function getAttendanceAuthority() {
    let urlParam = `?node_code=${filters.selectedNode}`
    attendance_service.get_attendance_authority(urlParam).subscribe((r) => {
      setExternalData(prevState => ({
        ...prevState, imageAttendance: r?.response?.data?.image_attendance ?? true,
        otpAttendance: r?.response?.data?.otp_attendance ?? true, fetchComplete: true
      }))
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
    });
  }

  function onChangeFilters(value, type, filterType) {
    setDisable(false)
    if (filterType === 'internalFilter') {
      setInternalFilters(prevState => ({ ...prevState, [type]: value, isSearch: true }))
    } else {
      setFilters(prevState => ({ ...prevState, [type]: value }))
      setInternalFilters(initialInternalFilters)
      filteredList.length === 0 && setFilteredList(false)
    }
  }

  useEffect(() => {
    localFilter()
  }, [internalFilters])

  const localFilter = () => {
    internalFilters.isSearch &&
      setFilteredList(tableData.filter(x =>
        (internalFilters.nameMobile ? (x.admin_name.toLowerCase().includes(internalFilters.nameMobile.toLowerCase()) || x.mobile_number.includes(internalFilters.nameMobile)) : true)
        &&
        (internalFilters.selectedRole ? (x.roles.includes(internalFilters.selectedRole)) : true)
        &&
        (internalFilters.selectedStatus ? (x.status == internalFilters.selectedStatus) : true)
        &&
        (internalFilters.selectedLog !== "all" ? internalFilters.selectedLog == "logout" ? (x.logged_out_at) :
          internalFilters.selectedLog == "login" ? (x.logged_in_at) : true : true)
      ))
  }

  const onChangeTime = (x, timeString, val, type) => {
    if (timeString.length > 0) {
      // this.setState({ timeDisabled: val.admin_id })
      const payload = {
        is_forced: false,
        event_type: type,
        mobile_number: val.mobile_number,
        event_time: timeString,
        image_attendance: externalData.imageAttendance,
        otp_attendance: externalData.otpAttendance,
        node_code: filters.selectedNode
      }
      attendance_service.capture_attendance(payload).subscribe((r) => {
        getTableList()
      }, (err) => {
        console.log(err)
        // this.setState({ timeDisabled: 'renable' })
        openNotificationWithIcon('error', err?.response?.errors ?? 'API Error')
      })
    }
  }

  const handleShowAttModal = (value, data) => {
    if (value) {
      if (!cameraErrorThrown) {
        setShowAttModal(value)
        setRowData(data)
      } else {
        openNotificationWithIcon('error', 'Please Allow Camera Access From Browser Settings')
      }
    } else {
      setShowAttModal(false)
    }
  }

  const showModalLog = (value, record, type) => {
    setEventType(type)
    handleShowAttModal(value, record)
  }

  const handleCameraError = (value) => {
    setCameraErrorThrown(value)
  }

  const reset = () => {
    setDisable(true)
    initialInternalFilters.isSearch = true
    setInternalFilters(initialInternalFilters)
    localFilter()
  }


  return (
    <div className="layer-nofilter">
      {localStorage.getItem('city') ?
        <div>
          {externalData.nodes && externalData.roles && externalData.vendors && externalData.shifts ?
            <div>
              {!state.err ?
                <Row>
                  <Col span={24}>
                    <Card>
                      <FrzDynamicSearch DropDownData={externalData.nodes} placeholder="Select Node" value="code" option="name"
                        isShowSearch={true} width={200} setOnChange={(e) => onChangeFilters(e, 'selectedNode')}
                        selectDisabled={state.spinner} customClass="frz-m-8" />
                      {filters.selectedNode && tableData?.length > 0 && externalData.fetchComplete ?
                        <>
                          <Input placeholder="Search Name / Mobile No" allowClear
                            onChange={(e) => onChangeFilters(e.target.value, 'nameMobile', 'internalFilter')}
                            value={internalFilters.nameMobile} className="frz-w-200 frz-m-8"
                            disabled={state.spinner} />
                          <FrzDynamicSearch DropDownData={externalData.roles} placeholder="Select Roles"
                            isShowSearch={true} width={200} setOnChange={(e) => onChangeFilters(e, 'selectedRole', 'internalFilter')}
                            selectDisabled={state.spinner} allowClear customClass="frz-m-8"
                            defValue={internalFilters.selectedRole} />
                          {/* <FrzDynamicSearch DropDownData={statusFilter} placeholder="Select Status"
                            isShowSearch={true} width={200} setOnChange={(e) => onChangeFilters(e, 'selectedStatus', 'internalFilter')}
                            selectDisabled={state.spinner} allowClear customClass="frz-m-8"
                            defValue={internalFilters.selectedStatus} /> */}
                          <FrzDynamicSearch DropDownData={logFilter} placeholder="Select Log"
                            isShowSearch={true} width={100} setOnChange={(e) => onChangeFilters(e, 'selectedLog', 'internalFilter')}
                            selectDisabled={state.spinner} customClass="frz-m-8" defValue={internalFilters.selectedLog} />
                          <Button disabled={disable} onClick={reset} type="primary"
                            className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                        </>
                        : null}
                      {filters.selectedNode && externalData.fetchComplete ?
                        <span className="fl-right">
                          <Button onClick={() => modalRef.current.showModal(true, 'Add User')} type="primary"
                            className="frz-m-8" disabled={state.spinner}>Add Users</Button>
                        </span>
                        : null}
                    </Card>
                  </Col>
                </Row>
                : null}
              {filteredList?.length > 0 && !state.err && externalData.fetchComplete ?
                <Spin spinning={state.spinner}>
                  <Row>
                    <Col span={24}>
                      <Card className="frz-mt-5">
                        <Table dataSource={filteredList} pagination={false} scroll={{ y: 'calc(100vh - 210px)' }} rowKey={'admin_id'}
                          columns={attendanceColumns((rowdata) => modalRef.current.showModal(rowdata, 'Edit Users'),
                            externalData.imageAttendance, externalData.otpAttendance, onChangeTime, showModalLog)} />
                      </Card>
                    </Col>
                  </Row>
                </Spin>
                :
                (filters.selectedNode && !filteredList && !externalData.fetchComplete ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">{state.err ? 'Backend API System Down'
                    : filters.selectedNode ? 'No Data Available' : 'Please Select A Node'}</div>)
              }
              <AddUsers ref={modalRef} shifts={externalData.shifts} roles={externalData.roles}
                vendors={externalData.vendors} attendance_service={attendance_service}
                getTableList={getTableList} node_code={filters.selectedNode} nodeData={externalData.nodes}
                imageAttendance={externalData.imageAttendance} otpAttendance={externalData.otpAttendance} />
            </div>
            :
            <div className="spin-center"><Spin tip="...Loading" /></div>
          }
        </div>
        :
        <div className="no-data">Please Select A City</div>
      }
      {showAttModal ?
        <ModalAttandance showAttModal={showAttModal} showModalLog={showModalLog}
          imgAtt={externalData.imageAttendance} otpAtt={externalData.otpAttendance} rowData={rowData}
          handleCameraError={handleCameraError} cameraErrorThrown={cameraErrorThrown}
          eventType={eventType} getUsers={getTableList} node_code={filters.selectedNode}/>
        : null}
    </div>
  )
}

export default Attendance