import React, { useEffect, useState } from 'react'
import { Spin, Card, TimePicker, Form, Button, Select, Divider, Checkbox } from 'antd'
import moment from 'moment';
import { humanize } from '../../../_modules/humanize';
import { openNotificationWithIcon } from '../../../_modules/notification';

const format = 'HH:mm';

const DetailPanel = ({ activeItem, listingData, adminList, updateApprovalSettings, loading }) => {
  const [form] = Form.useForm();

  const [formId, setFormId] = useState(false)
  const [days, setDays] = useState([])
  const [state, setState] = useState({})

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
  }

  useEffect(() => {
    generateDays()
  }, [])

  useEffect(() => {
    if (listingData.length) {
      reFactorData()
      setFormValues()
    }
  }, [listingData])

  const reFactorData = () => {
    listingData.map((data) => {
      let allLvlData = data["approver_settings"]["active_levels"].map((lvlList) => {
        return data["approver_settings"][lvlList]?.map((inLvlDetail) => {
          return Object.assign(inLvlDetail, { lvlname: lvlList + "_approver_" + data.id, levelactive: true, levelactiveKey: lvlList + "_" + data.id })
        }) ?? []
      })
      let tempData = []
      allLvlData?.map((alllvldata) => {
        tempData.push(...alllvldata)
      })
      data["approver_settings"].allLevel = tempData
    })
    // console.log("temp refactor", listingData)
  }

  const setFormValues = () => {
    let formValues = setValuesById()
    let formObj = {}
    formValues.map((itm) => {
      return Object.assign(formObj, { ...itm })
    })
    // console.log("formObj", formObj)
    form.setFieldsValue(
      formObj
    )
  }

  const setValuesById = () => {
    let tempData = listingData.map((data) => {
      return Object.assign({},
        {
          ["active_" + data.id]: data.is_active
        },
        { ['request_type_admins_' + data.id]: data.request_type_admins.map((data) => data.admin_id) },
        { ["start_day_cutoff_" + data.id]: data.cutoff_date_time_setting.start_day },
        { ["end_day_cutoff_" + data.id]: data.cutoff_date_time_setting.end_day },
        { ["start_time_cutoff_" + data.id]: moment(data.cutoff_date_time_setting.start_time, 'HHmm') },
        { ["end_time_cutoff_" + data.id]: moment(data.cutoff_date_time_setting.end_time, 'HHmm') },
        { ["end_day_approver_" + data.id]: data.approver_date_time_setting.end_day },
        { ["end_time_approver_" + data.id]: moment(data.approver_date_time_setting.end_time, 'HHmm') },
        ...data["approver_settings"]["allLevel"].map((nestedData) => {
          return ({ [nestedData.levelactiveKey]: nestedData.levelactive })
        }),
        // {
        //   [data.approver_settings.active_levels.map((itm) => {
        //     return itm + "_" + data.id
        //   })]: true
        // },
        // {
        //   [data.approver_settings["levels"].map((itm) => {
        //     return itm + "_approver_" + data.id
        //   })]: "ritik"
        // }

        ...data["approver_settings"]["allLevel"].map((nestedData) => {
          // console.log("nestedData", nestedData)
          return ({
            [nestedData.lvlname]: data["approver_settings"][nestedData.lvlname.split("_")[0] + "_" + nestedData.lvlname.split("_")[1]].map((e) => e.email)
          })
        })

        // {
        //   [Array(data.level_count).fill().map((itm, index) => {
        //     return "level_" + (index + 1) + "_approver_" + data.id
        //   })]: Array(data.level_count).fill().map((itm, index) => {
        //     console.log("first", data.approver_settings["level_" + (index + 1)][0].name)
        //     return data.approver_settings["level_" + (index + 1)][0].name
        //   })
        // },
      )
    })
    // console.log("tempData", tempData)
    return tempData
  }

  const generateDays = () => {
    let temp = []
    for (let i = -300; i <= 300; i++) {
      temp.push(i)
    }
    setDays(temp)
  }

  const MinusButton = (data) => {
    return (
      <div onClick={() => handleOnMinus(data)} className="frz-dis-flex frz-vt-center frz-hrz-center frz-plus-button frz-pointer">
        -
      </div>
    )
  }

  const PlusButton = (data) => {
    return (
      <div onClick={() => handleOnPlus(data)} className="frz-dis-flex frz-vt-center frz-hrz-center frz-plus-button frz-pointer">
        +
      </div>
    )
  }

  const onSubmit = (value) => {
    let formData = form.getFieldValue()
    console.log("formData", formData)
    let tempPayload = []
    Object.entries(formData).map(([key, value], index) => {
      let IdLength = key.split("_").length - 1
      if (key.split("_")[IdLength] == formId) {
        tempPayload.push({ [key]: value })
      }
    });
    let formPayload = {}
    tempPayload.map((itm) => {
      return Object.assign(formPayload, { ...itm })
    })

    let tempLvl = []
    Object.keys(formPayload)
      .forEach(function eachKey(key) {
        if (key.split("_")[0] == "level" && formPayload[key]) {
          if (key.split("_").length == 3) {
            tempLvl.push(parseInt(key.split("_")[1]))
          }
        }
        // alert(key); // alerts key 
        // alert(formPayload[key]); // alerts value
      });
    let checkLevel = true
    tempLvl.map((data, index) => {
      if (data !== index + 1) {
        checkLevel = false
        openNotificationWithIcon("error", `Please approve level ${index + 1} first`)
      }
      return
    })

    if (!tempLvl.length) {
      openNotificationWithIcon("error", `Please allow at least one approver`)
      return
    }

    let tempObj = {}
    Object.keys(formPayload)
      .forEach(function eachKey(key) {
        if (key.split("_")[0] == "level" && formPayload[key]) {
          if (key.split("_").length == 3) {

            Object.assign(tempObj,
              {
                [key.split("_")[0] + "_" + key.split("_")[1]]:
                  Array.isArray(formPayload[key.split("_")[0] + "_" + key.split("_")[1] + "_approver_" + formId]) ?
                    formPayload[key.split("_")[0] + "_" + key.split("_")[1] + "_approver_" + formId]
                    : [formPayload[key.split("_")[0] + "_" + key.split("_")[1] + "_approver_" + formId]]
              })
          }
        }
        // alert(key); // alerts key 
        // alert(formPayload[key]); // alerts value
      });

    if (checkLevel) {
      Object.keys(tempObj)
        .forEach(function eachKey(key) {
          if (tempObj[key][0] === undefined) {
            openNotificationWithIcon("error", `Please select at least one approver name for level ${key.split("_")[1]} approver`)
            checkLevel = false
          }
          return
          // alert(key); // alerts key 
          // alert(formPayload[key]); // alerts value
        });
    }

    if (checkLevel) {
      // console.log("formPayload", formPayload)
      const payload = {
        is_active: formPayload["active_" + formId],
        request_type_admins: formPayload["request_type_admins_" + formId],
        cutoff_date_time_setting: {
          start_day: formPayload["start_day_cutoff_" + formId],
          end_day: formPayload["end_day_cutoff_" + formId],
          start_time: moment(formPayload["start_time_cutoff_" + formId]).format("HH:mm"),
          end_time: moment(formPayload["end_time_cutoff_" + formId]).format("HH:mm")
        },
        level_count: tempLvl.length,
        approver_date_time_setting: {
          end_day: formPayload["end_day_approver_" + formId],
          end_time: moment(formPayload["end_time_approver_" + formId]).format("HH:mm")
        },
        approval: tempObj
      }
      // console.log("payload", payload)
      updateApprovalSettings(payload, formId)
      // console.log("payload", payload)
      // console.log("tempPayload", tempPayload)
    }
  }

  const handleOnMinus = (value) => {
    let name = value.name
    let getFormData = form.getFieldValue()
    let clickDate = moment(getFormData[name]).subtract(10, 'minutes').format("HH:mm")
    form.setFieldsValue({ [name]: moment(clickDate, "HHmm") })
  }

  const handleOnPlus = (value) => {
    let name = value.name
    let getFormData = form.getFieldValue()
    let clickDate = moment(getFormData[name]).add(10, 'minutes').format("HH:mm")
    form.setFieldsValue({ [name]: moment(clickDate, "HHmm") })
  }

  return (
    <div>
      {
        listingData ?
          listingData.length ?
            <Card className="frz-setting-card-p0 frz-setting-dp-card-height frz-dp-card frz-pos-rel" style={{ background: "#fffaf2", overflow: "scroll" }}>
              {listingData.map((data, index) => {
                return (
                  <div key={data.id} className="frz-settings-dp-container">
                    <Form id={data.name} form={form} name={data.name} onFinish={onSubmit}>
                      {/* section name */}
                      <div className="frz-dis-flex frz-justify-sb frz-bm-14">
                        <div className="frz-settings-dp-name">
                          {data.name}
                        </div>
                        <div>
                          <Form.Item valuePropName="checked" name={"active_" + data.id} className="frz-form-itm-no-padding-margin">
                            <Checkbox
                              className={"frz-custom-checkbox-test"}>
                              <div className={"frz-custom-checkbox-text"}>
                                Active
                              </div>
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>

                      <Card className="frz-setting-inner-card">
                        <div className="frz-dis-flex">
                          {/* cut off time */}
                          <div>
                            <div className="frz-setting-title frz-bp-5">
                              Cutoff time
                            </div>

                            <div className="frz-dis-flex">
                              <div>
                                <div>
                                  Start Day
                                </div>
                                <Form.Item name={"start_day_cutoff_" + data.id} >
                                  <Select style={{ width: 150 }} showSearch placeholder="Select Day">
                                    {days.map((data, index) => {
                                      return (
                                        <Select.Option key={index} value={data}>
                                          {data}
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>

                              <div className="frz-lm-10">
                                <div>
                                  End Day
                                </div>
                                <Form.Item name={"end_day_cutoff_" + data.id} >
                                  <Select style={{ width: 150 }} showSearch placeholder="Select Day">
                                    {days.map((data, index) => {
                                      return (
                                        <Select.Option key={index} value={data}>
                                          {data}
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>

                              <div className="frz-lm-10">
                                <div>
                                  Start Time
                                </div>
                                <div className="frz-dis-flex frz-setting-input-border">
                                  <MinusButton name={"start_time_cutoff_" + data.id} id={data.id} />
                                  <Form.Item name={"start_time_cutoff_" + data.id} className="frz-form-itm-no-padding-margin">
                                    <TimePicker className="frz-setting-time-picker" bordered={false} format={format} suffixIcon={false} allowClear={false} />
                                  </Form.Item>
                                  <PlusButton name={"start_time_cutoff_" + data.id} id={data.id} />
                                </div>
                              </div>

                              <div className="frz-lm-15">
                                <div>
                                  End Time
                                </div>
                                <div className="frz-dis-flex frz-setting-input-border">
                                  <MinusButton name={"end_time_cutoff_" + data.id} id={data.id} />
                                  <Form.Item name={"end_time_cutoff_" + data.id} className="frz-form-itm-no-padding-margin">
                                    <TimePicker className="frz-setting-time-picker" bordered={false} format={format} suffixIcon={false} allowClear={false} />
                                  </Form.Item>
                                  <PlusButton name={"end_time_cutoff_" + data.id} id={data.id} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Approval cut off time */}
                        <div>
                          <div className="frz-setting-title frz-bp-5">
                            Approval Cutoff time
                          </div>

                          <div className="frz-dis-flex">
                            <div>
                              <div>
                                End Day
                              </div>
                              <Form.Item name={"end_day_approver_" + data.id} >
                                <Select style={{ width: 150 }} showSearch placeholder="Select Day">
                                  {days.map((data, index) => {
                                    return (
                                      <Select.Option key={index} value={data}>
                                        {data}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </div>

                            {/* <div className="frz-lm-15">
                                <div>
                                  Start Time
                                </div>
                                <div className="frz-dis-flex frz-setting-input-border">
                                  <MinusButton />
                                  <Form.Item name={"a_start_time_" + data.id} className="frz-form-itm-no-padding-margin">
                                    <TimePicker className="frz-setting-time-picker" bordered={false} format={format} suffixIcon={false} allowClear={false} />
                                  </Form.Item>
                                  <PlusButton />
                                </div>
                              </div> */}

                            <div className="frz-lm-10">
                              <div>
                                End Time
                              </div>
                              <div className="frz-dis-flex frz-setting-input-border">
                                <MinusButton name={"end_time_approver_" + data.id} id={data.id} />
                                <Form.Item name={"end_time_approver_" + data.id} className="frz-form-itm-no-padding-margin">
                                  <TimePicker className="frz-setting-time-picker" bordered={false} format={format} suffixIcon={false} allowClear={false} />
                                </Form.Item>
                                <PlusButton name={"end_time_approver_" + data.id} id={data.id} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="frz-bm-20">
                          <div className="frz-setting-title">
                            Point of contact
                          </div>
                          <Form.Item name={"request_type_admins_" + data.id} className="frz-form-itm-no-padding-margin">
                            <Select mode="multiple" style={{ width: 200 }} showSearch
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Select Point of contacts">
                              {adminList.map((data, index) => {
                                return (
                                  <Select.Option key={index} value={data.id}>
                                    {data.name}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="frz-hrz-div" />

                        <div className="frz-dis-flex frz-mt-20">
                          {data.approver_settings.levels.map((itm, index) => {
                            return (
                              <div key={index} className={index !== 0 ? "frz-lm-20" : ""}>
                                <Form.Item valuePropName="checked" className="frz-form-itm-no-padding-margin" name={itm + "_" + data.id}>
                                  <Checkbox><div className="frz-fw-500">
                                    {humanize(itm)} Approver
                                    {/* Level 1 Approver */}
                                  </div></Checkbox>
                                </Form.Item>
                                {/* // level_1 + _approver_ + 22 */}
                                <Form.Item name={itm + "_approver_" + data.id} className="frz-tm-10">
                                  <Select mode="multiple" style={{ width: 150 }} showSearch placeholder="Select Approver">
                                    {adminList.map((data, index) => {
                                      return (
                                        <Select.Option key={index} value={data.email}>
                                          {data.name}
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            )
                          })}
                          {/* <div className="frz-lm-15">
                            <Form.Item valuePropName="checked" className="frz-form-itm-no-padding-margin" name={"level_2_" + data.id}>
                              <Checkbox><div className="frz-fw-500">Level 2 Approver</div></Checkbox>
                            </Form.Item>

                            <Form.Item name={"lvl2_approver" + data.id} className="frz-tm-10">
                              <Select style={{ width: 150 }} showSearch placeholder="Select Approver">
                                {days.map((data, index) => {
                                  return (
                                    <Select.Option key={index} value={data}>
                                      {data}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </div> */}

                          {/* <div className="frz-lm-15">
                            <Form.Item valuePropName="checked" className="frz-form-itm-no-padding-margin" name={"level_3_" + data.id}>
                              <Checkbox><div className="frz-fw-500">Level 3 Approver</div></Checkbox>
                            </Form.Item>

                            <Form.Item name={"lvl3_approver" + data.id} className="frz-tm-10">
                              <Select style={{ width: 150 }} showSearch placeholder="Select Approver">
                                {days.map((data, index) => {
                                  return (
                                    <Select.Option key={index} value={data}>
                                      {data}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </div> */}
                        </div>

                        {/* save form button */}
                        <div className="frz-setting-save-button">
                          <Button form={data.name} loading={loading && data.id == formId} type="primary" key="submit" htmlType="submit" onClick={() => setFormId(data.id)}>
                            Save
                          </Button>
                        </div>
                      </Card>
                    </Form>
                  </div>
                )
              })}
            </Card>
            :
            <div className="no-data">No Data Available</div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
      }
    </div>
  )
}

export default DetailPanel