import React, { useState, useEffect } from 'react'
import { Card, Spin, Progress, Row, Col } from 'antd'
import { OperationsService } from '../../../_services/operations'
import StepsList from './stepslist';
import StoDetail from './stodetail';

const ops_service = new OperationsService();

const StoList = (props) => {
  const { activeNodes, socketMessage, stoData, stoLineItem, updateStoLineItem,
    getStoListDetail, handleStoData, currentStoNull, currentStoData, handleCurrentStoNull } = props;
  const [current, setCurrent] = useState(false)
  const [node, setNode] = useState(false)

  // console.log("-1", activeNodes);
  // useEffect(() => {
  //   if (true) {
  //     if (true && true) {
  //       if (true) {
  //         activeNodes.find((e) => e.id == 288).status = "dispatch"
  //         // activeNodes.find(x, id == current).status = 'dis'
  //         setNode([...activeNodes])
  //         // activeNodes.find(x, id == socketMessage.sto_id).status = 'loading'
  //       }
  //       getStoListDetail(288)
  //       setCurrent(288)
  //     } else if (true) {
  //       updateStoLineItem(360)
  //     } else {
  //       getStoListDetail(activeNodes[activeNodes.length - 1].id)
  //       setCurrent(activeNodes[activeNodes.length - 1].id)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    setNode(activeNodes)
  }, [])

  useEffect(() => {
    if (socketMessage) {
      if (!socketMessage?.sto_line_item_id && socketMessage.sto_id) {
        // console.log("case 1");
        if (current !== socketMessage.sto_id) {
          // console.log("case 2");
          activeNodes.find((e) => e.id == current).status = "dispatched"
          activeNodes.find((e) => e.id == socketMessage.sto_id).status = "loading"
          setNode([...activeNodes])
        }
        getStoListDetail(socketMessage.sto_id)
        setCurrent(socketMessage.sto_id)
      } else if (socketMessage.sto_line_item_id) {
        // console.log("case 3");
        updateStoLineItem(socketMessage.sto_line_item_id)
      } else {
        // console.log("case 4");
        getStoListDetail(activeNodes[activeNodes.length - 1].id)
        setCurrent(activeNodes[activeNodes.length - 1].id)
        //DELIVERED
        activeNodes[activeNodes.length - 1].status = "delivered"
        setNode([...activeNodes])
        //SOCKET CLOSE
        handleCurrentStoNull(true)
      }
    } else if (currentStoData) {  //if id comes from api resopnse then set that id to current and if stauts of that is is assigned then set is to loading
      // console.log("case 5");
      let tempStatus = activeNodes.find((e) => e.id == currentStoData).status
      if (tempStatus == "ready_to_load") {
        // console.log("case 6");
        activeNodes.find((e) => e.id == currentStoData).status = "loading"
        setNode([...activeNodes])
      }
      setCurrent(currentStoData)
    } else if (currentStoNull) {   //if current sto give null directly mark last darkstore to delivered and set current to it
      // console.log("first", activeNodes);
      // console.log("case 7");
      setCurrent(activeNodes[activeNodes.length - 1].id)
      activeNodes.find((e) => e.id == activeNodes[activeNodes.length - 1].id).status = "delivered"
      setNode([...activeNodes])
    }
  }, [socketMessage, currentStoNull, currentStoData])


  const onStepsClick = (id) => {
    // setStoData(false)
    handleStoData(false)
    setCurrent(id)
    getStoListDetail(id)
  }

  return (
    node &&
    <div className="frz-tp-10">
      <Card>
        {/* {console.log("first0", activeNodes)} */}
        <StepsList activeNodes={node} onStepsClick={onStepsClick} current={current} />
        <div className="frz-sto-div frz-tm-10"></div>
        {stoData ? node.length ?
          <StoDetail stoLineItem={stoLineItem} />
          :
          <div className="no-data">
            No Data Found
          </div>
          : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
      </Card>
    </div>
  )
}

export default StoList
