import React from 'react'
import { Button, TimePicker } from 'antd'
import { EditOutlined } from '@ant-design/icons';

export const attendanceColumns = (onEdit, imageAttendance, otpAttendance, onChangeTime, showModalLog) => {
  return [
    {
      title: 'Profile Image',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 150,
      render: (record) => <div>
        <img src={record.profile_image_url} className="frz-w-100" />
      </div>
    },
    {
      title: 'Name',
      dataIndex: '',
      key: '',
      align: 'center',
      sorter: (a, b) => a?.admin_name?.localeCompare(b?.admin_name ?? ''),
      render: (record) => {
        return record.admin_name
      }
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      align: 'center',
    },
    {
      title: 'Roles',
      dataIndex: '',
      key: '',
      align: 'center',
      sorter: (a, b) => a.roles?.length - b.roles?.length,
      render: (record) => <div>
        {record?.roles.map(data => {
          return data + ', '
        })}
      </div>
    },
    {
      title: 'Shift Time',
      dataIndex: 'shift_time',
      key: 'shift_time',
      align: 'center',
    },
    // {
    //   title: 'Status',
    //   dataIndex: '',
    //   key: '',
    //   align: 'center',
    //   sorter: (a, b) => a?.status?.localeCompare(b?.status ?? ''),
    //   render: (record) => <div className={`${record?.status === 'available' ? 'color-green' :
    //     record?.status === 'offline' ? 'color-red' : 'color-orange'}`}>
    //     <b>{record?.status?.toUpperCase()}</b></div>
    // },
    {
      title: 'Login',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 180,
      render: (record) => {
        return (
          !otpAttendance && !imageAttendance ?
            record.is_login_and_logout_visible ?
              <div>
                {record.logged_in_at ?
                  <div>
                    {/* <div className='color-green'><b>Logged In</b></div> */}
                    {record.login_delay ?
                      <div className={record.logout_delay_message.toLowerCase().includes("on time") ? 'frz-green' : 'color-red'}>
                        <b>
                          {record.login_delay_message}
                          {/* Late by  */}
                          {/* {Math.floor(record.login_delay / 60) ? Math.floor(record.login_delay / 60) + ' h ' : ''} */}
                          {/* {record.login_delay % 60} min */}
                        </b>
                      </div>
                      :
                      <div className='color-green'><b>
                        {record.login_delay_message}
                        {/* On Time */}
                      </b></div>
                    }
                    {/* <div>{handleDateFormat(record.logged_in_at)}, {getStaticTime(record.logged_in_at)}</div> */}

                  </div>
                  : <TimePicker onChange={(e, f) => onChangeTime(e, f, record, "login")} format='HH:mm'
                  // disabled={this.state.timeDisabled === record.admin_id}
                  />}
              </div>
              :
              <div>
                {record.error_message_when_login_and_logout_not_visible}
              </div>
            :
            <div>
              {record.is_login_and_logout_visible ?
                record.logged_in_at ?
                  <div>
                    {/* <div className='color-green'><b>Logged In</b></div> */}
                    {record.login_delay ?
                      <div className='color-red'>
                        <b>
                          {/* Late by  */}
                          {/* {Math.floor(record.login_delay / 60) ? Math.floor(record.login_delay / 60) + ' h ' : ''} */}
                          {/* {record.login_delay % 60} min
                        */}
                          {record.login_delay_message}
                        </b>
                      </div>
                      :
                      <div className='color-green'><b>{record.login_delay_message}</b></div>
                    }
                    {/* <div>{handleDateFormat(record.logged_in_at)}, {getStaticTime(record.logged_in_at)}</div> */}
                  </div>
                  :
                  <Button
                    // loading={record.admin_id === buttonLoad}
                    type="primary"
                    //  disabled={buttonLoad}
                    onClick={() => showModalLog(true, record, "login")}>Login</Button>
                : <div>
                  {record.error_message_when_login_and_logout_not_visible}
                </div>
              }
            </div>
        )
      }
    },
    {
      title: 'Logout',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 180,
      render: (record) => {
        return (
          !otpAttendance && !imageAttendance ?
            record.is_login_and_logout_visible ?
              <div>
                {record.logged_out_at ?
                  <div>
                    {/* <div className='color-green'><b>Logged In</b></div> */}
                    {record.login_delay ?
                      <div className={record.logout_delay_message.toLowerCase().includes("on time") ? 'frz-green' : 'color-red'}>
                        <b>
                          {/* Late by  */}
                          {/* {Math.floor(record.login_delay / 60) ? Math.floor(record.login_delay / 60) + ' h ' : ''} */}
                          {/* {record.login_delay % 60} min
                     */}
                          {record.logout_delay_message}
                        </b>
                      </div>
                      :
                      <div className='color-green'><b>{record.logout_delay_message}</b></div>
                    }
                    {/* <div>{handleDateFormat(record.logged_in_at)}, {getStaticTime(record.logged_in_at)}</div> */}
                  </div>
                  : (record.logged_in_at ?
                    <TimePicker onChange={(e, f) => onChangeTime(e, f, record, "logout")} format='HH:mm'
                    // disabled={this.state.timeDisabled === record.admin_id} 
                    />
                    : null)
                }
              </div>
              :
              <div>
                {record.error_message_when_login_and_logout_not_visible}
              </div>
            :
            <div>
              {record.is_login_and_logout_visible ?
                record.logged_out_at ?
                  <div>
                    {/* <div className='color-red'><b>Logged Out</b></div> */}
                    {record.logout_delay ?
                      <div className='color-red'><b>Early by {Math.floor(record.logout_delay / 60) ? Math.floor(record.logout_delay / 60) + ' h ' : ''}
                        {record.logout_delay % 60} min</b></div>
                      :
                      <div className='color-green'><b>On Time</b></div>
                    }
                    {/* <div>{handleDateFormat(record.logged_out_at)}, {getStaticTime(record.logged_out_at)}</div> */}
                  </div>
                  : (record.logged_in_at ?
                    <Button
                      // loading={record.admin_id === buttonLoad}
                      type="primary"
                      //  disabled={buttonLoad}
                      onClick={() => showModalLog(true, record, "logout")}>Logout</Button>
                    : null)
                : <div>
                  {record.error_message_when_login_and_logout_not_visible}
                </div>}
            </div>)
      }
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
      </div>
    }
  ]
}