import { Http } from '../_modules/http'

export class AdminService {

  http = new Http()

  get_admins(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_admin/admins?page=' + payload.page +
      '&by_verified=' + payload.by_verified + '&from_freshvnf=' + payload.from_freshvnf +
      '&by_mobile_number=' + payload.by_mobile_number + '&by_email=' + payload.by_email + '&operations=true'
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_admin(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_admin/' + payload.id + '/update_admin'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  create_admin(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_admin/admin_registration'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_roles() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/get_all_roles?operations=true' + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}
