import React, {useEffect, useState} from 'react';
import {Row, Col, Spin, Select, Space, Button, Input, Table, Pagination} from 'antd';
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import BarChartWithoutPath from './barChart';
import {rejectionByReason} from '../../../components/antd/columns/procurementDashboard/fulfilmentColumns';

const {Option} = Select;

const RejectionComponent = (props) => {
  const {rejectionsData, dropDownOption, tableData, meta, setTableFilters, rejectByReasonSpinner, rangeType, setRangeType} = props;

  const handleTableSearch = (selectedKeys, setSelectedKeys, value, filterType) => {
    if (selectedKeys?.length) {
      if (filterType === 'reset') {
        setTableFilters((prev) => ({...prev, [value]: ''}))
        setSelectedKeys('')
      } else {
        setTableFilters((prev) => ({...prev, [value]: selectedKeys}))
      }
    }
  }

  const getColumnSearchProps = (value) => {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={"Search Sku"}
                    value={selectedKeys}
                    onChange={e => setSelectedKeys(e.target.value)}
                    onPressEnter={() => handleTableSearch(selectedKeys, setSelectedKeys, value)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <div>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleTableSearch(selectedKeys, setSelectedKeys, value)}
                            icon={<SearchOutlined />}
                            size="small">
                            Search
                        </Button>
                        <Button
                            onClick={() => { clearFilters(); handleTableSearch(selectedKeys, setSelectedKeys, value, 'reset') }}
                            size="small"
                            icon={<ReloadOutlined />}>
                            Reset
                        </Button>
                    </Space>
                </div>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    }
};

const handleFilterOnChange = (setSelectedKeys, selectedKeys, val) => {
  if (!isNaN(val) && val > 0 && val <= 100 || val === '') {
    setSelectedKeys({ ...selectedKeys, input: val })
  }
}

const handleTableFilter = (selectedKeys, order, value, setSelectedKeys, filterType) => {
  if (selectedKeys?.type && selectedKeys?.input) {
    if (filterType === 'reset') {
      setTableFilters((prev) => ({...prev, [order]: '', [value]: ''}))
      setSelectedKeys({})
    } else {
      setTableFilters((prev) => ({...prev, [order]: selectedKeys?.type, [value]: selectedKeys?.input}))
    }
  }
}

const getColumnFilterProps = (order, value) => {
  return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
          <div style={{ padding: 8 }}>
              <div className='frz-dis-flex farmer-filter-container'>
                  <Select value={selectedKeys?.type} placeholder='Select Order'
                      onChange={(e) => setSelectedKeys({ ...selectedKeys, type: e })}>
                      <Option value='less_than' key='l'>Less than</Option>
                      <Option value='greater_than' key='g'>Greater than</Option>
                      <Option value='equal_to' key='e'>Equal to</Option>
                  </Select>
                  <Input
                    placeholder={"Enter Value"}
                    value={selectedKeys?.input || ''}
                    onChange={e => handleFilterOnChange(setSelectedKeys, selectedKeys, e.target.value)}
                    onPressEnter={() => handleTableFilter(selectedKeys, order, value)}
                    style={{ width: 120, display: 'block' }}
                    maxLength={3}
                />
              </div>
              <div className='farmers-filter-btns'>
                  <Space>
                      <Button
                          type="primary"
                          onClick={() => handleTableFilter(selectedKeys, order, value)}
                          icon={<SearchOutlined />}
                          size="small"
                          style={{ width: 110 }}
                      >
                          Search
                      </Button>
                      <Button
                          onClick={() => {clearFilters(); handleTableFilter(selectedKeys, order, value, setSelectedKeys, 'reset')}}
                          size="small"
                          icon={<ReloadOutlined />}
                          style={{ width: 110 }} >
                          Reset
                      </Button>
                  </Space>
              </div>
          </div>
      ),
  }
};

const onPageChange = (e) => {
  setTableFilters((prev) => ({...prev, page: e}))
}

const itemRender = (_, type, originalElement) => {
  if (type === 'prev') {
      return <a className='frz-color-white frz-p-5'>Prev</a>;
  }

  if (type === 'next') {
      return <a className='frz-color-white frz-p-5'>Next</a>;
  }

  return originalElement;
};

const graphTooltip = (data, label) => {
  return "<span>" + label + ": " + data.count_range + "<br />Count: " + data.count +"</span>"
}

  return (
    <>
      <Row className="margin-section-two">
        <Col span={12}>
          Rejections
        </Col>
        <Col className="second-label-margin">
          Rejection by Reason
        </Col>
      </Row>
      <div className="fulfilment-grid-container">
        <div className="fulfilment-grid-card">
          <Select placeholder="Select Type" onChange={(e) => { setRangeType((prev) => ({...prev, rejectionsRangeType: e})) }} options={dropDownOption}
            className="frz-w-100px" value={rangeType?.rejectionsRangeType} />
          {rejectionsData ? rejectionsData?.length ?
            <BarChartWithoutPath chartData={rejectionsData} xlabel={'count_range'} ylabel={'count'} color="#E45B64"
            gHeight={390} gWidth={670} padding={"2% 0 0 2%"} barWidth={2} type={rangeType?.rejectionsRangeType} tooltipId={'allocation'}
            graphType="rejection" graphTooltip={graphTooltip} /> :
            <div className="spin-center">No Data Available</div>
            : <div className="spin-center"><Spin tip="...Powered by D3" /></div>}
        </div>
        <div>
          <Spin spinning={rejectByReasonSpinner}>
            <div className="fulfilment-grid-card rejection-table rejection-card">
              <Table rowKey={'product_id'} columns={rejectionByReason(getColumnSearchProps, getColumnFilterProps)} 
                dataSource={tableData} pagination={false} scroll={{ y: 415 }} getPopupContainer={trigger => trigger.parentNode} />
            </div>
            <div className='frz-tm-15 rejection-table-pagination'>
              <Pagination total={meta?.total_pages * 10 || 10} current={meta?.current_page} 
                pageSize={10} itemRender={itemRender} showSizeChanger={false} onChange={onPageChange} />
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default RejectionComponent;