export const wastageLisitngColumns = [
    {
        title: 'Node Name',
        dataIndex: 'node_name',
        key: 'node_name',
        align: 'center'
    },
    {
        title: 'Loss Type',
        dataIndex: 'loss_type',
        key: 'loss_type',
        align: 'center'
    },
    {
        title: 'Total Wastage Qty',
        dataIndex: 'total_wastage_qty',
        key: 'total_wastage_qty',
        align: 'center'
    },
    {
        title: 'Wastage Worth',
        dataIndex: 'wastage_worth',
        key: 'wastage_worth',
        align: 'center'
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center'
    },
]

export const wastageProductColumns = [
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
    },
    // {
    //     title: 'UOM',
    //     dataIndex: 'uom',
    //     key: 'uom',
    //     align: 'center'
    // },
    {
        title: 'Stock Left',
        dataIndex: 'current_stock',
        key: 'current_stock',
        align: 'center'
    },
    {
        title: 'Wastage Qty',
        dataIndex: 'wastage_qty',
        key: 'wastage_qty',
        align: 'center'
    },
    {
        title: 'Unaccounted Qty',
        dataIndex: 'unaccounted_qty',
        key: 'unaccounted_qty',
        align: 'center'
    },
    {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
        align: 'center'
    },
    {
        title: 'Wastage Worth',
        dataIndex: 'wastage_worth',
        key: 'wastage_worth',
        align: 'center'
    },
]