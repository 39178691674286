import React from "react";
import { handleDateFormat } from '../../../../_modules/date';
import { getStaticTime } from '../../../../_modules/time';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';


export const uploadLogsColumns = [
  {
    title: 'Job Name',
    dataIndex: 'job_name',
    key: 'job_name',
    align: 'center'
  },
  {
    title: 'Uploaded By',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Uploaded At',
    dataIndex: '',
    key: '',
    align: 'center',
    width: 150,
    render: (record) => {
      return record?.updated_at ? 
        <>
          <div>{handleDateFormat(record.updated_at)}</div>
          <div>{getStaticTime(record.updated_at)}</div>
        </> 
      : '';
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
  },
  {
    title: 'Upload Error',
    dataIndex: 'response_message',
    key: 'response_message',
    align: 'center',
    width: 350,
  },
  {
    title: 'Download',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <Button type="primary" icon={<DownloadOutlined />} href={record.url}></Button>
    }
  }
]