import React, { useState, useEffect } from "react";
import { OperationsService } from "../../../_services/operations";
import { Spin } from 'antd';
import MainTab from "./main_tab";
import WCDashboard from './wc_dashboard';
import {WastageClosingService} from '../../../_services/wastage_closing';

const wc_service = new WastageClosingService()
const operation_service = new OperationsService();

function WCMain(props){
  const [nodes, setNodes] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [currentCity] = useState(localStorage.getItem('city'))
  const [currentDate, setCurrentDate] = useState(null)
  const [allowSystemAccess, setAllowSystemAccess] = useState(null)
  const [systemLoad, setSystemLoad] = useState(true)
  const [currentProcType, setCurrentProcType] = useState(null)

  useEffect(() => {
    if(currentCity !== ''){
      getNodes()
    }
  },[])

  useEffect(() => {
    nodes && props.currentNode && setCurrentProcType(nodes?.find(x => x?.code === props?.currentNode)?.procurement_type)
  },[props.currentNode, nodes])

  // useEffect(() => {
  //   setCurrentDate(null)
  // },[props.currentNode])

  useEffect(() => {
    if(props.currentNode){
      setSystemLoad(true)
      setAllowSystemAccess(false)
      wc_service.get_module_access(props.currentNode).subscribe((r) => {
        setAllowSystemAccess(r.response.data?.partial_closing?.[0].versions.find(x => x.version === 2 && x.is_active))
        setSystemLoad(false)
      },
      (err) => {
        console.log(err)
        setApiError(true)
        setSystemLoad(false)
      })
    }
  },[props.currentNode])

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
    (err) => {
      console.log(err)
      setApiError(true)
    })
  }

  return(
    <div>
      {currentCity !== '' ? 
        <>
          {nodes && !apiError && props.reasons ? 
            <>
              <MainTab nodes={nodes} setCurrentNode={props.setCurrentNode} setCurrentDate={setCurrentDate}
                currentNode={props.currentNode} currentDate={currentDate} />
              {props.currentNode ? 
                <>
                  {!systemLoad ? 
                    <WCDashboard currentNode={props.currentNode} currentDate={currentDate} currentTab={props.currentTab}
                      setWCScreen={props.setWCScreen} setCurrentTab={props.setCurrentTab} reasons={props.reasons}
                      currentProcType={currentProcType} allowSystemAccess={allowSystemAccess}
                      setDashboardData={props.setDashboardData} dashboardData={props.dashboardData}
                      setCurrentMainTab={props.setCurrentMainTab} currentMainTab={props.currentMainTab}/>
                  : 
                    (systemLoad ? 
                      <div className="spin-center">
                        <Spin tip="...Loading" />
                      </div>
                    :
                      <div className="horizontal-vertical-center frz-font-20">
                        <b>Node Not Permitted For Wastage & Closing</b>
                      </div>
                    )
                  }
                </>
              : 
                <div className="horizontal-vertical-center frz-font-20">
                  <b>Please Select A Node</b>
                </div>
              }
            </>
          : 
            (apiError ? 
              <div className="horizontal-vertical-center frz-font-20">
                <b>Backend API System Down</b>
              </div>
            :
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            )
          }
        </>
      :
        <div className="horizontal-vertical-center frz-font-20">
          <b>Please Select A City</b>
        </div>
      }
    </div>
  )
}

export default WCMain