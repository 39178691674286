import FilterTab from "../../common"
import React,{ useState, useEffect} from "react"
import StatusTab from "../../common/StatusTab"
import { stoColumns } from "../../../antd/columns/operations/sto"
import { Col, Pagination, Row, Spin } from "antd"
import ManualTable from "../../common/ManualTable"
import ModalUploadSto from "../../../operations/sto/modal_upload_sto"
import { OperationsService } from "../../../../_services/operations"
import { useDispatch, useSelector } from "react-redux"
import { setSTO } from "../../../../_actions"
const ops_service = new OperationsService()

const StoOutward=({setScreen,setSto_id,setSpinEnable,spinEnable,showCreate,setShowCreate,page})=>{
    const [current,setCurrent]=useState(1);
    const [node,setNode]=useState(false)
    const [stoData,setStoData]=useState(null)
    const [apiError, setApiError]=useState(false)
    const [uploadStoModal, setUploadStoModal] = useState(false);
    const [payload,setPayload]=useState({valueByNode: undefined, valueByDestination: undefined,showDateValue:false ,valueByStatus: undefined,start_date:undefined,end_date:undefined,
      valueByDate: undefined, date: undefined, valueByOrderNumber: undefined,city_type:undefined,inter_city:undefined,date_type:undefined,by_source_node_type:undefined,by_destination_node_type:undefined,valueByDestinationNode:undefined,per_page:20,valueByProductId:undefined})
    const { valueByNode, valueByProductId,valueByDestination,start_date,end_date,by_status,showDateValue ,inter_city,valueByStatus,valueByDestinationNode, valueByDate, date, valueByOrderNumber,city_type,date_type,by_source_node_type ,by_destination_node_type,per_page} = payload;
    function pagination(page) {
      setCurrent(page);
    }
    const handleStoModal = (value) => {
      setUploadStoModal(value)
    }
    const getSTO=()=>{
      setSpinEnable(true)
      const payLoad = {
        sto_type: 'out',
        inter_city:city_type ? city_type:'',
        by_destination_city: valueByDestination ? valueByDestination :'',
        by_source: valueByNode ? valueByNode:'',
        by_destination: Number(valueByDestinationNode) ? Number(valueByDestinationNode) :'',
        by_procurement_type:localStorage.getItem('procurementtype')?localStorage.getItem('procurementtype'):'',
        date_type: date_type ?  date_type:'',
        start_date: start_date ? start_date : '',
        end_date: end_date ? end_date : '',
        by_source_city:localStorage.getItem('city')?localStorage.getItem('city'):'',
        by_status:by_status?by_status:'',
        by_destination_node_type:by_destination_node_type?by_destination_node_type:'',
        by_source_node_type :by_source_node_type?by_source_node_type:'',
        by_order_number: valueByOrderNumber ? valueByOrderNumber : '',
        page:page ?page:1,
        per_page:per_page?per_page:'',
        by_product: valueByProductId ?? '',
      }
      ops_service.get_sto_list(payLoad).subscribe((r) => {
        setSpinEnable(false)
        setStoData(r.response)
      },
        (err) => {
          console.log(err)
          setApiError(true)
          setSpinEnable(false)
        })
    }
    return(
        <>
        <FilterTab setScreen={setScreen} setPayload={setPayload} handleStoModal={handleStoModal} node={node} page={current} setApiError={setApiError} setPage={setCurrent} setNode={setNode} stoData={stoData} setStoData={setStoData} curr={'sto_outward'} setSpinEnable={setSpinEnable} showCreate={showCreate} setShowCreate={setShowCreate} />
        { stoData?.data ?
        <>
          <div>{stoData.data.length> 0 ?<StatusTab data={stoData?.meta?.status_wise_counts} />:null}</div>
          {stoData?.data?.length  ?
              <div className="sto-out-table-data"> 
                  <ManualTable getSto={getSTO} setSpinEnable={setSpinEnable}  setStoData={setStoData} setSto_id={setSto_id} setScreen={setScreen} setShowCreate={setShowCreate} currTab={'sto_outward'} data={stoData.data}/>
                  <Pagination current={stoData?.meta?.current_page} total={stoData?.meta?.total_pages * 20} onChange={pagination} pageSize={20} showSizeChanger={false}/>
              </div>
                  :
                  (stoData?.data?.length===0 ?<div className="no-data">No Data Available On Selected Filters</div>:null)}
        </>:apiError  ? <div className="no-data">
            <b>Backend API System Down</b>
          </div>:spinEnable ?<div className="spin-center"><Spin tip="...Loading" /></div>:<div className="no-data">Please Search Filters to Get Data</div>}
      {uploadStoModal ? <ModalUploadSto  uploadStoModal={uploadStoModal} handleStoModal={handleStoModal} getSto={getSTO} /> : null}
        
        </>
    )
}
export default StoOutward