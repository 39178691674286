import React from "react";
import { InputNumber, Select } from "antd";

function TableList(props) {

  return (
    <div className="wcl-listing-table-container-modal">
      {props.data.map((item, index) => {
        return (
          <div key={index} className="wcl-align-center wcl-sp-b wcl-listing-table frz-p-relative">
            <div className="wcl-align-center wcl-60">
              <img src={item.image_url} className="wcl-listing-img" />
              <div>{item.name} - ({item.sku})</div>
            </div>
            <div className="wcl-align-center wcl-20 text-center wcl-input">
              <div className="p-relative">
                <InputNumber min={0} size="large" onChange={(e) => props.onChangeClosing(e, item)}
                  className={props.enableError && (item.closing_quantity === null || item.errors) ? 'wcl-input-error' : ''}
                  disabled={props.buttonLoading} value={item.closing_quantity} />
                {props.enableError && item.errors ?
                  <div className="wcl-input-error-msg">
                    {item.errors}
                  </div>
                : null}
              </div>
            </div>
            <div className="wcl-align-center wcl-20 text-center frz-p-relative">
              {item.unit_changed ? <div className="wcl-unit-changed top-minus-32">Unit Changed</div> : null}
              {item.procurement_unit}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TableList