import React from 'react';
import { Row, Col } from 'antd';

const FRZNamingBlock = ({ label, value }) => {

  return (
    <Row className="frz-naming-margin-bt">
      <Col xs={7} className="text-right frz-padding-naming">
        {label} :
      </Col>
      <Col xs={17} className="frz-padding-naming">
        <b>{value}</b>
      </Col>
    </Row>
  )
}

export default FRZNamingBlock
