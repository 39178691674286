import { Http } from '../_modules/http'

export class AuthService {

  http = new Http()

  sign_in(body){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/auth/request'
    return this.http.post(url, body, this.get_headers())
  }

  get_headers(){
    return {'Content-Type': 'application/json'}
  }

}
