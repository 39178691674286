import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Select } from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import { WastageService } from '../../../_services/wastage';
import MainTable from '../../../components/antd/table';
import { wastageLisitngColumns } from '../../../components/antd/columns/operations/wastage';
import { openNotificationWithIcon } from '../../../_modules/notification';
import AddwastageModal from '../../../components/operations/wastage/AddwastageModal';
import NestedTable from '../../../components/operations/wastage/NestedTable';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import EditRtvWastageModal from '../../../components/operations/wastage/EditRtvWastageModal';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import ModalClosingStock from '../../../components/operations/wastage/ModalClosingStock';

const ops_service = new OperationsService()
const wastage_service = new WastageService()

function Wastage() {
  const modalRef = useRef(null);
  const modalRTVRef = useRef(null);

  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [listing, setListing] = useState(false)
  const [nodes, setNodes] = useState(false)
  const [state, setState] = useState({ nodeCode: undefined });
  const [showClosingStock, setShowClosingStock] = useState(false);
  const { nodeCode } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getWastage()
    }
  })

  useEffect(() => {
    getColumns()
    getNodes()
  }, [])

  const handleClosingStockModal = (value) => {
    setShowClosingStock(value)
  }

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`,
      admin_id: JSON.parse(localStorage.getItem('auth')).id
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getWastage() {
    setSpinEnable(true)
    setListing([])
    const payload = { nodeCode: nodeCode ?? '' }

    wastage_service.get_wastage_list(payload).subscribe((r) => {
      setSpinEnable(false)
      setListing(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    wastageLisitngColumns.map(data => {
      columns.push(data)
    })

    // productColumns.map(data => {
    //   columnsProducts.push(data)
    // })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />} disabled={record.loss_type !== "rtv"}
          onClick={() => showModalEditWastage(record, 'Edit RTV Wastage')}
        >
        </Button>
      }
    })
    setColumns(columns)
  }

  function showModalEditWastage(data, type) {
    modalRTVRef.current.showModal(type, data)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setState({
      nodeCode: undefined
    })
    setDisable(true)
    setCurrent(1)
    setListing([])
    setRenderData(false)
  }

  function modalSubmit(payloadData, lossType) {
    const payload = { ...payloadData, created_by: JSON.parse(localStorage.getItem('auth')).id, loss_type: lossType }
    wastage_service.create_wastage(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      modalRef.current.handleCancel()
      getWastage()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function editRtvmodalSubmit(payloadData) {
    wastage_service.update_rtv_wastage(payloadData).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      modalRTVRef.current.handleCancel()
      getWastage()
    },
      (err) => {
        console.log(err)
        modalRTVRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function expandedRow(record) {
    return <>
      <NestedTable data={record.id} nodeCode={nodeCode} />
    </>
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  return (
    <div className="layer">
      {listing && nodes ?
        <div>
          <Row>
            <Card>
              <Col span={24}>

                <FrzDynamicSearch DropDownData={nodes}
                  placeholder="Select Node" value="code" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type='nodeCode' margin={10} defValue={nodeCode} />

                <Button disabled={disable} onClick={getWastage}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Wastage')} className="frz-w-140px frz-m-10" type="primary">
                  Create Wastage
                </Button>
                <Button onClick={() => handleClosingStockModal(true)} className="frz-w-max-content frz-m-10" type="primary">
                  Empty Crate Closing
                </Button>
                <div className="frz-dis-upload dynamic-upload">
                  <FrzDynamicUpload name={'Wastage'} url={'admin/upload_daily_wastage?created_by='
                    + `${JSON.parse(localStorage.getItem('auth')).id}`}
                    uploadFunc={'upload_zapper_post'} callBack={getWastage} />
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Wastage'} fileName={'Wastage'}
                    url={'admin_dashboard/sku_wise_wastage_report'}
                    payload={'?node_code=' + `${nodeCode ?? ''}`} type={'job'}
                    reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                </span>
              </Col>
            </Card>
          </Row>
          {listing.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listing} columns={columns} expandedRow={expandedRow} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <AddwastageModal modalSubmit={modalSubmit} ref={modalRef} nodes={nodes} getWastage={getWastage} />
          <EditRtvWastageModal modalSubmit={editRtvmodalSubmit} ref={modalRTVRef} nodes={nodes} getWastage={getWastage} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
      {showClosingStock ?
        <ModalClosingStock showClosingStock={showClosingStock} handleClosingStockModal={handleClosingStockModal}
          nodes={nodes} />
        : null}
    </div>
  );
}

export default Wastage