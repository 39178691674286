import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin, Card, Icon, Tooltip, Button, Modal } from 'antd';
import { productGrnColumns } from '../../antd/columns/operations/category';
import { OperationsService } from '../../../_services/operations';
import AddProductModal from './product_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import { EditOutlined } from '@ant-design/icons';

const ops_service = new OperationsService()

function NestedGrnTable(props) {
  const modalRefProducts = useRef(null);
  const [nestedOrder, setNestedOrder] = useState(false)
  const [columns, setColumns] = useState([])
  const [operations, setOperations] = useState(false)
  const [fetchColm, setFetchColm] = useState(false)
  const [grnEditAccess, setGrnEditAccess] = useState(false)

  useEffect(() => {
    getDetail()
    // JSON.parse(localStorage.getItem('roles')).forEach(data => {
    //   if (['admin', 'developer', 'super_admin', 'procurement_admin', 'procurement', 'cc_admin', 'coldex', 'warehouse_admin', 'category', 'category_admin'].includes(data)) {
    //     setOperations(true)
    //   }
    // })
    setFetchColm(true)
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['procurement_admin', 'procurement'].includes(data)) {
        setGrnEditAccess(false)
        return
      }
      else if (['warehouse_admin', 'warehouse_staff', 'cc_admin', 'super_admin', "developer_admin"].includes(data)) {
        setGrnEditAccess(true)
      }
    })
  }, [])

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false)
      getColumns()
    }
  })

  function getDetail() {
    const payload = {
      id: props.data.id,
    }
    ops_service.get_grn_line_items(payload).subscribe((r) => {
      let productsList = r.response.data.map(v => ({ ...v, is_editable: props.data.is_editable, po_id: props.data.id }))
      setNestedOrder(productsList)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    productGrnColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_editable || !grnEditAccess}
          onClick={() => showModalProducts(record, 'Edit Graded Qty')}>
        </Button>
      }
    })
    setColumns(columns)
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  const info = (msg) => {
    Modal.info({
      title: 'GRN edit will get disabled in ',
      content: (
        <div className='grn-timer'>
          {msg}
        </div>
      ),
      onOk() {},
    });
  };

  function modalSubmitProduct(payloadData, poid, id) {
    const payload = { ...payloadData, purchase_order_id: poid, po_line_item_id: id }
    ops_service.grn_products(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getDetail()
      modalRefProducts.current.handleCancel()
      if(r.response?.data?.qty_time_remain){
        info(r.response?.data?.qty_time_remain)
      }
    },
      (err) => {
        console.log(err)
        modalRefProducts.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  return (
    <div>
      {nestedOrder ?
        <div>
          {nestedOrder.length > 0 ?
            <Card className="nested-table">
              <Table columns={columns} pagination={false} rowKey="id" dataSource={nestedOrder} />
            </Card>
            :
            <div className="no-data-nested">No Grn Products Found</div>
          }
          <AddProductModal modalSubmit={modalSubmitProduct} ref={modalRefProducts} products={props.products} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default NestedGrnTable;
