import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Input, InputNumber, Row, Col, Checkbox, Upload, Button, Switch } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZTimePicker from '../../../_controls/FRZTimePicker';
import FRZInput from '../../../_controls/FRZInput';
import moment from "moment";
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import { InboxOutlined, CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';
import { UploadService } from '../../../_services/uploads';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const upload_service = new UploadService()

let url = 'admin_dashboard/procurement/upload_sto_sheet_for_input?sto_id='

const UpdateSTOModal = forwardRef((props, ref) => {
  const { modalUpdate } = props
  const { Dragger } = Upload;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [isFieldRequired, setIsFieldRequired] = useState(false)
  const [copyAllQty, setCopyAllQty] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploadFile, setUploadFile] = useState(false)
  const [current, setCurrent] = useState(false)
  const [currentSpin, setCurrentSpin] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)

  const [challanFile, setChallanFile] = useState(false)
  const [challanB64, setChallanB64] = useState(false)
  const [inlineAprrove, setInlineAprrove] = useState(false)
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [listingData, setListingData] = useState(false)

  useEffect(() => {
    if ((data && (title == "Update Inward" || title == "Update STO"))) {
      form.setFieldsValue({
        formData: data?.line_items, driver_mobile_number: data?.driver_mobile_number,
        transport_provider: data?.transport_provider,
        vehicle_number: data?.vehicle_number,
        transporter_gstin: data?.transporter_gstin,
        expected_arrival_time: data?.expected_arrival_time ? moment(data.expected_arrival_time, "HH:mm") : undefined,
      })
      // console.log("form.getFieldValue().formData", form.getFieldValue().formData)
      setListingData(form.getFieldValue().formData)
      checkQtyPresent(data?.line_items)
      if (data?.vehicle_number?.length || data.transport_provider?.length) {
        setIsFieldRequired(true)
      } else {
        setIsFieldRequired(false)
      }
    }
    return () => {
      setCopyAllQty(false)
      handleRemove()
      handleRemoveChallan()
    }
  }, [data])

  const checkQtyPresent = (data) => {
    if (title !== "Update Inward") {
      for (let i = 0; i <= data?.length - 1; i++) {
        if (!data[i]?.transfer_unit_qty) {
          setCopyAllQty(false)
          return
        } else {
          setCopyAllQty(true)
        }
      }
    } else {
      for (let i = 0; i <= data?.length - 1; i++) {
        if (!data[i].qty) {
          setCopyAllQty(false)
          return
        } else {
          setCopyAllQty(true)
        }
      }
    }

  }

  useImperativeHandle(ref, () => ({

    showUpdateModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setUploadFile(false)
    setFileList([])
    setCurrent(false)
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  // --------filter--------
  useEffect(() => {
    if (listingData?.length) {
      setSearchTerm("")
      setSearchResults(listingData)
    }
  }, [listingData])

  useEffect(() => {
    if (listingData) {
      // console.log("results", data.name.toLowerCase().includes(searchTerm))
      const results = listingData.filter(data =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results.map((itm) => itm.id));
      // console.log("results", results)
      // form.setFieldsValue({ formData: results })
    }
  }, [searchTerm]);

  const handleChangeSearch = event => {
    setSearchTerm(event.target.value);
  };
  // --------filter--------

  const handleOnSubmit = (values) => {
    // making challan optional
    if (title == "Update Inward" && !challanB64) {
      openNotificationWithIcon("error", "Please upload delivery challan!")
      return
    }
    for (let i = 0; i < values.formData.length; i++) {
      if (!values.formData[i].isApproved && values.formData[i].totalvalue) {
        openNotificationWithIcon("error", "Please approve all fields before submitting.")
        return
      }
    }


    // console.log("challanFile.type", challanFile.type)
    // if (challanFile.type !== 'image/jpeg' || challanFile.type !== 'image/png' || challanFile.type !== 'application/pdf') {
    //   openNotificationWithIcon("error", "Please invalid file format for delivery challan!")
    //   return
    // }

    let sto_id
    let payloadData = values.formData.map((e) => {
      sto_id = e.sto_id
      return ({ id: e.id, qty: title == "Update Inward" ? e.qty : e.transfer_unit_qty, rejected_qty: e.rejected_qty ?? 0 })
    })
    setLoading(true)
    const payload = {
      sto_id: sto_id,
      items: payloadData,
    }
    if (title === 'Update STO') {
      payload.driver_mobile_number = values?.driver_mobile_number ?? ''
      payload.expected_arrival_time = values.expected_arrival_time ? moment(values.expected_arrival_time).format("HH:mm") : ''

      payload.vehicle_number = values?.vehicle_number ?? ''
      payload.transporter_gstin = values.transporter_gstin
      payload.transport_provider = values.transport_provider
    }
    modalUpdate(payload, title, challanFile, challanB64, data.id)
  }

  const onChangeForm = () => {
    let data = form.getFieldValue()
    if (data.vehicle_number?.length || data.transporter_gstin?.length || data.transport_provider?.length) {
      setIsFieldRequired(true)
    } else {
      setIsFieldRequired(false)
    }
  }

  const getList = (data) => {
    setData(data)
  }

  const handleOnCbClick = (value) => {
    setCopyAllQty(true)
    copyQty()
  }

  const copyQty = () => {
    let formValue = form.getFieldValue()
    let temp = formValue.formData
    temp.map((item) => {
      if (title == "Update Inward") {
        if (item.qty == null) {
          Object.assign(item, { ...item, qty: item.expected_transfer_qty })
        }
      } else {
        if (item.transfer_unit_qty == null) {
          Object.assign(item, { ...item, transfer_unit_qty: item.expected_transfer_qty })
        }
      }
    })

    Object.assign(formValue, { ...formValue, formData: temp })
    form.setFieldsValue({
      formData: [...temp], driver_mobile_number: formValue.driver_mobile_number,
      transport_provider: formValue.transport_provider,
      vehicle_number: formValue.vehicle_number,
      transporter_gstin: formValue.transporter_gstin,
      expected_arrival_time: formValue.expected_arrival_time ? moment(formValue.expected_arrival_time, "HH:mm") : undefined
    })
  }

  function beforeUploadFile(file, url) {
    setCurrent(url)
    setUploadFile(false)
    setFileList([])
    const formData = new FormData();
    formData.append('file', file)
    setFileList([file])
    setUploadFile(formData)
  }

  function beforeUploadFileChallan(file, url) {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
    // if (!isJpgOrPng) {
    //   openNotificationWithIcon("error", "You can only upload JPG/PNG file!")
    //   // message.error('You can only upload JPG/PNG file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   openNotificationWithIcon("error", "Image must smaller than 2MB!")
    //   // message.error('Image must smaller than 2MB!');
    // }
    // return isJpgOrPng && isLt2M;
    return true
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function handleRemove(url) {
    setUploadFile(false)
    setFileList([])
    setCurrent(false)
  }

  function handleRemoveChallan(url) {
    setChallanB64(false)
    setChallanFile(false)
  }

  function afterUpload(url) {
    setSpinEnable(true)
    setCurrentSpin(url)
    let finalUrl = url
    // finalUrl = url + '?action_name=' + actionValue + "&catalog_name=" + catalogValue

    upload_service.upload_zapper_put(uploadFile, finalUrl).subscribe((r) => {
      setData(r.response.data)
      setUploadFile(false)
      setSpinEnable(false)
      setFileList([])
      setCurrentSpin(false)
      // getList()
      setCurrent(false)
      openNotificationWithIcon('success', 'Upload File Succesfully')
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
    })
  }

  // function afterUploadChallan(id) {
  //   const payloadChallan = {
  //     sto_id: id,
  //     signed_dc_original_filename: challanFile.name,
  //     signed_dc_image_base: challanB64,
  //     signed_dc_content_type: challanFile.type
  //   }
  //   upload_service.inward_challan_upload(payloadChallan).subscribe((r) => {
  //     //success
  //     handleRemoveChallan()
  //   }, ((err) => {
  //     console.log("err", err)
  //   }))
  // }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = info => {
    console.log("info", info.file.status)
    if (info.file.status === 'uploading') {
      // this.setState({ loadingImg: true });

      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setChallanFile(info.file)
        setChallanB64(imageUrl)
      }
        // this.setState({
        //   imageUrl,
        //   loadingImg: false,
        //   fileList: info.file
        // }),
      );
    }
  };

  const onChangeQty = (value, name, type) => {
    if (type == "rej") {
      let formValue = form.getFieldValue()
      let qtyValue = formValue.formData[name].qty;
      let id = formValue.formData[name].id
      let rejectedValue = value;
      let total = qtyValue + rejectedValue;
      if (total !== formValue.formData[name].transfer_qty &&
        (total + (total * 5 / 100) < formValue.formData[name].transfer_qty || formValue.formData[name].transfer_qty < total - (total * 5 / 100))) {
        Object.assign(formValue.formData[name], {
          ...formValue.formData[name],
          totalvalue: true, isApproved: false,
          errorMsg: total > formValue.formData[name].transfer_qty ? "Exceed" : "Less"
        })
        setInlineAprrove(true)
      } else {
        Object.assign(formValue.formData[name], { ...formValue.formData[name], totalvalue: false })
        setInlineAprrove(false)
      }
      form.setFieldsValue({ formData: formValue.formData })
    } else {
      let formValue = form.getFieldValue()
      let qtyValue = value;
      let id = formValue.formData[name].id
      let rejectedValue = formValue.formData[name].rejected_qty;
      let total = qtyValue + rejectedValue;
      if (total !== formValue.formData[name].transfer_qty &&
        (total + (total * 5 / 100) < formValue.formData[name].transfer_qty || formValue.formData[name].transfer_qty < total - (total * 5 / 100))) {
        Object.assign(formValue.formData[name], {
          ...formValue.formData[name], totalvalue: true, isApproved: false,
          errorMsg: total > formValue.formData[name].transfer_qty ? "Exceed" : "Less"
        })
        setInlineAprrove(true)
      } else {
        Object.assign(formValue.formData[name], { ...formValue.formData[name], totalvalue: false })
        setInlineAprrove(false)
      }
      form.setFieldsValue({ formData: formValue.formData })
    }
  }

  const handleOnApprove = (name) => {
    let formValue = form.getFieldValue()
    formValue.formData[name].isApproved = true;
    form.setFieldsValue({ formData: formValue.formData })
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'80%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>

          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="formData"
            onChange={() => onChangeForm()}>
            <Row gutter={[20, 20]}>
              {title === 'Update STO' ?
                <Col span={title == "Update Inward" ? 16 : 24}>
                  {/* {title === 'Update STO' ?
              <>
                <FRZInputNumber isRequired={false} label="Mobile No" name="driver_mobile_number" width={200} />
                <FRZTimePicker isRequired={false} label="Extended Arrival Time" name="expected_arrival_time" />

                <FRZInput name="vehicle_number" label="Vehicle No" isRequired={isFieldRequired} placeholder="Vehicle No" width={200} />
                <FRZInput name="transport_provider" label="Transport Provider" isRequired={isFieldRequired}
                  placeholder="Transport Provider" width={200} />
                <FRZInput name="transporter_gstin" label="Transporter GSTIN" isRequired={false}
                  placeholder="Transporter GSTIN" width={200} />
              </> : null} */}
                  <div>
                    <Dragger
                      name='file'
                      fileList={current === url + data.id ? fileList : []}
                      showUploadList={true}
                      customRequest={handleClick}
                      beforeUpload={(e) => beforeUploadFile(e, url + data.id)}
                      accept=".csv"
                      maxCount={1}
                      onRemove={handleRemove}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{data.name}</p>
                      <p className="ant-upload-hint">
                        Format :- CSV File
                      </p>
                    </Dragger>
                    <div className="frz-dis-flex frz-vertical-center frz-tm-10 frz-bm-10">
                      <Button loading={spinEnable} disabled={current !== url + data.id} onClick={(e) => afterUpload(url + data.id)} style={{ width: 160, marginRight: 10 }}
                        type="primary">Upload transfer data</Button>

                      <FrzDynamicDownload name={'transfer data'} fileName={'Transfer data'} url={'admin_dashboard/procurement/sto_report'}
                        payload={'?sto_id=' + `${data.id}`}
                        type={'job'} reportFunc={'sto_transfer_data_download'} jobReportFunc={'zapper_job_report'} base={'zapper'} />

                      {title === "Update Inward" ? null :
                        <>
                          <Switch disabled={copyAllQty}
                            checked={copyAllQty} onClick={() => handleOnCbClick()} />
                          <div className="frz-rp-10 frz-lp-10">
                            Copy expected transfer QTY to QTY
                          </div>
                        </>
                      }
                    </div>
                  </div>

                </Col> : null}

              <Col span={title == "Update Inward" ? 8 : 0}>
                <div style={{ marginBottom: 10 }}>
                  <Dragger
                    name='file'
                    // fileList={challanFile ? "challanFile" : []}
                    showUploadList={true}
                    customRequest={handleClick}
                    beforeUpload={beforeUploadFileChallan}
                    maxCount={1}
                    // accept=".img"
                    onChange={handleChange}
                    onRemove={handleRemoveChallan}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{data.name}</p>
                    <p className="ant-upload-hint">
                      Delivery Challan :- PDF / Image
                    </p>
                  </Dragger>
                  {/* <div className="frz-dis-flex frz-vertical-center frz-tm-10 frz-bm-10">
                    <Button loading={challanLoading} disabled={!challanB64} onClick={(e) => afterUploadChallan(data.id)} style={{ width: 180, marginRight: 10 }}
                      type="primary">Upload Delivery Challan</Button>
                  </div> */}
                </div>
              </Col>
            </Row>


            {/* <Row gutter={[20, 20]}>
              <Col span={24}>
                <div className="frz-dis-flex frz-float-right frz-vertical-center">
                  <div className="frz-dis-flex frz-rp-20">
                    <FrzDynamicUpload customName="Update" name={'STO'} url={'admin_dashboard/procurement/upload_sto_sheet_for_input?sto_id=' + 803
                    }
                      uploadFunc={'upload_zapper_put'} callBack={getList} />
                  </div>
                  <div className="frz-dis-flex">
                    <Checkbox
                      disabled={copyAllQty}
                      checked={copyAllQty} onClick={() => handleOnCbClick()} />
                    <div className="frz-rp-10 frz-lp-10">
                      Copy expected transfer QTY to QTY
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
            <div className="frz-dis-flex frz-tm-10 frz-bp-10">
              <Input placeholder="Search SKU" style={{ width: 200, marginRight: 10 }}
                value={searchTerm}
                onChange={handleChangeSearch} />
              <Button onClick={() => setSearchTerm("")} type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
            </div>
            <Row>
              <Col span={title === 'Update STO' ? 5 : 4}>
                <div>
                  SKU
                </div>
              </Col>
              <Col span={title === 'Update STO' ? 2 : 3} style={{ display: "none" }}>
                <div className='frz-text-center'>
                  Unit
                </div>
              </Col>
              <Col span={title === 'Update STO' ? 2 : 3}>
                <div className='frz-text-center'>
                  Inventory Unit
                </div>
              </Col>

              <Col span={title === 'Update STO' ? 4 : 4}>
                <div className='frz-text-center'>
                  Expected Transfer Qty
                </div>
              </Col>

              <Col span={title !== 'Update STO' ? 3 : 4}>
                <div className='frz-text-center'>
                  Transfer Converted Qty
                </div>
              </Col>

              <Col span={title !== 'Update STO' ? 3 : 4}>
                <div className='frz-text-center'>
                  {title === "Update Inward" ?
                    "Received QTY" : "Transfer QTY"}
                </div>
              </Col>

              {title !== 'Update STO' ?
                <Col span={3}>
                  <div className='frz-text-center'>
                    Rejected QTY
                  </div>
                </Col>
                : null}
              {title == "Update Inward" ?
                <Col span={3}>
                  <div className='frz-text-center'>
                    Action
                  </div>
                </Col>
                : <Col span={2}>
                  <div className='frz-text-center'>
                    Current Stock
                  </div>
                </Col>}
              {/* allocated_qty */}
              {title !== "Update Inward" ?
                <Col span={3}>
                  <div className='frz-text-center'>
                    Allocated Qty
                  </div>
                </Col>
                : null}
            </Row>

            <Row>
              <Col span={24}>
                <div className="frz-division"></div>
              </Col>
            </Row>
            <Form.List name="formData">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={name} className={name == 0 ? "" : searchTerm.length ? searchResults.includes(form.getFieldValue().formData[name].id) ? "create-sto-border" : "frz-display-none" : "create-sto-border"}>
                      {/* {console.log("name", form.getFieldValue().formData[name].id)}
                      {console.log("first", searchResults.includes(form.getFieldValue().formData[name].id))}
                      {console.log("showww", searchTerm)} */}
                      <Row gutter={[0, 0]} unique={form.getFieldValue().formData[name].id}
                        className={searchTerm.length ? searchResults.includes(form.getFieldValue().formData[name].id) ? "" : "frz-display-none" : ""}>
                        {console.log(form.getFieldValue().formData[name])}
                        <Col span={title === 'Update STO' ? 5 : 4}>
                          {form.getFieldValue().formData[name].unit_changed ? <div className={name == 0 ? "wcl-unit-changed top-minus-38" : "wcl-unit-changed top-minus-40"}>Unit Changed</div> : null}
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            fieldKey={[fieldKey, 'name']}
                            // label="SKU"
                            rules={[{ required: true, message: 'Missing first name' }]}>
                            <Input placeholder="First Name" disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={title === 'Update STO' ? 2 : 3} style={{ display: "none" }}>
                          <Form.Item
                            {...restField}
                            className='tabs-to-center frz-text-center'
                            name={[name, 'transfer_unit_type']}
                            fieldKey={[fieldKey, 'transfer_unit_type']}
                            // label="Unit"
                            rules={[{ required: false, message: 'Missing Unit type' }]}>
                            <Input placeholder="Unit" disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={title === 'Update STO' ? 2 : 3}>
                          <Form.Item
                            {...restField}
                            className='tabs-to-center frz-text-center'
                            name={[name, 'inventory_unit']}
                            fieldKey={[fieldKey, 'inventory_unit']}
                            // label="Unit"
                            rules={[{ required: false, message: 'Missing Unit type' }]}>
                            <Input placeholder="Inventory Unit" disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={title === 'Update STO' ? 4 : 4}>
                          <Form.Item
                            {...restField}
                            className='tabs-to-center frz-text-center'
                            name={[name, 'expected_transfer_qty']}
                            // label="Qty"
                            fieldKey={[fieldKey, 'expected_transfer_qty']}
                            rules={[{ required: true, message: 'Missing Qty' }]}>
                            <InputNumber placeholder="Qty" disabled={true} />
                          </Form.Item>
                        </Col>


                        <Col span={title !== 'Update STO' ? 3 : 4}>
                          <Form.Item
                            {...restField}
                            className='tabs-to-center frz-text-center'
                            name={[name, 'transfer_qty']}
                            fieldKey={[fieldKey, 'transfer_qty']}
                            // label="Transfer Unit Qty"
                            rules={[{ required: false, message: 'Missing Transfer Qty' }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>


                        <Col span={title !== 'Update STO' ? 3 : 4}>
                          <Form.Item
                            extra={form.getFieldValue().formData[name].qty &&
                              form.getFieldValue().formData[name].totalvalue &&
                              !form.getFieldValue().formData[name].isApproved && <div className="frz-custom-extra-error">{
                                form.getFieldValue().formData[name].errorMsg !== "Less" ?
                                  "Total received qty is grater than the transfer qty"
                                  :
                                  "Total qty is less than the transfer qty"
                              }</div>}
                            {...restField}
                            className='tabs-to-center frz-text-center'
                            name={[name, title == "Update Inward" ? 'qty' : "transfer_unit_qty"]}
                            // label="Qty"
                            fieldKey={[fieldKey, title == "Update Inward" ? 'qty' : "transfer_unit_qty"]}
                            rules={[{ required: true, message: 'Missing Qty' }]}>
                            <InputNumber placeholder="Qty" onChange={(e) => title == "Update Inward" ? onChangeQty(e, name, "qty") : null} />
                          </Form.Item>
                        </Col>
                        {title !== 'Update STO' ?
                          <Col span={3}>
                            <Form.Item
                              {...restField}
                              className='tabs-to-center frz-text-center'
                              name={[name, 'rejected_qty']}
                              // label="Qty"
                              fieldKey={[fieldKey, 'rejected_qty']}
                              rules={[{ required: false }]}>
                              <InputNumber placeholder="Rejected Qty" onChange={(e) => title == "Update Inward" ? onChangeQty(e, name, "rej") : null} />
                            </Form.Item>
                          </Col>
                          : null}
                        {form.getFieldValue().formData[name].totalvalue && form.getFieldValue().formData[name].qty ?
                          <Col span={3}>
                            <div className='tabs-to-center frz-text-center'>
                              <div className="frz-box-btn">
                                <div className={form.getFieldValue().formData[name].isApproved ? "frz-circle-button-approve-active" : "frz-circle-button-approve"} onClick={() => handleOnApprove(name)}>
                                  <CheckCircleOutlined />
                                </div>
                              </div>
                            </div>
                          </Col>
                          : null}
                        {title === 'Update STO' ?
                          <>
                            <Col span={2}>
                              <Form.Item
                                {...restField}
                                className='tabs-to-center frz-text-center'
                                name={[name, 'current_stock']}
                                fieldKey={[fieldKey, 'current_stock']}
                                // label="Unit"
                                rules={[{ required: false, message: 'Missing Unit type' }]}>
                                <Input placeholder="current stock" disabled={true} />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                {...restField}
                                className='tabs-to-center frz-text-center'
                                name={[name, 'allocated_qty']}
                                fieldKey={[fieldKey, 'allocated_qty']}
                                // label="Unit"
                                rules={[{ required: false, message: 'Missing Unit type' }]}>
                                <Input placeholder="allocated qty" disabled={true} />
                              </Form.Item>
                            </Col>
                          </>
                          : null}
                      </Row>
                    </div>
                  ))}
                </>
              )}
            </Form.List>

            {/* <FRZInputNumber name="qty" isRequired={true}
              label="Qty" placeholder="Qty" width={200} /> */}

          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div >
  )
})

export default UpdateSTOModal
