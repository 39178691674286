import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const SupplierCard = (props) => {

  const { supplierData, supplierid } = props;


  return (
    <div>
      <Row>
        <Col span={24} className="resto-bar-box" />
        <Col span={24} className="supplier-bar">
          {
            supplierData.map((data) => {
              return (
                <div onClick={() => props.getSupplierDetail(data)}
                  className={`border-box border-box-display resto-box ${data.id === supplierid ? "active" : ""}`}
                  key={data.id}>
                  <Row>
                    <Col span={24}>
                      <div className="resto-name"><b>{data.name}</b></div>
                    </Col>
                    <Col span={14} className="frz-tp-10">
                      <div><b>Mobile: </b>
                        {data.mobile_number}
                      </div>
                    </Col>
                    <Col span={1} />
                    <Col span={9} className="frz-tp-10">
                      <div><b>Type:</b> {data.supplier_type}</div>
                    </Col>
                    <Col span={14} className="frz-tp-18">
                      <Button
                        onClick={() => props.showEditModal(data, 'Edit Suppliers Information')} 
                        type="default" disabled={!props.operations}>
                        Edit
                      </Button>
                      {data.is_verified ? 
                        <span className='frz-green frz-lp-12'>
                          <FontAwesomeIcon icon={faCheckCircle} /> Verified
                        </span> 
                      : <span className='frz-orange frz-lp-12'>
                          <FontAwesomeIcon icon={faExclamationCircle} /> Not Verified
                        </span>}
                    </Col>
                    <Col span={1} />
                    <Col span={9} className="frz-tp-18">
                      <Button onClick={() => props.showModal(data, 'Supplier Details')} type="primary" ghost>
                        View Details
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            })
          }
        </Col>
        <Col span={24} className="resto-bar-box-bottom" />
      </Row>
          {/* <ForcastListingModal modalSubmit={modalSubmit} ref={modalRef} listingData={listingData} products={products} /> */}

    </div>
  )
}

export default SupplierCard
