import { TOGGLE_LOGIN, DARKSTORE, CITY,STO } from "../_constants"

const initialState = {
  isAuthenticated: false,
  darkstore: false,
  city: false,
  sto :false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN:
      return { ...state, isAuthenticated: action.payload.value };
    case DARKSTORE:
      return { ...state, darkstore: action.payload.value };
    case CITY:
      return { ...state, city: action.payload.value };
    case STO :
      return {...state,sto:action.payload.value}
    default:
      return state;
  }
};
