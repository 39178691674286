import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Space, Row, Col, InputNumber, Spin } from 'antd';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import { ReloadOutlined } from '@ant-design/icons';
import { WastageService } from '../../../_services/wastage';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';

const wastage_service = new WastageService()

const ModalClosingStock = ({ showClosingStock, handleClosingStockModal, nodes }) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({ nodeCode: undefined });
  const [listingData, setListingData] = useState(false);
  const [isSpin, setIsSpin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);


  const { nodeCode } = state

  const onCreate = (value) => {
    setIsLoading(true)
    const payload = {
      node_code: nodeCode,
      products: [...value.listing]
    }
    wastage_service.post_crate_closing(payload).subscribe((r) => {
      setIsLoading(false)
      handleClosingStockModal(false)
      openNotificationWithIcon('success', 'Submitted Successfully')
    }, ((err) => {
      setIsLoading(false)
      console.log("err", err);
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    }))
    // console.log("payload", payload);
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  const getWastage = () => {
    setListingData(false)
    setIsSpin(true)
    const payload = {
      nodeCode: nodeCode
    }
    wastage_service.get_crate_closing(payload).subscribe((r) => {
      setIsDisable(false)
      setIsSpin(false)
      setListingData(r.response.data[0].closing_data)
    }, ((err) => {
      console.log("err", err);
    }))
  }

  function reset() {
    setState({
      nodeCode: undefined
    })
    setDisable(true)
    setListingData(false)
    // setCurrent(1)
    // setRenderData(false)
  }
  return <Modal
    visible={showClosingStock}
    title="Empty Crate Closing"
    okText="Submit"
    okButtonProps={{ loading: isLoading, disabled: isDisable }}
    width={"70%"}
    cancelText="Cancel"
    onCancel={() => handleClosingStockModal(false)}
    onOk={() => {
      form
        .validateFields()
        .then((values) => {
          onCreate(values);
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    }}
  >
    <FrzDynamicSearch DropDownData={nodes}
      placeholder="Select Node" value="code" option="name"
      isShowSearch={true} width={200} setOnChange={onChange}
      type='nodeCode' margin={10} defValue={nodeCode} />
    <Button disabled={disable} onClick={getWastage}
      className="frz-w-100px frz-m-10" type="primary">Search</Button>
    <Button disabled={disable} onClick={reset} type="primary"
      className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />

    {listingData ?
      listingData.length ?
        <>
          <Form name="dynamic_form_nest_item" form={form} autoComplete="off" initialValues={{ listing: listingData }}>
            <Form.List name="listing" >
              {(fields, { add, remove }) => (
                <>
                  <Row gutter={[20, 20]} className="frz-mt-20">
                    <Col span={6}>
                      Name
                    </Col>

                    <Col span={4}>
                      SKU
                    </Col>

                    <Col span={4}>
                      Last Closing
                    </Col>

                    <Col span={4}>
                      Last Empty Count
                    </Col>

                    <Col span={6}>
                      Last Empty Count
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <div className="frz-division"></div>
                    </Col>
                  </Row>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8 }}>
                      <Row gutter={[20, 20]}>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}>
                            <Input disabled placeholder="First Name" />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'sku']}>
                            <Input disabled placeholder="Last Name" />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'last_closing']}>
                            <Input disabled placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'last_empty_count']}>
                            <Input disabled placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'empty_count']}
                            rules={[{ required: true, message: 'Missing empty count' }]}>
                            <InputNumber min={0} style={{ width: 200 }} placeholder="Emter empty count" />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                    </div>
                  ))}
                  {/* <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item> */}
                </>
              )}
            </Form.List>
            {/* <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item> */}
          </Form>
        </>
        :
        <div>
          no data
        </div>
      : isSpin ? <div className="spin-center"><Spin tip="...Loading" /></div> : null}
  </Modal>
};

export default ModalClosingStock;
