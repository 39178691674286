import React, {useRef, forwardRef, useImperativeHandle} from "react";
import { Button, Popconfirm } from 'antd';
import PDFUpload from '../common/upload_pdf';

const CreateWCButtons = forwardRef((props, ref) => {
  const callUploadRef = useRef(null)

  useImperativeHandle(ref, () => ({

    callUpload(id) {
      callUploadRef.current.afterUpload(id)
    },

    cancelUpload(){
      callUploadRef.current.cancelUpload()
    }

  }));
  
  return(
    <div className="wcl-btn-container wcl-display-right">
      {(Object.keys(props.data).length === props.validated.length && props.touchedCategory.length === 0 ) || 
        props.data === 'nodata' ? 
        (props.fileUploaded ? 
          <Popconfirm
            title="Are you sure you want submit and all values entered are correct ?"
            onConfirm={props.submitWC}
            okText="Yes"
            cancelText="No"
            placement="leftTop">
            <Button type="primary" loading={props.buttonLoading}
              className="wcl-align-center">
              Submit {props.wcScreen === 'create_wastage' ? 'Wastage' : 'Closing'}
            </Button>
          </Popconfirm>
        :
          <PDFUpload name={props.wcScreen === 'create_wastage' ? 'Wastage' : 'Closing'} 
            url={'/v2/admin_v2/stock_losses/signed_closing_image'} submitWC={props.submitWC}
            uploadFunc={'upload_zapper_putv2'} ref={callUploadRef} setWCScreen={props.setWCScreen}/>
        )
      :       
        <Button type="primary" loading={props.buttonLoading} onClick={() => props.validateWC(null)}
          className="wcl-align-center">
          Next
        </Button>
      }
    </div>
  )
})

export default CreateWCButtons