import React from "react"
import { Popover, Row, Col, Image } from 'antd';

const content = (data, parentType) => {
  return (
    <div className="frz-p-8">
      {/* <div className="frz-text-center frz-clr-green frz-bm-10">
        Active Crate:  {data.current_stock}
      </div> */}
      {data?.map((item, index) => {
        return (
          <Row key={index} gutter={[15, 15]}>
            <Col span={10}>
              <Image src={item.image_url} className="frz-img-sm frz-img-boder" />
            </Col>

            <Col span={14}>
              <div className="frz-label frz-font-13">
                {item.product_name}
              </div>
              <div>

                {parentType === "current_stock" ? item.current_stock : parentType === "Available" ? item.unrealised_qty :
                  item.transit_stock
                }
              </div>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export const crateColumns = [
  {
    title: 'Nodes',
    dataIndex: 'node_name',
    key: 'node_name',
    align: 'center',
    width: 400
  },
  {
    title: 'Total',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      // console.log("record", record);
      return (
        <div>
          <Popover content={content(record?.current_stock?.types, "current_stock")} placement="bottom">
            <div className="frz-crate-colm">
              {record?.current_stock?.count}
            </div>
          </Popover>

          {/* //old */}
          {/* <Popover content={content(record.total.total_crates)} placement="bottom">
            <div className="frz-crate-colm">
              {record?.total?.total_crates?.count}
            </div>
          </Popover> */}

          {/* <div className="frz-dis-flex frz-justify-arnd frz-tp-10 frz-crate-colm-border">
            <Popover content={content(record.total.active_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Active: <b>{record?.total?.active_crates?.count}</b>
              </div>
            </Popover>
            <Popover content={content(record.total.inactive_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Inactive:  <b>{record?.total?.inactive_crates?.count}</b>
              </div>
            </Popover>
          </div> */}
        </div>
      )
    }
  },
  {
    title: 'Available Crates',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return (
        <div>
          <Popover content={content(record?.available?.types, "Available")} placement="bottom">
            <div className="frz-crate-colm frz-crate-green">
              {record?.available?.count}
            </div>
          </Popover>

          {/* <div className="frz-dis-flex frz-justify-arnd frz-tp-10 frz-crate-colm-border">
            <Popover content={content(record.in_store_crates.busy_with_product_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Occupied: <b>{record?.in_store_crates?.busy_with_product_crates?.count}</b>
              </div>
            </Popover>
            <Popover content={content(record.in_store_crates.empty_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Empty: <b>{record?.in_store_crates?.empty_crates?.count}</b>
              </div>
            </Popover>
          </div> */}
        </div>
      )
    }
  },
  {
    title: 'InTransit',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return (
        <div>
          <Popover content={content(record.in_transit.types)} placement="bottom">
            <div className="frz-crate-colm frz-crate-orange">
              {record?.in_transit?.count}
            </div>
          </Popover>
          {/* <div className="frz-dis-flex frz-justify-arnd frz-tp-10 frz-crate-colm-border">
            <Popover content={content(record.in_transit_crates.busy_with_product_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Occupied: <b>{record?.in_transit_crates?.busy_with_product_crates?.count}</b>
              </div>
            </Popover>
            <Popover content={content(record.in_transit_crates.empty_crates)} placement="bottom">
              <div className="frz-crate-subcolm">
                Empty: <b>{record?.in_transit_crates?.empty_crates?.count}</b>
              </div>
            </Popover>
          </div> */}
        </div>
      )
    }
  },
  // {
  //   title: 'Inactive',
  //   dataIndex: '',
  //   key: '',
  //   align: 'center',
  //   render: (record) => {
  //     return (
  //       <div className="frz-crate-colm frz-crate-grey">{record.inactive_crates}</div>
  //     )
  //   }
  // },
]

export const crateTypeColumns = [
  {
    title: 'Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <div className="frz-crate-type-colm">{record.name}</div>
    }
  },
  {
    title: 'Dimensions',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return (
        <div className="frz-crate-type-colm">
          <div>
            Length: <span className="frz-fw-400">
              {record?.dimensions_in_cm?.length} cm ({record?.dimensions_in_inches?.length.toFixed(0)}“ )
            </span>
          </div>
          <div>
            Width: <span className="frz-fw-400">
              {record?.dimensions_in_cm?.breadth} cm ({record?.dimensions_in_inches?.breadth.toFixed(0)}“ )
            </span>
          </div>
          <div>
            Height: <span className="frz-fw-400">
              {record?.dimensions_in_cm?.height} cm ({record?.dimensions_in_inches?.height.toFixed(0)}“ )
            </span>
          </div>
        </div>
      )
    }
  },
  {
    title: 'Capacity (In KG)',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <div className="frz-crate-type-colm">{record.capacity}</div>
    }
  },
  {
    title: 'Crate Weight',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <div className="frz-crate-type-colm">{record.weight_in_kgs}</div>
    }
  },
  {
    title: 'Crate Price',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <div className="frz-crate-type-colm">{record.cost}</div>
    }
  },
]

export const crateDetailColumns = [
  {
    title: 'Crate No.',
    dataIndex: 'crate_no',
    key: 'crate_no',
    align: 'center'
  },
  {
    title: 'Crate Type',
    dataIndex: 'crate_type',
    key: 'crate_type',
    align: 'center'
  },
  {
    title: 'Active/Inactive',
    dataIndex: 'is_active',
    key: 'is_active',
    align: 'center',
    render: (record) => {
      return <div>{record ? "True" : "False"}</div>
    }
  }
]

export const transitDetailColumns = [
  {
    title: 'Crate No.',
    dataIndex: 'crate_no',
    key: 'crate_no',
    align: 'center'
  },
  {
    title: 'Crate Type',
    dataIndex: 'crate_type',
    key: 'crate_type',
    align: 'center'
  },
]