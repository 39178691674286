import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, InputNumber, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";

const {Option}= Select;
const ops_service = new OperationsService();
const ListingCrate =({crateType,nodeId,hide,nodeCode})=>{
    const [spinEnable, setSpinEnable]=useState(false);
    const [crateData,setCrateData]=useState(null);
    const [listingData,setListingData]=useState({})
    const [listingId,setListingId]=useState([])
    const [ids,setIds]=useState([]);
    useEffect(()=>{
        getCrates(); 
    },[crateType])
    // console.log('nodeCodenodeId',nodeId,nodeCode)
    const getCrates = ()=>{
        setListingData([])
        setSpinEnable(true)
        const payload={
            id:nodeId
        }
        ops_service.get_crate_types(payload).subscribe((r) => {
            // console.log(r.response.data)
            setCrateData(r.response.data)
            setSpinEnable(false)
          },
          (err) => {
            console.log(err)
            setSpinEnable(false)
            openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          })
    }

    useEffect(() => {
        updateListing();
    },[listingId])
    const handleSelect=(e)=>{
        // console.log(e)
        setListingId(e)
    }
    useEffect(()=>{
        let temp=[];
        for(let i=0;i<listingData.length;i++){
            temp.push(listingData[i].id)
        }
        setIds(temp)
    },[listingData])
    // console.log(listingId,crateData)
    const updateListing =()=>{
        if(listingId?.length===0){
            setListingData(false)
        }else if(listingId?.length>0){
            let temp=[];
            for(let i=0;i<crateData.length;i++){
                for(let j=0;j<listingId.length;j++){
                    if(crateData[i].id===listingId[j]){
                        temp.push(crateData[i])
                    }
                }
            }
            setListingData(temp)
        }
    }
    // console.log(listingData,'listingData')
    const handleQty=(e,sku)=>{
        // console.log(e)
        let temp=[...listingData]
        for(let i=0;i<temp.length;i++){
            if(temp[i].sku===sku){
                if(Number.isInteger(e)){
                    if(e>=1){
                        temp[i].qty=e;
                        temp[i].error=false;
                        temp[i].missing=false;
                        temp[i].isDecimal=false;
                    }else{
                        temp[i].error=true;
                        temp[i].qty=null;
                        temp[i].missing=false;
                        temp[i].isDecimal=false;
                    }
                }else{
                    temp[i].isDecimal=true;
                }
            }
        }
        setListingData(temp)
    }
    const handleSubmit=()=>{
        if(checkRequiredFields()){
            setSpinEnable(true)
            let items=[];
            if(crateType==='new_crate'){
                for(let i=0;i<listingData.length;i++){
                    let each={product_id:listingData[i]?.id,qty:listingData[i]?.qty};
                    items.push(each)
                }
            }else if(crateType==='discard_crate'){
                for(let i=0;i<listingData.length;i++){
                    let each={product_id:listingData[i]?.id,wastage_quantity:listingData[i]?.qty};
                    items.push(each)
                }
            }
            if(crateType=="new_crate"){
                const payload={
                    "node_code": nodeCode,
                    "product_category": "",
                    "loss_type": crateType,
                    items:items,
                    "newly_added_items": []
                }
                ops_service.add_crate_type(payload).subscribe((r) => {
                    // console.log(r.response.data)
                    openNotificationWithIcon('success','successfully added crates')
                    setSpinEnable(false)
                    hide(false)
                  },
                  (err) => {
                    console.log(err)
                    setSpinEnable(false)
                    openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                  })
            }else if(crateType==='discard_crate'){
                const payload={
                    "node_code": nodeCode,
                    "loss_type": crateType,
                    items:items,
                }
                ops_service.create_partial_wastage(payload).subscribe((r) => {
                    console.log(r.response.data)
                    openNotificationWithIcon('success','successfully added crates')
                    hide(false)
                    setSpinEnable(false)
                  },
                  (err) => {
                    console.log(err)
                    setSpinEnable(false)
                    openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                  })
            }
        }else{
            openNotificationWithIcon('error','please enter all the fields')
        }
    }
    const checkRequiredFields=()=>{
        let temp=[...listingData];
        let flag=true;
        for(let i=0;i<temp.length;i++){
            if((!temp[i]?.qty && temp[i].qty!==0) || temp[i]?.missing || temp[i].isDecimal || temp[i].error ){
                temp[i].missing=true;
                temp[i].isDecimal=false;
                flag=false;
            }
        }
        setListingData(temp)
        return flag;
    }
    const handleRemoveProduct=(sku)=>{
        let filteredData=listingData.filter((item)=>{
            return item?.sku!==sku;
        })
        let tempId=[];
        for(let i=0; i<filteredData.length; i++){
            tempId.push(filteredData[i].id)
        }
        setIds(tempId)
        setListingData(filteredData)
    }
    return(
        <>
        {!spinEnable ? 
        <div>
        <div className='frz-tm-15 crate-select-box'>
            {/* <div className=''></div> */}
            <div>
            <Select
                mode="multiple"
                size={'large'}
                placeholder="Please select crates"
                value={[...ids]}
                onChange={(e)=>handleSelect(e)}
                style={{
                width: 268,
        }}
      >
        {crateData?crateData.map((item)=>{
            return(
                <Option value={item.id} key={item?.id}><img src={item?.image_url} width="30px" height="30px"alt={item?.id}></img>{item?.name} ({item?.sku})</Option>
            )
        }):null}
      </Select>
            </div>
        </div>
            {listingData?.length>0?
            <div className='frz-tm-40'>
                <div className='table-headers-sto'>
                    <Row>
                        <Col span={8}>Crate Type</Col>
                        <Col span={4} className="text-center">Current Stock</Col>
                        <Col span={4} className="text-center">Quantity</Col>
                        <Col span={4} className='text-center'>Action</Col>
                    </Row>
                </div>
                <div className='table-details-sto'>
                {listingData.map((item)=>{
                    return(
                        <Row key={item?.sku}>
                            <Col span={8}>
                                <img src={item.image_url} width='40px' height='40px' alt={item?.image_url}/>
                                {item?.name} ({item?.sku})
                            </Col>
                            <Col span={4} className='text-center'>{item?.current_stock}</Col>
                            <Col span={4} className="text-center">
                                <InputNumber value={item?.qty} onChange={(e)=>handleQty(e,item?.sku)} min={1} placeholder="Enter Quantity" className={(!item?.qty && item?.error) ||item?.missing || item?.isDecimal?'wcl-input-error':''}/>
                                {item?.isDecimal || item?.error ?<p className='wcl-input-error-msg'>Please a valid Integer</p>:null}
                                {item?.missing ?<p className='wcl-input-error-msg'>Missing QTY</p>:null}
                            </Col>
                            <Col span={4} className="delete-sku-icon text-center"><Button onClick={()=>handleRemoveProduct(item?.sku)} icon={<DeleteOutlined />} type="primary"></Button> </Col>
                        </Row>
                    )
                })}
                </div>
                <Button type='primary' className='crte-mgmt-ant-btn-primary' onClick={handleSubmit}>Submit Crates</Button>
            </div>:<p className='horizontal-vertical-center frz-font-20'>Please Add Crates</p>}
        </div> :<div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
        </>
    )
}
export default ListingCrate