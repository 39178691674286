import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Row, Col } from 'antd';
import FRZInputNumber from "../../../_controls/FRZInputNumber";
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';


const AddCrateType = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)

  useEffect(() => {
    if ((data && title == "Edit Crate Type")) {
      form.setFieldsValue({ ...data})
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel,

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancel() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit({...values, dimension_unit: "cm"}, data?.id)
  }


  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {

              });
          }}
          onCancel={handleCancel}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={props.crateProducts} name="sku" isRequired={true}
                label="SKU" placeholder="Select SKU" value="sku" option="sku"
                isShowSearch={true} width={200} selectDisabled={title === 'Edit Crate Type'}/>

            <FRZInput isRequired={true} label="Type Name" name="name" width={200} />

            <Row>
              <Col span={7}>
                <div className="frz-addcrate-dim"><span className="color-red">*</span> Dimension (In Cm) : </div>
              </Col>
              <Col span={14}>
                <div className="frz-dis-flex frz-justify-betw">
                  <FRZInputNumber isRequired={true} name="length" width={80} placeholder="Length"/>
                  <FRZInputNumber isRequired={true} name="breadth" width={80} placeholder="Breadth"/>
                  <FRZInputNumber isRequired={true} name="height" width={80} placeholder="Height"/>
                </div>
              </Col>
            </Row>
            <FRZInputNumber isRequired={true} label="Capacity (In Kg)" name="capacity" width={200} />

            <FRZInputNumber isRequired={true} label="Crate Weight" name="weight_in_kgs" width={200} />

            <FRZInputNumber isRequired={true} label="Crate Price" name="cost" width={200} />


          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})
export default AddCrateType