import { Button, Col, Row, Spin, Table, Tag } from "antd";
import Modal from "antd/lib/modal/Modal"
import { useEffect, useState } from "react";
import moment from "moment";
import { CCWHIncentoryViewDetailsColumns } from "../../../components/antd/columns/operations/category";
import { OperationsService } from "../../../_services/operations";
import MainTable from "../../antd/table";
const operation_service = new OperationsService();
let date=moment().format('DD/MM/YYYY');
const ViewDetailsModal =({show,hide,data,node})=>{
    const [payload,setPayload]=useState({
        'by_node':node ,'node_code':null ,'by_sku_code':data.sku,'by_product_id':null
    })
    const [spinEnable,setSpinEnable]=useState(false);
    const [listingData,setListingData]=useState([])
    const [startDate,setStartDate]=useState(date);
    const [endDate,SetEndDate]=useState(date);
    const [count,setCount]=useState(0)
    useEffect(()=>{
        getData()
    },[startDate])
    const getData=()=>{
       setSpinEnable(true)
        const payLoad={
            start_date:startDate ? startDate :moment().format("DD/MM/YYYY").subtract('days', 1),
            end_date:endDate ? endDate :moment().format("DD/MM/YYYY"),
            by_node:payload.by_node ? payload.by_node :'',
            node_code:payload.node_code ? payload.node_code :'',
            by_sku_code:payload.by_sku_code ? payload.by_sku_code :'',
            by_product_id:payload.by_product_id ? payload.by_product_id :'',
        }
        operation_service.get_inventory_transactions_data(payLoad).subscribe((r) => {
            setSpinEnable(false);
            setListingData(r.response.data);
          },
            (err) => {
            setSpinEnable(false);
              console.log(err);
            }
          );
    }
    const handleOk = () => {
        hide(false);
      };
      const handleCancel = () => {
        hide(false);
      };
    const getPreviousData=()=>{
        setCount((prev)=>prev+1)
       let day= moment(startDate,'DD/MM/YYYY').subtract('days',1);
       setStartDate(day.format('DD/MM/YYYY'))
    }
    return(<>
    <Modal title="View Details" footer={null} className="inventory_txn_container" visible={show} onOk={handleOk} onCancel={handleCancel} key='id'>
        <div className="to-from-container"><Tag>From : {startDate}</Tag>  <Tag> To : {endDate}</Tag></div>
        <div>
            {listingData.length > 0 && !spinEnable ?
            <div>
                    <Table rowKey='stock_ledger_id' dataSource={listingData} columns={CCWHIncentoryViewDetailsColumns} pagination={false} scroll={{y:385}}  />
                    <div className='show-details-btn'> <Button disabled={count===10 ?true:false} type='primary' onClick={getPreviousData}>{count<10 ?'Show More':'Max Limit Reached'}</Button></div>
            </div>
            :(
        listingData.length === 0 && !spinEnable?
        <div>
            <p className='no-data'> Not Data Found In this Range</p>
            <div className='show-details-btn'> <Button disabled={count===10 ?true:false} type='primary' onClick={getPreviousData}>{count<10 ?'Change Range':'Max Limit Reached'}</Button></div>
        </div>
             :     
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>)}
        </div>
      </Modal>
    </>)
}
export default ViewDetailsModal