import React from 'react'
import { Form, InputNumber } from 'antd'
import { tailLayout } from '../_modules/controllayout'
import { SettingOutlined } from '@ant-design/icons';

const FRZInputNumber = ({ name, label, isRequired, placeholder, width, isDisabled, maxvalue,
    setOnChange, type, marginLeft, marginRight, margin, addAfter, addAfterValue, minvalue, isThrowError }) => {

    const onChange = (e) => {
        if (setOnChange) {
            setOnChange(e, type)
        }
    }

    return (
        <Form.Item
            {...tailLayout}
            name={name}
            validateStatus={isThrowError ? "error" : ''}
            label={label}
            rules={[{ required: isRequired, message: "Please enter " + label ? label : placeholder }]}
        >
            {addAfter && addAfterValue ?
                <InputNumber
                    min={minvalue ? minvalue : null}
                    className="frz-pos-rel"
                    style={{ width: width, marginLeft: marginLeft, marginRight: marginRight, margin: margin }}
                    onChange={onChange} placeholder={placeholder ? placeholder : label}
                    addonAfter={<div className="frz-pos-abs frz-addafter">
                        {addAfterValue}
                    </div>}
                    disabled={isDisabled} />
                :
                <InputNumber
                    min={minvalue ? minvalue : null}
                    max={maxvalue ? maxvalue : null}
                    className="frz-pos-rel"
                    style={{ width: width, marginLeft: marginLeft, marginRight: marginRight, margin: margin }}
                    onChange={onChange} placeholder={placeholder ? placeholder : label}
                    disabled={isDisabled} />}
        </Form.Item>
    )
}

export default FRZInputNumber
