import React, { useState, useEffect } from 'react'
import { Spin, Table } from 'antd';
import { reportsNestedDayWiseColumns, reportsNestedUserWiseColumns } from '../../components/antd/columns/operations/attendance'
import { AttendanceService } from '../../_services/attendance'

const reports_service = new AttendanceService()

const NestedAttnTable = ({ record, tabsAttChanged, endDate, startDate, valueBySource }) => {

  const [listingData, setListingData] = useState(false)

  useEffect(() => {
    getLisitngData()
  }, [record])

  const getLisitngData = () => {
    const payload = {
      date: record.date ? record.date : '',
      node_code: valueBySource ? valueBySource : ''
    }
    if (tabsAttChanged == "3") {
      payload.end_date = endDate ? endDate : '';
      payload.start_date = startDate ? startDate : '';
      payload.mobile_number = record.mobile_number
    }
    if (tabsAttChanged == "2") {
      reports_service.get_attan_table_day_wise_nested(payload).subscribe((r) => {
        r.response.data.map((itm, index) => {
          Object.assign(itm, { ...itm, id: index })
        })
        setListingData(r.response.data)
      }, ((err) => {
        console.log("err", err)
      }))
    } else {
      reports_service.get_attan_table_user_wise_nested(payload).subscribe((r) => {
        r.response.data.map((itm, index) => {
          Object.assign(itm, { ...itm, id: index })
        })
        setListingData(r.response.data)
      }, ((err) => {
        console.log("err", err)
      }))
    }
  }

  return (
    <div>
      {listingData ?
        listingData.length ?
          <div>
            <Table columns={tabsAttChanged == "2" ? reportsNestedDayWiseColumns : reportsNestedUserWiseColumns}
              dataSource={listingData}
              pagination={false}
              scroll={{ x: 240, y: `calc(100vh - 330px)` }}
              rowKey="id" />
          </div>
          :
          <div className="frz-reports-no-data-found-nested-table">
            <h3>No Data Found</h3>
          </div>
        :
        <div className="spin-center"><Spin tip="...Loading" /></div>
      }
    </div>
  )
}

export default NestedAttnTable