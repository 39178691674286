import React, { useState } from 'react'
import { Card, Row, Col, Collapse, Progress, Button, Tooltip, Spin } from 'antd'
import { faChevronUp, faChevronDown, faPencilAlt, faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalVeichleNo from './modalveichleno';
import { OperationsService } from '../../../_services/operations'
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import FRZProgress from '../../../_controls/FRZProgress';
import { SplitCellsOutlined, CloseOutlined } from '@ant-design/icons'
import BucketCard from './bucketcard';
import PhaseTwoContainer from './phasetwocontainers';

const { Panel } = Collapse;

const ops_service = new OperationsService()

const BucketContainer = (props) => {
  const { transitBucketsData, getTransitBucketsData, nodeID, valueByNode, onChange,
    nodes, disable, spinEnable, handleCleanUp } = props
  const [isVeichleModal, setIsVeichleModal] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [veichleId, setVeichleId] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deatilView, setDeatilView] = useState(false)
  const [isPhaseTwo, setIsPhaseTwo] = useState(false)
  const [activeVeichleInfo, setActiveVeichleInfo] = useState(false)

  const onPhaseTwo = (vehicleNo, id) => {
    setActiveVeichleInfo({ vehicle_number: vehicleNo, id: id })
    handlePhaseTwo(true)
  }

  const handlePhaseTwo = (value) => {
    handleCleanUp(value)
    setIsPhaseTwo(value)
    if (!value) {
      getTransitBucketsData(nodeID, false, true)
    }
  }


  //common function for edit and view detail
  const veichleNoEdit = (value, vehicleNo, mobileNo, driverName, partnerName, id, edit) => {
    setDeatilView(!edit)
    setVeichleId(id)
    //check if vehicle no missing else open view detail / or is edit mode 
    if (!vehicleNo || edit) {
      let tempData = {
        mobile_number: mobileNo,
        driver_name: driverName,
        vehicle_number: vehicleNo,
        partner_name: partnerName
      }
      setRowData(tempData)
      setIsVeichleModal(value)
    } else {
      onPhaseTwo(vehicleNo, id)
    }
  }

  const vechileDetailsSubmit = (data) => {
    setLoading(true)
    const payload = {
      id: veichleId,
      ...data
    }

    ops_service.put_veichle_details(payload).subscribe((r) => {
      if (deatilView) {
        veichleNoEdit(false, payload.vehicle_number, payload.mobile_number, payload.driver_name, payload.partner_name, payload.id, false)
      }
      getTransitBucketsData(nodeID)
      setLoading(false)
      setIsVeichleModal(false)
    }, ((err) => {
      setLoading(false)
      console.log("err", err)
    }))
  }

  const veichleInfo = (data) => {
    return (
      <div>
        <div>
          <span>
            Driver Name: {data.driver_name}
          </span>
        </div>

        <div>
          <span>
            Driver No: {data.mobile_number}
          </span>
        </div>

        <div>
          <span>
            Veichle No: {data.vehicle_number}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="frz-w-100 frz-tp-10">
      <div>
        <Card>
          <div>
            <div className="frz-dis-flex frz-justify-sb">
              <div className="frz-font-20 frz-dis-flex frz-vt-center">
                {isPhaseTwo ? <div>Vehicle No: {activeVeichleInfo.vehicle_number}</div> :
                  <div>
                    Transit Vehicle Card
                  </div>}
              </div>
              <div>
                <FrzDynamicSearch DropDownData={nodes}
                  selectDisabled={isPhaseTwo}
                  placeholder="Select Source" value="id" option="name"
                  isShowSearch={true} width={600} setOnChange={(e) => onChange(e, 'valueByNode')}
                  type="valueByNode" margin={10} defValue={valueByNode} />
              </div>
              {isPhaseTwo ?
                <div className="frz-dis-flex frz-vt-center frz-rp-20">
                  {/* <FontAwesomeIcon icon={faChevronDown} onClick={() => handlePhaseTwo(false)} /> */}
                  <CloseOutlined onClick={() => handlePhaseTwo(false)} className="frz-fw-600 frz-font-18" />
                </div> :
                <div></div>
              }
            </div>

            {transitBucketsData === undefined ? spinEnable ?
              <div className="spin-center"><Spin tip="...Loading" /></div> :
              <div></div>
              :
              transitBucketsData.length > 0 ?
                <Row className="frz-p-20" gutter={[38, 24]}>
                  {isPhaseTwo ?
                    <Col xl={24}>
                      <PhaseTwoContainer activeVeichleInfo={activeVeichleInfo} transitBucketsData={transitBucketsData} />
                    </Col>
                    :
                    <BucketCard transitBucketsData={transitBucketsData} veichleInfo={veichleInfo} veichleNoEdit={veichleNoEdit} />
                  }
                </Row>
                : (disable || spinEnable ?
                  (spinEnable ?
                    <div className="spin-center"><Spin tip="...Loading" /></div>
                    :
                    <div className="no-data">No Data Available</div>)
                  :
                  <div className="no-data">No Data Available On Selected Filters</div>)
            }
          </div>
        </Card>
        {transitBucketsData === undefined && !spinEnable ? <div className="no-data">Please select source type</div> : null}
      </div>
      {
        isVeichleModal ?
          <ModalVeichleNo isVeichleModal={isVeichleModal} veichleNoEdit={veichleNoEdit} rowData={rowData}
            vechileDetailsSubmit={vechileDetailsSubmit} loading={loading} />
          : null
      }
    </div >

  )
}

export default BucketContainer