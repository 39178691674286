import React, { useState } from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import { useSelector } from "react-redux";
import moment from 'moment';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
// import Search from '../antd/search';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

function Store(props) {
  const [state, setState] = useState({ start_date: '', end_date: '', showDateValue: false, valueByDarkstore: 'all' });
  const [spinEnable, setSpinEnable] = useState(false)
  const [downloadOption, setDownloadOption] = useState(undefined)

  const { start_date, end_date, showDateValue, valueByDarkstore } = state

  function onChange(val) {
    setDownloadOption(val)
    if (val == 'generate_buying_rate_report') {
      setState(prevState => ({ ...prevState, valueByDarkstore: 'all' }))
    }
  }

  function onChangeDarkstore(value) {
    // console.log(values)
    setState(prevState => ({ ...prevState, valueByDarkstore: value }))
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
  }

  function disabledDate(current) {
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  return (
    <div className="layer">
      {!spinEnable ?
        <Row>
          <Card>
            <Col span={24} style={{ marginLeft: 10 }}>
              <h3 style={{ marginTop: 10, marginBottom: 0 }}>
                SKU Wise Wastage Report | Consolidated Stock Report | Stock Ledger Report | STO Report
                | PO GRN Report | Unrealized Stock Report | Buying Rate Report | Wastage Report | Closing Report | Inventory Correction Report
              </h3>
              <RangePicker onChange={onChangeRangePicker}
                value={showDateValue ? [moment(start_date, dateFormat),
                moment(end_date, dateFormat)] : ''} format={dateFormat}
                allowClear={false} disabledDate={disabledDate} />
              {props.nodes ?

                <FrzDynamicSearch DropDownData={props.nodes}
                  isCustomOption={true} customKey={"all"} customOption={"All Nodes"}
                  customValue={"all"} placeholder="Node Name" value="code" option="name"
                  isShowSearch={true} width={300} setOnChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')}
                  type="valueByZapperCode" margin={10} defValue={valueByDarkstore}
                  selectDisabled={downloadOption == 'generate_buying_rate_report'} />

                // <Search placeholder="Node Name" value={valueByDarkstore} data={props.nodes} width='medium'
                //   onChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')} type="valueByZapperCode" />
                :
                <FrzDynamicSearch placeholder="Darkstore Name" value="code" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')}
                  type="valueByZapperCode" margin={10} defValue={valueByDarkstore}
                  selectDisabled={downloadOption == 'generate_buying_rate_report'} />

                // <Search placeholder="Darkstore Name" value={valueByDarkstore}
                //   onChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')} type="valueByDarkstoreStatsCode" />
              }
              <Select style={{ width: 300, marginRight: 10 }}
                value={downloadOption}
                placeholder="Select Download Item" onChange={(e) => onChange(e)}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option key="sku_wise_wastage_report" value="sku_wise_wastage_report">
                  SKU Wise Wastage Report
                </Option>
                <Option key="consolidated_stock_report" value="consolidated_stock_report">
                  Consolidated Stock Report
                </Option>
                <Option key="stock_report" value="stock_report">
                  Stock Ledger Report
                </Option>
                <Option key="procurement/sto_report" value="procurement/sto_report">
                  STO Report
                </Option>
                <Option key="po_grn_report" value="po_grn_report">
                  PO GRN Report
                </Option>
                <Option key="unrealised_stock_report" value="unrealised_stock_report">
                  Unrealized Stock Report
                </Option>
                <Option key="generate_buying_rate_report" value="generate_buying_rate_report">
                  City Wise Buying Rate Report
                </Option>
                <Option key="node_wise_buying_rate_report" value="node_wise_buying_rate_report">
                  Node Wise Buying Rate Report
                </Option>
                <Option key="export_wastage_report" value="export_wastage_report-frzZR">
                  Wastage Report
                </Option>
                <Option key="export_closing_report" value="export_closing_report-frzZR">
                  Closing Report
                </Option>
                <Option key="export_inventory_correction_report" value="export_inventory_correction_report-frzZR">
                  Inventory Correction Report
                </Option>
              </Select>
              {/* <Button disabled={!downloadOption || start_date === ''} onClick={getDownloadList} style={{ width: 100, margin: 10 }}
                type="primary">Download</Button> */}
              <FrzDynamicDownload isDisabled={!downloadOption || start_date === ''} name={'Report'}
                fileName={downloadOption}
                url={downloadOption?.includes("frzZR") ?
                  '/v2/admin_dashboard_v2/stock_losses/' + downloadOption?.split('-')[0]
                  : 'admin_dashboard/' + `${downloadOption}`}
                payload={'?start_date=' + `${start_date ?? ''}` + '&end_date=' + `${end_date ?? ''}` + '&node_code=' + `${valueByDarkstore === 'all' ? '' : valueByDarkstore ?? ''}`} type={'job'}
                reportFunc={downloadOption?.includes("frzZR") ? 'zapper_report_v2' : 'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
            </Col>
          </Card>
        </Row>
        :
        <div className="spin-center">
          <Spin tip="...Download in Progress" />
        </div>
      }
    </div>
  )
}

export default Store