import React, { useState, useEffect } from 'react';
import loginRequired from "../_wrappers/login";
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomRoutes from './routes';
import { Switch as AntSwitch } from "antd";
import Roles from '../_roles/roles.json'
import NotFound from '../components/NotFound/NotFound';

let accessControl = []

function Routes(props) {
  const { toggleTheme, isDarkMode, currentTheme } = props;

  const [customRoutes, setCustomRoutes] = useState(false)
  const [renderData, setRenderData] = useState(false)

  let userRolesLocalData = JSON.parse(localStorage.getItem('roles'))

  useEffect(() => {
    if (localStorage.getItem('roles') !== null) {
      if (!renderData) {
        setRenderData(true)
        getAccessControl(CustomRoutes)
      }
    }
  })

  function getAccessControl(routes) {
    let allowedModules = []
    userRolesLocalData.map((roleData) => {
      if (Roles[roleData]){
        allowedModules.push(...Roles[roleData])
      }
    })
    let customComp=[]
    for(let i=0;i<allowedModules.length;i++){
      if(routes[allowedModules[i].name]){
        if(allowedModules[i]?.children?.length!==0 && allowedModules[i]?.children!==null){
          let childrens=routes[allowedModules[i].name].children;
          let children=[];
          for(let k=0;k<allowedModules[i].children.length;k++){
            for(let l=0;l<childrens.length;l++){
              if(childrens[l].key===allowedModules[i].children[k]){
                children.push(childrens[l])
              }
            }
          }
          customComp.push({...routes[allowedModules[i].name],children:children})
        }else{
          customComp.push(routes[allowedModules[i].name])
        }
      }
    }
    let uniqueAccessControl = [];
    let distinct={};
    for(let i=0; i<customComp.length; i++){
      if(!distinct[customComp[i].name]){
        uniqueAccessControl.push(customComp[i]);
        distinct[customComp[i].name]=1;
      }
    }
    setCustomRoutes(uniqueAccessControl)
  }

  return (
    <div>
      {customRoutes ?
        <Switch>
          {customRoutes.map((data, index) => (
            (data.children ?
              (data.children.map((val, index) => (
                (val.children ?
                  (val.children.map(({ path, component }, index) => (
                    <Route path={path} exact key={index} component={component} />
                  )))
                  : <Route path={val.path} exact key={index} component={val.component} />
                )
              )))
              : <Route path={data.path} exact key={index} component={data.component} />
            )
          ))}
          <Route path='/404' component={NotFound} />
          <Redirect from='*' to='/404' />
        </Switch>
        : null}
      {/* <AntSwitch checked={isDarkMode} onChange={toggleTheme} /> */}
    </div>
  )
}

const Wrapped = loginRequired(Routes)
export default Wrapped