import React, { useState, useEffect, useCallback } from 'react'
import { Tabs, Button, Card, Col, Row, Spin, Pagination, Table, DatePicker, TimePicker, Transfer, Form, Input, Space, InputNumber, Popconfirm, Select, Modal } from 'antd'
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { STOShipmentColumns } from '../../../components/antd/columns/operations/sto_shipment';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { OperationsService } from "../../../_services/operations";
import { openNotificationWithIcon } from '../../../_modules/notification';
import { STOService } from '../../../_services/sto';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop, createDndContext, DndProvider } from 'react-dnd';
import update from 'immutability-helper';

const operation_service = new OperationsService();

const RNDContext = createDndContext(HTML5Backend);

const { Option } = Select;

const transporterData = [{ name: "Letstransport" }, { name: "Porter" }]

const type = 'DraggableBodyRow';
const DraggableBodyRow = ({ index, moveParentRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveParentRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const TableTransfer = ({ leftColumns, rightColumns, moveParentRow, components, isReadOnly, partialEdit, ...restProps }) => (
  <Transfer {...restProps} className={`${"frz-custom-transfer"} ${!isReadOnly || !partialEdit ? '' : 'frz-hide-left-table'}`}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled || isReadOnly || partialEdit }),
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        direction === 'left' ?
          <Table
            className="frz-left-table"
            rowSelection={{ ...rowSelection, hideSelectAll: true }}
            columns={columns}
            dataSource={filteredItems}
            pagination={false}
            // components={components}
            size="small"
            onRow={({ key, disabled: itemDisabled }, index) => ({
              index,
              // moveParentRow,
              onClick: () => {
                if (itemDisabled || listDisabled || isReadOnly || partialEdit) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
          :
          !isReadOnly || !partialEdit ?
            <DndProvider backend={HTML5Backend}>
              <Table
                rowSelection={{ ...rowSelection, hideSelectAll: true }}
                columns={columns}
                dataSource={filteredItems}
                pagination={false}
                components={components}
                size="small"
                onRow={({ key }, index) => ({
                  index,
                  moveParentRow,
                  onClick: () => {
                    if (listDisabled || isReadOnly || partialEdit) return;
                    onItemSelect(key, !listSelectedKeys.includes(key));
                  },
                })}
              />
            </DndProvider>
            :
            <Table
              rowSelection={{ ...rowSelection, hideSelectAll: true }}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}
              // components={components}
              size="small"
              onRow={({ key }, index) => ({
                index,
                // moveParentRow,
                onClick: () => {
                  if (listDisabled || isReadOnly || partialEdit) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
      );
    }}
  </Transfer>
);

const CreateStoShipment = ({ setHide,nodeListing, handleOnCreate, getLisitng, draftData, isReadOnly, partialEdit, vehicleDetail, setPartialEdit }) => {
  const [state, setState] = useState({ nodeId: undefined, valueByDate: undefined, date: '', timeFrom: '', timeTo: '' });
  const { nodeId, valueByDate, date, timeFrom, timeTo } = state
  const [disable, setDisable] = useState(true)
  const [listingData, setListingData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [targetKeys, setTargetKeys] = useState([]);
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState(false)
  const [updateSeq, setUpdateSeq] = useState(false)
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [warningModal, setWarningModal] = useState(false)
  // const [searchTerm, setSearchTerm] = React.useState("");
  // const [searchResults, setSearchResults] = React.useState([]);

  const [form] = Form.useForm()

  useEffect(() => {
    if (!updateNestedRow) {
      setUpdateNestedRow(false)
    }
  }, [updateNestedRow]);

  useEffect(() => {
    if (draftData) {
      let newResponse = draftData.stos.map((x) => ({ ...x, key: x.id }))
      // setListingData(newResponse)
      setProducts(newResponse)
      let tempkeys = newResponse.map((data) => data.id)
      setTargetKeys(tempkeys)
      setState({
        date: moment(draftData.expected_delivery_date.split('-').reverse().join('-')), nodeId: draftData.source_node,
        timeFrom: moment(draftData.expected_delivery_starts_at, "HH:mm"), timeTo: moment(draftData.expected_delivery_ends_at, "HH:mm")
      })
      if (!isReadOnly && !partialEdit) {
        getTableData(draftData.source_node, draftData.expected_delivery_date, newResponse)
      } else {
        setListingData(newResponse)
      }
      form.setFieldsValue({
        transit_vehicles: draftData.transit_vehicles, transit_vehicles_attributes: draftData.transit_vehicles, comment: draftData.comment
      })
    }
  }, [draftData])

  useEffect(() => {
    statusSet()
  }, [state])


  useEffect(() => {
    getColumns()
  }, [listingData, targetKeys])



  const statusSet = () => {
    if (state.date !== ''
      // && state.timeTo !== '' && state.timeFrom !== ''
      && valueByDate !== undefined && nodeId !== undefined) {
      getTableData()
      // console.log("call api")
      setDisable(false)
    }
  }

  const moveParentRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = targetKeys[dragIndex];
      setTargetKeys(
        update(targetKeys, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateSeq(true)
    },
    [targetKeys],
  );

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };



  function getColumns() {
    let tempCol = []
    STOShipmentColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.unshift({
      title: "Sequence",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (record, data) => {
        return (
          <div className="frz-dis-flex frz-hrz-center">
            <div className="frz-seq-ui">
              {targetKeys.indexOf(targetKeys.find((x) => x == record)) + 1 ? targetKeys.indexOf(targetKeys.find((x) => x == record)) + 1 : "-"}
            </div>
          </div>
        );
      },
    })
    // tempCol.push({
    //   title: "Sequence",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   render: (record, data) => {
    //     console.log("data", data)
    //     return (
    //       <div>
    //         {console.log("targetKeys", targetKeys)}
    //         {/* {data.indexOf([data].find((x) => x.id == record))} */}
    //         {targetKeys.indexOf(targetKeys.find((x) => x == record)) + 1}
    //       </div>
    //     );
    //   },
    // });
    setColumns(tempCol)
  }

  /// --------filter--------
  // useEffect(() => {
  //   if (listingData.length) {
  //     setSearchTerm("")
  //     setSearchResults(listingData)
  //   }
  // }, [listingData])

  // useEffect(() => {
  //   if (listingData) {
  //     console.log("targetKeys", targetKeys)
  //     const results = listingData.filter(data =>
  //       data.destination.toLowerCase().includes(searchTerm)
  //     );
  //     setSearchResults(results);
  //   }
  // }, [searchTerm]);

  // const handleChange = event => {
  //   setSearchTerm(event.target.value);
  // };
  /// --------filter--------

  const getTableData = (id, date, restData) => {
    setSpinEnable(true)
    setListingData([])
    setUpdateNestedRow(true);
    const payload = {
      node_id: id ? nodeListing.find((x) => x.name == id).id : nodeId,
      transfer_date: date ? date : valueByDate
    }
    operation_service.get_shipment_table_listing(payload).subscribe((r) => {
      setSpinEnable(false);
      let newResponse = r.response.data.map((x) => ({ ...x, key: x.id }))
      if (restData) {
        newResponse = [...newResponse, ...restData]
      }
      setListingData(newResponse)
    }, ((err) => {
      setSpinEnable(false);
      console.log("err", err)
    }))
  }

  function disabledDate(current) {
    return current && (current < moment().subtract(91, "day") || current > moment().add(10, "day"));
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    // console.log("state", state)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
  }

  function onChangeTimeFrom(time, timeString) {
    setState(prevState => ({ ...prevState, timeFrom: time }))
  }

  function onChangeTimeTo(time, timeString) {
    setState(prevState => ({ ...prevState, timeTo: time }))
  }

  function onChangeKeys(nextTargetKeys) {
    if (nextTargetKeys.length && nextTargetKeys.length > 1 && targetKeys.length < 2 && targetKeys.length) {
      setWarningModal(nextTargetKeys)
    } else {
      setWarningModal(false)
      onOkWarning(nextTargetKeys)
    }
  }

  const onOkWarning = (nextTargetKeys, isConfirm) => {
    if (isConfirm) {
      let formValues = form.getFieldValue()
      form.setFieldsValue({ transit_vehicles_attributes: [formValues.transit_vehicles_attributes[0]] })
    }
    setWarningModal(false)
    let tempData = []
    tempData = listingData.filter(({ id }) => nextTargetKeys.includes(id))
    setProducts(tempData)
    // setSearchTerm("")
    setTargetKeys(nextTargetKeys)
  }

  function reset() {
    setListingData(false)
    setState({ nodeId: undefined, valueByDate: undefined, date: '', timeFrom: '', timeTo: '' })
    setDisable(true)
  }

  function onSubmit() {
    if (products) {
      // let tempkeys = [];
      // targetKeys.map((itm) => {
      //   if (itm) {
      //     tempkeys.push(itm)
      //   }
      // })
      let temp = targetKeys.map((data, index) => {
        return Object.assign({}, { id: data, transit_sequence: index + 1 })
      })
      // const payload = {
      //   transfer_date: valueByDate,
      //   stos: targetKeys
      // }
      return temp
      // console.log("payload", payload)
      // setLoading(true)
      // sto_service.update_sto_shipment_sequence(payload).subscribe((r) => {
      //   openNotificationWithIcon('success', 'Updated Successfully')
      //   setLoading(false)
      //   let tempListingData = listingData
      //   targetKeys.map((item) => {
      //     let sumbittedData = tempListingData.find((itm) => itm.id == item)
      //     let indexOfSubmittedData = tempListingData.indexOf(sumbittedData)
      //     if (indexOfSubmittedData > -1) {
      //       tempListingData.splice(indexOfSubmittedData, 1)
      //     }
      //   })
      //   setListingData(tempListingData)
      //   setTargetKeys([])
      //   // getListing()
      // },
      //   (err) => {
      //     console.log(err);
      //     setLoading(false)
      //     openNotificationWithIcon('error', 'Please Rearrange the sequence to Submit')
      //   }
      // );
    }
  }

  const onFinish = values => {
    // console.log('Received values of form:', values);
  };

  const submitComplete = (isDraft) => {
    if (isDraft) {
      let values = form.getFieldValue()
      setLoading(true)
      let tableData = onSubmit()
      const payload = {
        transit: {
          // id: draftData ? draftData.id : null,
          source_node_id: draftData ? nodeListing.find((x) => x.name == nodeId).id : nodeId,
          expected_delivery_date: valueByDate,
          expected_delivery_starts_at: moment(timeFrom, "HH:mm").format("HH:mm"),
          expected_delivery_ends_at: moment(timeTo, "HH:mm").format("HH:mm"),
          status: isDraft ? "draft" : "shipment_assigned",
          stos_attributes: tableData,
          ...values
        }
      }
      if (draftData) {
        payload['transit'].id = draftData.id
      }
      // console.log("payload", payload)
      if (draftData) {
        operation_service.update_complete_sto(payload).subscribe((r) => {
          getLisitng()
          handleOnCreate(false)
          openNotificationWithIcon('success', !isDraft ? 'Shipment Assigned' : 'Saved as draft')
          setLoading(false)
          setHide(false)
        }, ((err) => {
          setLoading(false)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          console.log("err", err)
        }))
      } else {
        operation_service.create_complete_sto(payload).subscribe((r) => {
          getLisitng()
          handleOnCreate(false)
          openNotificationWithIcon('success', !isDraft ? 'Shipment Assigned' : 'Saved as draft')
          setLoading(false)
          setHide(false)
        }, ((err) => {
          setLoading(false)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          console.log("err", err)
        }))
      }
      // console.log("payload", payload)
    }
    else {
      form
        .validateFields()
        .then(values => {
          var pattern = /^[A-Z]{2}[-][a-zA-Z0-9]{1,2}[-][A-Z]{1,2}[-][0-9]{3,4}$/i
          var pattern2 = /^[A-Z]{2}[-][a-zA-Z0-9]{1}[-][A-Z]{1}[-][0-9]{3,4}$/i
          var pattern3 = /^[A-Z]{2}[-][a-zA-Z0-9]{1,2}[-][A-Z]{1}[-][0-9]{3,4}$/i
          var pattern4 = /^[A-Z]{2}[-][a-zA-Z0-9]{1}[-][A-Z]{1,2}[-][0-9]{3,4}$/i
          var pattern5 = /^[A-Z]{2}[-][a-zA-Z0-9]{1}[-][A-Z]{3,4}[-][0-9]{3,4}$/i
          var pattern6 = /^[A-Z]{2}[-][a-zA-Z0-9]{1,2}[-][A-Z]{3,4}[-][0-9]{3,4}$/i
          var pattern6 = /^[A-Z]{2}[-][a-zA-Z0-9]{1,2}[-][A-Z]{3,4}[-][0-9]{3,4}$/i


          let mobNoOk = true
          let vehiclePattern = true
          for (let i = 0; i <= values.transit_vehicles_attributes.length - 1; i++) {
            if (values.transit_vehicles_attributes[i].driver_mobile_number.toString().length !== 10) {
              openNotificationWithIcon("error", "Invalid mobile no")
              mobNoOk = false
              break
            }
          }
          if (mobNoOk) {
            for (let i = 0; i <= values.transit_vehicles_attributes.length - 1; i++) {
              // console.log(values.transit_vehicles_attributes[i].number)
              if (!pattern.test(values.transit_vehicles_attributes[i].number) &&
                !pattern2.test(values.transit_vehicles_attributes[i].number) &&
                !pattern4.test(values.transit_vehicles_attributes[i].number) &&
                !pattern3.test(values.transit_vehicles_attributes[i].number) &&
                !pattern5.test(values.transit_vehicles_attributes[i].number) &&
                !pattern6.test(values.transit_vehicles_attributes[i].number)) {
                openNotificationWithIcon("error", "Invalid vehicle no")
                vehiclePattern = false
                break
              }
            }
          }
          if (mobNoOk && vehiclePattern) {
            setLoading(true)
            let tableData = onSubmit()
            const payload = {
              transit: {
                // id: draftData ? draftData.id : null,
                source_node_id: draftData ? nodeListing.find((x) => x.name == nodeId).id : nodeId,
                expected_delivery_date: valueByDate,
                expected_delivery_starts_at: moment(timeFrom, "HH:mm").format("HH:mm"),
                expected_delivery_ends_at: moment(timeTo, "HH:mm").format("HH:mm"),
                status: isDraft ? "draft" : "shipment_assigned",
                stos_attributes: tableData,
                ...values
              }
            }
            if (draftData) {
              payload['transit'].id = draftData.id
            }
            if (draftData) {
              operation_service.update_complete_sto(payload).subscribe((r) => {
                getLisitng()
                handleOnCreate(false)
                openNotificationWithIcon('success', !isDraft ? 'Shipment Assigned' : 'Saved as draft')
                setLoading(false)
              }, ((err) => {
                setLoading(false)
                openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                console.log("err", err)
              }))
            } else {
              operation_service.create_complete_sto(payload).subscribe((r) => {
                getLisitng()
                handleOnCreate(false)
                openNotificationWithIcon('success', !isDraft ? 'Shipment Assigned' : 'Saved as draft')
                setLoading(false)
              }, ((err) => {
                setLoading(false)
                openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                console.log("err", err)
              }))
            }
          }
        })
    }
    // form.submit()
  }
  const filterOption = (inputValue, option) => {
    return option.destination.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  }
  function disabledDate(current) {
    return current && (current < moment().subtract(1, "day") || current > moment().add(7, "day"));
  }
  return (
    <div>
      {!isReadOnly && listingData && nodeListing ?
        <div className="frz-card-bottom">
          <div className="frz-custom-card create-shipment-btns frz-dis-flex frz-justify-sb">
            <div>
            </div>
            <div>
              <Button disabled={!targetKeys.length || loading || partialEdit} type="ghost" className="frz-m-10" onClick={() => {submitComplete(true);setHide(false)}}>
                Save as draft
              </Button>

              <Button disabled={!targetKeys.length || loading} type="primary" className="frz-m-10" onClick={() => {submitComplete(false);setHide(false)}}>
                Submit
              </Button>
            </div>
          </div>
        </div>
        : null}

      <div className="frz-dis-flex frz-mt-10">
        {!isReadOnly ?
          <Popconfirm placement="topLeft" title={"Are you sure you want to go back? all the filled details will be rest"} onConfirm={() => {
            handleOnCreate(false)
            setPartialEdit(false)
            if(setHide){
              setHide(false)
            }
              
          }} okText="Yes" cancelText="No">
            <div className="frz-dis-flex frz-vt-center frz-pointer">
              <ArrowLeftOutlined />
            </div>
          </Popconfirm>
          :
          <div className="frz-dis-flex frz-vt-center">
            <ArrowLeftOutlined onClick={() => {handleOnCreate(false);if(setHide){setHide(false)}}} />
          </div>}
        {/* <Popconfirm placement="topLeft" title={"Are you sure you want to go back? all the filled details will be rest"} onConfirm={() => handleOnCreate(false)} okText="Yes" cancelText="No">
          <div className="frz-dis-flex frz-vt-center">
            <ArrowLeftOutlined />
          </div>
        </Popconfirm> */}
        <div className="frz-dis-flex frz-vt-center frz-lp-10">
          <div className="frz-font-20">
            {!isReadOnly ? "Create Shipment" : <># Shipment {draftData.id}</>}
          </div>
        </div>
      </div>

      <div className="frz-fix-scroll">
        <Card className="frz-tm-10">
          <div className="frz-title frz-lm-10">
            BASIC DETAILS
          </div>
          <Col span={24}>
            <div className="frz-dis-flex frz-justify-sb">
              {/* {console.log("partialEdit", partialEdit)} */}
              <div>
                <FrzDynamicSearch customClass={"frz-color-black"} selectDisabled={isReadOnly || partialEdit} isRequired={true} DropDownData={nodeListing}
                  placeholder="Source Node" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="nodeId" margin={10} defValue={nodeId} />

                {/* {console.log(date)} */}
                <DatePicker allowClear={false} disabled={isReadOnly || partialEdit} value={date} disabledDate={disabledDate} onChange={onChangeDate} className={`${"frz-w-200 frz-m-10"} ${!isReadOnly ? '' : 'frz-color-black'}`} placeholder="Expected Delivery Date" />

                {/* <TimePicker value={timeFrom} placeholder="Time from" onChange={onChangeTimeFrom} format={"HH:mm"} className="frz-m-10" />

                <TimePicker value={timeTo} placeholder="Time to" onChange={onChangeTimeTo} format={"HH:mm"} className="frz-m-10" /> */}
                {!isReadOnly ?
                  <Button disabled={disable} onClick={reset} type="primary"
                    className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                  : null}
              </div>
            </div>
          </Col>
        </Card>

        {listingData && nodeListing ?
          <div className='frz-mt-10'>
            {listingData.length > 0 ?
              <>
                <Card>
                  <div className="frz-title frz-lm-10">
                    DESTINATIONS
                  </div>
                  <div className="frz-lm-10 frz-rm-10">
                    {/* <div className="frz-dis-flex">
                      <Input placeholder="Search destinations" style={{ width: 200, marginRight: 10 }}
                        value={searchTerm}
                        onChange={handleChange} />
                      <Button onClick={() => setSearchTerm("")} type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                    </div> */}
                    <Row>
                      <Col className='frz-mt-11' span={24}>
                        {!isReadOnly && !partialEdit ?
                          <TableTransfer
                            dataSource={listingData}
                            targetKeys={targetKeys}
                            onChange={onChangeKeys}
                            partialEdit={partialEdit}
                            isReadOnly={isReadOnly}
                            leftColumns={columns}
                            showSearch
                            filterOption={filterOption}
                            disabled={loading}
                            rightColumns={columns}
                            components={components}
                            moveParentRow={moveParentRow}
                          />
                          : <Table columns={columns} dataSource={listingData} pagination={false} />}
                        {/* <Row>
                          <Col span={12}>
                          </Col>
                          <Col span={12}>
                            <div className='frz-float-right'>
                              <Button disabled={!targetKeys.length} loading={loading} onClick={onSubmit}
                                className="frz-w-100px frz-m-10" type="primary">Submit</Button>
                            </div>
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                  </div>
                </Card>

                {products.length ?
                  <div className='frz-mt-10'>
                    <Card>
                      <div className="frz-title frz-lm-10 frz-bm-14">
                        VEHICLE DETAILS
                      </div>
                      <div>
                        <Form name="dynamic_form_nest_item" form={form} onFinish={onFinish} autoComplete="off">
                          <Form.List name="transit_vehicles_attributes" initialValue={products.length == 1 ? [{}] : [{}]}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                  <Row key={key} gutter={[10, 10]} className="frz-lp-10">
                                    <Col span={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'number']}
                                        rules={[{ required: true, message: 'Missing Vehicle No' }]}>
                                        <Input className={!isReadOnly ? "frz-w-250" : "frz-color-black frz-w-200"} disabled={isReadOnly} placeholder="Vehicle No (MH-03-C-3843)"/>
                                      </Form.Item>
                                    </Col>

                                    <Col span={5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'driver_name']}
                                        rules={[{ required: true, message: 'Missing Driver Name' }]}>
                                        <Input className={!isReadOnly ? "frz-w-250" : "frz-color-black frz-w-200"} disabled={isReadOnly} placeholder="Driver Name"  style={{marginRight:10,width:280}} />
                                      </Form.Item>
                                    </Col>

                                    <Col span={2.5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'driver_mobile_number']}
                                        // rules={[{ required: true, message: 'Missing Driver Mobile No' }]}>
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing Driver Mobile No'
                                          },
                                          {
                                            pattern: /^(?:\d*)$/,
                                            message: "Value should contain just number",
                                          },
                                          {
                                            pattern: /^[\d]{10}$/,
                                            message: "Invalid mobile no",
                                          },
                                        ]}
                                        validateTrigger="onBlur"
                                      >
                                        <InputNumber className={!isReadOnly ? "" : "frz-color-black"} disabled={isReadOnly} placeholder="Driver Mobile No" style={{width:200}}/>
                                      </Form.Item>
                                    </Col>

                                    <Col span={3.5}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'transport_provider_id']}
                                        rules={[{ required: true, message: 'Missing Transport Provider' }]}>
                                        <Select showSearch
                                          filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                          placeholder="Transport provider" disabled={isReadOnly} className={!isReadOnly ? "frz-w-250" : "frz-color-black frz-w-250"} >
                                          {vehicleDetail.map((data) => {
                                            return (
                                              <Option value={data.id}>{data.name}</Option>
                                            )
                                          })}
                                        </Select>
                                        {/* <Input className={!isReadOnly ? "" : "frz-color-black"} disabled={isReadOnly} placeholder="Transport Provider" /> */}
                                      </Form.Item>
                                    </Col>

                                    {/* <Col span={3}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'transporter_gstin']}
                                        rules={[{ required: true, message: 'Missing Transporter GSTIN' }]}>
                                        <Input className={!isReadOnly ? "" : "frz-color-black"} disabled={isReadOnly} placeholder="Transporter GSTIN" />
                                      </Form.Item>
                                    </Col> */}

                                    <Col span={3}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'capacity_in_tons']}
                                        rules={[{ required: true, message: 'Missing capacity in tons' }]}>
                                        <InputNumber className={!isReadOnly ? "" : "frz-color-black"} disabled={isReadOnly} placeholder="Capacity in tons" style={{ width: "200px" }} />
                                      </Form.Item>
                                    </Col>

                                    {!isReadOnly ?
                                      <Col span={2}>
                                        {products.length == 1 && index > 0 ?
                                          <MinusCircleOutlined onClick={() => {
                                            remove(name)
                                          }} style={{ marginTop: 7 }} />
                                          : null}
                                        {products.length > 1 && null}
                                      </Col>
                                      : null}
                                  </Row>
                                ))}
                                {/* {console.log("products", products, !isReadOnly)} */}
                                {products.length == 1 && !isReadOnly ?
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => {
                                      add()
                                    }} block icon={<PlusOutlined />}>
                                      Add Vehicle Detail
                                    </Button>
                                  </Form.Item>
                                  : null}
                              </>
                            )}
                          </Form.List>
                          {/* <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item> */}
                        </Form>
                      </div>
                    </Card>

                    <div className="frz-tm-10 frz-bm-20">
                      <Card>
                        <div className="frz-title frz-lm-10 frz-bm-10">
                          NOTE
                        </div>
                        <div className="frz-lm-10 frz-rm-10">
                          <Row>
                            <Col span={24}>
                              <Form name="dynamic_form_nest_item" form={form} onFinish={onFinish} autoComplete="off">
                                <Form.Item name="comment">
                                  <Input.TextArea className={!isReadOnly ? "" : "frz-color-black"} disabled={isReadOnly} />
                                </Form.Item>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </div>
                  : null}
              </>
              : (disable || spinEnable ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">No Data Available</div>)
                :
                <div className="no-data">No Data Available On Selected Filters</div>)
            }
          </div>
          :
          null
          // <div className="no-data">Please select source type and transfer date</div>
        }
      </div>

      {warningModal ?
        <Modal
          visible={warningModal}
          onOk={() => onOkWarning(warningModal, true)}
          onCancel={() => setWarningModal(false)}
          okText="Yes"
          cancelText="No">
          <h4>
            Are you sure you want to add the destination?, multiple destination can have only single vehicle detail.
          </h4>
        </Modal>
        : null}
    </div>
  )
}

export default CreateStoShipment