import React, { useState } from 'react';
import { Button } from 'antd'
import { DownloadService } from '../_services/downloads';
import { openNotificationWithIcon } from '../_modules/notification';
import { DownloadOutlined } from '@ant-design/icons';

const download_service = new DownloadService()

function FrzDynamicDownload({ name, fileName, url, payload, type, reportFunc, jobReportFunc, base, isDisabled, buttonTypeIcon, onlyName, setAllocationId, setReportUrl }) {

    const [loading, setLoading] = useState(false)

    function getDownloadList() {
        setLoading(true)
        download_service[reportFunc](url, payload).subscribe((r) => {
            if (setAllocationId) {
                setAllocationId(r.response.data)
                setLoading(false)
            }
            if (type === 'job') {
                var myVar = setInterval(() => {
                    download_service[jobReportFunc](base === 'fraazo' ? r.response.data.id : r.response.data).subscribe(res => {
                        if (base === 'fraazo') {
                            if (res.response.csv_data.status === 'success') {
                                clearInterval(myVar)
                                window.open(res.response.csv_data.url, '_blank')
                                openNotificationWithIcon('success', 'Downloaded Successfully')
                                setLoading(false)
                            }
                        } else if (base === 'zapper') {
                            if (res.response.response.status === 'success') {
                                if (setReportUrl) {
                                    setReportUrl(res.response.response.url)
                                    clearInterval(myVar)
                                    return
                                }
                                clearInterval(myVar)
                                window.open(res.response.response.url, '_blank')
                                openNotificationWithIcon('success', 'Downloaded Successfully')
                                setLoading(false)
                            }
                        }
                    }, (err) => {
                        setLoading(false)
                        clearInterval(myVar)
                        openNotificationWithIcon('error', 'Download Failed')
                    })
                }, 6000);
            } else {
                const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
                const downloadLink = document.createElement("a");
                const downloadFileName = `${fileName}_${(new Date).toDateString().replace(/ /g, '_')}.csv`;
                downloadLink.href = linkSource;
                downloadLink.download = downloadFileName;
                downloadLink.click();
                openNotificationWithIcon('success', 'Downloaded Successfully')
                setLoading(false)
            }
        }, (err) => {
            setLoading(false)
            openNotificationWithIcon('error', 'Download Failed')
        })
    }

    return (
        !buttonTypeIcon ?
            <Button disabled={isDisabled} loading={loading} onClick={getDownloadList} className="frz-dwn"
                type="primary"> {onlyName ? name : `${"Download" + " " + name}`}</Button>
            :
            <Button loading={loading} className="frz-lm-10" title="Edit" icon={<DownloadOutlined />} onClick={getDownloadList}>
            </Button>
    );
}

export default FrzDynamicDownload;
