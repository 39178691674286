import { ReloadOutlined } from "@ant-design/icons"
import { Button, Col, Input, Row, Tag } from "antd"
import { useState } from "react"

const ViewDetails =({viewDetailsMain,setViewNormalData,viewNormalMainData,viewPackageData,viewCrateData,viewNormalData})=>{
    const [searchValue,setSearchValue]=useState(null)
    const handleSearch=(e)=>{
        let value=e.target.value;
        setSearchValue(value)
        
        let filtered=viewNormalMainData.filter((item)=>{
            return (item?.sku + item?.name).toLowerCase().includes(value.toLowerCase())
        })
        setViewNormalData(filtered)
    }
    return(
        <>
        <div style={{backgroundColor:'white',padding:'20px',display:'flex'}}>
           <div>
                <span className='frz-fw-bold frz-font-16'>STO NO : </span>
                <Tag><span className="frz-font-13">{viewDetailsMain?.order_number}</span></Tag>
           </div>
           <div>
               <span className='frz-fw-bold frz-font-16'>Source : </span>
            <Tag><span className="frz-font-13">{viewDetailsMain?.source_node}</span></Tag>
           </div>
           <div>
            <span className='frz-fw-bold frz-font-16'>Destination : </span>
            <Tag><span className="frz-font-13">{viewDetailsMain?.destination_node}</span></Tag>
           </div>
           <div>
            <span className='frz-fw-bold frz-font-16'>Delivery Date : </span>
            <Tag><span className="frz-font-13">{viewDetailsMain?.transfer_date}</span></Tag>
           </div>
           <div>
            <span className='frz-fw-bold frz-font-16'>Status :  </span>
            <Tag ><span className="frz-font-13">{viewDetailsMain?.status}</span></Tag>
           </div>
        </div>
        <div>  
        {viewNormalMainData.length>0 ?<div className='view-details-container-sto'>
            <div className="table-headers-sto">
                        <Input className="frz-w-150" value={searchValue} onChange={handleSearch} placeholder="search sku"/>
                        <Button icon={<ReloadOutlined />} type='primary' onClick={()=>{setSearchValue(null);setViewNormalData(viewNormalMainData)}} disabled={!searchValue?true:false} className='frz-lm-5 frz-border-radius-50p' ghost></Button>
                        <Row>
                            <Col span={7}>Product</Col>
                            <Col span={3} className="frz-text-center">Expected Transfer QTY</Col>
                            {viewDetailsMain.status!=="assigned"&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Transfer QTY</Col>:null}
                            {viewDetailsMain.status!=="assigned"&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Received QTY </Col>:null}
                            {viewDetailsMain.status!=="assigned"&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Rejected QTY </Col>:null}
                            <Col span={2} className='frz-text-center'>Units</Col>
                        </Row>
            </div>
            <div className="table-details-sto"> 
                {viewNormalData.length>0 ? viewNormalData?.map((item,i)=>{
                        return(<Row key={i}>
                            <Col span={7}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                            <Col span={3} className='frz-text-center'>{item?.expected_transfer_qty}</Col>
                            {viewDetailsMain.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.transfer_qty}</Col>:null}
                            {viewDetailsMain.status!=="assigned"&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.qty}</Col>:null}
                            {viewDetailsMain.status!=="assigned"&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.rejected_qty}</Col>:null}
                            <Col span={2} className='frz-text-center'>{item?.inventory_unit}</Col>
                        </Row>)}):null}
            </div>
        </div>:null}
        <div>
        {viewCrateData.length>0?<div className="view-details-container-sto">
            <div className="table-headers-sto">
                        <Row>
                            <Col span={8}>Crate Type</Col>
                            {viewDetailsMain.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Transfer QTY</Col>:null}
                            {viewDetailsMain.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Received QTY </Col>:null}
                            <Col span={2} className='frz-text-center'>Units</Col>
                        </Row>
            </div>
            <div className="table-details-sto"> 
                {viewCrateData.length>0 ? viewCrateData?.map((item,i)=>{
                        return(<Row key={i}>
                            <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                            {viewDetailsMain?.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.transfer_qty}</Col>:null}
                            {viewDetailsMain?.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.qty}</Col>:null}
                            <Col span={2} className='frz-text-center'>{item?.inventory_unit}</Col>
                        </Row>)}):null}
            </div>
        </div>:null} 
        {viewPackageData.length>0?<div className="view-details-container-sto">
            <div className="table-headers-sto">
                        <Row>
                            <Col span={8}>Package Type</Col>
                            {viewDetailsMain.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Transfer QTY</Col>:null}
                            {viewDetailsMain.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>Received QTY </Col>:null}
                            <Col span={2} className='frz-text-center'>Units</Col>
                        </Row>
            </div>
            <div className="table-details-sto"> 
                {viewPackageData.length>0 ? viewPackageData?.map((item,i)=>{
                        return(<Row key={i}>
                            <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                            {viewDetailsMain?.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.transfer_qty}</Col>:null}
                            {viewDetailsMain?.status!=='assigned'&&viewDetailsMain?.status!=="shipment_assigned"?<Col span={2} className='frz-text-center'>{item?.qty}</Col>:null}
                            <Col span={2} className='frz-text-center'>{item?.inventory_unit}</Col>
                        </Row>)}):null}
            </div>
        </div>:null}    
        </div>
        </div>
        </>
    )
}
export default ViewDetails