import React, { useEffect, useState } from 'react';
import { Layout, Menu, Select, Popover, Spin, Dropdown } from 'antd';
import {  MenuOutlined } from '@ant-design/icons';
import { NavLink, useLocation, useHistory } from "react-router-dom";
import CustomRoutes from '../../_routes/routes';
import { GoogleLogout } from 'react-google-login';
import { GeneralService } from '../../_services/general';
import { getPreLoadData } from '../../_actions';
import { useDispatch } from "react-redux";
import Roles from '../../_roles/roles.json'
import { SettingOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import Sider from 'antd/lib/layout/Sider';

const { Header, Content } = Layout;
const { Option } = Select;
const procurementData = [{ name: "Fnv", value: "fnv" }, { name: "Fmcg", value: "fmcg" }]
const general_service = new GeneralService()


function CustomLayout(props) {

  const history = useHistory();

  const [cityChanged, setCityChanged] = useState(false)
  const [cityData, setCityData] = useState(false)
  const [activeKey, setActiveKey] = useState(false)
  const [selectCity, setSelectCity] = useState('')
  const [procurementType, setProcurementType] = useState(undefined)
  const location = useLocation()
  const dispatch = useDispatch();
  const [adminLvlOps, setAdminLvlOps] = useState(false)
  const [customRoutes, setCustomRoutes] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [expand,setExpand]=useState(false)
  const [coord,setCoord]=useState({x:null,y:null})
  const [currPage,setCurrPage] = useState(false)
  let userRolesLocalData = JSON.parse(localStorage.getItem('roles'))

  const { SubMenu } = Menu;

  useEffect(() => {
    if (location.pathname !== "/") {
      setActiveKey(location.pathname.split("/")[1])
      setCurrPage(location.pathname.split("/")[1].replaceAll('_'," ").split(' ')
      .map(word => word[0]
      .toUpperCase()
      .concat(word.slice(1)))
      .join(' '));
    }
    if (localStorage.getItem('roles') !== null) {
      if (!renderData) {
        setRenderData(true)
        if (localStorage.getItem('city') === null) {
          localStorage.setItem('city', '')
        } else {
          setSelectCity(localStorage.getItem('city'))
        }
        getAccessControl(CustomRoutes)
      }
    }
  })

  useEffect(() => {
    if (customRoutes) {
      if (location.pathname === "/") {
        setActiveKey(customRoutes[0].key)
        history.push(customRoutes[0].path)
        setCurrPage(customRoutes[0].name.replaceAll('_'," ").split(' ')
        .map(word => word[0]
        .toUpperCase()
        .concat(word.slice(1)))
        .join(' '))
      }
    }
  }, [customRoutes])

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin'].includes(data)) {
        setAdminLvlOps(true)
      }
    })
    getDs()
    getCity()

  }, [])

  useEffect(() => {
    if (adminLvlOps !== undefined) {
      getCurrentPoType()
    }
  }, [adminLvlOps])

  const getCurrentPoType = () => {
    // let potype = localStorage.getItem("procurementtype")
    if (adminLvlOps) {
      setProcurementType("All")
      localStorage.setItem('procurementtype', '')
    } else {
      setProcurementType("Fnv")
      localStorage.setItem('procurementtype', 'fnv')
    }
  }

  function getDs() {
    general_service.get_darkstore().subscribe((r) => {
      dispatch(getPreLoadData(r.response.data, 'DARKSTORE'))
      setCityChanged(true)
    },
      (err) => {
        console.log(err)
      })
  }
  
  function getCity() {
    general_service.get_city().subscribe((r) => {
      dispatch(getPreLoadData(r.response.data, 'CITY'))
      setCityData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getAccessControl(routes) {
    let accessControl = []
    userRolesLocalData.map((roleData) => {
      if (Roles[roleData]){
        accessControl.push(...Roles[roleData])
      }
    })
    let customComp=[]
    for(let i=0;i<accessControl.length;i++){
      if(routes[accessControl[i].name]){
        if(accessControl[i]?.children?.length!==0 && accessControl[i]?.children!==null){
          let childrens=routes[accessControl[i].name].children;
          let children=[];
          for(let k=0;k<accessControl[i].children.length;k++){
            for(let l=0;l<childrens.length;l++){
              if(childrens[l].key===accessControl[i].children[k]){
                children.push(childrens[l])
              }
            }
          }
          customComp.push({...routes[accessControl[i].name],children:children})
        }else{
          customComp.push(routes[accessControl[i].name])
        }
      }
    }
    let uniqueAccessControl = [];
    let distinct={};
    for(let i=0; i<customComp.length; i++){
      if(!distinct[customComp[i].name]){
        uniqueAccessControl.push(customComp[i]);
        distinct[customComp[i].name]=1;
      }
    }
    setCustomRoutes(uniqueAccessControl)
  }


  function onChangeCity(val) {
    localStorage.setItem('city', val)
    setSelectCity(val)
    setCityChanged(false)
    getDs()
  }

  function onChangeProcurementType(val) {
    localStorage.setItem('procurementtype', val)
    setProcurementType(val)
    setCityChanged(false)
    getDs()
  }

  const handleOnThemeChange = () => {
    props.children.props.toggleTheme(props.children.props.currentTheme === "light" ? true : false)
    localStorage.setItem("themeChoice", props.children.props.currentTheme === "light" ? "dark" : "light");
  }

  useEffect(() =>{
      if(coord.x===0){
        setExpand(true)
      }
      if(coord.x>360){
        setExpand(false)
      }
  },[coord])

  const handleMouseMove=(e)=>{
    setCoord({ x: e.screenX, y: e.screenY });
  }

  const content = (
    <div>
      <div className="theme-hover">
        <GoogleLogout
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={renderProps => (
            <div className="frz-pointer frz-dis-flex" onClick={() => { renderProps.onClick(); props.userlogout() }}>
              <div className="frz-rp-10 frz-dis-flex frz-vt-center">
                <FontAwesomeIcon icon={faSignOutAlt} className="frz-danger" />
              </div>
              <button disabled={renderProps.disabled} className="sign-out frz-danger frz-dis-flex frz-vt-center">
                <div className="frz-fw-500">Logout</div>
              </button>
            </div>
          )}
          buttonText="Logout"
          onLogoutSuccess={props.responseGoogleLogout} />
      </div>


      <div className="theme-hover" onClick={() => handleOnThemeChange()}>
        <div className="frz-dis-flex frz-pointer">
          <div className="frz-rp-10 frz-dis-flex frz-vt-center">
            <FontAwesomeIcon icon={props.children.props.currentTheme === "light" ? faMoon : faSun} />
          </div>
          <div className="frz-dis-flex frz-vt-center frz-fw-500">
            {props.children.props.currentTheme === "light" ? "Dark Theme" : "Light Theme"}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    customRoutes && activeKey &&
      <Layout onMouseMove={handleMouseMove}>
        <Header>
          <div className="frz-dis-flex frz-justify-sb frz-bb-1" >
            <span>
              <span className="frz-rm-20 frz-lm-n-10">
                  <b> <MenuOutlined onClick={()=>setExpand(!expand)} /></b>
              </span>
              <span className="fraazo-color frz-rp-30">
                <b>Fraazo Ops</b>
              </span>
            </span>
            <span className='curr-page frz-pl-150'><b>{currPage==='Cc Wh'?"Inventory List":currPage}</b></span>
            <div>
              <Select className="frz-rm-10 frz-w-130" value={procurementType}
                placeholder="PO Type" onChange={onChangeProcurementType}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {procurementData.map(data => {
                  return (
                    <Option key={data.name} value={data.value}>{data.name}</Option>
                  )
                })}
                {adminLvlOps ?
                  <Option key="all" value={""}>{"All"}</Option>
                  : null}
              </Select>
              {cityData && localStorage.getItem('city') !== null ?
                <Select className="frz-w-150" value={selectCity}
                  placeholder="Select City" onChange={onChangeCity}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option key={''} value={''}>All Cities</Option>
                  {cityData.map(data => {
                    return (
                      <Option key={data.code} value={data.code}>{data.name}</Option>
                    )
                  })}
                </Select>
                : null}
              <span className="frz-ml-20 frz-mt-6">
                <Popover trigger="click" placement="bottomLeft" content={content}>
                  <SettingOutlined className="frz-font-16" />
                </Popover>
              </span>
            </div>
          </div>
        </Header>
        <div className='frz-dis-flex  main-container'>
          <div className="sider-hover"><Sider width={expand?230:1}  className={'frz-bg-inh sider-container'}>
              <Menu mode="vertical" defaultSelectedKeys={[activeKey]}>
                {customRoutes.map((data) => {
                  return (
                    data.children ?
                      <SubMenu key={data.key} title={data.name} className="frz-font-15 frz-fw-500 custom-submenu"> 
                        {data.children.map((childData) => {
                          return (
                            <Menu.ItemGroup key={childData.key}>
                              <Menu.Item key={childData.key}>
                                <NavLink to={childData.path}>
                                  {childData.name}
                                </NavLink>
                              </Menu.Item>
                            </Menu.ItemGroup>
                          )
                        })}
                      </SubMenu>
                      : <Menu.Item className="frz-font-15 frz-fw-500" key={data.key}>
                        <NavLink to={data.path}>
                          {data.name}
                        </NavLink>
                      </Menu.Item>
                  )
                })}
              </Menu>
          </Sider></div>
          {cityChanged ?
              <Content className="content-container frz-mt-11">
                {props.children}
              </Content>
            :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>
          }
        </div>
      </Layout>
  )
}

export default CustomLayout