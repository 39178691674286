import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Row, Col, Space, InputNumber } from 'antd';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZSelect from '../../../_controls/FRZSelect';
import { useSelector } from 'react-redux';
import moment from 'moment'

const NewForcastListingModal = ({ showNewForeCastModal, handleNewForeCastModal, products, rowData, newModalSubmit, loading }) => {
  const [form] = Form.useForm();

  const [isAllFiledSelected, setIsAllFiledSelected] = useState(rowData ? true : false)

  const cityData = useSelector(state => state.auth.city);

  const onCreate = (values) => {
    const payload = { ...values, delivery_date: moment(values.delivery_date).format("DD-MM-YYYY") }
    newModalSubmit(payload)
  }

  const onFormChange = () => {
    const formData = form.getFieldValue()
    if (formData.delivery_date && formData.sku) {
      setIsAllFiledSelected(true)
    } else {
      setIsAllFiledSelected(false)
    }
  }

  return (
    <Modal
      visible={showNewForeCastModal}
      title="Create Forecasting"
      okText="Create"
      width={"60%"}
      cancelText="Cancel"
      okButtonProps={{ loading: loading }}
      onCancel={() => handleNewForeCastModal(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ delivery_date: rowData ? moment(rowData.delivery_date) : '', sku: rowData.sku }}
      >
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <FRZDatepicker placeholder="Select Delivery Date" isRequired={true}
              name="delivery_date" label='Select Delivery Date' setOnChange={onFormChange}
              isDisabled={rowData}
            />
          </Col>
          <Col span={16}>
            <FRZSelect DropDownData={products} label="Select SKU Name" name="sku" isRequired={true}
              placeholder="Select SKU Name" value="sku" option="name"
              setOnChange={onFormChange}
              isShowSearch={true} width={"100%"}
              selectDisabled={rowData}
              breakOption={true} />
          </Col>
        </Row>

        {isAllFiledSelected ?
          <Form.List name="cities" initialValue={rowData ? rowData.product_forecast : cityData}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row className="frz-form-list-grey-card" key={key} gutter={[20, 20]}>
                    <Col span={6}>
                      <Form.Item
                        className="frz-form-item-no-margin"
                        {...restField}
                        name={[name, 'name']}
                      // rules={[{ required: true, message: 'Missing first name' }]}
                      >
                        <Input className="frz-input-textview" disabled={true} bordered={false} placeholder=" First Name" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label="Forecast Quantity"
                        {...restField}
                        name={[name, 'forecast_qty']}
                      // rules={[{ required: true, message: 'Missing last name' }]}
                      >
                        <InputNumber placeholder="Forecast Quantity" disabled={true}/>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label="Edit Quantity"
                        {...restField}
                        name={[name, 'qty']}
                      // rules={[{ required: true, message: 'Missing last name' }]}
                      >
                        <InputNumber placeholder="Edit Quantity" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label="Target Price"
                        {...restField}
                        name={[name, 'target_price']}
                      // rules={[{ required: true, message: 'Missing last name' }]}
                      >
                        <InputNumber placeholder="Target Price" />
                      </Form.Item>
                    </Col>
                    <div className="frz-hrz-div" />
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          : null}
      </Form>
    </Modal >
  )
}

export default NewForcastListingModal