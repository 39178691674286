import React, { useState } from 'react';
import { Modal, Button, Radio } from 'antd';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';

const ModalUploadSto = ({ uploadStoModal, handleStoModal, getSto }) => {

  const [stoFormat, setStoFormat] = useState("old");

  const handleStoFormat = (value) => {
    setStoFormat(value.target.value)
  }

  return <Modal className="upload-sto-modal-outward"
    title="Upload STO"
    okButtonProps={{ style: { display: "none" } }}
    visible={uploadStoModal}
    onOk={() => handleStoModal(false)}
    onCancel={() => handleStoModal(false)}>
    <div className="frz-dis-flex frz-vt-center">
      <Radio.Group className="frz-dis-flex-imp frz-vt-center" value={stoFormat} onChange={handleStoFormat}>
        {/* <Radio value={"new"} checked>New</Radio> */}
        <Radio value={"old"}>Old</Radio>
      </Radio.Group>
      <FrzDynamicUpload checkPoType={true} disabled={!stoFormat} name={'STO'} url={`admin_dashboard/procurement/upload_sto_create_file?sto_format_type=${stoFormat}&created_by=`
        + `${JSON.parse(localStorage.getItem('auth')).id}` + '&procurement_type=' + localStorage.getItem('procurementtype')}
        uploadFunc={'upload_zapper_put'} callBack={getSto} />
    </div>
    <div style={{marginLeft:'13%',textDecoration:'underline'}}>
      <a download href='https://sku-zapper.s3.ap-south-1.amazonaws.com/1654583875-create-sto-upload.csv'>Download Sample STO</a>
    </div>
  </Modal>;
};

export default ModalUploadSto;
