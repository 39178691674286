import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Select, Button, Row, Col, Input, InputNumber, Spin, Radio } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import { WastageService } from '../../../_services/wastage';
import { ReloadOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import { tailLayout } from '../../../_modules/controllayout';

const wastage_service = new WastageService()

const lossTypeData = [
  { value: "rtv", option: "RTV" }
]

const AddwastageModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodeCode, setNodeCode] = useState(undefined)
  const [noDataFound, setNoDataFound] = useState(false)
  const [formListData, setFormListData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [lossType, setLossType] = useState()

  const [form] = Form.useForm();

  const { Option } = Select

  useEffect(() => {
    if ((data && formListData && title == "Create Wastage")) {
      formListData.map((e) => {
        return (Object.assign(e, {
          closing_stock: e.current_stock,
          edit_wastage_qty: 0,
          edit_unaccounted_qty: 0,
        }))
      })
      form.setFieldsValue({ data: formListData })
    } else {
      setDisable(false)
      setNodeCode(undefined)
      setFormListData(false)
      setNoDataFound(false)
    }
  }, [data, formListData])

  function handleOnSubmit(values) {

    let validate = true;

    values.data.map((e) => {
      if (e.edit_wastage_qty < 0) {
        openNotificationWithIcon('error', 'Wastage Qty cannot be negative')
        validate = false
      }
    })

    values.data.map((e) => {
      return (Object.assign(e, {
        product_id: e.id,
        wastage_qty: e.edit_wastage_qty,
        unaccounted_qty: e.edit_unaccounted_qty,
        unit_price: e.unit_price
      }))
    })

    const finalPayload = values.data.map((e) => {
      return (
        {
          product_id: e.product_id, unaccounted_qty: e.unaccounted_qty,
          wastage_qty: e.wastage_qty, unit_price: e?.unit_price ?? ""
        }
      )
    })

    if (validate) {
      const payload = { node_code: nodeCode, items: finalPayload, loss_type: lossType }
      setLoading(true)
      modalSubmit(payload)
    }
  }

  function getWastage() {
    setIsLoading(true)
    setDisable(true)
    const payload = { nodeCode: nodeCode }
    wastage_service.wastage_get_all_products(payload).subscribe((r) => {
      setIsLoading(false)
      if (r.response.success = "success") {
        if (r.response.data.length > 0) {
          setFormListData(r.response.data)
          setNoDataFound(false)
        } else {
          setNoDataFound(true)
          setDisable(false)
        }
      }
    },
      (err) => {
        console.log(err)
        setIsLoading(false)
        setDisable(false)
      })
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setNodeCode(undefined)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  function reset() {
    form.resetFields()
    setNodeCode(undefined)
    setFormListData(false)
    setNoDataFound(false)
    setLossType()
  }

  const handleNodeCode = (e) => {
    setNodeCode(e)
    setDisable(false)
  }

  const handleChange = () => {
    let { data } = form.getFieldValue();
    data = data.map(innerData => {
      if (innerData.edit_wastage_qty === undefined || innerData.edit_unaccounted_qty === undefined) {
        innerData.edit_wastage_qty = 0;
        innerData.edit_unaccounted_qty = 0;
      }
      var newclosing = innerData.current_stock - innerData.edit_wastage_qty
      return {
        ...innerData,
        edit_unaccounted_qty: innerData.current_stock - innerData.edit_wastage_qty - innerData.closing_stock 
      }
    })
    form.setFieldsValue({ data })
  }

  const handleOnChangeLoss = (value) => {
    setLossType(value)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'95%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>

          <Select
            showSearch
            style={{ width: 200, margin: 10 }}
            placeholder="Select Node"
            optionFilterProp="children"
            onChange={handleNodeCode}
            value={nodeCode}
            allowClear
            disabled={disable}>
            {props.nodes.map((e) => {
              return (
                <Option value={e.code} key={e.id}>{e.name}</Option>
              )
            })}
          </Select>

          <Button disabled={disable || nodeCode === undefined} onClick={getWastage}
            className="frz-w-100px frz-m-10" type="primary">Search</Button>
          <Button disabled={nodeCode === undefined || !formListData } onClick={reset} type="primary"
            className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
          {!isLoading ?
            <div>
              {formListData &&
                <Form
                  form={form}
                  {...formItemLayout}
                  layout="horizontal"
                  onValuesChange={handleChange}
                  name="data">
                  
                  <div className="frz-wastage-radio">
                    <FRZCustomRadioButon name="loss_type" label="Loss Type" isRequired={true}
                                        optionData={lossTypeData} option="option" value="value"
                                        setOnChange={handleOnChangeLoss} disabled={lossType ? true : false} />
                  </div>
                  {lossType ? 
                  <>
                    <Row className="frz-mt-20">
                      <Col span={6}>
                        SKU
                      </Col>

                      {/* <Col span={2}>
                        UOM
                      </Col> */}
                      <Col span={2}>
                        Stock Left
                      </Col>

                      <Col span={2}>
                        Todays Wastage Qty
                      </Col>

                      <Col span={3}>
                        {lossType === 'wastage' ? 'Wastage Qty' : 'RTV Qty'}
                      </Col>

                      <Col span={2}>
                        Todays Unaccounted Qty
                      </Col>

                      <Col span={3}>
                        Unaccounted Qty
                      </Col>

                      <Col span={3}>
                        Closing Stock
                      </Col>

                      <Col span={3}>
                        Unit Price
                      </Col>

                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="frz-division"></div>
                      </Col>
                    </Row>

                    <Row gutter={[0, 16]} className>
                      <Form.List name="data">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    fieldKey={[fieldKey, 'name']}>
                                    <Input placeholder="First Name" style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                {/* <Col span={2}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'uom']}
                                    fieldKey={[fieldKey, 'uom']}>
                                    <Input placeholder="First Name" style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col> */}

                                <Col span={2}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'current_stock']}
                                    fieldKey={[fieldKey, 'current_stock']}>
                                    <InputNumber style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                <Col span={2}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'wastage_qty']}
                                    fieldKey={[fieldKey, 'wastage_qty']}>
                                    <InputNumber style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'edit_wastage_qty']}
                                    fieldKey={[fieldKey, 'edit_wastage_qty']}
                                    rules={[{ required: true, message: "field required" }]}>
                                    <InputNumber placeholder="Wastage Qty" style={{ width: "100%" }} min={0} />
                                  </Form.Item>
                                </Col>

                                <Col span={2}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unaccounted_qty']}
                                    fieldKey={[fieldKey, 'unaccounted_qty']}>
                                    <InputNumber placeholder="unaccounted_qty" style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'edit_unaccounted_qty']}
                                    fieldKey={[fieldKey, 'edit_unaccounted_qty']}
                                    rules={[{ required: true, message: "field required" }]}>
                                    <InputNumber placeholder="unaccounted_qty" style={{ width: "100%" }} disabled={true} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'closing_stock']}
                                    fieldKey={[fieldKey, 'closing_stock']}
                                    rules={[{ required: true, message: "field required" }]}>
                                    <InputNumber placeholder="closing_stock" style={{ width: "100%" }} min={0} disabled={lossType === 'rtv'}/>
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                  {...restField}
                                  name={[name, 'unit_price']}
                                  fieldKey={[fieldKey, 'unit_price']}
                                  rules={[{ required: false }]}>
                                    <InputNumber placeholder="Unit Price" style={{ width: "100%" }} min={0} disabled={lossType === 'wastage'}/>
                                  </Form.Item>
                                </Col>
                              </div>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </> : null}
                </Form>}
            </div> :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>}
          {noDataFound && <div className="frz-fw-600 frz-font-24 frz-dis-flex frz-hrz-center">No data found</div>}
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default AddwastageModal
