import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, Input, InputNumber,
  Pagination, notification
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { AdminService } from '../../_services/admin';
import MainTable from '../../components/antd/table';
import AdminModal from '../../components/admin/admin_modal';
import { adminColumns } from '../../components/antd/columns/admin/admin';
import { openNotificationWithIcon } from '../../_modules/notification';
import FrzDynamicSearch from '../../_controls/FrzDynamicSearch';

let defaultState = { valueByVerified: undefined, valueByFreshvnf: undefined, valueByEmail: undefined, valueByNo: undefined }

const admin_service = new AdminService()

function AdminList() {
  const modalRef = useRef(null);
  const [adminData, setAdminData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [roles, setRoles] = useState(false)
  const [verified, setVerified] = useState([{ name: 'Is Verified', key: "Is Verified" }, { name: 'Is Not Verified', key: "Is Not Verified" }])
  const [freshvnf, setFreshvnf] = useState([{ name: 'Is From FreshVnF', key: 'Is From FreshVnF' }, { name: 'Is Not From FreshVnF', key: 'Is Not From FreshVnF' }])
  const [state, setState] = useState(defaultState);
  const { valueByVerified, valueByFreshvnf, valueByEmail, valueByNo } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getAdmin()
    }
  })

  useEffect(() => {
    getColumns()
    getRoles()
  }, [])

  function getRoles() {
    admin_service.get_roles().subscribe((r) => {
      setRoles(r.response)
    },
      (err) => {
        console.log(err)
      })
  }

  function getAdmin() {
    setSpinEnable(true)
    setAdminData([])
    const payload = {
      page: current,
      by_verified: valueByVerified === undefined ? '' : valueByVerified === 'Is Verified' ? true : false,
      from_freshvnf: valueByFreshvnf === undefined ? '' : valueByFreshvnf === 'Is From FreshVnF' ? true : false,
      by_mobile_number: valueByNo === undefined ? '' : valueByNo,
      by_email: valueByEmail === undefined ? '' : valueByEmail
    }

    admin_service.get_admins(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setAdminData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    adminColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Roles',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {record.get_roles.map(data => {
              return (
                <span key={data}>{data}, </span>
              )
            })}
          </div>
        )
      }
    },
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Users')}>Edit</Button>
          )
        }
      })
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({ valueByVerified: undefined, valueByFreshvnf: undefined, valueByEmail: undefined, valueByNo: undefined })
    setDisable(true)
    setCurrent(1)
    setAdminData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setAdminData(false)
    setRenderData(false)
  }

  function modalSubmit(payloadData, id) {
    if (mode === 'Add Users') {
      if (payloadData.mobile_number.toString().length !== 10) {
        openNotificationWithIcon('error', "Please Enter 10 Digit Mobile No")
        modalRef.current.stopLoading()
        return
      }
      const payload = { ...payloadData }
      admin_service.create_admin(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getAdmin()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
        })
    } else {
      const payload = {
        id: id,
        body: { ...payloadData }
      }
      admin_service.update_admin(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Updated Successfully')
        getAdmin()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
        })
    }
  }

  return (
    <div className="layer">
      {adminData && roles ?
        <div>
          <Row>
            <Card>
              <Col span={24}>

                <FrzDynamicSearch DropDownData={verified}
                  placeholder="Select Source" value="name" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByVerified')}
                  type="valueByVerified" margin={10} defValue={valueByVerified} />

                <FrzDynamicSearch DropDownData={freshvnf}
                  placeholder="Select Source" value="name" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByFreshvnf')}
                  type="freshvnf" margin={10} defValue={valueByFreshvnf}/>

                <Input
                  placeholder="Email"
                  onChange={(e) => onChange(e.target.value, "valueByEmail")}
                  value={valueByEmail}
                  className="frz-w-200 frz-m-10" />
                <InputNumber
                  placeholder="Mobile No"
                  onChange={(e) => onChange(e, "valueByNo")}
                  value={valueByNo}
                  className="frz-w-200 frz-m-10" />
                <Button disabled={disable} onClick={getAdmin}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Add Users')} className="frz-w-100px frz-m-10" type="primary">
                  Add Users</Button>
              </Col>
            </Card>
          </Row>
          {adminData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={adminData} columns={columns} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <AdminModal modalSubmit={modalSubmit} ref={modalRef} roles={roles} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default AdminList