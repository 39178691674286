import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Radio, Table, Popconfirm, Button, Spin } from 'antd';
import { OperationsService } from '../../../_services/operations'
import { pendingApprovalTableColumns } from '../../antd/columns/operations/approval';
import { openNotificationWithIcon } from '../../../_modules/notification';

const operations_service = new OperationsService();

const ModalViewSku = ({ showModal, setShowModal, readOnly, rowId, getParentListing }) => {
  const [form] = Form.useForm();

  const [column, setColumn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listingData, setListingData] = useState(false)

  const onCreate = () => {

  }

  useEffect(() => {
    getTableData()
  }, [])

  useEffect(() => {
    getColumn()
  }, [])

  const getTableData = () => {
    const payload = {
      id: rowId
    }
    operations_service.get_pending_approval_table_data(payload).subscribe((r) => {
      setListingData(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const getColumn = () => {
    let tempCol = []
    pendingApprovalTableColumns.map((data) => {
      tempCol.push(data)
    })
    setColumn(tempCol)
  }

  const handleOnApprove = (value) => {
    setLoading(true)
    const payload = {
      request_id: rowId,
      status: value ? "approved" : "rejected",
      comment: ""
    }
    operations_service.reject_pending_req(payload).subscribe((r) => {
      setLoading(false)
      setShowModal(false)
      getParentListing()
      openNotificationWithIcon("success", "Updated successfully")
    }, ((err) => {
      setLoading(false)
      setShowModal(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
      console.log("err", err)
    }))
  }

  return (
    <Modal
      visible={showModal}
      title=""
      okText="Create"
      cancelText="Cancel"
      width="85%"
      onCancel={() => setShowModal(false)}
      footer={[
        <Button key="back" onClick={() => setShowModal(false)}>
          Cancel
        </Button>,
        <>
          {!readOnly && listingData ?
            <>
              <Popconfirm placement="topLeft" title={"Are you sure you want to Reject all SKU?"}
                onConfirm={() => handleOnApprove(false)} okText="Yes, reject all" cancelText="No">
                <Button type="primary" loading={loading}>
                  Reject all
                </Button >
              </Popconfirm>

              <Popconfirm placement="topLeft" title={"Are you sure you want to approve all SKU?"}
                onConfirm={() => handleOnApprove(true)} okText="Yes, Approve" cancelText="No">
                <Button type="primary" loading={loading}>
                  Approve all
                </Button >
              </Popconfirm>
            </> : null}
        </>
      ]}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}>
      {listingData ?
        listingData.length ?
          <Table
            columns={column}
            dataSource={listingData}
            pagination={false}
            scroll={{ x: 240, y: `calc(100vh - 320px)` }}
            rowKey="id"
          />
          :
          <div className="no-data">No Data Available</div>
        : <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </Modal>
  )
}

export default ModalViewSku