import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, InputNumber, Select, Spin } from 'antd';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import { OperationsService } from '../../../_services/operations';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';
import { Option } from 'antd/lib/mentions';
const ops_service = new OperationsService()
let timer;
const ShowAddProductModal = ({show,hide,id,getSto,procureType,sto_id}) => {
  const [products,setProducts]=useState(false)  
  const [product,setProduct]=useState({id:id,sku:null,transfer_qty:null})
  const [loading,setLoading]=useState(false)
  const [dropDownData,setDropDownData]=useState(false)
  const [spinEnable, setSpinEnable]=useState(false)
  const [error,setError]=useState(false)
  const handleOk = () => {
    hide(false)
  };
  const handleCancel = () => {
    hide(false)
  };
  const handleSkuData=(a,b) => {
    setProduct((prev)=>({...prev,sku:b.sku}))
  }
  const handleTranferQT=(e)=>{
      if(Number.isInteger(e)){
          setError(false)
          setProduct((prev)=>({...prev,transfer_qty:e}))
      }else{
          setError(true)
      }
  }
  const handleSubmit=()=>{
      if(checkRequiredFields()){
          setLoading(true)
        const payload={
            "id": sto_id,
            "sku": product?.sku?product?.sku:'',
            "transfer_unit_qty": product?.transfer_qty?product?.transfer_qty:0,
            "transfer_unit_type": "po_unit"
        }
        ops_service.sto_products(payload).subscribe((r) => {
            openNotificationWithIcon('success', 'Successfully added product')
            setLoading(false)
            getSto()
            hide(false)
          },
            (err) => {
              console.log(err)
              if(err?.response?.errors){
                  openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
              }else if(err?.response?.product_id[0]){
                openNotificationWithIcon('error', err?.response?.product_id[0] ?? 'API Error')
              }
              else{
                openNotificationWithIcon('error', 'something went wrong try again')
              }
              setLoading(false)
            })
      }else{
          setError(true)
      }
  }
  const checkRequiredFields=()=>{
        let flag=true
        for(let k in product){
            if(!product[k]){
                flag=false;
            }
        }
        return flag;
  }
  
  const debounce = (cb, d) => {
    //let timer;
    return function (...args) {
      setSpinEnable(true)
      setDropDownData([])
        clearInterval(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, d);
    };
  };
  const handleChange=debounce((e)=>{
      let value=e;
      if(value.length>=3){
        const payload = { id: id ,by_name:value,procurement_type:procureType}
        ops_service.get_sto_skus(payload).subscribe((r) => {
        setSpinEnable(false)
        setDropDownData(r.response.data)
      },
        (err) => {
          setSpinEnable(false)
          console.log(err)
        }) 
      }
  },1000)
  return (
    <>
      <Modal footer={false} title="Add Product" visible={show} onOk={handleOk} onCancel={handleCancel}>
        <div> 
              <span> <span style={{color:'red'}}>* </span>Add Product :   </span>
               <Select placeholder={'Please Search Sku'} showSearch 
                          showArrow={false}  filterOption={false} className="add-sku-container frz-w-290px frz-bm-10 frz-ml-6"
                          onSearch={handleChange} onChange={(e, f) => handleSkuData(e, f)}
                          notFoundContent={null}>
                          {dropDownData?.length>0 ? dropDownData?.map(data => {
                            return <Option expected_transfer_qty={null}  value={data.id} error="true" key={data.id} sku={data.sku} inventory_unit={data.inventory_unit} image_url={data.image_url} name={data.name} available_qty={data.available_qty} product_current_stock={data.product_current_stock}>
                              <div className="frz-dis-flex frz-justify-sb frz-w-290px">
                                <div><img src={data.image_url} alt={data.image_url} width='30px' heigth='30px'/>  {data.name} ({data.sku})</div> 
                                </div>
                              </Option>
                          }):(spinEnable ?<div className="spin-center add-sku-loader"><Spin tip="...Loading" /></div>:null)}
                        </Select>
            </div>
            <div className='frz-dis-flex  frz-align-item-center'>
            <div> <span style={{color:'red'}}>*</span> Transfer QTY : </div>
            <InputNumber className={error?'wcl-input-error':''} style={{width: 290,marginLeft:5}} placeholder="Transfer Quantity"  onChange={handleTranferQT}  />
            </div>
            {error?<div className="frz-text-center color-red">Please a valid number</div>:<div></div>}
        <div className='add-prod-btn-container'><Button type='primary' loading={loading} className='frz-tm-15' onClick={handleSubmit}>Add SKU</Button></div>
      </Modal>
    </>
  );
};

export default ShowAddProductModal;