import React, { useEffect, useState } from 'react'
import { Tabs, Button, Card, Col, Row, Spin, Pagination, Table } from 'antd'
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch";
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { pendingApprovalColumns } from '../../../components/antd/columns/operations/approval'
import { useSelector } from 'react-redux'
import moment from 'moment';
import { OperationsService } from "../../../_services/operations";
import { openNotificationWithIcon } from '../../../_modules/notification';
import { humanize } from '../../../_modules/humanize';
import ModalReason from '../../../components/operations/pendingapproval/modalreason';
import CountdownTimer from '../../../components/operations/pendingapproval/countdowntimer';
import ModalViewSku from '../../../components/operations/pendingapproval/modalviewsku';

// const nodeType = `CC`
const nodeType = `["CC","WH"]`
const { TabPane } = Tabs;

const ops_service = new OperationsService();
const ApprovalPending = () => {
  const [listingData, setListingData] = useState(false)
  const [columns, setColumns] = useState([])
  const [nodeData, setNodeData] = useState([])
  const [disable, setDisable] = useState(true)
  const [statusType, setStatusType] = useState(false)
  const [current, setCurrent] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [currentTab, setCurrentTab] = useState(false)
  const [rowId, setRowId] = useState(false)
  const [onRowReject, setOnRowReject] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isRest, setIsRest] = useState(false)
  const [isTimerUp, setIsTimerUp] = useState(false)
  const [pedningReqCount, setPedningReqCount] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [state, setState] = useState({
    valueByCity: undefined, valueByRequester: undefined, valueByStatus: undefined, valueByNode: undefined
  });
  const { valueByCity, valueByRequester, valueByStatus, valueByNode } = state
  const [showModal, setShowModal] = useState(false)

  const cityData = useSelector(state => state.auth.city);

  useEffect(() => {
    if (isRest) {
      getListingData(currentTab)
    }
  }, [isRest])

  useEffect(() => {
    if (valueByCity || valueByNode) {
      getListingData(currentTab)
    }
  }, [valueByCity, valueByNode])

  useEffect(() => {
    getStatusType()
    getNodeListing()
  }, [])

  useEffect(() => {
    getColumns()
  }, [listingData, rowId, loading, isTimerUp])

  useEffect(() => {
    if (currentTab) {
      setListingData(false)
      getListingData(currentTab)
    }
  }, [currentTab, current])

  const getStatusType = () => {
    ops_service.get_pending_approval_status().subscribe((r) => {
      setStatusType(r.response.data)
      setCurrentTab(r.response.data[0])
    }, (err) => {
      console.log(err);
    })
  }

  const handleTimerUp = (value) => {
    setIsTimerUp(value)
  }

  const handleOnRowApprove = (id) => {
    setLoading(true)
    const payload = {
      request_id: id,
      status: "approved",
      comment: ""
    }
    ops_service.reject_pending_req(payload).subscribe((r) => {
      getListingData()
      setLoading(false)
      openNotificationWithIcon('success', 'Approved Successfully')
      handleOnRowReject(false)
    }, ((err) => {
      console.log("err", err)
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
    }))
  }


  const handleOnRowReject = (value, id, data) => {
    setRowData(data)
    setRowId(id)
    setOnRowReject(value)
  }

  const setEndTimeNull = (id) => {
    console.log("called")
    let temp = listingData
    temp.map((data) => {
      if (data.id === id) {
        Object.assign(data, { ...data, expiry_at: null, can_take_action: false })
      }
    })
    setListingData([...temp])
  }

  const handleOnViewSku = (value, id, readonly) => {
    setShowModal(value)
    setReadOnly(readonly)
  }

  const getColumns = () => {
    let tempCol = []

    pendingApprovalColumns.map(data => {
      tempCol.push(data)
    })

    if (currentTab === "approved") {
      tempCol.push(
        {
          title: 'Approved By',
          dataIndex: 'approved_by',
          key: 'approved_by',
          align: 'center',
          width: 120
        },
        {
          title: 'Approve On',
          dataIndex: 'last_action_at',
          key: 'last_action_at',
          align: 'center',
          width: 120
        },
        {
          title: 'Action',
          dataIndex: '',
          key: '',
          align: 'center',
          width: 400,
          render: (record) => {
            return (
              record.has_child_requests ?
                <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                  <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                    onClick={() => {
                      setRowId(record.id)
                      handleOnViewSku(true, record.id, true)
                    }}>
                    View SKU
                  </Button>
                </div> : null
            )
          }
        }
      )
    }

    if (currentTab == "expired") {
      tempCol.push({
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 400,
        render: (record) => {
          return (
            record.has_child_requests ?
              <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                  onClick={() => {
                    setRowId(record.id)
                    handleOnViewSku(true, record.id, true)
                  }}>
                  View SKU
                </Button>
              </div> : null
          )
        }
      })
    }

    if (currentTab === "rejected") {
      tempCol.push({
        title: 'Rejection Reason',
        dataIndex: 'rejection_reason',
        key: 'rejection_reason',
        align: 'center',
        width: 120
      },
        {
          title: 'Rejected By',
          dataIndex: 'rejected_by',
          key: 'rejected_by',
          align: 'center',
          width: 120
        },
        {
          title: 'Action',
          dataIndex: '',
          key: '',
          align: 'center',
          width: 400,
          render: (record) => {
            return (
              record.has_child_requests ?
                <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                  <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                    onClick={() => {
                      setRowId(record.id)
                      handleOnViewSku(true, record.id, true)
                    }}>
                    View SKU
                  </Button>
                </div> : null
            )
          }
        }
      )
    }

    if (currentTab === "pending") {
      tempCol.push({
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 400,
        render: (record) => {
          return (
            <div className="frz-dis-flex frz-vt-center">
              {record.status === "pending" ?
                <div>
                  <Button disabled={!record.can_take_action || record.expiry_at == null} className="frz-lm-10 frz-rm-10 frz-red-border" type="ghost" onClick={() => handleOnRowReject(true, record.id, record)}>
                    Reject
                  </Button>

                  <Button disabled={!record.can_take_action || record.expiry_at == null} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                    onClick={() => {
                      setRowId(record.id)
                      handleOnRowApprove(record.id)
                    }}>
                    Approve
                  </Button>

                  {record.has_child_requests ?
                    <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                      onClick={() => {
                        setRowId(record.id)
                        handleOnViewSku(true, record.id)
                      }}>
                      View SKU
                    </Button>
                    : null}

                </div> : null
              }
            </div >
          )
        }
      },
        {
          title: 'Time',
          dataIndex: '',
          key: '',
          align: 'center',
          render: (record) => {
            return (
              <div className="show-counter">
                <CountdownTimer targetDate={record.expiry_at} handleTimerUp={handleTimerUp} id={record.id} setEndTimeNull={setEndTimeNull} />
              </div>
            )
          }
        })
    }

    setColumns(tempCol)
  }

  const getNodeListing = () => {
    const payload = {
      nodeType: nodeType
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodeData(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  const getListingData = (type) => {
    setIsRest(false)
    setSpinEnable(true)
    setPedningReqCount(false)
    // setListingData([])
    const payload = {
      page: current ? current : 1,
      // by_city: valueByCity ? valueByCity : '',
      by_requester: valueByRequester ? valueByRequester : '',
      status: currentTab ? currentTab : type,
      by_node: valueByNode ? valueByNode : ''
    }
    ops_service.get_pending_approval_detail(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setPedningReqCount(r.response.meta.total_data_count)
      setSpinEnable(false)
      setListingData(r.response.data)
    },
      (err) => {
        console.log("err", err)
      })
  }

  function onChange(value, type) {
    setListingData(false)
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(false)
  }

  function reset() {
    setState({
      valueByCity: undefined, valueByNode: undefined,
    })
    setIsRest(true)
    // setListingData(false)
    // getListingData(statusType[0])
    setDisable(true)
    setCurrent(false)
    // setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData(false)
    // setListingData([]);
    // setRenderData(false);
    // setRenderData(false)
  }

  const onChangeTab = (e) => {
    setCurrentTab(e)
  }

  return (
    <div className="layer">

      <div>
        <Row>
          <Card>
            <Col span={24}>
              {/* <FrzDynamicSearch DropDownData={cityData ? cityData : []}
                placeholder="Select city" value="code" option="name"
                isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByCity')}
                type="valueByCity" margin={10} defValue={valueByCity} /> */}

              <FrzDynamicSearch DropDownData={nodeData}
                // isCustomOption={true} customValue={''} customOption={"All"}
                placeholder="Select Node" value="id" option="name"
                isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'valueByNode')}
                type="valueByNode" margin={10} defValue={valueByNode} />

              {/* <Button disabled={disable}
                className="frz-w-100px frz-m-10" type="primary" onClick={getListingData}>Search</Button> */}
              <Button disabled={disable} onClick={reset} type="primary"
                className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
            </Col>
          </Card>
        </Row>
        {statusType ?
          statusType.length ?
            <div className="card-container frz-mt-10">
              <Tabs type="card" className="frz-approval-tab" onChange={(e) => onChangeTab(e)}>
                {statusType.map((data) => {
                  return (
                    <TabPane tab={data === "pending" ? <div className="frz-dis-flex">
                      {currentTab === "pending" ?
                        <div className="frz-count-pending frz-dis-flex frz-vertical-center frz-hrz-center">
                          {pedningReqCount}
                        </div>
                        : null}
                      <div className="frz-dis-flex frz-vertical-center">
                        {humanize(data)}
                      </div>
                    </div> : humanize(data)} key={data}>
                      <Card className="frz-pending-approval-card">
                        {listingData ?
                          listingData.length ?
                            <>
                              <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ x: 240, y: `calc(100vh - 320px)` }}
                                rowKey="id"
                              />
                            </>
                            : <div className="no-data">No Data Available</div>
                          : <div className="spin-center">
                            <Spin tip="...Loading" />
                          </div>}
                      </Card>
                      {listingData.length ?
                        <Pagination className="frz-tm-10 frz-bm-10" current={current ? current : 1}
                          pageSize={50} total={state.total} onChange={pagination}
                          showSizeChanger={false} />
                        : null}
                    </TabPane>
                  )
                })}
              </Tabs>
            </div>
            : <div className="no-data">No Data Available</div>
          : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
      </div>
      {showModal ?
        <ModalViewSku showModal={showModal} setShowModal={(value) => setShowModal(value)} rowId={rowId} readOnly={readOnly} getParentListing={getListingData} />
        : null}
      {onRowReject ?
        <ModalReason onRowReject={onRowReject} handleOnRowReject={handleOnRowReject} rowId={rowId} getParentListing={getListingData}
          rowData={rowData} />
        : null}
    </div>
  )
}

export default ApprovalPending