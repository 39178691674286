import { Modal, Form, InputNumber, Select, Row, Col, Button, Spin } from 'antd';
import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { formItemLayout, tailLayout } from "../../../_modules/controllayout";
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";
import { ReloadOutlined } from '@ant-design/icons';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const ops_service = new OperationsService()

const CreateStockLossApprovalModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [disable, setDisable] = useState(true)
  const [skuData, setSkuData] = useState(false)
  const [noDataFound, setNoDataFound] = useState(false)
  const [isSelected, setIsSelected] = useState([]);
  const [isClose, setIsClose] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [breakOption, setBreakOption] = useState(true)
  const [state, setState] = useState({ lossType: undefined })
  const [valueByNode, setValueByNode] = useState()
  const { lossType } = state

  const { Option } = Select

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setDisable(false)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setValueByNode(undefined)
      setVisible(false)
      setLoading(false)
      setIsSelected([])
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setValueByNode(undefined)
    setVisible(false)
    setLoading(false)
    setIsSelected([])
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    if (values.items === undefined || values.items.length === 0) {
      openNotificationWithIcon('error', "Please fetch and add at least one sku")
      ref.stopLoading()
    } else {
      const payload = {
        ...values, node_id: valueByNode, loss_type: lossType === undefined ? 'retail' : lossType,
      }
      console.log(payload)
      setLoading(true)
      modalSubmit(payload)
    }
  }

  function reset() {
    form.resetFields()
    setState({ lossType: undefined })
    setValueByNode(undefined)
    setSkuData(false)
    setNoDataFound(false)
    setDisable(false)

  }

  function fetchSkus() {
    setIsLoading(true)
    setDisable(false)
    const payload = { valueByNode: valueByNode, lossType: lossType === undefined ? 'retail' : lossType }
    ops_service.get_all_products(payload).subscribe((r) => {
      console.log(payload)
      setIsLoading(false)
      if (r.response.success = "success") {
        if (r.response.data.length > 0) {
          setSkuData(r.response.data)
          setNoDataFound(false)
        } else {
          setNoDataFound(true)
          setDisable(false)
        }
      }

    },
      (err) => {
        console.log(err)
        setIsLoading(false)
        setDisable(false)
      })
  }

  const onChangeNode = (value) => {
    setValueByNode(value)
    setSkuData(false)
    setDisable(false)
    form.resetFields()
    setIsSelected([])
  }

  const handleChange = (e) => {
    let res = form.getFieldValue("items")
    setIsSelected(res)

  };

  return (
    <div>
      {data ?
        <Modal className="layer"
          visible={visible}
          title={title}
          okText="Submit"
          width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <Select
              showSearch
              style={{ width: 200, margin: 10 }}
              placeholder="Select Node"
              optionFilterProp="children"
              onChange={onChangeNode}
              value={valueByNode}
              disabled={disable}>
              {props.nodes.map((e) => {
                console.log(props.nodes)
                return (
                  <Option value={e.id} key={e.id}>{e.name}</Option>
                )
              })}
            </Select>

            <Button onClick={fetchSkus} disabled={disable || valueByNode === undefined}
              className="frz-w-100px frz-m-10" type="primary">Fetch SKU's</Button>
            <Button disabled={valueByNode === undefined || !skuData} onClick={reset} type="primary"
              className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />

            {skuData ?
              <div>
                <div className="sto-modal-border"></div>
                <Form.List name="items" key={Math.random(1, 100)}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={index}>
                          <Row gutter={41} key={index}>
                            <Col>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights}>
                                {() => (
                                  <Form.Item
                                    {...field}
                                    {...tailLayout}
                                    name={[field.name, 'sku']}
                                    fieldKey={[field.fieldKey, 'sku']}
                                    rules={[{ required: true, message: 'Missing sku' }]}>

                                    <Select className="frz-width-220p" onChange={handleChange}
                                      placeholder="Select SKU"
                                      showSearch
                                      optionFilterProp="children"
                                      disabled={disable}>
                                      {skuData.map(item => (
                                        <Option disabled={isSelected?.map(e => e?.sku).includes(item.sku)}
                                          className={breakOption ? "frz-line-break" : ""}
                                          key={item.sku} value={item.sku} >
                                          {item.name} - ( {item.sku} )
                                        </Option>
                                      ))}
                                    </Select>

                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'wastage_qty']}
                                fieldKey={[field.fieldKey, 'wastage_qty']}
                                rules={[{ required: true, message: 'Missing Qty' }]}>
                                <InputNumber placeholder="Wastage Qty" required={true} name="wastage_qty"
                                  className="frz-width-110p" />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Button className="frz-lm-20 frz-p-0 frz-width-36p frz-tp-2" type="primary"
                                icon={<DeleteOutlined />}
                                shape="round" onClick={() => {
                                  remove(field.name)
                                  let response = form.getFieldValue("items")
                                  setIsSelected(response)
                                }}>
                              </Button>
                            </Col>

                          </Row>
                        </div>
                      ))}

                      <Button className="frz-width-104p" type="primary" onClick={() => add()} block
                        icon={<PlusCircleOutlined />}>
                        Add SKU
                      </Button>

                    </>
                  )}
                </Form.List>
              </div>
              :
              isLoading ?
                <div className="spin-center">
                  <Spin tip="...Loading" />
                </div>
                : null}
          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}

    </div>
  )
})

export default CreateStockLossApprovalModal