import React, {useRef, useState} from 'react';
import clock from "../../../../static/images/clock.svg";
import clockwhite from "../../../../static/images/clock-white.svg";
import { Spin, Button } from 'antd';
import RequestApprovalModal from '../../common/request_approval_modal';
import { openNotificationWithIcon } from '../../../../_modules/notification';

function TimeScreen(props){
  const modalRef = useRef(null);
  const [requestSubmitted, setRequestSubmitted] = useState(false)
  const [allowDownload, setAllowDownload] = useState(false)

  function requestApprovalModal(){
    modalRef.current.showModal('Request city head approval', true)
  }
  
  function modalSubmit(values){
    const payload = {
      node_code: props.currentNode,
      request_type: 'closing',
      ...values
    }
    props.wc_service.city_head_request(payload).subscribe((r) => {
      setRequestSubmitted(true)
      openNotificationWithIcon('success', 'Request Submitted Successfully')
      modalRef.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
    })
  }

  return(
    <div className="frz-position-center">
      {props.allowRequest || props.allowFinalClosing || props.timer || props.showTimeScreen || props.currentMainTab !== 'node_procurement' ?
        <>
          {!props.showTimeScreen && !props.allowRequest && (props.allowFinalClosing || props.timer) ?
            <div className="wcl-remaining-timer">
              <img src={clock} />
              <div className="frz-fw-500 frz-tp-10">
                {props.allowFinalClosing ? 'Time left for today’s closing' : 'Closing starts in'}</div>
              <div className="closing-time">
                {props.timer && props.timer !== '00:00:00' ? props.timer : <Spin size="small" />} 
              </div>
              {props.data.request_status ? 
                <div className="frz-fw-500 frz-tm-8">
                  Request : <span className="wcl-tab-green frz-fw-600">Approved</span>
                </div>
              : null}
              <div className='frz-tp-10'>
                <Button type="primary" disabled={(!props.allowFinalClosing && props.currentMainTab === 'node_procurement') || 
                   !props.data.grn_done || !props.data.sto_done || 
                  ((!props.allowFinalClosing || props.allowPackagingClosing) && props.currentMainTab === 'packaging') || 
                  ((!props.allowFinalClosing || props.allowCratesClosing) && props.currentMainTab === 'crates')} 
                  onClick={props.createWC}>
                  Create Final Closing
                </Button>
              </div>
              <div className='frz-tp-15'>
                {!allowDownload ? 
                  <>
                    {!props.data.grn_done ? 
                      <div className="frz-fw-500 frz-bp-10 color-red">
                        (GRN is created and yet to update the Graded quantity. Kindly update the 
                        graded quantity or cancel the PO before starting the wastage/closing for order 
                        numbers{props.data?.pending_grn_numbers?.map(data => {
                          return <b>, {data}</b>
                        })})
                      </div>
                    : null
                    }
                    {!props.data.sto_done ? 
                      <div className="frz-fw-500 frz-bp-10 color-red">
                        (STO is created and yet to dispatch, Kindly dispatch or cancel 
                        the STO before starting the wastage/closing for order 
                        numbers{props.data?.pending_sto_numbers?.map(data => {
                          return <b>, {data}</b>
                        })})
                      </div>
                    : null
                    }
                    <div className="frz-fw-500 frz-bp-10">Please download the print sheet to enter the final closing!</div>
                    <div type="primary" onClick={() => setAllowDownload(true)} className="wcl-hyperlink">
                      Not able to print sheet?
                    </div>
                  </>
                  :
                  <Button type="primary" loading={props.jobLoading === 'closing_excel'}
                    onClick={() => props.getJobDownloads(`download_closing_items?node_code=${props.currentNode}&product_category=${props.currentMainTab}`, 'closing_excel')}>
                    Download Sheet
                  </Button>
                }
              </div>
            </div>
          : null}
          {props.allowRequest || props.showTimeScreen ?
            <div className="wcl-timer-exhuasted">
              <img src={clockwhite} />
              <div className="frz-fw-500 frz-tp-10 closing-white">Time exhuasted for today’s closing</div>
              <div className="closing-time-exhausted">
                00:00:00
              </div>
              {props.currentMainTab === 'node_procurement'?
                <Button type="primary" 
                        className={props.allowRequest && !requestSubmitted && props.data.request_status !== 'pending' && props.data.request_status !== 'rejected' ? 
                          "wcl-black-button frz-tm-8" : 'frz-tm-8'} size="large"
                        disabled={!props.allowRequest || requestSubmitted || props.data.request_status === 'pending' 
                                  || props.data.request_status === 'rejected'} 
                        onClick={requestApprovalModal}>
                  {props.data.request_status ? 'Request - ' + props.data.request_status.toUpperCase() : 
                  requestSubmitted ? 'Requested - Awaiting Approval' : 'Request Approval'}
                </Button>
              : null}
            </div>
          : null}
          <RequestApprovalModal modalSubmit={modalSubmit} ref={modalRef} />
        </>
      :
        <div className="spin-center">
          <Spin />
        </div>
      }
    </div>
  )
}

export default TimeScreen