import { Checkbox, Col, Divider, Row, Select, Spin } from "antd"
import { Option } from "antd/lib/mentions"
import { useEffect, useState } from "react"
import { openNotificationWithIcon } from '../../../_modules/notification'
import { GeneralService } from "../../../_services/general"
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch"
import { OperationsService } from "../../../_services/operations"
const initState = {
    spinner: false,
    data: false,
    stateId: false,
    states: false,
    nodes: false
}
const general_service = new GeneralService()
const operation_service = new OperationsService();
const FilterCCSelection = ({ handleChange, ccData, setCcData, parentState }) => {
    const [state, setState] = useState(initState)
    const [stateId, setStateId] = useState(parentState?.by_CC || false)
    const [disableSearch, setDisableSearch] = useState(false)
    useEffect(() => {
        getState();
    }, [])
    const getState = () => {
        setState((prev) => ({ ...prev, spinner: true }))
        general_service.get_state().subscribe((r) => {
            setState((prev) => ({ ...prev, spinner: false }))
            setState((prev) => ({ ...prev, states: r.response.data }))
        },
            (err) => {
                console.log(err)
                setState((prev) => ({ ...prev, spinner: false }))
                openNotificationWithIcon('error', 'Backend API Error')
            })
    }

    useEffect(() => {
        if (stateId) {
            getNodeListing()
        }
    }, [stateId])

    const getNodeListing = () => {
        setDisableSearch(true)
        setState((prev) => ({ ...prev, spinner: true }))
        const payload = {
            nodeType: `["CC","WH"]`,
            by_state: stateId ? stateId : ''
        }
        operation_service.get_node_listing(payload).subscribe((r) => {
            let temp = r?.response?.data.map((item) => {
                let exist = parentState?.by_node?.find(id => id === item.id)
                item.isChecked = exist ? true : false;
                return item;
            })
            setState((prev) => ({ ...prev, nodes: temp }))
            setState((prev) => ({ ...prev, spinner: false }))
            setCcData(r.response.data)
        },
            (err) => {
                console.log(err)
                setState((prev) => ({ ...prev, spinner: false }))
            })
    }

    const onChange = (e) => {
        setStateId(e)
        handleChange('by_CC', e)
    }

    return (
        <>
            {!state.spinner ? <div>
                <FrzDynamicSearch DropDownData={state.states ? state.states : null}
                    placeholder="Select State" value="id" option="name"
                    isShowSearch={true} width={140} setOnChange={onChange}
                    type="" defValue={stateId ? stateId : null} />
                <div>
                    {stateId ? <div>
                        <Divider />
                        <Checkbox.Group
                            style={{
                                width: '100%',
                            }}
                            defaultValue={parentState?.by_node}
                            onChange={(e) => handleChange('by_node', e)}>
                            <Row>
                                {state?.nodes.length > 0 ? state?.nodes.map((item) => {
                                    return (
                                        <Col span={8} key={item?.id}>
                                            <Checkbox value={item?.id} key={item.id} checked={true}>{item.name}</Checkbox>
                                        </Col>
                                    )
                                })
                                    : <p className='no-data frz-ml-25p'>No-Data Selected State</p>}
                            </Row>
                        </Checkbox.Group>
                    </div> : <div className='no-data'>Please Select A State</div>}
                </div>
            </div> : <div className="spin-center">
                <Spin tip="...Loading" />
            </div>}
        </>
    )
}
export default FilterCCSelection