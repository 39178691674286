import React, { useEffect, useState } from 'react'
import { OperationsService } from '../../../_services/operations'
import SocketManager from './socket';
import { Spin } from 'antd'
import StoList from './stolists';
import PhaseTwo from './phasetwo';

const ops_service = new OperationsService()

const PhaseTwoContainer = ({ activeVeichleInfo, transitBucketsData }) => {
  const [activeNodes, setActiveNodes] = useState(false)
  const [currentStoData, setCurrentStoData] = useState(false)
  const [socketMessage, setSocketMessage] = useState(false)
  const [transitId, setTransitId] = useState(false)
  const [stoData, setStoData] = useState(false)
  const [stoLineItem, setStoLineItem] = useState(false)
  const [currentStoNull, setCurrentStoNull] = useState(false)


  // useEffect(() => {
  //   setTransitId(activeVeichleInfo.id)
  //   getCurrentSto(activeVeichleInfo.id)
  //   getActiveNodes()
  // }, [activeVeichleInfo, transitBucketsData])


  useEffect(() => {
    setTransitId(activeVeichleInfo.id)
    getCurrentSto(activeVeichleInfo.id)
    getActiveNodes()
  }, [])

  const handleStoData = (value) => {
    setStoData(value)
  }

  const updateStoLineItem = (id) => {
    const payload = {
      id: id
    }
    ops_service.get_line_items_updates(payload).subscribe((r) => {
      stoLineItem.find(x => x.id === id).dispatch_crate_count = r.response.data
      setStoLineItem([...stoLineItem])
    }, (err) => {
      console.log("err", err)
    })
  }

  const getStoListDetail = (id) => {
    const payload = {
      id: id
    }

    ops_service.list_sto_out(payload).subscribe((r) => {
      setStoData(r.response)
      setStoLineItem(r.response.line_items)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const getActiveNodes = (socketData) => {
    let tempnodes = transitBucketsData.find((e) => e.id === activeVeichleInfo.id).nodes
    if (socketData) {
      setActiveNodes(false)
      let objIndex = tempnodes.findIndex((obj => obj.id == socketData.id))
      tempnodes[objIndex].status = socketData.status
    }
    setActiveNodes(tempnodes)
  }

  const handleSocketMessage = (value) => {
    setSocketMessage(value)
  }

  const handleCurrentStoNull = (value) => {
    setCurrentStoNull(value)
  }

  const getCurrentSto = (id) => {
    const payload = {
      id: id
    }
    ops_service.get_current_sto(payload).subscribe((r) => {
      let nodeList = transitBucketsData.find(x => x.id === activeVeichleInfo.id).nodes
      setCurrentStoData(r.response.data?.id ?? nodeList[nodeList.length - 1].id)
      getStoListDetail(r.response.data?.id ?? nodeList[nodeList.length - 1].id)
      if (r.response.data == null) {
        setCurrentStoNull(true)
      }
    }, ((err) => {
      console.log("err", err)
    }))
  }

  return (
    <div>
      {/* {console.log("phase two", activeNodes)} */}
      {activeNodes ?
        <PhaseTwo handleStoData={handleStoData} getStoListDetail={getStoListDetail} stoData={stoData} updateStoLineItem={updateStoLineItem} stoLineItem={stoLineItem} transitId={transitId}
          activeNodes={activeNodes} currentStoData={currentStoData} handleSocketMessage={handleSocketMessage} socketMessage={socketMessage}
          currentStoNull={currentStoNull} handleCurrentStoNull={handleCurrentStoNull} />
        : null}
    </div>
  )
}

export default PhaseTwoContainer
