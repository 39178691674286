import { useEffect, useState } from "react"
import { Button, Col, Divider, Input, Pagination, Row, Select, Space, Spin, Table, Tabs, Tag } from "antd"
import '../../../static/css/supplier_management.css'
import { ReactComponent as Filter } from '../../../static/images/filter.svg'
import SupplierFilterModal from "../../../components/procurementDashboard/supplier/FilterModal"
import { SupplierService } from "../../../_services/supplier"
import BarChart from "../../../components/procurementDashboard/supplier/BarChart"
import { openNotificationWithIcon } from "../../../_modules/notification"
import DonutChart from "../../../components/procurementDashboard/supplier/DonutChart"
import { onBoardedColumns, tonnageColumns, topFarmersColumns } from "../../../components/antd/columns/operations/category"
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import StatusData from '../../../components/procurementDashboard/supplier/StatusData';


const supplier_service = new SupplierService()
const { Option } = Select;
const { TabPane } = Tabs;
const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a className='frz-color-white frz-p-5'>Prev</a>;
    }

    if (type === 'next') {
        return <a className='frz-color-white frz-p-5'>Next</a>;
    }

    return originalElement;
};


const SupplierManagement = () => {
    const [showFilter, setShowFilter] = useState(false)
    const [state, setState] = useState({
        start_date: undefined, end_date: undefined, by_supplier_type: '', showDate: false,
        by_node: undefined, by_holding_start: "", by_holding_end: '', by_credit_cycle: '', range_type: 'daily',
        by_CC: undefined
    })
    const [onBoardedData, setOnBoardedData] = useState(false)
    const [isChanged, setIsChanged] = useState(true)
    const [curr, setCurr] = useState('daily')
    const [statusData, setStatusData] = useState(false)
    const [data, setData] = useState({ onBoardedTableData: [], onBoardedTableMeta: {}, onBoardedTableDataPage: 1, topFarmersData: [], topFarmersMeta: {}, topFarmersPage: 1, tonnageTableData: [], tonnageMeta: {}, tonnagePage: 1, tonnageCostTableData: [], tonnageCostMeta: {}, tonnageCostPage: 1 })
    const { onBoardedTableData, onBoardedTableMeta, onBoardedTableDataPage, topFarmersData, topFarmersMeta, topFarmersPage, tonnageTableData, tonnageMeta, tonnagePage, tonnageCostPage, tonnageCostMeta, tonnageCostTableData } = data;
    const [spinner, setSpinner] = useState({ tonnageCostSpinner: false, tonnageValue: false, onBoarded: false, farmers: false, barSpinner: false, donutSpinner: false })
    const [columns, setColumns] = useState(onBoardedColumns)
    const [farmersCol, setFarmersCol] = useState(topFarmersColumns)
    const [donutData, setDonutData] = useState({ donutChartValues: false, DonutChartcolors: false })
    const [activeTab, setActiveTab] = useState('vegetables')
    const [reset, setReset] = useState(false)
    const [spin, setSpin] = useState(false)
    const [filter, setFilter] = useState(false)
    const [skuData, setSkuData] = useState({})
    const [ccData, setCcData] = useState(false)
    const [msg, setMsg] = useState('loading')
    useEffect(() => {
        getOnboardedStats()
    }, [curr])
    useEffect(() => {
        if (reset) {
            handleApplyFilters()
        }
    }, [reset])
    useEffect(() => {
        if (filter) {
            handleApplyFilters()
        }
    }, [filter])

    useEffect(() => {
        getColumns()
        handleGetStatus()
        getSupplierSku()
        setTimeout(() =>
            handleSwap('fruits'), 1000)

    }, [])
    useEffect(() => {
        if (spin) {
            handleGetStatus()
            getOnboardedStats()
            handleGetTonnageCost()
            handleGetTonnage()
            handleGetFarmerRating()
            handleGetOnBoardTable()
            getSupplierSku()
        }
    }, [spin])
    useEffect(() => {
        filterOut(skuData)
    }, [activeTab])
    const separateData = (data) => {
        let fruits = [], veggies = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].category == 'Fruits') {
                fruits.push(...data[i].tags)
            }
            else if (data[i].category == 'Vegetables') {
                veggies.push(...data[i].tags)
            }
        }
        setSkuData({ 'fruits': fruits, 'vegetables': veggies })
        setTimeout(() => {
            filterOut({ 'fruits': fruits, 'vegetables': veggies })
        }, 600)
    }
    const filterOut = (data) => {
        let donut = {};
        let colors = [];
        
        for (let i = 0; i < data[activeTab]?.length; i++) {
            donut[data[activeTab][i].name] = +(data[activeTab][i]?.supplier_percentage.replaceAll('%', "").trim())
            colors.push(data[activeTab][i]?.color.toString())
        }
        setDonutData({ donutChartValues: Object.keys(donut).length ? donut : false, DonutChartcolors: colors.length ? colors : false })
        setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false })
    }
    const handleGetStatus = (values) => {
        setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false, barSpinner: true })
        const payload = {
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? ''
        }
        supplier_service.get_stats(payload).subscribe((r) => {
            setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false, barSpinner: false })
            setStatusData(r.response.data)
        },
            (err) => {
                console.log(err)
                setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false, barSpinner: false })
                openNotificationWithIcon("error", 'api error')
            })
    }
    function getColumnSearchProps(dataIndex, type) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys}
                        onChange={e => setSelectedKeys(e.target.value)}
                        onPressEnter={() => handleSearch(type, selectedKeys)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(type, selectedKeys)}
                                icon={<SearchOutlined />}
                                size="small">
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters(); handleSearch(type, '') }}
                                size="small"
                                icon={<ReloadOutlined />}
                            >
                                Reset
                            </Button>
                        </Space>
                    </div>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
        }
    };
    const handleFilter = (type, value) => {
        handleGetFarmerRating('', value.ratingType, value.ratingValue)
    }
    function getColumnFilterProps(dataIndex, type) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <div className='frz-dis-flex farmer-filter-container'>
                        <Select value={selectedKeys?.ratingType} placeholder='select rating type'
                            onChange={(e) => setSelectedKeys(e ? { ...selectedKeys, ratingType: e } : [])}>
                            <Option value='less_than' key='l'>Less than</Option>
                            <Option value='greater_than' key='g'>Greater than</Option>
                            <Option value='equal_to' key='e'>Equal to</Option>
                        </Select>
                        <Select value={selectedKeys?.ratingValue} placeholder='select rating value'
                            onChange={(e) => setSelectedKeys(e ? { ...selectedKeys, ratingValue: e } : [])}>
                            <Option value='1' key='1'>1</Option>
                            <Option value='2' key='2'>2</Option>
                            <Option value='3' key='3'>3</Option>
                            <Option value='4' key='4'>4</Option>
                            <Option value='5' key='5'>5</Option>
                        </Select>
                    </div>
                    <div className='farmers-filter-btns'>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleFilter(type, selectedKeys)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 110 }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters(); setSelectedKeys({}); handleFilter(type, '') }}
                                size="small"
                                icon={<ReloadOutlined />}
                                style={{ width: 110 }} >
                                Reset
                            </Button>
                        </Space>
                    </div>
                </div>
            ),
            //   filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
        }
    };
    const getColumns = () => {
        let temp = [...columns];
        temp.splice(1, 1, {
            title: `Name`,
            dataIndex: 'supplier_name',
            key: 'supplier_name',
            align: 'center',
            ...getColumnSearchProps('supplier_name', 'onboard')
        })
        setColumns(temp)
        let temp1 = [...farmersCol];
        temp1.splice(0, 1, {
            title: 'Name',
            dataIndex: 'farmer_name',
            key: 'farmer_name',
            align: 'center',
            ...getColumnSearchProps('farmer_name', 'topfarmer')
        })
        temp1.splice(1, 1, {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            align: 'center',
            ...getColumnFilterProps('rating', 'topfarmer')
        })
        setFarmersCol(temp1)
    }
    const getOnboardedStats = () => {
        setIsChanged(false)
        const payload = {
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? '',
            range_type: curr ?? ''
        }
        supplier_service.get_onboarded(payload).subscribe((r) => {
            setOnBoardedData(r.response.data)
            setIsChanged(true)
        },
            (err) => {
                console.log(err)
                openNotificationWithIcon("error", 'api error')
            })
    }
    const handleResetFilters = () => {
        setSpin(true)
        setState((prev) => ({
            ...prev, start_date: undefined, end_date: undefined, by_supplier_type: '', showDate: false,
            by_node: undefined, by_holding_start: 0, by_holding_end: 0, by_credit_cycle: '', range_type: 'daily',
            by_CC: undefined
        }))
        setShowFilter(false)
        setReset(true)
        setTimeout(() => {
            setSpin(false)
        }, [1000])
    }

    const handleGetOnBoardTable = (onBoardedValue) => {
        setSpinner({ onBoarded: true, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false})
        const payload = {
            page: onBoardedValue ? 1 : onBoardedTableDataPage,
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            name_search: onBoardedValue ?? '', 
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? '',
            per_page: 5
        }
        supplier_service.get_recently_onboarded(payload).subscribe((r) => {
            setData((prev) => ({ ...prev, onBoardedTableData: r.response.data, onBoardedTableMeta: r.response.meta }))
            setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false })
        },
            (err) => {
                console.log(err)
                setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false })
                openNotificationWithIcon("error", 'api error')
            })
    }
    const getSupplierSku = () => {
        setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: true })
        setMsg('loading')
        setDonutData({ donutChartValues: false, DonutChartcolors: false })
        setSkuData({})
        const payload = {
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? ''
        }
        supplier_service.get_sku_tag_wise(payload).subscribe((r) => {
          r?.response?.data?.length ? separateData(r.response.data) : setMsg('No Data Available')
            setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false })
        },
            (err) => {
                console.log(err)
                setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: false })
                openNotificationWithIcon("error", 'api error')
            })
    }
    const handleSearch = (type, selectedKeys) => {
        if (type === 'onboard') {
            handleGetOnBoardTable(selectedKeys)
        }
        else if (type === 'topfarmer') {
            handleGetFarmerRating(selectedKeys)
        }
    }
    const handleGetFarmerRating = (nameSearch, rating_order, rating) => {
        setSpinner({ onBoarded: false, farmers: true, tonnageValue: false, tonnageCostSpinner: false })
        const payload = {
            page: nameSearch ? 1 : topFarmersPage,
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            name_search: nameSearch ?? '',
            by_rating_order: rating_order ?? '',
            by_rating: rating ?? '',
            by_credit_cycle: state.by_credit_cycle ?? '',
            per_page: 5,
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
        }
        supplier_service.get_farmers_rating(payload).subscribe((r) => {
            setData((prev) => ({ ...prev, topFarmersData: r.response.data, topFarmersMeta: r.response.meta }))
            setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false })
        },
            (err) => {
                console.log(err)
                setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false })
                openNotificationWithIcon("error", 'api error')
            })
    }
    const handleGetTonnage = () => {
        setSpinner({ tonnageValue: true, tonnageCostSpinner: false, onBoarded: false, farmers: false })
        const payload = {
            page: tonnagePage,
            per_page: 5,
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? ''
        }
        supplier_service.get_tonnage(payload, 'city_wise_tonnage').subscribe((r) => {
            setData((prev) => ({ ...prev, tonnageTableData: r.response.data, tonnageMeta: r.response.meta }))
            setSpinner({ tonnageValue: false, tonnageCostSpinner: false, onBoarded: false, farmers: false })
        },
            (err) => {
                console.log(err)
                openNotificationWithIcon("error", 'api error')
                setSpinner({ tonnageValue: false, tonnageCostSpinner: false, onBoarded: false, farmers: false })
            })
    }
    const handleGetTonnageCost = () => {
        setSpinner({ tonnageCostSpinner: true, tonnageValue: false, onBoarded: false, farmers: false })
        const payload = {
            page: tonnageCostPage,
            per_page: 5,
            start_date: state.start_date ?? '',
            end_date: state.end_date ?? '',
            by_supplier_type: state.by_supplier_type ?? '',
            by_node: state.by_node ?? '',
            by_holding_start: state.by_holding_start ?? '',
            by_holding_end: state.by_holding_end ?? '',
            by_credit_cycle: state.by_credit_cycle ?? ''
        }
        supplier_service.get_tonnage(payload, 'city_wise_tonnage_cost').subscribe((r) => {
            setData((prev) => ({ ...prev, tonnageCostTableData: r.response.data, tonnageCostMeta: r.response.meta }))
            setSpinner({ tonnageCostSpinner: false, tonnageValue: false, onBoarded: false, farmers: false })
        },
            (err) => {
                console.log(err)
                setSpinner({ tonnageCostSpinner: false, tonnageValue: false, onBoarded: false, farmers: false })

                openNotificationWithIcon("error", 'api error')
            })
    }

    const handleApplyFilters = () => {
        setReset(false)
        setSpin(true);
        setFilter(false)
        setShowFilter(false)
        setTimeout(() => {
            setSpin(false)
        }, [1000])
    }
    useEffect(() => {
        handleGetOnBoardTable()
    }, [onBoardedTableDataPage])
    useEffect(() => {
        handleGetFarmerRating()
    }, [topFarmersPage])
    useEffect(() => {
        handleGetTonnage()
    }, [tonnagePage])
    useEffect(() => {
        handleGetTonnageCost()
    }, [tonnageCostPage])
    const pagination = (page) => {
        setData((prev) => ({ ...prev, onBoardedTableDataPage: page }))
    }
    const farmersPagination = (page) => {
        setData((prev) => ({ ...prev, topFarmersPage: page }))
    }
    const tonnagePagination = (page) => {
        setData((prev) => ({ ...prev, tonnagePage: page }))
    }
    const tonnageCostPagination = (page) => {
        setData((prev) => ({ ...prev, tonnageCostPage: page }))
    }
    const handleSwap = (e) => {
        setActiveTab(false);
        setSpinner({ onBoarded: false, farmers: false, tonnageValue: false, tonnageCostSpinner: false, donutSpinner: true })
        setTimeout(() => {
            setActiveTab(e);
        }, 100)
    }
    const handleClose = (removedTag) => {
        if (removedTag == 'start_date' || removedTag == 'end_date') {
            setState((prev) => ({ ...prev, start_date: undefined, end_date: undefined, showDate: false }))
        } else if (removedTag == 'by_holding_start' || removedTag == 'by_holding_end') {
            setState((prev) => ({ ...prev, by_holding_start: undefined, by_holding_end: undefined }))
        }
        else {
            setState((prev) => ({ ...prev, [removedTag]: undefined }))
        }
        if (removedTag === 'by_node') {
            setState((prev) => ({ ...prev, 'by_CC': undefined }))
        }
        setFilter(true)
    };
    const showCC = (data) => {
        let str = ""
        if (ccData?.length) {
            let res = [];
            for (let i = 0; i < ccData.length; i++) {
                for (let j = 0; j < data.length; j++) {
                    if (ccData[i].id === data[j]) {
                        res.push(ccData[i].name)
                    }
                }
            }
            str = res.join(", ")
        }
        return str;

    }
    return (
        <Spin spinning={spin}>
            <div className="frz-dis-flex frz-justify-sb">
                <div className="frz-font-22 frz-fw-400 frz-mt-10 frz-bm-10">Supplier Management</div>
                <div className='filter-align-sm filter-values'>
                    <>
                        {Object.keys(state).map((filter, i) => {
                            return (
                              <div key={i}>
                                {state[filter] && filter !== 'showDate' && filter !== 'range_type' && filter !== 'by_CC' ?
                                  <Tag closable color={'#FFEAC5'} onClose={(e) => {
                                    e.preventDefault();
                                    if (state[filter]) {
                                      handleClose(filter);
                                    }
                                  }} style={{ color: 'black' }}>{filter?.replaceAll("_", " ")} :
                                    {filter == 'by_node' ? showCC(state[filter]) : (state[filter] == 'by_week_of_transaction' ? "By Week" : (state[filter] == 'by_date_of_transaction' ? 'By Date' : state[filter]))}</Tag>
                                  : null}</div>
                            )
                        })}
                    </>
                </div>
                <div className='filter-align-sm align-center-container'>
                    <Button icon={<Filter />} onClick={() => setShowFilter(true)}></Button>
                </div>
            </div>
            {statusData ?
                <Row className='home-stats-container' gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}>
                    {Object.keys(statusData).map((item, index) => {
                        return <StatusData statusData={statusData} item={item} i={index} />
                    })}
                </Row>
                : null}
            <div className='spend-mgmt-grid-main-container'>
                <div className="frz-fw-400 frz-tm-20 frz-bm-10 frz-font-22">Supplier's By SKU</div>
                <div className="frz-fw-400 frz-tm-20 frz-font-22 frz-bm-10 ">Onboarding</div>
            </div>
            <div className='spend-mgmt-grid-main-container'>
                <div className="spend-mgmt-grid-container frz-h-420 donut-chart-container">
                    {localStorage.getItem('city') || donutData?.DonutChartcolors?.length > 0 ?
                        <div className='frz-dis-flex dnt-container'>
                            <div className='frz-tm-70 frz-p-10 frz-w-240px'>
                                <Tabs value={activeTab} onChange={(e) => handleSwap(e)}>
                                    <TabPane tab="Fruits" key="fruits">
                                        <div>
                                            {skuData['fruits']?.map((item, i) => {
                                                return (<div key={i}><div className="frz-dis-flex frz-justify-sb frz-p-5">
                                                    <div className='frz-dis-flex frz-align-item-center'>
                                                        <div style={{ width: '5px', height: '5px', marginRight: '5px', backgroundColor: item.color }}></div>
                                                        <div>{item.name}</div>
                                                    </div>
                                                    <div>
                                                        {item.supplier_count}
                                                    </div>
                                                </div>
                                                    <Divider />
                                                </div>)
                                            })}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Vegetables" key="vegetables">
                                        <div>
                                            {skuData['vegetables']?.map((item, i) => {
                                                return (<div key={i}><div className="frz-dis-flex frz-justify-sb frz-p-5">
                                                    <div className='frz-dis-flex frz-align-item-center'>
                                                        <div style={{ width: '5px', height: '5px', marginRight: '5px', backgroundColor: item.color }}></div>
                                                        <div>{item.name}</div>
                                                    </div>
                                                    <div>
                                                        {item.supplier_count}
                                                    </div>
                                                </div>
                                                    <Divider />
                                                </div>)
                                            })}
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                            <div className='frz-w-450px'>
                                {Object.keys(donutData?.donutChartValues)?.length > 0 && donutData?.DonutChartcolors?.length > 0 ?
                                    <DonutChart data={donutData?.donutChartValues} colors={donutData?.DonutChartcolors} />
                                  : msg === 'loading' ? <div className="spin-center frz-pt-100px"><Spin title={"...loading"} /></div> : 
                                  <p className='no-data frz-mt-25pr'>{"No Data Found"}</p>}
                            </div>
                        </div>
                    : <p className='no-data frz-mt-25pr'>{localStorage.getItem('city') ? "No Data Found" : "Please Select A City"}</p>}
                </div>
                <div className="spend-mgmt-grid-container bar-chart-container frz-h-420">
                    <Select placeholder="select type" onChange={(value) => { setCurr(value); setIsChanged(false) }} className='frz-w-100px frz-m-10' value={curr}>
                        <Option value={'daily'} key={'daily'}>Daily</Option>
                        <Option value={'week'} key={'week'}>Weekly</Option>
                        <Option value={'month'} key={'monthly'}>Monthly</Option>
                    </Select>
                    {isChanged && onBoardedData ? onBoardedData?.length > 0 ? <BarChart type={curr} chartData={onBoardedData} /> : 
                    <p className='no-data frz-mt-25pr'>{"No Data Found"}</p>
                    : <div className="spin-center"><Spin title={"...loading"}></Spin></div>}
                </div>
            </div>

            <div className='spend-mgmt-grid-main-container'>
                <div className="frz-fw-400 frz-tm-20 frz-bm-10 frz-font-22">Recently Onboarded</div>
                <div className="frz-fw-400 frz-tm-20 frz-font-22 frz-bm-10 ">Top Farmers</div>
            </div>
            <div className='spend-mgmt-grid-main-container'>
                <div className='frz-dis-flex frz-fd-c supplier-table-container'>
                    <Spin spinning={spinner.onBoarded}>
                        <div className="spend-mgmt-grid-container" style={{ padding: '10px', borderRadius: '5px' }}>
                            <Table rowKey="id" columns={columns} dataSource={onBoardedTableData} pagination={false} scroll={{ y: 270 }}
                                getPopupContainer={trigger => trigger.parentNode} />
                        </div>
                        <div className='frz-tm-15'>
                            <Pagination total={data?.onBoardedTableMeta?.total_pages * 5} current={data?.onBoardedTableMeta?.current_page} pageSize={5} onChange={pagination} itemRender={itemRender} showSizeChanger={false} />
                        </div>
                    </Spin>
                </div>
                <div className='frz-dis-flex frz-fd-c supplier-table-container'>
                    <Spin spinning={spinner.farmers}>
                        <div className="spend-mgmt-grid-container" style={{ padding: '10px', borderRadius: '5px' }}>
                            <Table rowKey="id" columns={farmersCol} dataSource={topFarmersData} pagination={false} scroll={{ y: 260 }}
                                getPopupContainer={trigger => trigger.parentNode} />
                        </div>
                        <div className='frz-tm-15'>
                            <Pagination total={topFarmersMeta?.total_pages * 5} current={topFarmersMeta?.current_page} pageSize={5} onChange={farmersPagination} itemRender={itemRender} showSizeChanger={false} />
                        </div>
                    </Spin>
                </div>
            </div>

            <div className='spend-mgmt-grid-main-container'>
                <div className="frz-fw-400 frz-tm-50 frz-bm-10 frz-font-22">Suppliers by Tonnage</div>
                <div className="frz-fw-400 frz-tm-50 frz-font-22 frz-bm-10 "></div>
            </div>
            <div>
                <div className='frz-dis-flex frz-fd-c supplier-table-container'>
                    <Spin spinning={spinner?.tonnageValue}>
                        <div className="spend-mgmt-grid-container" style={{ padding: '10px', borderRadius: '5px' }}>
                            <Table rowKey={'id'} columns={tonnageColumns('tonnage')} dataSource={tonnageTableData} pagination={false} scroll={{ y: 275 }} />
                        </div>
                        <div className='frz-tm-15'>
                            <Pagination total={tonnageMeta?.total_pages * 5} current={tonnageMeta?.current_page} pageSize={5} onChange={tonnagePagination} itemRender={itemRender} showSizeChanger={false} />
                        </div>
                    </Spin>
                </div>
            </div>

            <div className='spend-mgmt-grid-main-container'>
                <div className="frz-fw-400 frz-tm-50 frz-bm-10 frz-font-22">Suppliers by Value</div>
                <div className="frz-fw-400 frz-tm-50 frz-font-22 frz-bm-10 "></div>
            </div>
            <div>
                <div className='frz-dis-flex frz-fd-c supplier-table-container'>
                    <Spin spinning={spinner?.tonnageCostSpinner}>
                        <div className="spend-mgmt-grid-container" style={{ padding: '10px', borderRadius: '5px' }}>
                            <Table rowKey={'id'} columns={tonnageColumns('value')} dataSource={tonnageCostTableData} pagination={false} scroll={{ y: 275 }} />
                        </div>
                        <div className='frz-tm-15'>
                            <Pagination total={tonnageCostMeta?.total_pages * 5} current={tonnageCostMeta?.current_page} pageSize={5} onChange={tonnageCostPagination} itemRender={itemRender} showSizeChanger={false} />
                        </div>
                    </Spin>
                </div>
            </div>
            {showFilter ? <SupplierFilterModal ccData={ccData} setCcData={setCcData} handleClear={handleResetFilters} handleApplyFilters={handleApplyFilters} state={state} setState={setState} show={showFilter} hide={setShowFilter} /> : null}
        </Spin>
    )
}
export default SupplierManagement