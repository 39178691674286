import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Input, InputNumber, Pagination, Row, Spin } from "antd";
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch";
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";
import CreateNodeUserModal from "../../../components/operations/nodeuser/create_nodeuser_modal";
import MainTable from "../../../components/antd/table";
import { NodeUserColumns } from "../../../components/antd/columns/admin/nodeuser";
import { useSelector } from 'react-redux'

let defaultState = { valueByEmail: undefined, valueByNo: undefined, valueByNode: undefined }

const ops_service = new OperationsService()

function NodeUser() {
  const modalRef = useRef(null)
  const [renderData, setRenderData] = useState(false)
  const [nodeUserData, setNodeUserData] = useState(false)
  const [columns, setColumns] = useState([])
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [spinEnable, setSpinEnable] = useState(false)
  const [state, setState] = useState(defaultState);
  const { valueByEmail, valueByNo, valueByNode } = state
  const darkstore = useSelector(state => state.auth.darkstore);
  const [allNodes, setAllNodes] = useState(false);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getAllNodes()
      getNodeUser()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  const getAllNodes = () => {
    const paylaod = {
      nodeType: '',
      show_all: true,
      by_city: '',
    }
    ops_service.get_node_listing(paylaod).subscribe((r) => {
      setAllNodes(r.response.data)
    }, ((err) => {
      console.log("err", err);
    }))
  }

  function getColumns() {
    NodeUserColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function getNodeUser() {
    setSpinEnable(true)
    setNodeUserData([])
    const payload = {
      page: current,
      node_id: valueByNode === undefined ? '' : valueByNode,
      mobile_number: valueByNo === undefined ? '' : valueByNo,
      email: valueByEmail === undefined ? '' : valueByEmail,
    }
    ops_service.get_users_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setNodeUserData(r.response.data)
    },
      (err) => {

      })
  }

  function reset() {
    setState({
      valueByEmail: undefined, valueByNo: undefined, valueByNode: undefined,
    })
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function modalSubmit(payloadData) {
    if (payloadData.mobile_number.toString().length !== 10) {
      openNotificationWithIcon('error', "Please Enter 10 Digit Mobile No")
      modalRef.current.stopLoading()
      return
    }
    const payload = { ...payloadData }
    ops_service.create_user(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      getNodeUser()
      modalRef.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  return (
    <div className="layer">
      {nodeUserData && allNodes ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <FrzDynamicSearch DropDownData={allNodes}
                  placeholder="Select Node" value="id" option="name"
                  isShowSearch={true} width={400} setOnChange={(e) => onChange(e, 'valueByNode')}
                  type="valueByNode" margin={10} defValue={valueByNode} />

                <Input
                  placeholder="Email"
                  onChange={(e) => onChange(e.target.value, "valueByEmail")}
                  value={valueByEmail}
                  className="frz-w-200 frz-m-10" />

                <InputNumber
                  placeholder="Mobile No"
                  onChange={(e) => onChange(e, "valueByNo")}
                  value={valueByNo}
                  className="frz-w-200 frz-m-10" />

                <Button disabled={disable} onClick={getNodeUser}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Node')} className="frz-w-100px frz-m-10" type="primary">
                  Create User
                </Button>
              </Col>
            </Card>
          </Row>
          {nodeUserData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={nodeUserData} columns={columns} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CreateNodeUserModal darkstore={allNodes} modalSubmit={modalSubmit} ref={modalRef} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}
export default NodeUser;