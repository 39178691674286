import { Button, DatePicker, Divider, Modal, Tabs } from "antd"
import moment from "moment";
import { useEffect, useState } from "react";
import CCFilterComponent from "./filterCC";
import CategoryModule from "./category";
import '../../../static/css/supplier_management.css';

const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';

const FulfilmentFilterModal = (props) => {
  const {toggleModal, toggleFilterModal, initialFilters, filters, setFilters, setFilterForDisplay, 
    miscellaneous, filterForDisplay, setSpinner} = props;
  const [modalFilters, setModalFilters] = useState(initialFilters)
  const [modalDisplayFilter, setModalDisplayFilter] = useState(miscellaneous)
  const [ccState, setCCState] = useState([])
  const [isFilterApplied, setFilerApplied] = useState({date: false, cc: false, category: false, isFilterOn: false})
  const [currentTab, setCurrentTab] = useState('1')

  useEffect(() => {
    setModalFilters((prev) => ({ ...prev, ...filters }))
    setModalDisplayFilter((prev) => ({...prev, ...filterForDisplay}))
    checkFilterForFilter()
  }, [])

  const checkFilterForFilter = () => {
    let flag = {date: false, cc: false, category: false, isFilterOn: false};
    Object.keys(filters).map((key) => {
      if ((key === 'by_delivery_start_date' || key === 'by_delivery_end_date') && filters[key]?.length) {
        flag.date = true;
      }
      if (key === 'by_node' && filters[key]?.length) {
        flag.cc = true;
      }
      if ((key === 'by_product' || key === 'by_category_code' || key === 'by_sub_category_code') && filters[key]?.length) {
        flag.category = true;
      }
    })
    if (flag.date || flag.cc || flag.category) {
      flag.isFilterOn = true
    }
    setFilerApplied(flag)
  }

  function handleDatePicker(value, dateString) {
    setModalFilters(prevState => ({ ...prevState, by_delivery_start_date: dateString[0], by_delivery_end_date: dateString[1] }))
  }

  const handleSubmit = () => {
    setSpinner((prev) => ({...prev, mainSpinner: true}))
    toggleFilterModal()
    let node = [];
    modalDisplayFilter?.by_node.map((item) => {
      if (modalFilters.by_node.includes(item.id)) {
        node.push(item.name)
      }
    });
    setFilters((prev) => ({...prev, ...modalFilters}))
    setModalDisplayFilter((prev) => ({...prev, by_node: node}))
    setFilterForDisplay((prev) => ({...prev, ...modalDisplayFilter, by_node: node}))
    setTimeout(() => {
      setSpinner((prev) => ({...prev, mainSpinner: false}))
    }, 1000)
  }

  const clearFilter = () => {
    if (currentTab === '1') {
      setModalFilters((prev) => ({...prev, by_delivery_start_date: '', by_delivery_end_date: ''}))
    } else if (currentTab === '2') {
      setModalFilters((prev) => ({...prev, by_node: []}))
      setModalDisplayFilter((prev) => ({...prev, stateId: false}))
    } else {
      setModalFilters((prev) => ({...prev, by_product: '', by_category_code: '', by_sub_category_code: ''}))
    }
  }

  const clearAllFilter = () => {
    if (isFilterApplied.isFilterOn) {
      setSpinner((prev) => ({ ...prev, mainSpinner: true }))
      toggleFilterModal()
      setFilters((prev) => ({ ...prev, ...initialFilters }))
      setModalFilters((prev) => ({ ...prev, ...initialFilters }))
      setTimeout(() => {
        setSpinner((prev) => ({ ...prev, mainSpinner: false }))
      }, 1000)
    }
  }

  function disabledDate(current) {
    return current && (current < moment().subtract(365, "day") || current > moment().add(0, "day"));
  }

  const onTabChange = (e) => {
    setCurrentTab(e)
  }

  const headerText = () => {
    return (
      <div className="filter-header">
        <div>Filters</div>
        <div className="clear"><Button className='frz-lm-5 apply-filter-btn' type='danger' ghost onClick={clearAllFilter}>Clear All Filters</Button></div>
      </div>
    )
  }

  return (
    <>
      <Modal className='supplier-mgmt-filter-modal' footer={false}
        title={headerText()} visible={toggleModal} onCancel={toggleFilterModal}>
        <Tabs tabPosition={'left'} onChange={onTabChange}>
          <TabPane tab={<div className={`filter-tabs ${isFilterApplied.date ? 'filter-selected' : ''}`}>Date
            <div className={`${isFilterApplied.date ? 'filter-selected-circle' : ''}`} /></div>} key="1">
            <div className='frz-fw-500'>Select Start date and End date</div>
            <Divider />
            <DatePicker.RangePicker
              value={modalFilters.by_delivery_start_date ? [moment(modalFilters.by_delivery_start_date, dateFormat),
              moment(modalFilters.by_delivery_end_date, dateFormat)] : ''} allowClear={false} format={dateFormat}
              onChange={handleDatePicker} disabledDate={disabledDate}
            />
          </TabPane>
          <TabPane tab={<div className={`filter-tabs ${isFilterApplied.cc ? 'filter-selected' : ''}`}>CC
            <div className={`${isFilterApplied.cc ? 'filter-selected-circle' : ''}`} /></div>} key="2">
            <CCFilterComponent setModalFilters={setModalFilters} modalFilters={modalFilters} setCCState={setCCState} ccState={ccState}
              setModalDisplayFilter={setModalDisplayFilter} modalDisplayFilter={modalDisplayFilter} />
          </TabPane>
          <TabPane tab={<div className={`filter-tabs ${isFilterApplied.category ? 'filter-selected' : ''}`}>Category
            <div className={`${isFilterApplied.category ? 'filter-selected-circle' : ''}`} /></div>} key="3">
            <CategoryModule setModalFilters={setModalFilters} modalFilters={modalFilters} setModalDisplayFilter={setModalDisplayFilter} />
          </TabPane>
        </Tabs>
        <div className='frz-m-20 frz-ml-17p frz-mt-10p'>
          <Button type='primary' className='apply-filter-btn' onClick={handleSubmit}>Apply Filters</Button>
          <Button className='frz-lm-5 apply-filter-btn' type='danger' ghost onClick={clearFilter}>Clear</Button>
        </div>
      </Modal>
    </>
  )
}
export default FulfilmentFilterModal