import React from "react";
import {Button, Popconfirm } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';

function MainTab(props){

  return(
    <div>
      <div className="wcl-create-tab-container">
        <div>
          <span className="frz-fw-600 frz-lh-20">
            <Popconfirm
              title="Are you sure you want to go back, all changes made will be lost?"
              onConfirm={() => props.setWCScreen('wc_main')}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
            >
              <Button type="link" icon={<ArrowLeftOutlined />} className="wcl-blk-icon"></Button> 
            </Popconfirm>
            <span className="frz-lm-10">
              {props.wcScreen === 'create_wastage' ? 'Create Wastage' : 'Create Closing'}
            </span>
          </span>
        </div>
        <div className="wcl-align-center">
          {props.wcScreen === 'create_wastage' ? 
            <FrzDynamicUpload name={'Sheet'} uploadFunc={'upload_wc_sheet'} modType={'wcl'} 
              callBack={props.updateUploadList} setScreenLoad={props.setScreenLoad}
              url={`/v2/admin_dashboard_v2/stock_losses/wastage_item_with_sheet_upload?node_code=${props.currentNode}`}
            />
          : 
            <FrzDynamicUpload name={'Sheet'} uploadFunc={'upload_wc_sheet'} modType={'wcl'} 
              callBack={props.updateUploadList} setScreenLoad={props.setScreenLoad}
              url={`/v2/admin_dashboard_v2/stock_losses/closing_item_with_sheet_upload?node_code=${props.currentNode}&product_category=${props.currentMainTab}`}
            />
          }
          {/* <Button type="primary" className="wcl-align-center wcl-black-button" 
                  onClick={props.openSkuModal} icon={ <PlusOutlined /> }>
            Add SKU
          </Button> */}
        </div>
      </div>
    </div>
  )
}

export default MainTab