import React, {useState, useEffect} from 'react'
import {Spin, Button, Row, Col, Card} from 'antd'
import { ReactComponent as Filter } from '../../../static/images/filter.svg'
import {FulfilmentService} from '../../../_services/procurementDashboard/fulfilment';
import Stats from '../../../components/procurementDashboard/fullfilment/stats';
import '../../../static/css/fulfilment.css'
import ValueAndAllocation from '../../../components/procurementDashboard/fullfilment/valueAndAllocation';
import RejectionComponent from '../../../components/procurementDashboard/fullfilment/rejections';
import OrderFulFilment from '../../../components/procurementDashboard/fullfilment/orderFulFilment';
import FulfilmentFilterModal from '../../../components/procurementDashboard/fullfilment/fulfilmentFilterModal';
import AppliedFilters from '../../../components/procurementDashboard/fullfilment/appliedFilters';

const fulfilment_service = new FulfilmentService()

const initialSpinners = {
  mainSpinner: false,
  rejectByReasonSpinner: false,
  statsSpinner: false
}

const initialFilters = {
  by_delivery_start_date: '',
  by_delivery_end_date: '',
  by_product: '',
  by_category_code: '',
  by_sub_category_code: '',
  by_node: [],
}

const initialTableFilters = {
  by_count: '',
  by_count_order: '',
  by_percentage: '',
  by_percentage_order: '',
  page: 1,
  product_name_search: ''
}

const dropDownOption = [
  {label: 'Daily', value: 'daily'},
  {label: 'Weekly', value: 'week'},
  {label: 'Monthly', value: 'month'}
]

const initialRangeType = {
  valueRangeType: 'daily',
  allocationRangeType: 'daily',
  rejectionsRangeType: 'daily',
  orderRangeType: 'daily',
}

const initialDataList = {
  stats: false, 
  fulfilment: false, 
  rejections: false, 
  orderFulfilment: false, 
  rejectionByReason: false, 
  meta: false, 
  allocation: false
}

const miscellaneous = {
  stateId: false,
  by_product: '',
  by_category_code: '',
  by_sub_category_code: '',
  by_node: []
}

const FulfilmentComponent = () => {
  const [spinner, setSpinner] = useState(initialSpinners)
  const [filters, setFilters] = useState(initialFilters)
  const [tableFilters, setTableFilters] = useState(initialTableFilters)
  const [rangeType, setRangeType] = useState(initialRangeType)
  const [dataList, setDataList] = useState(initialDataList)
  const [filterForDisplay, setFilterForDisplay] = useState(miscellaneous)
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    getStats()
  }, [filters])

  useEffect(() => {
    getFulfiment()
  }, [rangeType?.valueRangeType, filters])

  useEffect(() => {
    getAllocation()
  }, [rangeType?.allocationRangeType, filters])

  useEffect(() => {
    getRejections()
  }, [rangeType?.rejectionsRangeType, filters])

  useEffect(() => {
    getRejectionByReason()
  }, [tableFilters, filters])

  useEffect(() => {
    getOrderFulfilment()
  }, [rangeType?.orderRangeType, filters])

  const getStats = () => {
    dataList?.fulfilment?.length && setDataList((prev) => ({...prev, stats: false}))
    fulfilment_service.get_dashboard_stats(filters).subscribe((r) => {
      setDataList((prev) => ({...prev, stats: r.response.data}))
    }, 
    (err) => {
      console.log(err?.response)
    })
  }

  const getFulfiment = () => {
    dataList?.fulfilment?.length && setDataList((prev) => ({...prev, fulfilment: false}))
    const payload = {...filters}
    payload.range_type = rangeType?.valueRangeType ?? ''
    fulfilment_service.get_fulfilment_value(payload).subscribe((r) => {
      setDataList((prev) => ({...prev, fulfilment: r.response.data}))
    },
    (err) => {
      setDataList((prev) => ({...prev, fulfilment: []}))
      console.log(err?.response)
    })
  }

  const getRejections = () => {
    dataList?.rejections?.length && setDataList((prev) => ({...prev, rejections: false}))
    const payload = {...filters}
    payload.range_type = rangeType?.rejectionsRangeType ?? ''
    fulfilment_service.get_rejections(payload).subscribe((r) => {
      setDataList((prev) => ({...prev, rejections: r.response.data}))
    },
    (err) => {
      setDataList((prev) => ({...prev, rejections: []}))
      console.log(err?.response)
    })
  }

  const getOrderFulfilment = () => {
    dataList?.orderFulfilment?.length && setDataList((prev) => ({...prev, orderFulfilment: false}))
    const payload = {...filters}
    payload.range_type = rangeType.orderRangeType ?? ''
    fulfilment_service.get_order_fulfilment(payload).subscribe((r) => {
      setDataList((prev) => ({...prev, orderFulfilment: r.response.data}))
    },
    (err) => {
      setDataList((prev) => ({...prev, orderFulfilment: []}))
      console.log(err?.response)
    })
  }

  const getRejectionByReason = () => {
    setSpinner((prev) => ({...prev, rejectByReasonSpinner: true}))
    const payload = {...filters, ...tableFilters}
    fulfilment_service.get_rejection_by_reason(payload).subscribe((r) => {
      setDataList((prev) => ({...prev, rejectionByReason: r.response.data, meta: r.response.meta}))
      setSpinner((prev) => ({...prev, rejectByReasonSpinner: false}))
    }, (err) => {
      setSpinner((prev) => ({...prev, rejectByReasonSpinner: false}))
      console.log(err?.response)
    })
  }

  const getAllocation = () => {
    dataList?.allocation?.length && setDataList((prev) => ({...prev, allocation: false}))
    const payload = {...filters}
    payload.range_type = rangeType?.allocationRangeType ?? ''
    fulfilment_service.get_allocation(payload).subscribe((r) => {
      setDataList((prev) => ({...prev, allocation: r.response.data}))
    }, (err) => {
      console.log(err.response)
    })
  }

  const toggleFilterModal = () => {
    setToggleModal(!toggleModal)
  }

  return (
    <Spin spinning={spinner.mainSpinner}>
      <div className="fulfilment fulfilment-font">
        <Row className="fulfilment-header margin-section-one">
          <div>Fulfilment</div>
          <AppliedFilters filters={filters} setFilters={setFilters} initialFilters={initialFilters} filterForDisplay={filterForDisplay} />
          <div className='filter-align-sm'>
            <Button icon={<Filter />} onClick={toggleFilterModal}></Button>
          </div>
        </Row>
        <Row>
          {dataList?.stats ?
            Object.keys(dataList?.stats)?.length ?
              Object.keys(dataList?.stats).map((item, index) => <Stats item={item} data={dataList.stats} key={index} />) :
              <Col span={12}>No Stats Available</Col> :
            <Col span={12}><div><Spin /></div></Col>}
        </Row>
        {toggleModal && <FulfilmentFilterModal toggleModal={toggleModal} toggleFilterModal={toggleFilterModal}
          initialFilters={initialFilters} filters={filters} setFilters={setFilters} filterForDisplay={filterForDisplay} 
          setFilterForDisplay={setFilterForDisplay} miscellaneous={miscellaneous} setSpinner={setSpinner} />}
        <ValueAndAllocation fulfilData={dataList.fulfilment} dropDownOption={dropDownOption} getFulfiment={getFulfiment}
          allocation={dataList.allocation} rangeType={rangeType} setRangeType={setRangeType} />
        <RejectionComponent rejectionsData={dataList.rejections} dropDownOption={dropDownOption}
          setTableFilters={setTableFilters} tableData={dataList.rejectionByReason} meta={dataList.meta} getRejectionByReason={getRejectionByReason}
          rejectByReasonSpinner={spinner.rejectByReasonSpinner} rangeType={rangeType} setRangeType={setRangeType} />
        <OrderFulFilment orderFulfilData={dataList.orderFulfilment} dropDownOption={dropDownOption} getOrderFulfilment={getOrderFulfilment}
          rangeType={rangeType} setRangeType={setRangeType} />
      </div>
    </Spin>
  )
}

export default FulfilmentComponent;