import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination, Input, DatePicker, Tabs, Image, Dropdown, Menu, Divider } from 'antd';
import { OperationsService } from "../../../_services/operations";
import MainTable from '../../../components/antd/table';
import { forcastListingColumns } from "../../../components/antd/columns/operations/procurement";
import { openNotificationWithIcon } from '../../../_modules/notification'
import ForcastListingModal from '../../../components/operations/forcasting/ForcastListingModal';
import { ReloadOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import NewForecastListingModal from '../../../components/operations/forcasting/NewForcastListingModal';

const { TabPane } = Tabs;

const operation_service = new OperationsService();

const Forcasting = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [products, setProducts] = useState(false)
  const [nodes, setNodes] = useState(false)
  const [operations, setOperations] = useState(false)
  const [city, setCity] = useState(null)
  const [fetchColm, setFetchColm] = useState(false)
  const [state, setState] = useState({ valueByName: "", date: "" });
  const [currentTab, setCurrentTab] = useState(1);
  const { valueByName, date } = state

  const [showNewForeCastModal, setShowNewForeCastModal] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing(currentTab)
    }
  })

  useEffect(() => {
    getProducts()
    getNodes()
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'category', 'category_admin'].includes(data)) {
        setOperations(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false)
      getColumns()
    }
  })

  function getListing() {
    setSpinEnable(true)
    setListingData([]);
    if (currentTab == 1) {
      // console.log("where it should be")
      const payload = {
        page: current,
        date: moment(date).isValid() ? date : "",
        valueByName: valueByName,
      }

      operation_service.get_forcast_listing(payload).subscribe((r) => {
        setTotal(r.response.meta.total_pages * 50);
        setSpinEnable(false);
        setListingData(r.response.data);
      },
        (err) => {
          console.log(err);
        }
      );
    } else {
      console.log("nooo")
      const payload = {
        page: current,
        date: moment(date).isValid() ? date : "",
        valueByName: valueByName,
      }

      operation_service.get_forcast_new_listing(payload).subscribe((r) => {
        setTotal(r.response.meta.total_pages * 50);
        setSpinEnable(false);
        setListingData(r.response.data);
      },
        (err) => {
          console.log(err);
        }
      );

    }


  }

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProducts() {
    const payload={
      by_name:''
    }
    operation_service.get_sku_list(payload).subscribe((r) => {
      setProducts(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    forcastListingColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')} disabled={!operations}>Edit</Button>
        );
      },
    });
    setColumns(columns)
  }

  function showModal(data, type) {

    // if(type === 'Create Forcasting' && localStorage.getItem('city') === ''){
    //   openNotificationWithIcon('error', 'Please select a city on top right')
    //   return
    // }
    setCity(data.by_city)
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setState({ valueByName: "", date: "" })
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }


  function changeTab(key) {
    setCurrentTab(key);
    setState({ valueByName: "", date: "" })
    setListingData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  const handleNewForeCastModal = (value, data) => {
    if (data) {
      setRowData(data)
    } else {
      setRowData(false)
    }
    setShowNewForeCastModal(value)
  }

  // const handleNewForecast = (value) => {
  //   handleNewForeCastModal(value)
  // }

  function modalSubmit(payloadData, id) {
    const payload = {
      ...payloadData, id: id ? id : undefined
    }

    if (mode === 'Edit') {
      payload.by_city = city
      operation_service.update_forcast_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      operation_service.create_forcast_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    }
  }

  const newModalSubmit = (data) => {
    setLoading(true)
    operation_service.update_new_forcast_listing(data).subscribe((r) => {
      setLoading(false)
      getListing()
      openNotificationWithIcon('success','Created Successfully')
      handleNewForeCastModal(false)
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors[0] + " Creation Failed")
    }))
  }

  function newmodalSubmit(payloadData, id) {
    const payload = {
      ...payloadData, id: id ? id : undefined
    }
    if (mode === 'Edit') {
      payload.by_city = city
      operation_service.update_new_forcast_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      operation_service.create_new_forcast_listening(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'created Successfuly')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.error?.[0])
        }
      )
    }
  }

  const getDateFormat = date => {
    let d = moment(date);
    return date && d.isValid() ? d : '';
  };



  return (
    <div className="layer">
      <div className="tabs-to-center">
        <Tabs defaultActiveKey='1' onChange={changeTab}>
          <TabPane style={{ fontSize: 16 }} tab="Old" key="1"></TabPane>
          <TabPane className="frz-font-15" tab="New" key="2"></TabPane>
        </Tabs>
      </div>

      {listingData && products && nodes ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input
                  placeholder="SKU Name"
                  onChange={(e) => onChange(e.target.value, "valueByName")}
                  value={valueByName}
                  className="frz-w-200 frz-m-10" />
                <DatePicker
                  placeholder="Date"
                  onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"), "date")}
                  value={getDateFormat(date)}
                  className="frz-w-200 frz-m-10" />
                <Button disabled={disable} onClick={getListing}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />

                {currentTab == 2 ? (
                  <span className="frz-fl-right">
                    <div className="frz-dis-flex frz-justify-center frz-align-center">
                    <Dropdown overlay={
                    <Menu className='forecast-dropdown'>
                      {/* <FrzDynamicUpload name={'Sales Forecast'} url={'admin_dashboard/procurement/sales_forecast_upload'} uploadFunc={'upload_zapper_post'}
                        callBack={getListing} disabled={!operations} /> */}
                      <div className='frz-text-decoration-underline'><a download="" href="https://sku-zapper.s3.ap-south-1.amazonaws.com/city_settings_update_sheet/last_sto/2022-07-06_12%3A25_.csv">Download Sample Upload File</a></div>
                      <FrzDynamicUpload name={'Forecasting'} url={'admin_dashboard/procurement/upload_sheet'} uploadFunc={'upload_zapper_put'}
                        callBack={getListing} disabled={!operations} />

                      {/* <FrzDynamicUpload name={'Shortfall Data'} url={'admin_dashboard/procurement/upload_expected_shortfall_data'} uploadFunc={'upload_zapper_put'}
                        callBack={getListing} /> */}
                      
                      <Divider />
                      <FrzDynamicDownload name={'Forecast'} fileName={'Forecast'} url={`admin_dashboard/procurement/forecast_report`}
                        payload={'?date=' + `${date ?? ''}` + '&by_name=' + `${valueByName ?? ''}`} type={'job'}
                        reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  </Menu>} 
                      placement={'topRight'} arrow={{ pointAtCenter: true }}>
                      <div><Button type='primary'>
                          <span className="filter-download-btn"><DownloadOutlined  /></span>
                          <span>Upload / Download Forecast</span>
                      </Button></div>
                  </Dropdown>   
                      <Button className="frz-dwn" onClick={() => handleNewForeCastModal(true)} type="primary" disabled={!operations}>Create Forecasting</Button>
                    </div>
                  </span>
                ) : (
                  ""
                )}
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <>
              <Row style={{ marginTop: "10px" }}>
                <Col span={24}>

                  {currentTab == 1 ? (
                    <>
                      <MainTable dataSource={listingData} columns={columns} />
                    </>
                  ) : (
                    <>
                      {listingData.map((item, index) => {
                        return (
                          <div key={index} style={{ backgroundColor: "white", marginTop: "8px" }}>

                            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #E3E3E3", alignItems: "center", height: "60px" }}>

                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="frz-img" >
                                  <img src={item.image_url} width="100%" />
                                </div>
                                <div className="frz-text">{item.product_name} - {item.sku} ({item.inventory_unit})</div>
                              </div>

                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="frz-table-date">
                                  Delivery Date: <b style={{ color: "black" }}> {item.delivery_date} </b>
                                </div>
                                <div className="frz-pen frz-pointer" >
                                  <img onClick={() => handleNewForeCastModal(true, item)} src="./images/Frame 42.svg" style={{ height: "33px", borderRadius: "2px" }} />
                                </div>
                              </div>
                            </div>

                            <div style={{ display: "flex", overflowX: "auto" }}>
                              {item.product_forecast.map((t, index) => {
                                return (
                                  <div key={index} style={{ borderLeft: "1px solid #E3E3E3", marginRight: "40px" }}>
                                    <div className="frz-inside-table-col">
                                      <div className="frz-loc">{t.name}</div>
                                      <div className="frz-quan">F.Qty: <span className="frz-fw-700">{" "}{t.forecast_qty}</span></div>
                                      <div className="frz-quan">E.Qty: <span className="frz-fw-700">{" "}{t.qty}</span></div>
                                      <div className="frz-quan">T.Rate: <span className="frz-fw-700">{" "}{t.target_price}</span></div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}

                    </>
                  )}
                  <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                    pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
                </Col>
              </Row>
            </>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          {currentTab == 1 ? (
            <ForcastListingModal modalSubmit={modalSubmit} ref={modalRef} products={products} nodes={nodes} />
          ) : (
            showNewForeCastModal ?
              <NewForecastListingModal showNewForeCastModal={showNewForeCastModal} handleNewForeCastModal={handleNewForeCastModal}
                products={products} rowData={rowData} newModalSubmit={newModalSubmit} loading={loading} />
              : null
          )}

        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default Forcasting



