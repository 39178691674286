import React, { useEffect, useState, useRef } from "react";
import { Spin, Modal } from 'antd';
import MainTab from './main_tab';
import WCList from './wc_list';
import {WastageClosingService} from '../../../_services/wastage_closing';
import UomMessage from './uom_message';

const wc_service = new WastageClosingService()

function WCCreate(props){
  const childRef = useRef()
  const [wcList, setWCList] = useState(false)
  const [addProductList, setAddProductList] = useState(false)
  const [screenLoad, setScreenLoad] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [fileUploaded, setFileUploaded] = useState(false)

  useEffect(() =>{
    getWCList()
    getAddProductList()
  },[])

  function getWCList(){
    wc_service['get_wc_'+props.wcScreen](props.currentNode, props.wcScreen === 'create_closing' ? props.currentMainTab : false).subscribe((r) => {
      if(r.response.data){
        let newResponse = r.response.data
        for (const key in newResponse) {
          let newData
          if(props.wcScreen === 'create_wastage'){
            newData = newResponse[key].map(v => ({...v, wastage_quantity: null, errors: null, reason_id: null}))
          }else{
            newData = newResponse[key].map(v => ({...v, closing_quantity: null, errors: null}))
          }
          newResponse[key] = newData
        }
        setWCList(newResponse)
      }else{
        setWCList('nodata')
      }
      props.dashboardData?.message?.length > 0 && info()
    },
    (err)=>{
      setApiError(true)
      setWCList(true)
      console.log(err)
    })
  }

  function info() {
    Modal.info({
      title: 'Inventory Unit Update',
      width: 700,
      className: 'closing-confirm',
      content: (
        <UomMessage data={props.dashboardData.message}/>
      ),
      onOk() {},
    });
  }

  function getAddProductList(){
    wc_service['get_product_data_'+props.wcScreen](props.currentNode).subscribe((r) => {
      // let newResponse = r.response.data.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
      let newData
      if(props.wcScreen === 'create_wastage'){
        newData = r.response.data.map(v => ({...v, wastage_quantity: null, errors: null, reason_id: null}))
      }else{
        newData = r.response.data.map(v => ({...v, closing_quantity: null, errors: null}))
      }
      setAddProductList(newData)
    },
    (err)=>{
      setApiError(true)
      setAddProductList(true)
      console.log(err)
    })
  }

  function showAddSkuModal(){
    childRef.current.openSkuModal()
  }

  function updateUploadList(data){
    setWCList(false)
    if(data){
      let newResponse = data
      for (const key in newResponse) {
        let newData
        newData = newResponse[key].map(v => ({...v, errors: null}))
        newResponse[key] = newData
      }
      setWCList(newResponse)
      setScreenLoad(false)
      setFileUploaded(true)
    }else{
      setWCList('nodata')
      setScreenLoad(false)
    }
  }

  return(
    <div>
      {wcList && addProductList && props.reasons ? 
        <>
          {!apiError ? 
            <Spin spinning={screenLoad}>
              <MainTab setWCScreen={props.setWCScreen} openSkuModal={showAddSkuModal} setScreenLoad={setScreenLoad}
                wcScreen={props.wcScreen} currentNode={props.currentNode} updateUploadList={updateUploadList}
                currentMainTab={props.currentMainTab}/>
              <div className="wc-main-container">
                <WCList data={wcList} addProductList={addProductList} wc_service={wc_service}
                  ref={childRef} setWCScreen={props.setWCScreen} wcScreen={props.wcScreen} 
                  currentNode={props.currentNode} reasons={props.reasons}
                  fileUploaded={fileUploaded} currentMainTab={props.currentMainTab}/>
              </div>
            </Spin>
          : 
            <div className="horizontal-vertical-center frz-font-20">
              <b>Backend API System Down</b>
            </div>
          }
        </>
      : 
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default WCCreate