import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, InputNumber, Pagination, Table,
  DatePicker, Popconfirm, Popover, Dropdown, Menu, Input
} from 'antd';
import { ReloadOutlined, EditOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import { UploadService } from '../../../_services/uploads';
import MainTable from '../../../components/antd/table';
import { stoColumns, productColumns } from '../../../components/antd/columns/operations/sto';
import CreateStoModal from '../../../components/operations/sto/create_sto_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import UpdateSTOModal from '../../../components/operations/sto/UpdateSTOModal';
import AddProductModalSTO from '../../../components/operations/sto/add_products';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import ModalUploadSto from '../../../components/operations/sto/modal_upload_sto';
import moment from 'moment';
import ModalUpdateSTO from '../../../components/operations/sto/modalupdatesto';

const ops_service = new OperationsService()
const upload_service = new UploadService()
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

function STOList() {
  const modalRef = useRef(null);
  const updateModalRef = useRef(null);
  const modalRefProducts = useRef(null);

  const [stoData, setStoData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [columnsProducts, setColumnsProducts] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [products, setProducts] = useState(false)
  const [destinations, setDestinations] = useState(false)
  const [operations, setOperations] = useState(false)
  const [ccoperations, setCCOperations] = useState(false)
  const [editoperations, setEditOperations] = useState(false)
  const [whoperations, setWHOperations] = useState(false)
  // const [fetchColm, setFetchColm] = useState(false)
  const [uploadStoModal, setUploadStoModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [irnLoading, setIrnLoading] = useState(false)
  const [currentId, setCurrentId] = useState(false)
  const [challanLoading, setChallanLoading] = useState(false)
  const [updateStoModal, setUpdateStoModal] = useState(false)

  const [status] = useState([{ id: 'dispatched', name: 'Dispatched' }, { id: 'delivered', name: 'Delivered' },
  { id: 'cancelled', name: 'cancelled' }, { id: "material_allocated", name: "material_allocated" },
  { id: "assigned", name: "assigned" }, { id: "shipment_assigned", name: "shipment_assigned" },
  { id: "ready_to_load", name: "ready_to_load" }, { id: "loading", name: "loading" },
  { id: "allocated", name: "allocated" }, { id: "ready_to_dispatch", name: "ready_to_dispatch" }])

  const [state, setState] = useState({
    valueByNode: undefined, valueByDestination: undefined, valueByStatus: undefined,
    valueByDate: undefined, date: '', valueByOrderNumber: undefined, valueByProductName: undefined,
    // by_shipment_id: undefined, by_sto_order_number: undefined
  });
  const { valueByNode, valueByDestination, valueByStatus, valueByDate, date, valueByOrderNumber, valueByProductName,
    //  by_shipment_id, by_sto_order_number 
  } = state
  const [stateFilter, setStateFilter] = useState({ start_date: '', end_date: '', showDateValue: false, });
  const { start_date, end_date, showDateValue } = stateFilter

  function disabledDate(current) {
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  function onChangeRangePicker(value, dateString) {
    setStateFilter(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
    setCurrent(1)
  }

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getSto()
    }
  })

  useEffect(() => {
    getNodes()
    getNodeDestinations()
    getProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'warehouse_staff', 'warehouse_admin', 'coldex', 'category', 'category_admin'].includes(data)) {
        setOperations(true)
        setWHOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'category', 'category_admin', 'cc_admin'].includes(data)) {
        setCCOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'category', 'category_admin', 'cc_admin', 'warehouse_staff', 'warehouse_admin', 'coldex'].includes(data)) {
        setEditOperations(true)
      }
    })
    // setFetchColm(true)

  }, [])

  useEffect(() => {
    getColumns()
  }, [loading, stoData, irnLoading])

  const handleStoModal = (value) => {
    setUploadStoModal(value)
  }

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getNodeDestinations() {
    const payload = {
      nodeType: '',
      show_all: true,
      no_admin: true
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setDestinations(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProducts() {
    ops_service.get_product_list().subscribe((r) => {
      setProducts(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getSto() {
    setSpinEnable(true)
    setStoData([])
    const payload = {
      page: current,
      sto_type:'',
      by_source: !valueByNode ? '' : valueByNode,
      by_destination: !valueByDestination ? '' : valueByDestination,
      by_status: !valueByStatus ? '' : valueByStatus,
      transfer_date: !valueByDate ? '' : valueByDate,
      admin_id: JSON.parse(localStorage.getItem('auth')).id,
      end_date: end_date ? end_date : '',
      start_date: start_date ? start_date : '',
      by_order_number: valueByOrderNumber ? valueByOrderNumber : '',
      name_search: valueByProductName ?? '',
      by_source_city:'',
      inter_city:'',
      by_destination_city:'',
      by_procurement_type:localStorage.getItem('procurementtype')?localStorage.getItem('procurementtype'):'',
      date_type:'',
      by_source_node_type:'',
      by_destination_node_type:''
      // by_sto_order_number: by_sto_order_number ? by_sto_order_number : '',
      // by_shipment_id: by_shipment_id ? by_shipment_id : ''
    }

    ops_service.get_sto_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setStoData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  const handleOnUpdate = (values, type) => {
    showUpdateModal(values, type)
  }

  const handleOnRetry = (id, isIRN) => {
    setCurrentId(id)
    const payload = {
      id: id
    }
    if (!isIRN) {
      setLoading(true)
      ops_service.sto_retry_ewaybill(payload).subscribe((r) => {
        setLoading(false)
        setCurrentId(false)
        // reset()
        getSto()
      }, ((err) => {
        setLoading(false)
        setCurrentId(false)
        console.log("err", err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }))
    } else {
      setIrnLoading(true)
      ops_service.sto_irn_report(payload).subscribe((r) => {
        setIrnLoading(false)
        setCurrentId(false)
        // reset()
        getSto()
      }, ((err) => {
        setIrnLoading(false)
        setCurrentId(false)
        console.log("err", err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }))
    }
  }


  // const convertPdf = (pdfBlob, billNo) => {
  //   // set the blog type to final pdf
  //   const file = new Blob([pdfBlob], { type: 'application/pdf' });
  //   // process to auto download it
  //   const fileURL = URL.createObjectURL(file);
  //   const link = document.createElement('a');
  //   link.href = fileURL;
  //   link.download = "Eway bill " + billNo + new Date() + ".pdf";
  //   link.click();
  // }



  // const downloadPdf = (id, billNo) => {
  //   setLoading(true)
  //   const payload = {
  //     id: id
  //   }
  //   ops_service.get_eway_bill_pdf(payload).subscribe((r) => {
  //     window.open(r.response.data, '_blank');
  //     // convertPdf(r.response.data, billNo)
  //   }, ((err) => {
  //     console.log("err", err)
  //   }))
  // }

  function getColumns() {
    let tempCol = []
    let tempProdCol = []
    stoColumns.map(data => {
      tempCol.push(data)
    })
    // columns.push({
    //   title: 'Edit',
    //   dataIndex: '',
    //   key: '',
    //   align: 'center',
    //   render: (record) => {
    //     return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_updatable}
    //                     onClick={() => showModal(record, 'Edit Payments')}>
    //             </Button>
    //   }
    // })
    productColumns.map(data => {
      tempProdCol.push(data)
    })
    tempCol.push(
      {
        title: 'Edit STO',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Button icon={<EditOutlined />}
            disabled={!record.is_editable || !editoperations || record.source_node_type === 'DS'}
            onClick={() => handleOnUpdate(record, "Update STO")}>
          </Button>
        }
      },
      {
        title: 'Create Inwarding',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Button disabled={record.status !== "dispatched" ||
            record.destination_node_type === 'DS' || !editoperations}
            type="primary"
            onClick={() => handleOnUpdate(record, "Update Inward")}>
            Create Inwarding
          </Button>
        }
      },
      // {
      //   title: 'Move To NC',
      //   dataIndex: '',
      //   key: '',
      //   align: 'center',
      //   render: (record) => {
      //     return <Popconfirm
      //       title="Are you sure you want to move this sto to non crate?"
      //       onConfirm={() => moveToNonCrate(record.id)}
      //       okText="Yes"
      //       cancelText="No"
      //       placement="leftTop"
      //       // disabled={record.status !== "ready_to_load" && record.status !== "loading" && record.status !== "assigned"}
      //       disabled={true}
      //     >
      //       <Button
      //         disabled={true}
      //         // disabled={record.status !== "ready_to_load" && record.status !== "loading" && record.status !== "assigned"}
      //         type="primary">
      //         Move To Non-Crate
      //       </Button>
      //     </Popconfirm >
      //   }
      // },
      {
        title: 'Download',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <div>
            <Dropdown overlay={<Menu>
              {/* bill_url */}
              <Menu.Item
                disabled={!record.bill_url && (record.status !== "ready_to_dispatch" ||
                  record.status !== "dispatched" || record.status !== "delivered")}>
                <a target="_blank" rel="noopener noreferrer" href={record.bill_url}>
                  Eway bill
                </a>

              </Menu.Item>

              <Menu.Item disabled={!record.irn_invoice_url}>
                <a target="_blank" rel="noopener noreferrer" href={record.irn_invoice_url}>
                  IRN
                </a>
              </Menu.Item>

              {/* <Menu.Item disabled={record.delivery_challan_url}>
                <a target="_blank" rel="noopener noreferrer" href={record.delivery_challan_url}>
                  Delivery challan
                </a>
              </Menu.Item> */}

              <Menu.Item disabled={!record.tax_invoice_url}>
                <a target="_blank" rel="noopener noreferrer" href={record.tax_invoice_url}>
                  Tax Invoice
                </a>
              </Menu.Item>
            </Menu>} placement="bottomCenter" arrow>
              <Button type="primary" icon={<DownloadOutlined />}></Button>
            </Dropdown>
          </div>
        }
      },
      {
        title: 'Eway Bill',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <div>
            {!record.bill_url ?
              record.bill_error_reason ?
                <Popover content={<div className="frz-m-15">
                  <div className="frz-p-2">
                    {record.bill_error_reason == null ? " " : record.bill_error_reason}
                  </div>
                </div>} trigger="hover">
                  <Button loading={loading && currentId === record.id} type="primary" onClick={() => handleOnRetry(record.id)}>
                    Retry
                  </Button>
                </Popover>
                :
                <Button
                  disabled={record.status !== "ready_to_dispatch" &&
                    record.status !== "dispatched" &&
                    record.status !== "delivered"}
                  loading={loading && currentId === record.id} type="primary" onClick={() => handleOnRetry(record.id)}>
                  Generate
                </Button>
              //       < Popover content={<div className="frz-m-15">
              //         <div>
              //           {record.bill_error_reason == null ? " " : record.bill_error_reason}
              //         </div>
              //         <Button loading={loading} type="primary" className={record.bill_error_reason === null ? "frz-tm-10 frz-bm-10" : "frz-tm-10 frz-bm-10"} onClick={() => handleOnRetry(record.id)}>
              //           {record.bill_error_reason === null ? "Generate" : "Retry"}
              //         </Button>
              //       </div>} trigger="hover">
              //   <InfoCircleOutlined />
              // </Popover>
              : null
            }
          </div>
        }
      },
      {
        title: 'E-Invoice',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <div>
            {!record.irn_invoice_url ?
              record.irn_error_reason ?
                <Popover content={<div className="frz-m-15">
                  <div className="frz-p-2">
                    {record.irn_error_reason == null ? " " : record.irn_error_reason}
                  </div>
                </div>} trigger="hover">
                  <Button loading={loading && currentId === record.id} type="primary" onClick={() => handleOnRetry(record.id)}>
                    Retry
                  </Button>
                </Popover>
                :
                <Button disabled={localStorage.getItem('procurementtype') != "fmcg" || record.status !== "ready_to_dispatch" &&
                  record.status !== "dispatched" &&
                  record.status !== "delivered"
                  && !record.irn_invoice_url} loading={irnLoading && currentId === record.id}
                  type="primary" onClick={() => handleOnRetry(record.id, true)}>
                  Generate IRN
                </Button>
              : null
            }
          </div>
        }
      },
      {
        title: 'Cancel STO',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Popconfirm
            title="Are you sure you want to cancel this sto?"
            onConfirm={() => cancelSTO(record.id)}
            okText="Yes"
            cancelText="No"
            placement="leftTop"
            disabled={record.status === "cancelled" || record.status === "delivered" || (record.source_node_type === 'CC' && !ccoperations) ||
              record.status === "dispatched" || (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}
          >
            <Button type="primary" ghost disabled={record.status === "cancelled" || record.status === "delivered" || record.status === "dispatched" || (record.source_node_type === 'CC' && !ccoperations) ||
              (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}>
              Cancel
            </Button>
          </Popconfirm>
        }
      })

    setColumnsProducts([...tempProdCol])
    setColumns([...tempCol])
  }

  function cancelSTO(id) {
    const payload = {
      id: id
    }
    setSpinEnable(true)
    setStoData([])
    ops_service.cancel_sto(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'STO Cancelled Successfully')
      getSto()
    },
      (err) => {
        console.log(err)
        getSto()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function showModal(data, type) {
    if ((localStorage.getItem('procurementtype') === null || localStorage.getItem('procurementtype') === '')) {
      openNotificationWithIcon('error', 'Please select a procurement type on top right')
      return
    }
    modalRef.current.showModal(type, data)
  }

  function showUpdateModal(data, type) {
    updateModalRef.current.showUpdateModal(type, data)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByNode: undefined, valueByDestination: undefined, valueByStatus: undefined, valueByProductName: undefined,
      valueByDate: undefined, date: '', valueByOrderNumber: undefined, by_shipment_id: undefined, by_sto_order_number: undefined
    })
    setStateFilter({ start_date: '', end_date: '', showDateValue: false, })
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function expandedRow(record) {
    return <>
      <Button type="primary" className="frz-bm-20" onClick={() => showModalProducts(record, 'Add Product')}
        disabled={!record.is_editable || (record.source_node_type === 'CC' && !ccoperations) ||
          (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}>
        Add Products
      </Button>
      <Card className="nested-table">
        <Table columns={columnsProducts} pagination={false} rowKey="id" dataSource={record.line_items} />
      </Card>
    </>
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
    setDisable(false)
    setCurrent(1)
  }

  function modalSubmit(payloadData) {
    const payload = {
      ...payloadData,
      created_by: JSON.parse(localStorage.getItem('auth')).id,
      procurement_type: localStorage.getItem('procurementtype')
    }
    ops_service.create_sto(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      getSto()
      modalRef.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function modalUpdate(payloadData, title, challanFile, challanB64, id) {
    if (title === "Update Inward") {
      const payload = { ...payloadData, inwarded_by: JSON.parse(localStorage.getItem('auth')).id }
      ops_service.update_inward(payload).subscribe((r) => {
        // if challan url comes only then hit the update api
        // if (challanB64) {
        const payloadChallan = {
          sto_id: id,
          signed_dc_original_filename: challanFile.name,
          signed_dc_image_base: challanB64,
          signed_dc_content_type: challanFile.type
        }
        upload_service.inward_challan_upload(payloadChallan).subscribe((r) => {
          //success
          openNotificationWithIcon('success', 'Created Successfully')
          getSto()
          // handleRemoveChallan()
          setChallanLoading(false)
          updateModalRef.current.handleCancel()
        }, ((err) => {
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          setChallanLoading(false)
          console.log("err", err)
        }))
        // }
        // else {
        //   //success
        //   openNotificationWithIcon('success', 'Created Successfully')
        //   getSto()
        //   // handleRemoveChallan()
        //   setChallanLoading(false)
        //   updateModalRef.current.handleCancel()
        // }
      },
        (err) => {
          console.log(err)
          updateModalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    } else if (title === "Update STO") {
      ops_service.update_sto(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getSto()
        updateModalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          updateModalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    }
  }

  function modalSubmitProduct(payloadData, id) {
    const payload = { ...payloadData, id: id }
    ops_service.sto_products(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getSto()
      modalRefProducts.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRefProducts.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function moveToNonCrate(id) {
    setSpinEnable(true)
    setStoData([])
    ops_service.move_to_nc(id).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getSto()
    },
      (err) => {
        console.log(err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  return (
    <div className="layer">
      {stoData && nodes && destinations && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} className="frz-w-200 frz-m-10" placeholder="Delivery Date" />

                <RangePicker onChange={onChangeRangePicker}
                  value={showDateValue ? [moment(start_date, dateFormat),
                  moment(end_date, dateFormat)] : ''} format={dateFormat}
                  allowClear={false} disabledDate={disabledDate} className="frz-m-10 frz-w-300px"
                  placeholder={["Created Start Date", "Created End Date"]} />

                <FrzDynamicSearch DropDownData={nodes}
                  placeholder="Select Source" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByNode')}
                  type="valueByNode" margin={10} defValue={valueByNode} />

                <FrzDynamicSearch DropDownData={destinations}
                  placeholder="Select Destination" value="id" option="name"
                  isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'valueByDestination')}
                  type="valueByDestination" margin={10} defValue={valueByDestination} />

                <FrzDynamicSearch DropDownData={status}
                  placeholder="Select Status" value="id" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByStatus')}
                  type="valueByStatus" margin={10} defValue={valueByStatus} />

                <InputNumber value={valueByOrderNumber} placeholder="Order Number" onChange={(e) => onChange(e, 'valueByOrderNumber')}
                  style={{ width: 180, margin: 10 }} />

                <Input value={valueByProductName} placeholder="Product Name" onChange={(e) => onChange(e.target.value, 'valueByProductName')}
                  style={{ width: 180, margin: 10 }} />

                {/* <InputNumber value={valueByOrderNumber} placeholder="Shipment id" onChange={(e) => onChange(e, 'by_shipment_id')}
                  style={{ width: 180, margin: 10 }} />

                <InputNumber value={valueByOrderNumber} placeholder="STO order number" onChange={(e) => onChange(e, 'by_sto_order_number')}
                  style={{ width: 180, margin: 10 }} /> */}

                <Button disabled={disable} onClick={getSto}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create STO')} className="frz-w-100px frz-m-10" type="primary">
                  Create STO
                </Button>
                <div className="frz-dis-upload dynamic-upload">
                  <Button onClick={() => handleStoModal(true)} className="frz-w-100px frz-m-10" type="primary">
                    Upload STO
                  </Button>

                  <Button onClick={() => setUpdateStoModal(true)} className="frz-w-100px frz-m-10" type="primary">
                    Update STO
                  </Button>
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Order Sheet'} fileName={'Order Sheet'} url={'admin_dashboard/procurement/sto_report'}
                    payload={'?by_source=' + `${valueByNode ?? ''}`
                      + '&start_date=' + `${start_date ?? ''}` + '&end_date=' + `${end_date ?? ''}`
                      + '&by_destination=' + `${valueByDestination ?? ''}`
                      + '&by_status=' + `${valueByStatus ?? ''}` + '&transfer_date=' + `${valueByDate ?? ''}`
                      + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&order_sheet=true'}
                    type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  <FrzDynamicDownload name={'STO'} fileName={'STO'} url={'admin_dashboard/procurement/sto_report'}
                    payload={'?by_source=' + `${valueByNode ?? ''}` + '&by_destination=' + `${valueByDestination ?? ''}`
                      + '&start_date=' + `${start_date ?? ''}` + '&end_date=' + `${end_date ?? ''}`
                      + '&by_status=' + `${valueByStatus ?? ''}` + '&transfer_date=' + `${valueByDate ?? ''}`
                      + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&operations=true'}
                    type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  <FrzDynamicDownload name={'Shortfall'} fileName={'Shortfall'} url={`admin_dashboard/procurement/shortfall_report`}
                    payload={'?date=' + `${valueByDate ?? ''}` + '&start_date=' + `${start_date ?? ''}` + '&end_date=' + `${end_date ?? ''}`}
                    type={'job'}
                    reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                </span>
              </Col>
            </Card>
          </Row>
          {stoData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={stoData} columns={columns} expandedRow={expandedRow} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CreateStoModal modalSubmit={modalSubmit} ref={modalRef} nodes={nodes} destinations={destinations}
            products={products} />
          <UpdateSTOModal modalUpdate={modalUpdate} ref={updateModalRef} challanLoading={challanLoading} />
          <AddProductModalSTO modalSubmit={modalSubmitProduct} ref={modalRefProducts} products={products} />
          {updateStoModal ?
            <ModalUpdateSTO updateStoModal={updateStoModal} setUpdateStoModal={(value) => setUpdateStoModal(value)} getSto={getSto} />
            : null}
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
      {uploadStoModal ? <ModalUploadSto uploadStoModal={uploadStoModal} handleStoModal={handleStoModal} getSto={getSto} /> : null}
    </div >
  );
}

export default STOList