import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form, InputNumber, Select, Row, Col, Button, Spin } from 'antd';
import { openNotificationWithIcon } from "../../../_modules/notification";
import FRZSelect from "../../../_controls/FRZSelect";
import FRZInputNumber from "../../../_controls/FRZInputNumber";
import { OperationsService } from "../../../_services/operations";
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import FRZPassword from '../../../_controls/FRZPassword';

const ops_service = new OperationsService()
const { Option } = Select;

const CreateNodeUserModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [isSelected, setIsSelected] = useState([]);
  const [isClose, setIsClose] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsSelected([])
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsSelected([])
    setIsClose(true)
  }


  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values)
  }


  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'60%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {

              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            <FRZSelect DropDownData={props.darkstore} name="node_id" isRequired={true}
              label="Select Node" placeholder="Select Node" value="id" option="name"
              isShowSearch={true} width={400} />

            <FRZInput isRequired={true} label="First Name" name="first_name" width={200} />

            <FRZInput isRequired={true} label="Last Name" name="last_name" width={200} />

            <FRZInput name="email" isRequired={true}
              label="Email" width={200} ofType="email" />

            <FRZInputNumber isRequired={true} label="Mobile No" name="mobile_number"
              width={200} />

            <FRZPassword isRequired={true} label="Password" name="password" type="password" width={200} />

          </Form>

        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})
export default CreateNodeUserModal