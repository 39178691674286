import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Select, Button, Popconfirm } from 'antd';
import TableHeaders from './table_headers';
import TableList from './table_list';
import {openNotificationWithIcon} from '../../../../../_modules/notification';

const { Option } = Select;

const AdhocWastageModal = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [tableData, setTableData] = useState([])
  const [enableError, setEnableError] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

  }));

  function handleOnSubmit(values) {
    if(tableData.find(x => x.wastage_quantity === null)){
      openNotificationWithIcon('error', 'Please fill wastage qty for all skus')
      setEnableError(true)
      return
    }
    console.log(tableData)
    if(tableData.find(x => x.wastage_quantity > 0 && x.reason_id === null)){
      openNotificationWithIcon('error', 'Please select reasons')
      setEnableError(true)
      return
    }
    setButtonLoading(true)
    var items = []
    tableData.map(data => {
      items.push({
        product_id: data.id,
        wastage_quantity: data.wastage_quantity,
        reason_id: data.reason_id
      })
    })
    const payload = {
      node_code: props.currentNode,
      items: items
    }
    props.wc_service.create_adhoc_wastage(payload).subscribe((r) => {
      handleCancelModal()
      openNotificationWithIcon('success', 'Adhoc Wastage Submitted Successfully')
      props.getWastageData()
    },
    (err)=>{
      setButtonLoading(false)
      if(err?.response?.errors.length > 0){
        setEnableError(true)
        openNotificationWithIcon('error', 'Please check wastage qty for all skus')
        return err?.response?.errors.map(data =>{
          if(tableData.find(x => x.id === data.product_id)){
            tableData.find(x => x.id === data.product_id).errors = data.message
            setTableData([...tableData])
          }
        })
      }else if(err?.response?.message.length > 0){
        openNotificationWithIcon('error', err?.response?.message?.[0] ?? 'API Error')
      }else{
        openNotificationWithIcon('error', 'API Error')
      }
      console.log(err)
    })
  }

  function handleCancelModal() {
    setVisible(false)
    setData(false)
    setTableData([])
    setEnableError(false)
    setButtonLoading(false)
    form.resetFields()
  }

  function onSelectSKUs(e, option){
    let tableD = []
    option.map(val => {
      val.data = {...val.data, wastage_quantity: null, errors: null}
      tableD.push(val.data)
    })
    setTableData(tableD)
  }

  function onChangeWastage(e, item, type){
    if(type === 'wastage'){
      tableData.find(x => x.id === item.id).wastage_quantity = e
      tableData.find(x => x.id === item.id).errors = null
      if(e === 0){
        tableData.find(x => x.id === item.id).reason_id = null
      }
    }else{
      tableData.find(x => x.id === item.id).reason_id = e
    }
    setTableData([...tableData])
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          centered
          width={'70%'}
          onCancel={handleCancelModal}
          className="order fl-right"
          footer={[
            <Button key="back" onClick={handleCancelModal} disabled={buttonLoading}>
              Cancel
            </Button>,
            <Popconfirm
              title="Are you sure you want to submit this & the quantities entered are correct?"
              onConfirm={() => {
                form
                  .validateFields()
                  .then((values) => {
                    handleOnSubmit(values)
                  })
                  .catch(info => {
                    // console.log('Validate Failed:', info);
                  });
              }}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
            >
              <Button type="primary" loading={buttonLoading} key="submit">
                Submit
              </Button>
            </Popconfirm>
          ]}>
            <div className="wcl-modal-container">
              <Form
                form={form}
                layout="vertical"
                name="form">

                <Form.Item
                  label="Add SKU's"
                  name="sku_id"
                  rules={[{ required: true, message: "Please add SKU's" }]}
                >
                  <Select mode="multiple" placeholder="Select SKU's"
                    showSearch optionFilterProp="children" disabled={buttonLoading}
                    onChange={(e, option) => onSelectSKUs(e, option)}
                    className='add-sku-select'
                    filterOption={(input, option) => 
                      option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {data.map((val, index) => {
                      return(
                        <Option key={index} value={val.id} data={val} name={val.name}>
                          <img src={val.image_url} width={30} height={30} className='select-dropdown-img'/>{val.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                {tableData.length > 0 ?
                  <>
                    <TableHeaders />
                    <TableList data={tableData} enableError={enableError} reasons={props.reasons}
                      onChangeWastage={onChangeWastage} buttonLoading={buttonLoading} />
                  </>
                : null
                }
              </Form>
            </div>
        </Modal>
      : 
        <Form
          form={form}
          layout="vertical"
          name="form">
        </Form>
      }
    </div>
  )
})

export default AdhocWastageModal
