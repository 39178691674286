import React from 'react'
import Lottie from 'react-lottie'
import ErrorAnimation from '../../static/json/404-error-page.json';
const NotFound = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: ErrorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isClickToPauseDisabled={true} />
      <div>
        <h1 style={{textAlign:"center", fontSize:22}}>You are not authorized to access this page.<br/>
        Please contact your administrator.</h1> <br />
      </div>
    </div>
  );
}
export default NotFound
