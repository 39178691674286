import React from 'react'
export const cultivationColumns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    // {
    //   title: 'Consumer Name',
    //   dataIndex: 'consumer_name',
    //   key: 'consumer_name',
    //   align: 'center'
    // },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      align: 'center',
      render: (record) => {
        return <img src={record} className="cs-w-100p"/>
      }
    },
    {
      title: 'Sowing Date',
      dataIndex: 'sowing_date',
      key: 'sowing_date',
      align: 'center'
    },
    {
      title: 'Cultivation Land',
      dataIndex: 'cultivation_land',
      key: 'cultivation_land',
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center'
    },
]

export const familyColumns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      align: 'center'
    },
    {
      title: 'Relation',
      dataIndex: 'relation',
      key: 'relation',
      align: 'center'
    },
    {
      title: 'Dob',
      dataIndex: 'dob',
      key: 'dob',
      align: 'center'
    },
    {
      title: 'Qualification',
      dataIndex: 'qualification',
      key: 'qualification',
      align: 'center'
    },
]

export const documentsColumns = [
    {
      title: 'Document Type',
      dataIndex: 'document_type',
      key: 'document_type',
      align: 'center'
    },
]

export const bankColumns = [
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
      align: 'center'
    },
    {
      title: 'Bank Account No',
      dataIndex: 'bank_account_no',
      key: 'bank_account_no',
      align: 'center'
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifsc',
      key: 'ifsc',
      align: 'center'
    },
    {
      title: 'Beneficiary Name',
      dataIndex: 'beneficiary_name',
      key: 'beneficiary_name',
      align: 'center'
    },
    {
      title: 'Is Verified',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return record.is_verified.toString()
      }
    },
]