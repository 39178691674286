import FilterTab from "../../common"
import React,{ useState, useEffect} from "react"
import StatusTab from "../../common/StatusTab"
import ManualTable from "../../common/ManualTable"
import { Pagination, Spin } from "antd"

const StoInward=({setSto_id,setScreen,setSpinEnable,spinEnable,showCreate,setShowCreate})=>{
    const [node,setNode]=useState(false)
    const [stoData, setStoData]=useState(false)
    const [current,setCurrent]=useState(1)
    const [apiError, setApiError]=useState(false)
    function pagination(page) {
      setCurrent(page);
    }
    return(
        <>
        <FilterTab node={node} page={current} setApiError={setApiError} setPage={setCurrent} setNode={setNode} stoData={stoData} setStoData={setStoData} curr={'sto_inward'} setSpinEnable={setSpinEnable}  />
        { stoData?.data ?
        <>
          <div>{stoData.data.length> 0 ?<StatusTab data={stoData?.meta?.status_wise_counts} />:null}</div>
          {stoData?.data?.length  ?
              <div className="sto-out-table-data"> 
                  <ManualTable showCreate={showCreate} setShowCreate={setShowCreate} setSto_id={setSto_id} setScreen={setScreen} currTab={'sto_inward'} data={stoData.data}/>
                  <Pagination current={current} total={stoData.meta.total_pages * 20} onChange={pagination} pageSize={20} showSizeChanger={false}/>
              </div>
                  :
                  (stoData?.data?.length===0 ?<div className="no-data">No Data Available On Selected Filters</div>:null)}
        </>:(apiError  ? <div className="no-data">
            <b>Backend API System Down</b>
          </div>:(spinEnable ?<div className="spin-center"><Spin tip="...Loading" /></div>:<div className="no-data">Please Search Filters to Get Data</div>))}
        </>
    )
}
export default StoInward