import { Http } from '../_modules/http'

export class OperationsService {

  http = new Http()

  get_supplier(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/suppliers?by_name='
      + `${payload.valueByName}` + "&page=" + `${payload.page}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_supplier(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/suppliers";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  update_supplier_bank(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/supplier_bank_details";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_map_supplier() {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/map_suppliers?'
      + 'by_city=' + `${localStorage.getItem('city') ?? ''}` + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_destination_node(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/list_node?by_node_type=' + payload.nodeType
      + '&show_all=' + `${payload?.show_all ?? ''}`
      + '&by_city=' + payload.by_city
      + '&admin_id=' + `${payload?.no_admin ? '' : JSON.parse(localStorage.getItem('auth')).id}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_node_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/list_node?by_node_type=' + payload.nodeType
      + '&show_all=' + `${payload?.show_all ?? ''}`
      + '&by_state=' + `${payload?.by_state ?? ''}`
      + '&by_city=' + `${payload?.no_city ?? localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + `${payload?.no_admin ? '' : JSON.parse(localStorage.getItem('auth')).id}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_partial_wastage(payload) {
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/stock_losses/create_partial_wastage'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }
  add_node_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/nodes'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }
  add_crate_type(payload) {
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/stock_losses'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }
  edit_node_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/nodes'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_admin_cc() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_cc_admins?'
      + 'by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_list() {
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/index_products?'
      + 'by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/procurement_forecasting?page='
      + payload.page + '&date=' + payload.date + '&by_name=' + payload.valueByName
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_forcast_new_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement_v2/po_forecasting?page='
      + payload.page + '&date=' + payload.date + '&by_name=' + payload.valueByName
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/procurement_forecasting";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  create_new_forcast_listening(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement_v2/po_forecasting";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  update_new_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement_v2/po_forecasting";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  update_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/procurement_forecasting";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_po_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/purchase_orders?page=" + payload.page
      + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
      + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number
      + '&by_status=' + payload.by_status + '&admin_id=' + payload.admin_id + '&by_product=' + payload.by_product
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_suppliers(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/get_all_suppliers?"
      + 'by_city=' + `${payload?.no_city ?? localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_po(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/purchase_orders"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  cancel_po(id) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/cancel_purchase_order?id=" + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, {}, headers);
  }

  po_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/create_po_line_item"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_grn_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/grns?page=" + payload.page
      + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
      + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number
      + '&by_status=' + payload.by_status + '&admin_id=' + payload.admin_id + '&by_product=' + payload.by_product
      + '&due_date=' + payload.due_date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  allow_edit_grn(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/update_edit"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  grn_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/grns"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  create_payments(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_payment_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments?page=" + payload.page
      + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
      + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number
      + '&by_status=' + payload.by_status + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  update_payments(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_users_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/get_users_list?page=" + payload.page
      + '&email=' + payload.email
      + '&node_id=' + payload.node_id
      + '&mobile_number=' + payload.mobile_number
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_user(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/create_user"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_sto_list(payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stos?page=" + payload.page
      + '&by_source=' + payload.by_source + '&sto_type=' + payload.sto_type
      + '&by_destination=' + payload.by_destination
      + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date + "&by_order_number=" + payload.by_order_number
      + '&by_source_city=' + payload.by_source_city
      + '&inter_city=' + payload.inter_city
      + '&by_destination_city=' + payload.by_destination_city
      + '&by_procurement_type=' + payload.by_procurement_type
      + '&date_type=' + payload.date_type
      + '&by_status=' + payload.by_status
      + '&by_product=' + payload.by_product
      + '&by_source_node_type=' + payload.by_source_node_type
      + '&by_destination_node_type=' + payload.by_destination_node_type
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_sto(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/stos"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  update_sto(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/stos'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }
  get_sto_edit_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/stos/' + payload
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
  cancel_sto(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/cancel_sto'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  update_inward(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/inward_receiving'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_cc_wh_inventory(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_current_stock?node_id="
      + payload.nodeId + "&by_name=" + payload.valueByName + "&page=" + payload.page
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}` + '&stock_type=' + payload.invType
      + "&by_category_code=" + payload.valueByCategory + "&by_sub_category_code=" + payload.valueBySubCategory
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_sto_shipment_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/non_transit_sto?source_node_code=" + payload.source_node_code +
      "&transfer_date=" + payload.transfer_date + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  update_sto_shipment_sequence(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/assign_transit"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  upload_bills(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/upload_procurement_bill"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_po_payment_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/order_po_payment?id=" + payload.id
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_sto_skus(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_sto_products?node_id=" + payload.id
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + (payload.procurement_type ? payload.procurement_type : `${localStorage.getItem('procurementtype')}`)
      + '&by_name=' + (payload.by_name ? payload.by_name : '')
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
  get_sku_list(payload) {
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/products/sku_list?by_name=' + payload.by_name
      + '&by_procurement_type=' + (payload.procurement_type ? payload.procurement_type : `${localStorage.getItem('procurementtype')}`)
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  sto_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/add_sto_product"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }


  get_supplier_category() {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/supplier_category_parameter_list"
      + '?by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_crate_types(payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/index_products?by_procurement_type=fnv&by_name=&by_category_code=CRA1&by_sub_category_code=&by_city=" + "&admin_id=" + JSON.parse(localStorage.getItem('auth')).id + "&node_id=" + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
  get_po_line_items(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_po_items?id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_grn_line_items(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_grn_items?id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_transit_buckets(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/sto_transit?source_node_id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  put_veichle_details(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/assign_transit_driver"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_crate_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_sto_products?node_id=" + payload.node_id + "&by_city=" + `${localStorage.getItem('city')}` + "&admin_id=" + `${JSON.parse(localStorage.getItem('auth')).id}` + "&by_procurement_type=" + `${localStorage.getItem('procurementtype')}` + "&by_crate=true"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
  get_current_sto(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/get_current_sto?transit_id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_line_items_updates(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/get_line_item_updates?sto_line_item_id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  list_sto_out(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_sto_out?sto_id=" + payload.id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  move_to_nc(id) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/non_crate_sto?id=" + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, {}, headers);
  }

  get_po_products(id) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_products?purchase_order_id=" + id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
  get_all_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/get_all_products?node_id=" + payload.valueByNode
      + '&loss_type=' + payload.lossType
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_Stock_loss_Approvals(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/stock_loss_approvals";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }

  sto_retry_ewaybill(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + `admin_dashboard/procurement/retry_eway_bill?sto_id=${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  get_eway_bill_pdf(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + `admin_dashboard/procurement/eway_bill?sto_id=${payload.id}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_work_bench(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + `admin_dashboard/product_node_stats?by_date=${payload.date}&by_city=${payload.city}&node_code=${payload.darkstore}&by_sku=${payload.sku}&page=${payload.page}`
      + "&fnv_category=" + payload.fnv + "&in_stock=" + payload.stock + "&edited_orders=" + payload.edited + "&active_products=" + payload.activeProduct +
      "&active_node_products=" + payload.activeNodeProduct + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_approval_setting(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/approval_dashboard/request_types?node_id=${payload.nodeId}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_admin_list(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/delivery_admin/admins?all=true&is_verified=true'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  update_approval_settings(payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/approval_dashboard/request_types/" + payload.id;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_pending_approval_status(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/approval_dashboard/requests/statuses`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_pending_approval_detail(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/approval_dashboard/requests?by_city=${localStorage.getItem('city') ?? ''}&by_requester=${payload.by_requester}&by_status=${payload.status}`
      + '&by_node=' + payload.by_node + "&page=" + payload.page;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  reject_pending_req(payload) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/approval_dashboard/requests/approve";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  getProductCategories() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_category_list";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  getProductSubCategories() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_sub_categories";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_shipment_listing(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/transits?status=" + payload.status + "&expected_delivery_date=" + payload.valueByDate
      + "&city_code=" + `${localStorage.getItem('city') ?? ''}` + "&source_node_id=" + payload.source_node_id + "&page=" + payload.current
      + '&by_sto_order_number=' + payload.by_sto_order_number
      + '&by_shipment_id=' + payload.by_shipment_id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_shipment_table_listing(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits/sto_list?source_node_id=${payload.node_id}&transfer_date=${payload.transfer_date}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_pending_approval_table_data(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/approval_dashboard/requests/child?request_id=${payload.id}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  create_complete_sto(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }

  get_shipment_draf_data(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits/${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  update_complete_sto(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits/${payload.transit.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  dispatch_sto(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits/dispatch?id=${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  sto_irn_report(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + `admin_dashboard/procurement/irn_invoice?sto_id=${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  cancel_shipment_data(payload) {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transits/${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload.body, headers);
  }

  get_shipment_vehicle_data() {
    const pathUrl = process.env.REACT_APP_AWS3_URL + `/v2/admin_dashboard_v2/transport_providers`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_inventory_transactions_data(payload) {
    const url = process.env.REACT_APP_AWS_URL + "/admin_dashboard/inventory_transactions?start_date="
      + payload.start_date + "&end_date=" + payload.end_date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&by_product_id=' + payload.by_product_id + '&by_sku_code=' + payload.by_sku_code + '&by_node=' + payload.by_node + '&node_code=' + payload.node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_alloc_prod_list(payload) {
    // {{rails-new}}v2/admin_dashboard_v2/stos/allocation_products_list/KC01
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stos/allocation_products_list/" + payload.node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  alloc_prod_report_detail(payload) {
    // {{rails-new}}v2/admin_dashboard_v2/stos/allocation_logs/3010
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stos/allocation_logs/" + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
}

