

import React, { useState, useEffect } from 'react'
import { Modal, Table, Spin, Button, Col, Row, Progress, Popover } from 'antd';
import { CrateService } from '../../../_services/crate';
import { crateDetailColumns } from '../../antd/columns/operations/crate';
import AddCrateType from './add_crate_type';
import { EditOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../_modules/notification';

const crate_service = new CrateService()

const ModalCrateDetail = ({ crateDetailModal, handleCrateModal, nodeCode, rowData }) => {
  const [listingData, setListingData] = useState(false);
  const [crateData, setCrateData] = useState(false);
  const [column, setColumn] = useState(false);

  useEffect(() => {
    getTransitDetails()
    getColumns()
  }, [])

  const getTransitDetails = () => {
    const payload = {
      node_code: nodeCode
    }
    crate_service.get_crate_details(payload).subscribe((r) => {
      setListingData(r.response.data)
      setCrateData(r.response.crates_count)
    }, ((err) => {
      console.log("err", err);
    }))
  }

  const getColumns = () => {
    let tempCol = []
    crateDetailColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (record) => {
        return (
          <div className="frz-dis-flex frz-hrz-center">
            <div className={record === "AV" ? "frz-crate-detail-status-av" : "frz-crate-detail-status-intran"}>
              {record === "AV" ? "Available" : "InTransit"}
            </div>
          </div>
        )
      }
    },
      {
        title: 'State',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center',
        render: (record, data) => {
          return (
            !record ?
              <div className="frz-dis-flex frz-hrz-center">
                <div className={record ? "frz-crate-detail-state-occ" : "frz-crate-detail-state-empty"}>
                  {record ? "Occupied" : "Empty"}
                </div>
              </div>
              :

              <Popover placement="bottom" content={() => <div style={{ padding: 12 }}>
                <div className="frz-label">
                  Occupied with:
                </div>
                <span className="frz-crate-colm">
                  {data.product_name}
                </span>
                <span className="frz-lp-5">
                  -
                </span>
                <span className="frz-crate-colm frz-lp-5">
                  {data.weight}
                </span>
                <span className="frz-crate-colm">
                  {data.unit}
                </span>
              </div>}>
                <div className="frz-dis-flex frz-hrz-center">
                  <div className={record ? "frz-crate-detail-state-occ" : "frz-crate-detail-state-empty"}>
                    {record ? "Occupied" : "Empty"}
                  </div>
                </div>
              </Popover>
          )
        }
      })
    setColumn(tempCol)
  }

  return (
    <div>
      <Modal
        visible={crateDetailModal}
        title={rowData ? rowData.node_name : "Crate Detail"}
        onCancel={() => handleCrateModal(false)}
        width='85%'
        className="order fl-right"
        footer={[
          <Button key="back" onClick={() => handleCrateModal(false)}>
            Close
          </Button>
        ]}>
        <div className="order-modal-container">
          {listingData && crateData ?
            listingData.length ?
              <div>
                <div className="frz-bp-20">
                  <Row gutter={[10, 10]}>
                    <Col span={7}>
                      <div className="frz-active-crates-card">
                        <Row>
                          <Col span={10}>
                            <div className="frz-crate-colm-sm">
                              Active Crates
                            </div>
                            <div className="frz-crate-colm">
                              {crateData.total_av_crates + crateData.total_bwp_crates}
                            </div>
                          </Col>
                          <Col span={14}>
                            <div>
                              <Progress showInfo={false} percent={100 * (crateData.total_bwp_crates) / (crateData.total_av_crates + crateData.total_bwp_crates)} status="active" />
                              <div className="frz-dis-flex">
                                <div className="frz-dis-flex frz-rm-10">
                                  <div className="frz-label">
                                    Occupied:
                                  </div>
                                  <div className="frz-lm-5 frz-fw-800">
                                    {crateData.total_bwp_crates}
                                  </div>
                                </div>

                                <div className="frz-dis-flex">
                                  <div className="frz-label">
                                    Empty:
                                  </div>
                                  <div className="frz-lm-5 frz-fw-800">
                                    {crateData.total_av_crates}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={7}>
                      <div className="frz-active-crates-card">
                        <Row>
                          <Col span={10}>
                            <div className="frz-crate-colm-sm">
                              In Transit
                            </div>
                            <div className="frz-crate-colm">
                              {crateData.total_bt_crates + crateData.total_bwpt_crates}
                            </div>
                          </Col>
                          <Col span={14}>
                            <div>
                              <Progress className="frz-intransit-color" showInfo={false} percent={100 * (crateData.total_bwpt_crates) / (crateData.total_bwpt_crates + crateData.total_bt_crates)} status="active" />
                              <div className="frz-dis-flex">
                                <div className="frz-dis-flex frz-rm-10">
                                  <div className="frz-label">
                                    Occupied:
                                  </div>
                                  <div className="frz-lm-5 frz-fw-800">
                                    {crateData.total_bwpt_crates}
                                  </div>
                                </div>

                                <div className="frz-dis-flex">
                                  <div className="frz-label">
                                    Empty:
                                  </div>
                                  <div className="frz-lm-5 frz-fw-800">
                                    {crateData.total_bt_crates}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={3}>
                      <div className="frz-inactive-crates-card">
                        <Row>
                          <Col span={24}>
                            <div className="frz-label">
                              Inactive Crates
                            </div>
                            <div className="frz-crate-colm">
                              {crateData.total_number_of_crates - crateData.total_bwpt_crates - crateData.total_bwp_crates - crateData.total_bt_crates - crateData.total_av_crates}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={7}>
                    </Col>

                  </Row>
                </div>
                <Table dataSource={listingData} columns={column}
                  pagination={false} scroll={{ y: 'calc(100vh - 274px)' }}
                  rowKey={'node_code'} />
              </div>
              :
              <div className="no-data">No Data Available</div>
            :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>}
        </div>

      </Modal>
    </div>

  )
}

export default ModalCrateDetail