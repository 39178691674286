import { Http } from '../_modules/http'

export class GeneralService {

  http = new Http()

  get_darkstore(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/darkstores?by_city=' 
                + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_city(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_cities'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }
  get_state(){
      const url = process.env.REACT_APP_AWS3_URL + '/api/v1/admin/states'
      let headers = {"Access-Token": process.env.REACT_APP_AWS_TOKEN, 'Content-Type': 'application/json'}
      return this.http.get(url, headers)
  }

}
