import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col } from 'antd';
// import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
// import { openNotificationWithIcon } from '../../../_modules/notification';
import TransitCard from '../../../components/operations/management/index';

const ops_service = new OperationsService()

function Management() {
  const [transitBucketsData, setTransitBucketsData] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [state, setState] = useState({
    valueByNode: undefined
  });
  const [cleanUp, setCleanUp] = useState(false)
  const [timeIntervalId, setTimeIntervalId] = useState(false)
  const { valueByNode } = state
  const [reSpin, setReSpin] = useState(false)


  // useEffect(() => {
  //   if (!renderData) {
  //     setRenderData(true)
  //     getSto()
  //   }
  // })

  useEffect(() => {
    getNodes()
  }, [])

  useEffect(() => {
    let intervalId;
    if (valueByNode && !cleanUp) {
      intervalId = setInterval(() => {
        getTransitBucketsData(valueByNode, true)
      }, 30000);
      setTimeIntervalId(intervalId)
    }
  }, [valueByNode, cleanUp])

  useEffect(() => {
    if (timeIntervalId || cleanUp) {
      return (() => {
        console.log("cleaning up")
        clearInterval(timeIntervalId)
      })
    }
  }, [timeIntervalId, cleanUp])


  const getTransitBucketsData = (nodeid, isInterval, shouldSpin) => {
    if (!isInterval) {
      setSpinEnable(true)
    }
    if (shouldSpin) {
      setReSpin(shouldSpin)
    }
    const payload = {
      id: nodeid
    }

    ops_service.get_transit_buckets(payload).subscribe((r) => {
      setTransitBucketsData(r.response.data)
      setSpinEnable(false)
      setReSpin(false)
    }, ((err) => {
      setSpinEnable(false)
      setReSpin(false)
      console.log("err", err)
    }))
  }



  function getNodes() {
    const payload = {
      nodeType: ''
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  const handleCleanUp = (value) => {
    setCleanUp(value)
  }

  function onChange(value, type) {
    clearInterval(timeIntervalId)
    setState({ [type]: value })
    getTransitBucketsData(value)
    // setReSpin(true)
    setDisable(false)
    setCurrent(1)
  }

  return (
    <div className="layer-transit">
      {nodes && !reSpin ?
        <div>
          {/* <Row>
            <Card className="frz-dis-flex frz-hrz-center">
              <Col span={24}>
                <FrzDynamicSearch DropDownData={nodes}
                  placeholder="Select Source" value="id" option="name"
                  isShowSearch={true} width={600} setOnChange={(e) => onChange(e, 'valueByNode')}
                  type="valueByNode" margin={10} defValue={valueByNode} />
              </Col>
            </Card>
          </Row> */}
          <Row>
            <TransitCard transitBucketsData={transitBucketsData}
              getTransitBucketsData={getTransitBucketsData} nodeID={valueByNode}
              spinEnable={spinEnable} disable={disable} onChange={onChange} valueByNode={valueByNode} nodes={nodes}
              handleCleanUp={handleCleanUp} />
          </Row>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default Management