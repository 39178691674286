import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";
import { Tabs, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ListingHeader from './listing_header';
import ListingTable from './listing_table';
import CreateWCButtons from './create_wc_btn';
import {openNotificationWithIcon} from '../../../_modules/notification';
import greentick from '../../../static/images/green-tick.svg';
import AddSkuModal from "./add_sku";

const { TabPane } = Tabs;

const WCList = forwardRef((props, ref) =>{
  const modalRef = useRef(null);
  const uploadRef = useRef(null)
  const [tableData, setTableData] = useState(props.data)
  const [enableError, setEnableError] = useState(false)
  const [selectedKey, setSelectedKey] = useState(Object.keys(props.data)[0])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [searchProduct, setSearchProduct] = useState(null)
  const [validated, setValidated] = useState([])
  const [enabledTabs, setEnabledTabs] = useState([Object.keys(props.data)[0]])
  const [touchedCategory, setTouchedCategory] = useState([])
  const [selectedSkus, setSelectedSkus] = useState([])
  const [selectedSkusTable, setSelectedSkusTable] = useState([])
  const [selectedKeyIndex, setSelectedKeyIndex] = 
    useState(Object.keys(props.data).findIndex(x => x === Object.keys(props.data)[0]))

  function filterData(val){
    setSearchProduct(val?.toLowerCase() ?? null)
  }

  function onChangeWC(e, item, keyname){
    if(keyname === 'addsku'){
      if(props.wcScreen === 'create_wastage'){
        selectedSkusTable.find(x => x.id === item.id).wastage_quantity = e
      }else{
        selectedSkusTable.find(x => x.id === item.id).closing_quantity = e
      }
      selectedSkusTable.find(x => x.id === item.id).errors = null
      setSelectedSkusTable([...selectedSkusTable])
    }else{
      if(!touchedCategory.includes(keyname)){
        touchedCategory.push(keyname)
        setTouchedCategory([...touchedCategory])
      }
      if(props.wcScreen === 'create_wastage'){
        tableData[keyname].find(x => x.id === item.id).wastage_quantity = e
      }else{
        tableData[keyname].find(x => x.id === item.id).closing_quantity = e
      }
      tableData[keyname].find(x => x.id === item.id).errors = null
      setTableData({...tableData})
    }
  }

  function onChangeWCReason(e, item, keyname){
    if(keyname === 'addsku'){
      selectedSkusTable.find(x => x.id === item.id).reason_id = e
      setSelectedSkusTable([...selectedSkusTable])
    }else{
      if(!touchedCategory.includes(keyname)){
        touchedCategory.push(keyname)
        setTouchedCategory([...touchedCategory])
      }
      tableData[keyname].find(x => x.id === item.id).reason_id = e
      setTableData({...tableData})
    }
  }

  function onChangeTab(keyname){
    filterData(null)
    if(validated.includes(selectedKey) && touchedCategory.includes(selectedKey)){
      validateWC(keyname)
    }else{
      setSelectedKey(keyname)
      setSelectedKeyIndex(Object.keys(props.data).findIndex(x => x === keyname))
    }
  }

  function validateWC(keyname){
    filterData(null)
    if(props.wcScreen === 'create_wastage'){
      if(tableData[selectedKey].find(x => x.wastage_quantity === null)){
        openNotificationWithIcon('error', 'Please fill wastage qty for all skus')
        setEnableError(true)
      }else if(tableData[selectedKey].find(x => x.wastage_quantity > 0 && x.reason_id === null)){
        openNotificationWithIcon('error', 'Please select reasons')
        setEnableError(true)
      }else{
        setButtonLoading(true)
        var items = []
        tableData[selectedKey].map(data => {
          items.push({
            product_id: data.id,
            wastage_quantity: data.wastage_quantity,
            reason_id: data.reason_id
          })
        })
        const payload = {
          node_code: props.currentNode,
          items: items
        }
        props.wc_service.validate_wastage(payload).subscribe((r) => {
          setEnableError(false)
          setButtonLoading(false)
          updateTabStatus(keyname)
          openNotificationWithIcon('success', 'Wastage data validated for ' + selectedKey)
        },
        (err)=>{
          setButtonLoading(false)
          if(err?.response?.errors.length>0){
            setEnableError(true)
            openNotificationWithIcon('error', 'Please check wastage qty for all skus')
            return err?.response?.errors.map(data =>{
              tableData[selectedKey].find(x => x.id === data.product_id).errors = data.message
              setTableData({...tableData})
            })
          }else{
            openNotificationWithIcon('error', 'API Error')
          }
          console.log(err)
        })
      }
    }else{
      if(tableData[selectedKey].find(x => x.closing_quantity === null)){
        openNotificationWithIcon('error', 'Please fill closing qty for all skus')
        setEnableError(true)
      }else{
        setButtonLoading(true)
        var items = []
        tableData[selectedKey].map(data => {
          items.push({
            product_id: data.id,
            closing_quantity: data.closing_quantity,
          })
        })
        const payload = {
          node_code: props.currentNode,
          items: items,
          product_category: props.currentMainTab
        }
        props.wc_service.validate_closing(payload).subscribe((r) => {
          setEnableError(false)
          setButtonLoading(false)
          updateTabStatus(keyname)
          openNotificationWithIcon('success', 'Closing data validated for ' + selectedKey)
        },
        (err)=>{
          setButtonLoading(false)
          if(err?.response?.errors.length>0){
            setEnableError(true)
            openNotificationWithIcon('error', 'Please check closing qty for all skus')
            return err?.response?.errors.map(data =>{
              tableData[selectedKey].find(x => x.id === data.product_id).errors = data.message
              setTableData({...tableData})
            })
          }else{
            openNotificationWithIcon('error', 'API Error')
          }
          console.log(err)
        })
      }
    }
  }

  function updateTabStatus(keyname){
    setTouchedCategory([])
    if(Object.keys(props.data).length > selectedKeyIndex){
      if(keyname){
        setSelectedKey(keyname)
        setSelectedKeyIndex(Object.keys(props.data).findIndex(x => x === keyname))
      }else{
        if(!validated.includes(selectedKey)){
          validated.push(selectedKey)
          setValidated([...validated])
        }
        if(Object.keys(props.data).length - 1 === selectedKeyIndex && selectedSkusTable.length > 0){
          setSelectedKey('addsku')
          setSelectedKeyIndex(null)
        }else if(Object.keys(props.data).length - 1 > selectedKeyIndex){
          setSelectedKey(Object.keys(props.data)[selectedKeyIndex+1])
          setSelectedKeyIndex(prevState => prevState + 1)
        }
      }
    }
  }

  useEffect(() => {
    if(validated.length > 0 && Object.keys(props.data).length - 1 > selectedKeyIndex){
      if(!enabledTabs.includes(Object.keys(props.data)[selectedKeyIndex+1])){
        enabledTabs.push(Object.keys(props.data)[selectedKeyIndex+1])
        setEnabledTabs([...enabledTabs])
      }
    }
  }, [validated])

  useImperativeHandle(ref, () => ({
    openSkuModal(){
      modalRef.current.showModal('Add SKU', props.addProductList)
    }
  }));

  function addSku(skus){
    if(Object.keys(props.data).length === validated.length || props.data === 'nodata'){
        onChangeTab('addsku')
    }
    setSelectedSkus(skus)
    var newSkus = []
    skus.map(data => {
      let newData = props.addProductList.find(x => x.id === data)
      newSkus.push(newData)
    })
    setSelectedSkusTable([...newSkus])
  }

  function removeSku(id){
    let newsku = selectedSkus.filter(x => x !== id)
    let newskutable = selectedSkusTable.filter(x => x.id !== id)
    if(newsku.length === 0){
      setSelectedKey(Object.keys(props.data)[Object.keys(props.data).length - 1])
      setSelectedKeyIndex(Object.keys(props.data).length - 1)
    }
    setSelectedSkus(newsku)
    setSelectedSkusTable(newskutable)
  }
  
  function submitWC (){
    filterData(null)
    if(props.data !== 'nodata'){
      for (const key in tableData) {
        if(props.wcScreen === 'create_wastage'){
          if(tableData[key].find(x => x.wastage_quantity === null)){
            onChangeTab(key)
            openNotificationWithIcon('error', 'Please fill wastage qty for all skus')
            setEnableError(true)
            return
          }
          if(tableData[key].find(x => x.wastage_quantity > 0 && x.reason_id === null)){
            onChangeTab(key)
            openNotificationWithIcon('error', 'Please select reasons')
            setEnableError(true)
            return
          }
        }else{
          if(tableData[key].find(x => x.closing_quantity === null)){
            onChangeTab(key)
            openNotificationWithIcon('error', 'Please fill closing qty for all skus')
            setEnableError(true)
            return
          }
        }
      }
    }

    if(props.wcScreen === 'create_wastage'){
      if(selectedSkusTable.length > 0 && selectedSkusTable.find(x => x.wastage_quantity === null)){
        onChangeTab('addsku')
        openNotificationWithIcon('error', 'Please fill wastage qty for all skus')
        setEnableError(true)
        return
      }
      if(selectedSkusTable.length > 0 && selectedSkusTable.find(x => x.wastage_quantity > 0 && x.reason_id === null)){
        onChangeTab('addsku')
        openNotificationWithIcon('error', 'Please select reasons')
        setEnableError(true)
        return
      }
    }else{
      if(selectedSkusTable.length > 0 && selectedSkusTable.find(x => x.closing_quantity === null)){
        onChangeTab('addsku')
        openNotificationWithIcon('error', 'Please fill closing qty for all skus')
        setEnableError(true)
        return
      }
    }

    setButtonLoading(true)
    var items = []
    var newly_added_items = []
    if(props.data !== 'nodata'){
      for (const key in tableData) {
        tableData[key].map(data => {
          if(props.wcScreen === 'create_wastage'){
            items.push({
              product_id: data.id,
              wastage_quantity: data.wastage_quantity,
              reason_id: data.reason_id
            })
          }else{
            items.push({
              product_id: data.id,
              closing_quantity: data.closing_quantity,
              wastage_quantity: data.wastage_quantity
            })
          }
        })
      }
    }
    const payload = {
      node_code: props.currentNode,
      items: items
    }
    if(selectedSkusTable.length > 0){
      selectedSkusTable.map(data => {
        if(props.wcScreen === 'create_wastage'){
          newly_added_items.push({
            product_id: data.id,
            wastage_quantity: data.wastage_quantity,
            reason_id: data.reason_id
          })
        }else{
          newly_added_items.push({
            product_id: data.id,
            closing_quantity: data.closing_quantity,
            wastage_quantity: data.wastage_quantity
          })
        }
      })
      payload.newly_added_items = newly_added_items
    }
    if(props.wcScreen === 'create_closing'){
      payload.product_category = props.currentMainTab
    }
    props.wc_service['submit_'+props.wcScreen](payload).subscribe((r) => {
      setEnableError(false)
      setButtonLoading(false)
      if(props.fileUploaded){
        openNotificationWithIcon('success', 'Submitted Successfully')
        props.setWCScreen('wc_main')
      }else{
        uploadRef.current.callUpload(r.response.data)
      }
    },
    (err)=>{
      setButtonLoading(false)
      uploadRef.current.cancelUpload()
      if(err?.response?.errors.length > 0){
        setEnableError(true)
        openNotificationWithIcon('error', 'Please check closing qty for all skus')
        return err?.response?.errors.map(data =>{
          if(props.data !== 'nodata'){
            for (const key in tableData) {
              if(tableData[key].find(x => x.id === data.product_id)){
                tableData[key].find(x => x.id === data.product_id).errors = data.message
                if(key !== selectedKey){
                  onChangeTab(key)
                }
                setTableData({...tableData})
              }
            }
          }
          if(selectedSkusTable.length > 0 && selectedSkusTable.find(x => x.id === data.product_id)){
            if('addsku' !== selectedKey){
              onChangeTab('addsku')
            }
            selectedSkusTable.find(x => x.id === data.product_id).errors = data.message
            setSelectedSkusTable([...selectedSkusTable])
          }
        })
      }else if(err?.response?.message.length > 0){
        openNotificationWithIcon('error', err?.response?.message?.[0] ?? 'API Error')
      }else{
        openNotificationWithIcon('error', 'API Error')
      }
      console.log(err)
    })
  }

  return(
    <div>
      <div className='wcl-product-search frz-lp-0 frz-tp-0'>
        {props.data !== 'nodata' || selectedSkusTable.length > 0 ? 
          <Input placeholder="Search Product" prefix={<SearchOutlined />} className="frz-w-300"
            onChange={(e) => filterData(e.target.value)} value={searchProduct}/>
        : null}
      </div>
      <div className="wcl">
        <div className="wcl-card-container">
          <Tabs type="card" activeKey={selectedKey} onChange={onChangeTab}>
            {props.data !== 'nodata' && Object.entries(tableData).map(([key, value]) =>{
              return(
                <TabPane key={key} disabled={!enabledTabs.includes(key) || buttonLoading}
                  tab={<span className="wcl-align-center">
                        {validated.includes(key) ? 
                          <img src={greentick} className="frz-rm-8" width={15} height={15}/>
                        : null}
                        {key}
                      </span>}>
                  <ListingHeader wcScreen={props.wcScreen}/>
                  <ListingTable data={value} onChangeWC={onChangeWC} keyname={key}
                    enableError={enableError} buttonLoading={buttonLoading} searchProduct={searchProduct}
                    wcScreen={props.wcScreen} reasons={props.reasons} onChangeWCReason={onChangeWCReason}/>
                </TabPane>
              )
            })}
            {selectedSkusTable.length > 0 ?
              <TabPane key='addsku' disabled={buttonLoading || Object.keys(props.data).length !== validated.length}
                tab='Newly Added'>
                <ListingHeader wcScreen={props.wcScreen}/>
                <ListingTable data={selectedSkusTable} onChangeWC={onChangeWC} keyname={'addsku'}
                  enableError={enableError} buttonLoading={buttonLoading} searchProduct={searchProduct}
                  removeSku={removeSku} wcScreen={props.wcScreen} reasons={props.reasons}
                  onChangeWCReason={onChangeWCReason}/>
              </TabPane>
            : null}
          </Tabs>
          {props.data !== 'nodata' || selectedSkusTable.length > 0 ?
            <CreateWCButtons validateWC={validateWC} buttonLoading={buttonLoading} 
              validated={validated} data={props.data} touchedCategory={touchedCategory} fileUploaded={props.fileUploaded}
              submitWC={submitWC} wcScreen={props.wcScreen} ref={uploadRef} setWCScreen={props.setWCScreen}/>
          : null}
        </div>
      </div>
      <AddSkuModal modalSubmit={addSku} ref={modalRef} selectedSkus={selectedSkus}/>
      {props.data === 'nodata' && selectedSkusTable.length === 0 ? 
        <div className="horizontal-vertical-center frz-font-20">
          <b>No SKU's Available</b>
        </div>
      : null}
    </div>
  )
})

export default WCList