import React, { useEffect, useState } from 'react'
import { Tabs, Button, Card, Col, Row, Spin, Pagination, Table, DatePicker, Popconfirm, InputNumber } from 'antd'
import { ReloadOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import { OperationsService } from "../../../_services/operations";
import { STOShipmentTableColumns } from '../../../components/antd/columns/operations/sto_shipment';
import CreateStoShipment from '../../../components/operations/sto/createstoshipment';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const operation_service = new OperationsService();

const statusData = [{ name: "Draft", value: "draft" }, { name: "Shipment Assigned", value: "shipment_assigned" },
{ name: "Ready to dispatch", value: "ready_to_dispatch" }, { name: "Dispatched", value: "dispatched" }, { name: "Delivered", value: "delivered" }]

const StoShipment = ({setHide}) => {
  const [listingData, setListingData] = useState(false)
  const [columns, setColumns] = useState([])
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [nodeListing, setNodeLisiting] = useState([])
  const [rowId, setRowId] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isRest, setIsRest] = useState(false)
  const [createMode, setCreateMode] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [draftData, setDraftData] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [partialEdit, setPartialEdit] = useState(false)
  const [vehicleDetail, setVehicleDetail] = useState([])
  const [state, setState] = useState({
    nodeId: undefined, transfer_date: '',
    status: undefined, valueByDate: undefined, date: '',
    by_shipment_id: undefined, by_sto_order_number: undefined
  });
  const { nodeId, transfer_date, status, date, valueByDate,
    by_shipment_id, by_sto_order_number } = state

  useEffect(() => {
    getNodeListing()
    getVehicleDetail()
  }, [])

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getLisitng()
    }
  })

  // useEffect(() => {
  //   if (draftData) {
  //     handleOnCreate(true, draftData)
  //   }
  // }, [draftData])


  useEffect(() => {
    getColumns()
  }, [listingData, rowId, loading])


  const getVehicleDetail = () => {
    operation_service.get_shipment_vehicle_data().subscribe((r) => {
      setVehicleDetail(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const handleOnClickEdit = (id, showDetail, isPartial) => {
    if (showDetail) {
      setLoading("detail")
      setIsReadOnly(showDetail)
    } else {
      setLoading("edit")
      setPartialEdit(isPartial)
      setIsReadOnly(false)
    }
    setRowId(id)
    const payload = {
      id: id
    }
    operation_service.get_shipment_draf_data(payload).subscribe((r) => {
      setLoading(false)
      setRowData(false)
      setDraftData(r.response.data)
      if (showDetail) {
        handleOnCreate(true, true)
      } else {
        handleOnCreate(true)
      }
    }, ((err) => {
      setRowData(false)
      setLoading(false)
      console.log("err", err)
    }))
  }

  const handleOnClickCancel = (id, showDetail) => {
    setRowId(id)
    // setLoadingCan(true)
    setLoading('cancel')
    const payload = {
      id: id,
      body: {
        transit: {
          status: 'cancelled'
        }
      }
    }
    operation_service.cancel_shipment_data(payload).subscribe((r) => {
      setLoading(false)
      getLisitng()
      openNotificationWithIcon("success", "Cancelled Successfully")
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error')
      console.log("err", err)
    }))
  }

  // const handleOnClickDownload = (id) => {
  //   setRowId(id)
  //   const payload = {
  //     id: id
  //   }
  //   operation_service.download_sto(payload).subscribe((r) => {
  //     setLoadingDownload(false)
  //     openNotificationWithIcon("success", "Downloaded")
  //   }, ((err) => {
  //     setLoadingDownload(false)
  //     console.log("err", err)
  //   }))
  // }

  const handleOnClickDispatch = (id) => {
    setRowId(id)
    setLoading("dispatch")
    const payload = {
      id: id
    }
    operation_service.dispatch_sto(payload).subscribe((r) => {
      setLoading(false)
      getLisitng()
      openNotificationWithIcon("success", "Dispatched")
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      console.log("err", err)
    }))
  }

  function getColumns() {
    let tempCol = []
    STOShipmentTableColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push({
      title: 'Action',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {<a className="frz-rm-10" target="_blank" rel="noopener noreferrer" href={record.delivery_challan_url}>
              <Button disabled={record.status !== "ready_to_dispatch"} type="ghost" icon={<DownloadOutlined />}></Button>
            </a>}
            {record.status === "draft" || record.status === "shipment_assigned" || record.status === "ready_to_dispatch" ?
              <>
                <Button loading={loading == "edit" && rowId == record.id}
                  disabled={(loading == "edit") && rowId != record.id}
                  title="Edit" icon={<EditOutlined />}
                  onClick={() => {handleOnClickEdit(record.id, false, record.status === "shipment_assigned" || record.status === "ready_to_dispatch" ? true : false);setHide(true)}}>
                </Button>
                {record.status === "shipment_assigned" ?
                <Popconfirm title='Are you sure want to cancel this request ??' onConfirm={() => handleOnClickCancel(record.id, true)}>
                  <Button loading={loading == "cancel" && rowId == record.id} disabled={(loading == "cancel" || record.status === 'cancelled') && rowId != record.id} title="Cancel" className="frz-lm-10">
                    Cancel
                  </Button>
                </Popconfirm>
                  : null}
                {record.status === "ready_to_dispatch" ?
                  <>
                    <Popconfirm placement="topLeft" title={"Have you printed the delivery challan and handed over to driver?"} onConfirm={() => handleOnClickDispatch(record.id)} okText="Yes" cancelText="No">
                      <Button className="frz-lm-10" loading={loading == "dispatch" && rowId == record.id} disabled={(loading) && rowId != record.id} title="Edit"
                      // onClick={() => handleOnClickDispatch(record.id)}
                      >
                        Dispatch
                      </Button>
                    </Popconfirm>

                    <Button loading={loading == "detail" && rowId == record.id} disabled={(loading == "detail") && rowId != record.id} className="frz-lm-10" title="Edit" onClick={() => {handleOnClickEdit(record.id, true);setHide(true)}}>
                      Show detail
                    </Button>
                    <Popconfirm title='Are you sure want to cancel this request ??' onConfirm={() => handleOnClickCancel(record.id, true)}>
                    <Button loading={loading == "cancel" && rowId == record.id} disabled={(loading == "cancel" || record.status === 'cancelled') && rowId != record.id} title="Cancel" className="frz-lm-10">
                      Cancel
                    </Button>
                    </Popconfirm>
                  </>
                  : null
                }
              </>
              :
              record.status !== "draft" ?
                <>
                  <Button loading={loading == "detail" && rowId == record.id} disabled={(loading == "detail") && rowId != record.id} title="Edit" onClick={() => {handleOnClickEdit(record.id, true);setHide(true)}}>
                    Show Detail
                  </Button>
                  {record.status !== "delivered" ?
                  <Popconfirm title='Are you sure want to cancel this request ??' onConfirm={() => handleOnClickCancel(record.id, true)}>
                    <Button loading={loading == "cancel" && rowId == record.id} disabled={(loading == "cancel" || record.status === 'cancelled') && rowId != record.id} title="Cancel" className="frz-lm-10">
                      Cancel
                    </Button>
                  </Popconfirm>
                    : null}
                </>
                : null}
          </div>
        )
      }
    })
    setColumns([...tempCol])
  }

  const getLisitng = () => {
    setPartialEdit(false)
    const payload = {
      source_node_id: nodeId ? nodeId : '',
      status: status ? status : '',
      valueByDate: valueByDate ? valueByDate : '',
      current: current ? current : 1,
      by_sto_order_number: by_sto_order_number ? by_sto_order_number : '',
      by_shipment_id: by_shipment_id ? by_shipment_id : ''
    }
    operation_service.get_shipment_listing(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setListingData(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const getNodeListing = () => {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodeLisiting(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  function onChange(value, type) {
    setRenderData(false)
    setListingData(false)
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }


  function onChangeDate(date, dateString) {
    setRenderData(false)
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
    setListingData(false)
    setDisable(false)
    setCurrent(1)
  }


  function reset() {
    setListingData(false)
    setState({
      nodeId: undefined, transfer_date: '', status: undefined
    })
    setRenderData(false)
    setIsRest(true)
    // setListingData(false)
    // getListingData(statusType[0])
    setDisable(true)
    setCurrent(1)
    // setRenderData(false)
  }

  function pagination(page) {
    setListingData(false)
    setCurrent(page)
    setRenderData(false)
  }

  const handleOnCreate = (value, isDraf) => {
    if (isDraf) {
      setIsReadOnly(true)
    } else {
      setIsReadOnly(false)
    }
    if (!value) {
      setDraftData(false)
      setLoading(false)
    }
    setCreateMode(value)
  }

  return (
    <div className="layer">
      {!createMode ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <div className="frz-dis-flex frz-justify-sb">
                  <div>
                    <FrzDynamicSearch isRequired={true} DropDownData={nodeListing}
                      placeholder="Source Node" value="id" option="name"
                      isShowSearch={true} width={200} setOnChange={onChange}
                      type="nodeId" margin={10} defValue={nodeId} />

                    <FrzDynamicSearch isRequired={true} DropDownData={statusData}
                      placeholder="Status" value="value" option="name"
                      isShowSearch={true} width={200} setOnChange={onChange}
                      type="status" margin={10} defValue={status} />

                    <InputNumber value={by_shipment_id} placeholder="Shipment id" onChange={(e) => onChange(e, 'by_shipment_id')}
                      style={{ width: 200, margin: 10 }} />

                    <InputNumber value={by_sto_order_number} placeholder="STO order number" onChange={(e) => onChange(e, 'by_sto_order_number')}
                      style={{ width: 200, margin: 10 }} />

                    <DatePicker value={date} onChange={onChangeDate} className="frz-w-200 frz-m-10" placeholder="Expected Delivery Date" />

                    <Button disabled={disable} onClick={reset} type="primary"
                      className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                  </div>
                  <div className="frz-dis-flex frz-vt-center">
                    <Button type="primary" onClick={() => {handleOnCreate(true);setHide(true)}}>
                      Create Shipment
                    </Button>
                  </div>
                </div>
              </Col>
            </Card>
          </Row>

          <div className="frz-tm-10">
            {listingData ?
              listingData.length ?
                <Row>
                  <Col span={24}>
                    <Table dataSource={listingData} columns={columns} rowKey="id" pagination={false}
                      scroll={{ x: 'max-content', y: `calc(97vh - 294px)` }} />

                    <Pagination className="frz-tm-10 frz-bm-10" current={current}
                      pageSize={50} total={state.total} onChange={pagination}
                      showSizeChanger={false} />
                  </Col>
                </Row>
                : <div className="no-data">No Data Available</div>
              : <div className="spin-center">
                <Spin tip="...Loading" />
              </div>}
          </div>
        </div>
        : <CreateStoShipment setHide={setHide} handleOnCreate={handleOnCreate} nodeListing={nodeListing} getLisitng={getLisitng} draftData={draftData} isReadOnly={isReadOnly}
          partialEdit={partialEdit} vehicleDetail={vehicleDetail} setPartialEdit={(value) => setPartialEdit(value)} />}
    </div>
  )
}

export default StoShipment