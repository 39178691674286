import React from 'react'
import { Form, Radio } from 'antd'
import { tailLayout } from '../_modules/controllayout'
const FRZCustomRadioButon = ({
  name,
  label,
  isRequired,
  optionData,
  value,
  setOnChange,
  option,
  disabled }) => {

  const onchange = (e) => {
    if (setOnChange) {
      setOnChange(e.target.value)
    }
  }

  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      rules={isRequired ? [
        {
          required: true,
          message: `Please input the value of ${label}!`,
        },
      ] : []}>
      <Radio.Group onChange={onchange} disabled={disabled}>
        {optionData?.map((data) => {
          return (
            <Radio key={data[value]} value={data[value]}>{data[option]}</Radio>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}

export default FRZCustomRadioButon
