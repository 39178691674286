import { ArrowLeftOutlined, DeleteOutlined, InboxOutlined, ReloadOutlined } from "@ant-design/icons";
import { Spin ,Select,Tooltip,Upload, Button, Row, Col, Input, Radio, Popconfirm, Switch, InputNumber} from "antd";
import { useEffect, useState } from "react";
import {useSelector } from "react-redux"
import AddSkuModal from "./AddSkuModal";
import FRZDatepicker from '../../../../_controls/FRZDatepicker';
import moment from "moment";
import { OperationsService } from "../../../../_services/operations";
import { openNotificationWithIcon } from "../../../../_modules/notification";
import FrzDynamicUpload from '../../../../_controls/FRZDynamicUpload';
import FrzDynamicDownload from "../../../../_controls/FRZDynamicDownload";
import { UploadService } from "../../../../_services/uploads";
import FrzDynamicSearch from "../../../../_controls/FrzDynamicSearch";
import ShowAddProductModal from "../../common/AddProductModal";
import ViewDetails from "./ViewDetails";
import { EditStoCrate, EditStoNormal, EditStoPackage } from "./EditSTO";
import { InwardStoCrate, InwardStoNormal, InwardStoPackage } from "./InwardSTO";
const {Option}=Select;
const { Dragger } = Upload;
let url = 'admin_dashboard/procurement/upload_sto_sheet_for_input?sto_id='
const ops_service = new OperationsService()
const upload_service = new UploadService()
const CreateSto =({show,hide,screen,sto_id})=>{
    const [spinEnable, setSpinEnable]=useState(false);
    const [nodes,setNodes]=useState([])
    const [showSku,setShowSku]=useState(false);
    const [destinationNodes,setDestinationNodes]=useState([])
    const [normal,setNormal]=useState(false);
    const cityData = useSelector(state => state.auth.city);
    const [state,setState]=useState({
        inter_city:undefined,sourceNode:undefined,destinationNode:undefined,destinationCity:undefined,
        expectedDeliveryDate:undefined,typeOfTransfer:true
    })
    const [showAddProduct,setShowAddProduct]=useState(false)
    const [buttonDisable,setButtonDisable]=useState(false)
    const [selectedData,setSelectedData]=useState([])
    const {inter_city,sourceNode,destinationNode,destinationCity,expectedDeliveryDate,typeOfTransfer}=state;
    const [errorEnable,setErrorEnable]=useState(false);
    const [normalMainData,setNormalMainData]=useState(false)
    const [editData,setEditData]=useState(null)
    const [editCrateData,setEditCrateData]=useState(null);
    const [editNormalData,setEditNormalData]=useState(null)
    const [crateData,setCrateData]=useState([]);
    const [inwardData,setInwardData]=useState(null)
    const [addSkuType,setAddSkuType]=useState(null)
    const [inlineAprrove, setInlineAprrove] = useState(false)
    const [challanFile, setChallanFile] = useState(false)
    const [challanB64, setChallanB64] = useState(false)
    const [inwardCrate,setInwardCrate]=useState(false)
    const [inwardNormal,setInwardNormal]=useState(false)
    const [searchValue,setSearchValue]=useState(null)
    const [comment,setComment]=useState(undefined)
    const [disableSearch,setDisableSearch]=useState(true)
    const [inwardNormalMain,setInwardNormalMain]=useState(false)
    const [disableSwitch,setDisableSwitch]=useState(false)
    const [disableAll,setDisableAll]=useState(false)
    const [viewDetailsMain,setViewDetailsMain]=useState(false)
    const [viewNormalData,setViewNormalData]=useState(false)
    const [viewCrateData,setViewCrateData]=useState(false)
    const [editPackageData,setEditPackageData]=useState(false)
    const [inwardPackageData,setInwardPackageData]=useState(false)
    const [viewPackageData,setViewPackageData]=useState(false)
    const [viewNormalMainData,setViewNormalMainData]=useState(false)
    function showContainer(){
        setNormal(!normal)
        setDisableAll(!disableAll)
        setSelectedData([])
        setCrateData([])
    }
    function getNodes() {
        const payloadNode = {
          nodeType: `["CC","WH"]`
        }
        ops_service.get_node_listing(payloadNode).subscribe((r) => {
            setNodes(r.response.data)
          },
            (err) => {
              console.log(err)
            })
        }

    const getDestinationNode=()=>{

        let payloadDestNode;
        if(destinationCity){
            payloadDestNode = {
                nodeType: `["CC","WH","DS"]`,
                by_city: destinationCity ,
                no_admin:true
              }            
        }else{
            payloadDestNode = {
              nodeType: `["CC","WH","DS"]`,
              by_city: inter_city==='intra' ?localStorage.getItem('city'):'',
              no_admin:true
            }
        }
        ops_service.get_destination_node(payloadDestNode).subscribe((r) => {
          setDestinationNodes(r.response.data)
          setSpinEnable(false)
        },
          (err) => {
            console.log(err)
          })
        }
    function disabledDate(current) {
        return current && (current < moment().subtract(1, "day") || current > moment().add(7, "day"));
      }
    const filterOutData=(data)=>{
        let crate=[];
        let normal=[];
        let packages=[];
        for(let i=0; i<data.length; i++) {
            if(data[i].item_type==='crate'||data[i].item_type==='CRATE' ||data[i].item_type==='Crate'){
                crate.push({...data[i],error:data[i].transfer_qty!==null?false:true});
            }else if(data[i].item_type==='package'){
                packages.push({...data[i],error:data[i].transfer_qty!==null?false:true})
            }
            else{
                normal.push({...data[i],error:data[i].transfer_qty!==null?false:true});
            }
        }
        if(screen==='EditSTO'){
            setEditCrateData(crate?crate:null);
            setEditNormalData(normal?normal:null);
            setNormalMainData(normal?normal:null);
            setEditPackageData(packages?packages:null)
        }else if(screen==='inwardSTO'){
            setInwardCrate(crate?crate:null);
            setInwardNormal(normal?normal:null);
            setInwardNormalMain(normal?normal:null);
            setInwardPackageData(packages?packages:null)
        }else if(screen==='ViewDetails'){
            setViewNormalMainData(normal?normal:null);
            setViewNormalData(normal??null);
            setViewCrateData(crate??null);
            setViewPackageData(packages?packages:null)
        }
    }
    useEffect(()=>{
        if(screen==='EditSTO'){
            getData()
        }
        if(screen==='inwardSTO'){
            getData()
        }
        if(screen==='ViewDetails'){
            getData();
        }
    },[]) 
    const getData =()=>{
        ops_service.get_sto_edit_list(sto_id).subscribe((r) => {
            if(screen==='EditSTO') {
                setEditData(r.response.data)
                filterOutData(r.response.data.line_items)
            }
            if(screen==='inwardSTO')
            {
                setInwardData(r.response.data)
                filterOutData(r.response.data.line_items)
            }
            if(screen==='ViewDetails'){
                setViewDetailsMain(r.response.data)
                filterOutData(r.response.data.line_items)
            }
        },
            (err) => {
            console.log(err)
            })
    }
    useEffect(()=>{
        if(destinationCity){
            getDestinationNode()
        }
    },[state])
    const onChange=(value,type) => {
        setState((prev)=>({...prev,[type]:value}))
        if(type==='inter_city'){
            setState((prev)=>({...prev,sourceNode:undefined,destinationNode:undefined,destinationCity:undefined,
                expectedDeliveryDate:undefined}))
            getNodes()
        }
        if(type==='sourceNode'&& inter_city==='intra'){
            getDestinationNode()
        }
    }
    const handleRemoveProduct=(sku)=>{
        let filteredData=selectedData.filter((item)=>{
            return item?.sku!=sku;
        })
        setSelectedData(filteredData)
    }
    const handleTransferQT=(sku,value,unit)=>{
        let temp=[...selectedData];
        if((Number.isInteger(value)&& value!==0) || unit==='kg' || unit==='grams'){
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    temp[i].error=value!=null?false:true;
                    temp[i].expected_transfer_qty=value;
                    if(temp[i]?.isDecimal){
                        temp[i].isDecimal=false;
                    }
                }
            }
        }else{
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    Object.assign(temp[i],{...temp[i],isDecimal:true,expected_transfer_qty:null})
                }
            }
        }
        setSelectedData(temp)
    }
    useEffect(()=>{
        if(!state.typeOfTransfer){
            setAddSkuType('crate')
        }else if(state.typeOfTransfer){
            setAddSkuType('sku')
        }
    },[state])
    const handleSubmit=()=>{
      if(checkRequiredFields(addSkuType==='sku'?selectedData:crateData)){
          openNotificationWithIcon('error','please enter all transfer qty')
          setErrorEnable(true)
      }else if(selectedData?.length>0 || crateData?.length>0){
          setButtonDisable(true)
          setSpinEnable(true)
          let sku_items=[];
          if(addSkuType==='sku') {
              for(let i=0;i<selectedData.length;i++){
                let each={"sku":selectedData[i]?.sku,"transfer_unit_qty":+selectedData[i]?.expected_transfer_qty,"transfer_unit_type":"po_unit", "item_type": "normal"};
                    sku_items.push(each);
              }
          }else if(addSkuType==='crate'){
            for(let i=0;i<crateData.length;i++){
                let each={"sku":crateData[i]?.sku,"transfer_unit_qty":+crateData[i]?.transfer_qty,"transfer_unit_type":"po_unit", "item_type": "crate"};
                    sku_items.push(each);
              }
          }
          const payload ={
            "source_node_id":sourceNode?sourceNode:'',
            "destination_node_id":+destinationNode ?+destinationNode:'',
            "transfer_date":expectedDeliveryDate?expectedDeliveryDate:'',
            "transfer_type":typeOfTransfer ?'normal':'crate',
            "procurement_type":localStorage.getItem('procurementtype')?localStorage.getItem('procurementtype'):'',
            "sto_format_type":"old",
            "items":sku_items,
            "intra_city":inter_city,
            "comment":comment?comment:'',
            "created_by":JSON.parse(localStorage.getItem('auth')).id
          }
          ops_service.create_sto(payload).subscribe((r) => {
            setSpinEnable(false)
            openNotificationWithIcon('success','successfully created sto')
            hide(false)
        },
            (err) => {
              console.log(err)
              setSpinEnable(false)
              setButtonDisable(false)
              openNotificationWithIcon('error',err?.response?.errors[0]??'Create Failed')
            })
      }else{
          setSpinEnable(false)
          openNotificationWithIcon('error','Please Add SKU / Crate for Creating STO')
      }

    }
    const checkRequiredFields=(data)=>{
        let flag=false;
        for(let i=0; i<data.length; i++){
            if(data[i]?.error){
                flag=true;
            }
        }
        return flag;
    }
    const handleSearch=(e)=>{
        setDisableSearch(false)
        let value=e.target.value;
        setSearchValue(value)
        
        let filtered=(screen==='EditSTO'?normalMainData:inwardNormalMain)?.filter((item)=>{
            return (item?.sku + item?.name).toLowerCase().includes(value.toLowerCase())
        })
        if(screen==='EditSTO'){
            setEditNormalData(filtered)
        }else if(screen==='inwardSTO'){
            setInwardNormal(filtered)
        }
    }
    const getUploadData=(data)=>{
        let temp=[];
        for(let i=0; i<data.length; i++){
            temp.push({...data[i],error:false})
        }
        setSelectedData((prev)=>[...prev,...temp])
    }
    const checkTransferQty=(data)=>{
        let flag=false;
        for(let i=0; i<data.length; i++){
            if(data[i]?.error||data[i]?.transfer_qty==='null'||data[i]?.isDecimal){
                flag=true;
            }
        }
        return flag;
    }
    const handleUpdateSku=()=>{
        handleReset()
        if(checkTransferQty([...editNormalData,...editCrateData,...editPackageData])){
            openNotificationWithIcon('error','please enter all transfer qty')
            setErrorEnable(true)
        }
        else{
            setSpinEnable(true)
            setButtonDisable(true)
            let wholeData=[...editNormalData,...editCrateData,...editPackageData]
            const items=[]
            for(let i=0;i<wholeData.length;i++){
                let item={"id":wholeData[i].id,"qty":wholeData[i].transfer_qty,"rejected_qty":0}
                items.push(item)
            }
            const payload={
                "sto_id":editData?.id,
                "items":items,
                "driver_mobile_number":"",
                "expected_arrival_time":"",
                "vehicle_number":""
            }
            ops_service.update_sto(payload).subscribe((r) => {
            //     // console.log("updated response",r.response.data)
                setSpinEnable(false)
                openNotificationWithIcon('success','Successfully updated sto')
                hide(false)
            },
                (err) => {
                  console.log(err)
                  setSpinEnable(false)
                  openNotificationWithIcon('error',err?.response?.errors[0]??'Update Failed')
                  setButtonDisable(false)
                })
        }
    }
    const handleEditTQ=(value,sku,unit)=>{
        let temp=[...editNormalData];
        if(Number.isInteger(value)|| unit==='grams'|| unit==='kg'){
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    temp[i].error=value!=null?false:true;
                    temp[i].transfer_qty=value;
                    if(temp[i]?.isDecimal){
                        temp[i].isDecimal=false;
                    }
                }
            }
        }else{
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    Object.assign(temp[i],{...temp[i],isDecimal:true,transfer_qty:null})
                }
            }
        }
        setEditNormalData(temp)
    }
    const handleEditCrateTQ= (value,sku)=>{
        let temp=[...editCrateData];
        if(Number.isInteger(value)){
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    temp[i].error=value!=null?false:true;
                    temp[i].transfer_qty=value;
                    if(temp[i]?.isDecimal){
                        temp[i].isDecimal=false;
                    }
                }
            }
        }else{
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    Object.assign(temp[i],{...temp[i],isDecimal:true,transfer_qty:null})
                }
            }
        }
        setEditCrateData(temp)
    }
    const handleEditPackageTQ=(value,sku)=>{
        let temp=[...editPackageData];
        if(Number.isInteger(value)){
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    temp[i].error=value!=null?false:true;
                    temp[i].transfer_qty=value;
                    if(temp[i]?.isDecimal){
                        temp[i].isDecimal=false;
                    }
                }
            }
        }else{
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    Object.assign(temp[i],{...temp[i],isDecimal:true,transfer_qty:null})
                }
            }
        }
        setEditPackageData(temp)
    }
    const handleCrateTQ=(value,sku)=>{
        let temp=[...crateData];
        if(Number.isInteger(value) && value!==0){
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    temp[i].error=value!=null?false:true;
                    temp[i].transfer_qty=value;
                    if(temp[i]?.isDecimal){
                        temp[i].isDecimal=false;
                    }
                }
            }
        }else{
            for(let i=0; i<temp.length; i++){
                if(temp[i].sku===sku){
                    Object.assign(temp[i],{...temp[i],isDecimal:true,expected_transfer_qty:null})
                }
            }
        }
        setCrateData(temp)
    }
    const handleReceivedRejQTY=(type,value,sku,item_type,unit)=>{
        // console.log('called')
        if(type==='receive'){
            let temp;
            if(item_type==='normal'){
                temp=[...inwardNormal]
            }else if(item_type==='package'){
                temp=[...inwardPackageData]
            }else{
                temp=[...inwardCrate]
            }       
            if(Number.isInteger(value)&& value>=0 || (unit==='kg'||unit==='grams')&& value>=0 ){
                    for(let i=0;i<temp.length;i++){
                        if(temp[i].sku===sku){
                            let qtyValue = value;
                            let rejectedValue = temp[i].rejected_qty;
                            if(item_type ==='normal'){
                                let total = qtyValue + rejectedValue;
                                if (total !== temp[i].transfer_qty &&
                                    ((total + (total * 5 / 100) < temp[i].transfer_qty) || (temp[i].transfer_qty < total - (total * 5 / 100)))) {
                                        
                                        Object.assign(temp[i], {
                                            ...temp[i], totalvalue: true,isDecimal:false ,isApproved: false,qty:value,error:value?false:true,
                                            errorMsg: total > temp[i].transfer_qty ? "Exceed" : (total === temp[i].transfer_qty?null:"Less")
                                        })
                                    setInlineAprrove(true)
                                } else {
                                    Object.assign(temp[i], { ...temp[i],errorMsg:false,isDecimal:false,qty:value, totalvalue: false,error:false})
                                    setInlineAprrove(false)
                                }
                            }
                            if(item_type ==='crate'||item_type==='package'){
                                let total = qtyValue;
                                if (total !== temp[i].transfer_unit_qty &&
                                    ((total + (total * 5 / 100) < temp[i].transfer_unit_qty) || (temp[i].transfer_unit_qty < total - (total * 5 / 100)))) {
                                        
                                        Object.assign(temp[i], {
                                            ...temp[i], totalvalue: true,isDecimal:false ,isApproved: false,qty:value,error:false,
                                            errorMsg: total > temp[i].transfer_unit_qty ? "Exceed" : (total === temp[i].transfer_unit_qty?null:"Less")
                                        })
                                    setInlineAprrove(true)
                                } else {
                                    Object.assign(temp[i], { ...temp[i],errorMsg:false,isDecimal:false,qty:value, totalvalue: false,error:false})
                                    setInlineAprrove(false)
                                }
                            }
                        }
                    }
                }else{
                    for(let i=0; i<temp.length; i++){
                        if(temp[i].sku===sku){
                            Object.assign(temp[i],{...temp[i],isDecimal:true,qty:null,error:false,errorMsg:false})
                        }
                    }
                }
            if(item_type ==='normal'){
                setInwardNormal(temp)
            }
            else if(item_type ==='package'){
                setInwardPackageData(temp)
            } else{
                setInwardCrate(temp)
            }
        }
        else{
            let temp;
            if(item_type ==='normal'){
                temp=[...inwardNormal]
            }else if(item_type ==='package'){
                temp=[...inwardPackageData]
            }else{
                temp=[...inwardCrate]
            }
            if(Number.isInteger(value)|| (unit==='kg'||unit==='grams')){
                for(let i=0;i<temp.length;i++){
                    if(temp[i].sku===sku){
                        let qtyValue=temp[i].qty;
                        let rejectedValue=value;
                        let total = qtyValue + rejectedValue;
                        if(total !== temp[i].transfer_qty && ((total + (total * 5 / 100) < temp[i].transfer_qty) || (temp[i].transfer_qty < total - (total * 5 / 100)))){
                            Object.assign(temp[i], {
                                    ...temp[i],
                                    totalvalue: true, isDecimal:false,isApproved: false,rejected_qty:value,error:false,
                                    errorMsg: total > temp[i].transfer_qty ? "Exceed" : (total === temp[i].transfer_qty?null:"Less")
                                  })
                                  setInlineAprrove(true)
                            }
                        else{
                            Object.assign(temp[i], { ...temp[i],rejected_qty:value,isDecimal:false,totalvalue:false ,error:false,errorMsg:false })
                            setInlineAprrove(false)
                        }
                    }
                }
            }else{
                for(let i=0; i<temp.length; i++){
                    if(temp[i].sku===sku){
                        Object.assign(temp[i],{...temp[i],isDecimal:true,rejected_qty:null,error:false,errorMsg:false})
                    }
                }
            }
            if(item_type ==='normal'){
                setInwardNormal(temp)   
            }else if(item_type==='package'){
                setInwardPackageData(temp)
            }else{
                setInwardCrate(temp)
            }
            
        }
    }
    const handleApprove=(sku,type)=>{
        let temp;
        if(type==='normal'){
            temp=[...inwardNormal];
        }else if(type==='package'){
            temp=[...inwardPackageData];
        }else{
            temp=[...inwardCrate]
        }

        for(let i=0;i<temp.length;i++){
            if(temp[i].sku===sku){
                Object.assign(temp[i], {
                    ...temp[i], isApproved:true,errorMsg:null,error:false
                  })
            }
        }
       if(type==='normal') {
           setInwardNormal(temp);
       }else if(type==='package'){
            setInwardPackageData(temp)
        }else{
           setInwardCrate(temp)
       }
    }
    function handleClick({ file, onSuccess }) {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }

      function beforeUploadFileChallan(file, url) {
        return true
      }
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }
      const handleChange = info => {
        // console.log("info", info.file.status)
        if (info.file.status === 'uploading') {
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => {
            setChallanFile(info.file)
            setChallanB64(imageUrl)
          }
          );
        }
      };
      function handleRemoveChallan(url) {
        setChallanB64(false)
        setChallanFile(false)
      }
      const checkReceivedQty=()=>{
          let flag=true;
            let temp=[...inwardNormal]
            for(let i=0;i<temp.length;i++) {
                if(temp[i].qty===null){
                    flag=false;
                    Object.assign(temp[i], {
                        ...temp[i], error:true,errorMsg:false,isDecimal:false
                      })
                }
            }
            setInwardNormal(temp);
            let tempC = [...inwardCrate];
            for(let i=0; i<tempC.length; i++){
                if(tempC[i].qty===null){
                    flag=false;
                    Object.assign(tempC[i], {
                        ...tempC[i], error:true,errorMsg:false,isDecimal:false
                      })
                }
            }
            let tempP = [...inwardPackageData];
            for(let i=0; i<tempP.length; i++){
                if(tempP[i].qty===null){
                    flag=false;
                    Object.assign(tempP[i], {
                        ...tempP[i], error:true,errorMsg:false,isDecimal:false
                      })
                }
            }
            return flag;
      }    
        const handleCompleteInward=()=>{
            handleReset()
            if(checkReceivedQty()){
              if(inwardData.challan_mandatory && !challanB64){
                  openNotificationWithIcon('error','please provide a delivery challan')
                  return
              }
              setSpinEnable(true)
              setButtonDisable(true)
              const item=[];
              let temp=[...inwardNormal,...inwardCrate,...inwardPackageData]
              for(let i=0;i<temp.length;i++){
                  let each={
                      id:temp[i].id,
                      qty:temp[i].qty,
                      rejected_qty:temp[i].rejected_qty ?temp[i].rejected_qty:0
                  }
                  item?.push(each);
              }
              const payload={
                  sto_id:inwardData.id,
                  inwarded_by: JSON.parse(localStorage.getItem('auth')).id,
                  items:item
              }

            //   let flag=true;
            //   if(inwardData.challan_mandatory){
            //     if(challanB64){
            //         flag=true;
            //     }else{
            //         openNotificationWithIcon('error','please provide a delivery challan')
            //         setSpinEnable(false)
            //         setButtonDisable(false)
            //         flag=false;
            //     }
            //   }
            //   if(flag){
                  ops_service.update_inward(payload).subscribe((r) => {
                    // if challan url comes only then hit the update api
                    if (challanB64) {
                      const payloadChallan = {
                        sto_id: inwardData.id,
                        signed_dc_original_filename: challanFile.name,
                        signed_dc_image_base: challanB64,
                        signed_dc_content_type: challanFile.type
                      }
                      upload_service.inward_challan_upload(payloadChallan).subscribe((r) => {
                          //success
                          setSpinEnable(false)
                        openNotificationWithIcon('success', 'Created Successfully')
                        hide(false)
                    }, ((err) => {
                        setSpinEnable(false)
                        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                        console.log("err", err)
                        setButtonDisable(false)
                      }))
                    } else {
                      //success
                      openNotificationWithIcon('success', 'Created Successfully')
                      setSpinEnable(false)
                      hide(false)
                    }
                  },
                    (err) => {
                      console.log(err)
                      setSpinEnable(false)
                      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
                      setButtonDisable(false)
                    })
                // }
              }
          else
          {
              setSpinEnable(false)
              openNotificationWithIcon('error', 'please enter received qty')

        }
      }
      const handleCrateRemoveProduct=(sku)=>{
          let temp=crateData.filter((item)=>{
              return item?.sku!=sku
          })
          setCrateData(temp)
      }
      const getEditUploadedData=(data)=>{
        let temp=data?.line_items;
        let normal=[...editNormalData];
        let crate =[...editCrateData]
        for(let i=0;i<temp?.length;i++){
            for(let j=0;j<normal.length; j++){
                if(temp[i].sku===normal[j].sku){
                    normal[j].transfer_qty=temp[i].transfer_qty;
                }
            }
        }
        for(let i=0;i<temp?.length;i++){
            for(let j=0;j<crate.length; j++){
                if(temp[i].sku===crate[j].sku){
                    crate[j].transfer_qty=temp[i].transfer_qty;
                }
            }
        }
        setEditNormalData(normal);
        setEditCrateData(crate)
      }
      const handleComment=(e)=>{
          setComment(e.target.value)
      }
      const handleReset=()=>{
        setSearchValue(null);
        setDisableSearch(true)
        if(screen==='EditSTO'){
            setEditNormalData(normalMainData);
        }else if(screen==='inwardSTO'){
            setInwardNormal(inwardNormalMain)
        }
      }
      const transferData=(value)=>{
          setDisableSwitch(true)
          if(screen==='EditSTO'){
              let temp=[...editNormalData];
              for(let i=0;i<temp.length; i++){
                  temp[i].transfer_qty=temp[i].expected_transfer_qty;
                  temp[i].error=false
              }
              setEditNormalData(temp)
          }else if(screen==='inwardSTO'){
              let temp=[...inwardNormal];
              for(let i=0; i<temp.length; i++){
                temp[i].qty=temp[i].expected_transfer_qty
                temp[i].error=false
              }
              setInwardNormal(temp)
          }
      }
      
    return(
        !spinEnable  ? 
        <div>
            {screen!=='ViewDetails'?<Popconfirm
              title="Are you sure you want to go back, all changes made will be lost?"
              onConfirm={() => {hide(false)}}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
            >
              <Button type="link" icon={<ArrowLeftOutlined />} className="wcl-blk-icon"></Button> 
            </Popconfirm>:<Button onClick={()=>hide(false)} icon={<ArrowLeftOutlined />} style={{backgroundColor:'whitesmoke',border:'none'}} className="wcl-blk-icon"></Button>}
            {screen==='CreateSTO'?<span> Create STO</span>:(screen==='EditSTO'?<span>Edit / Update STO #{editData?.order_number}</span>:(screen==='inwardSTO'?<span>Start Inwarding #{inwardData?.order_number}</span>:<span>STO # {viewDetailsMain?.order_number}</span>))}
       {screen==='CreateSTO'? <div className='basic-details-modal'>
            <p className='sku-title'>Basic Details</p>
            <div className='frz-dis-flex filter-container'>
                <div className='intra-city-inter-city'>
                    <p>Category</p>
                    <Radio.Group disabled={disableAll} value={inter_city} buttonStyle="solid"  onChange={(value)=>onChange(value.target.value,'inter_city')}>
                            <Tooltip title="Within a City">
                                <Radio.Button  value="intra">Intra City</Radio.Button>
                            </Tooltip> 
                            <Tooltip title="City To City"> 
                                <Radio.Button  value="inter">Inter City</Radio.Button>
                            </Tooltip>
                    </Radio.Group>
                </div>
                {inter_city?<div>
                    <p>Source Node</p>
                    <FrzDynamicSearch DropDownData={nodes?nodes:null}
                    placeholder="Select Source Node" value="id" option="name"
                    isShowSearch={true} selectDisabled={disableAll} isDisabled={disableAll} setOnChange={onChange}
                    type="sourceNode"  defValue={sourceNode} />
                </div>:null}
                { inter_city==='inter' && sourceNode ?<div>
                    <p>Destination City</p>
                    <Select disabled={disableAll} className='input-box-sto' placeholder='select a destination' value={destinationCity} onChange={(value)=>onChange(value,'destinationCity')}>
                        {cityData ? cityData.map((city)=><Option value={city.code} disabled={localStorage.getItem('city')===city.code?true:false} key={city.id}>{city.name}</Option>):null}
                    </Select>
                </div>:null}
                {(destinationCity && inter_city==='inter') || (sourceNode && inter_city==='intra')?<div>
                    <p>Destination Node</p>
                    <Select
                    onChange={(value)=>onChange(value,'destinationNode')} value={destinationNode}
                    showSearch={true}
                    disabled={disableAll ? true : false}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={'Select Destination Node'}>
                    {destinationNodes?.map((data, index) => {
                        return (
                        <Option  key={index} disabled={data.id===sourceNode ? true : false} value={data.id}>
                            {data.name}
                        </Option>
                        )
                    })}
                    </Select>
                    {/* <FrzDynamicSearch DropDownData={destinationNodes?destinationNodes:null}
                    placeholder="Select Destination Node" value="id" option="name"
                    isShowSearch={true} isDisabled={disableAll||(sourceNode)}  setOnChange={onChange}
                    type="destinationNode"  defValue={destinationNode} /> */}
                </div>:null}
            </div>
            {destinationNode?<div className="frz-dis-flex filter-container">
                <div>
                    <p>Expected Delivery Date</p>
                    <FRZDatepicker placeholder="Select Expected Delivery Date"  isRequired={'true'}
              name="transfer_date" isDisabled={disableAll} disabledDate={disabledDate} value={expectedDeliveryDate} setOnChange={(value)=>onChange(value?.format('YYYY-MM-DD'),'expectedDeliveryDate')} />
                </div>
                {expectedDeliveryDate?<div className='intra-city-inter-city'>
                    <p>Transfer Type</p>
                    <Radio.Group disabled={disableAll} value={typeOfTransfer} buttonStyle="solid"  onChange={(value)=>onChange(value.target.value,'typeOfTransfer')}>
                                <Radio.Button  value={true}>Normal</Radio.Button>
                                <Radio.Button  value={false}>Crate</Radio.Button>
                    </Radio.Group>
                </div>:null}

                {expectedDeliveryDate?<div>
                    {/* <p>Transfer Type</p> */}
                        {/* <div> */}
                            {/* <Switch checkedChildren="Normal" unCheckedChildren="Crate" disabled={disableAll} checked={typeOfTransfer} onChange={(value)=>onChange(value,'typeOfTransfer')}/> */}
                            <Button onClick={showContainer} className="frz-m-20 frz-tm-20" type="primary">{!disableAll ?"Add STO":'Change Values' }</Button>
                        {/* </div>/ */}
                </div>:null}
            </div>:null}
        </div>:null}
        {(normal && typeOfTransfer) || (screen==='EditSTO' && normalMainData?.length > 0 ) || screen==='inwardSTO' ? <div className=" product-container-sto">
            <div className="frz-dis-flex frz-justify-sb">
                    <div>
                    <span className='sku-title'>SKU</span>
                    {screen === 'EditSTO' || screen==='inwardSTO'?<div className="search-sku-edit-sto">
                        <Input value={searchValue??searchValue} onChange={handleSearch} placeholder="Search SKU"/>
                        <Button type="primary" disabled={disableSearch} onClick={handleReset} ghost icon={<ReloadOutlined/>}></Button>
                        {screen==='EditSTO'?<><span className='frz-lm-10'>Copy expected transfer QTY to QTY </span><Switch disabled={disableSwitch} onChange={(value)=>transferData(value)}></Switch></>:null}
                    </div>:null}
                    </div>
                {screen==='CreateSTO'?<div className="frz-dis-flex frz-justify-sb sku-btns">
                    <Button className="frz-m-10"  onClick={()=>{setShowSku(true);setErrorEnable(false)}} type='primary'>Add SKU</Button>
                     <FrzDynamicUpload name={'SKU'} url={"admin_dashboard/procurement/upload_sto_create_sheet?transfer_type=normal&created_by="+`${JSON.parse(localStorage.getItem('auth')).id}`+'&source_node_id='+`${sourceNode}` + '&procurement_type=' + `${localStorage.getItem('procurementtype')}`} uploadFunc={'upload_zapper_post'}
                        callBack={getUploadData} />
                    <div className='frz-m-15' style={{textDecoration: 'underline'}}><a download href='https://sku-zapper.s3.ap-south-1.amazonaws.com/1654583077-create-sto-form.csv'>Dowload Sample File</a></div>
                </div>:null}
                {screen==='EditSTO' && normalMainData?.length?<div>
                    <div className='frz-dis-flex'>
                    <FrzDynamicUpload name={'Transfer Data'} url={url+"&sto_id="+`${sto_id}`} uploadFunc={'upload_zapper_put'}
                        callBack={getEditUploadedData} />
                    <FrzDynamicDownload name={'Transfer Data'} fileName={'Transfer data'} url={'admin_dashboard/procurement/sto_report'}
                        payload={'?sto_id=' + `${sto_id}`}
                        type={'job'} reportFunc={'sto_transfer_data_download'} jobReportFunc={'zapper_job_report'} base={'zapper'} /> 
                    {screen==='EditSTO'?<><span className='frz-m-10'><Button type='primary' onClick={()=>setShowAddProduct(true)} disabled={(editData?.status==='material_allocated'||editData?.status==='assigned'||editData?.status==='ready_to_dispatch'||editData?.status==='shipment_assigned') ? false:true}>Add SKU</Button></span></>:null}
                    </div>

                </div>:null}

            </div>
            {screen==='CreateSTO' ? 
            <div>
                {selectedData?.length>0 ?
                <div>
                    <div className="table-headers-sto">
                    <Row>
                        <Col span={8}>Product</Col>
                        <Col span={3} className='frz-text-center'>Current Stock</Col>
                        <Col span={3} className='frz-text-center'>Available Qty</Col>
                        <Col span={4}>Expected Transfer QTY</Col>
                        <Col span={2} className='frz-text-center'>Units</Col>
                        <Col span={3}></Col>
                    </Row>
                    </div>
                    <div className="table-details-sto">
                        {selectedData?.length > 0 ? selectedData?.map((item,i)=>{
                        return(<Row key={i}>
                            <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                            <Col span={3} className='frz-text-center'>{item?.product_current_stock}</Col>
                            <Col span={3} className='frz-text-center'>{item?.available_qty}</Col>
                            <Col span={4}>
                                <InputNumber value={item?.expected_transfer_qty} onChange={(e)=>handleTransferQT(item?.sku,e,item?.inventory_unit)} className={(errorEnable && item?.error) || item?.isDecimal ?'wcl-input-error':''} status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                                {errorEnable && item?.error ? <div className='wcl-input-error-msg'>Please enter a valid number</div>:null}
                                {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                            </Col>
                            <Col span={2} className='frz-text-center'>{item?.inventory_unit}</Col>
                            <Col span={3} className="delete-sku-icon"><Button onClick={()=>handleRemoveProduct(item?.sku)} icon={<DeleteOutlined />} type="primary"></Button> </Col>
                        </Row>)}):null}
                    </div>
                    </div>:<p className='sto-no-data'>Please add sku's</p>}
            </div>
            :null}
            {screen==='EditSTO' ? <EditStoNormal editNormalData={editNormalData} errorEnable={errorEnable} handleEditTQ={handleEditTQ}  />:null}
            {screen==='inwardSTO'?<InwardStoNormal handleApprove={handleApprove} handleReceivedRejQTY={handleReceivedRejQTY} inwardNormal={inwardNormal}/>:null}
        </div>:null}
        {(normal && !typeOfTransfer) || (screen==='EditSTO'&&editCrateData?.length>0) ||(screen ==='inwardSTO'&&inwardCrate?.length>0)  ? <div className=" product-container-sto">
            <div className="frz-dis-flex frz-justify-sb">
                <p className='sku-title'>CRATE</p>
                {screen==='CreateSTO'?<div className="frz-dis-flex frz-justify-sb sku-btns">
                    <Button type="primary" className=" frz-mt-10 frz-border-none frz-bgcolor-black" onClick={()=>setShowSku(true)}>Add Crate</Button>
                </div>:null}
            </div>
            <div>
                {screen==='CreateSTO' ?
                <div>
                    {
                        crateData?.length > 0 ?<div>
                    <div className="crate-title-container">
                        <Row>
                            <Col span={8} className='frz-align-left'>Crate Type</Col>
                            <Col span={3}>Transfer Qty</Col>
                            <Col span={3}>Action</Col>
                        </Row>
                    </div>
                    <div className="crate-details-container">
                        {crateData?.map((item,i)=>
                        {
                            return(
                                <Row key={i}>
                                    <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                                    <Col span={3}>
                                                <InputNumber onChange={(value)=>handleCrateTQ(value,item?.sku)} value={item?.transfer_qty}  className={(errorEnable && item?.error)|| item.isDecimal ?'wcl-input-error':''} status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                                                {errorEnable && item?.error ? <div className='wcl-input-error-msg'>Please enter a valid number</div>:null}
                                                {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer number</div>:null}
                                    </Col>
                                    <Col span={3} className="delete-sku-icon"><Button onClick={()=>handleCrateRemoveProduct(item?.sku)} icon={<DeleteOutlined />} type="primary"></Button> </Col>
                                </Row>
                            )
                        })}
                    </div>
                    </div>:<p className='sto-no-data'>Please add a crates</p>
                    }
                </div>:null}

                {screen==='inwardSTO'?<InwardStoCrate inwardCrate={inwardCrate} handleApprove={handleApprove} handleReceivedRejQTY={handleReceivedRejQTY} />:null}
                {screen==='EditSTO' ?<EditStoCrate editCrateData={editCrateData} errorEnable={errorEnable} handleEditCrateTQ={handleEditCrateTQ}/>:null}
            </div>
        </div>:null}
        {(screen==='EditSTO' && editPackageData?.length>0)  ||(screen ==='inwardSTO' && inwardPackageData?.length>0) ?
            <div className="product-container-sto">
                    <div className="frz-dis-flex frz-justify-sb">
                        <p className='sku-title'>PACKAGE</p>
                    </div>
                {screen==='EditSTO' ?<EditStoPackage editPackageData={editPackageData} errorEnable={errorEnable} handleEditPackageTQ={handleEditPackageTQ}/>:null}
                {screen==='inwardSTO'?<InwardStoPackage inwardPackageData={inwardPackageData} handleApprove={handleApprove} handleReceivedRejQTY={handleReceivedRejQTY}/>:null}   
            </div>
        :null}
        {screen==='ViewDetails'?<ViewDetails viewPackageData={viewPackageData} setViewNormalData={setViewNormalData} viewNormalMainData={viewNormalMainData} viewCrateData={viewCrateData} viewNormalData={viewNormalData} viewDetailsMain={viewDetailsMain}/>:null}
        {screen==='inwardSTO'?
            <div className="frz-bm-60 drag-container">
            <Dragger
                    name='file'
                    showUploadList={true}
                    customRequest={handleClick}
                    beforeUpload={beforeUploadFileChallan}
                    maxCount={1}
                    onChange={handleChange}
                    onRemove={handleRemoveChallan}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{}</p>
                    <p className="ant-upload-hint">
                      Delivery Challan :- PDF / Image
                    </p>
                  </Dragger>
            </div>:null}
            {normal ||screen==='EditSTO'|| screen ==='inwardSTO'?<>{screen!=='inwardSTO'?<div className="add-note-container">
                <div>
                    <p className="sku-title">ADD NOTE</p>
                </div>
                <div>
                    <Input type='textarea' placeholder='Enter Text' onChange={handleComment}/>
                </div>
            </div>:null}
            <div className='save-container-sto'>
                <Popconfirm
                title="Are you sure you want to go back, all changes made will be lost?"
                onConfirm={() => {hide(false)}}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
                >
                <Button  type='danger' ghost>Cancel</Button>
                </Popconfirm>
                <Button type='primary' loading={buttonDisable} onClick={screen==='EditSTO'?handleUpdateSku:(screen==='inwardSTO'?handleCompleteInward:handleSubmit)}>{screen==='EditSTO'?"Update SKU":(screen==='inwardSTO'?"Complete Inward":"Submit")}</Button>
            </div></>:null}
            {showAddProduct?<div><ShowAddProductModal sto_id={sto_id} id={editData?.source_node_id} procureType={editData?.procurement_type}show={showAddProduct} hide={setShowAddProduct} getSto={getData}/></div>:null}
            {showSku ?<div className="sku-modal"><AddSkuModal crateData={crateData} setCrateData={setCrateData} type={addSkuType}  setSelectedData={setSelectedData} selectedData={selectedData.length>0?selectedData:''} node={sourceNode} show={showSku} hide={setShowSku}/></div>:null}
        </div> :(spinEnable ? <div className="spin-center"><Spin tip="...Loading" /></div>:null)
    )
}
export default CreateSto