import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from 'react-google-login';
import { toggleLogin } from '../../_actions';
import { Spin, notification } from 'antd';
import {AuthService} from "../../_services/authentication";
import CustomLayout from '../layout';
import logo from '../../static/logo.png';

const auth_service = new AuthService()

function Login(props) {
  const [loading, setLoading] = useState(false)
  const [pageload, setPageload] = useState(true)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if(localStorage.getItem('auth') &&
       localStorage.getItem('roles') &&
       localStorage.getItem('stores')){
       dispatch(toggleLogin(true))
       setPageload(false)
    }else{
       dispatch(toggleLogin(false))
       setLoading(false)
       setPageload(false)
    }
  }, []);

  function responseGoogle(response) {
    auth_service.sign_in(response.tokenObj).subscribe((r) => {
      if(r.response.roles.length > 0){
        const auth = {
          "access-token" : r.xhr.getResponseHeader('access-token'),
          "client" : r.xhr.getResponseHeader('client'),
          "expiry" : r.xhr.getResponseHeader('expiry'),
          "uid" : r.xhr.getResponseHeader('uid'),
          "token-type" : r.xhr.getResponseHeader('token-type'),
          "id" : r.response.admin_detail.id
        }
        localStorage.setItem('auth', JSON.stringify(auth))
        localStorage.setItem('roles', JSON.stringify(r.response.roles))
        localStorage.setItem('stores', JSON.stringify(r.response.darkstores))
        setLoading(false)
        dispatch(toggleLogin(true))
      }else{
        setLoading(false)
        openNotificationWithIcon('error', 'No user roles found. Access denied')
      }
    },
    (err)=>{
      console.log(err)
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0]??'API Error')
    })
  }

  function userlogout() {
    setLoading(true)
    dispatch(toggleLogin(false))
    localStorage.clear()
    setLoading(false)
  }

  function responseGoogleLogout() {
    console.log("Logout Success")
  }


  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div>
      { !pageload ?
        <div>
          { isAuthenticated ?
            <CustomLayout children={props.children} userlogout={userlogout}
                          responseGoogleLogout={responseGoogleLogout}/>
            :
            <div className="text-center position-center">
              <div>
                <img src={logo} alt="Logo" className="logo"/>
                <h1>Fraazo Operations Dashboard</h1>
              </div>
              <div className="loader">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  render={renderProps => (loading ?
                    <Spin /> :
                    <button className="signup"
                            onClick={() => {renderProps.onClick(); setLoading(true)}}
                            disabled={renderProps.disabled}>Google Signin</button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
            </div>
          }
        </div>
        :
        <div className="position-center">
          <Spin />
        </div>
      }
    </div>
  )
}

export default Login
