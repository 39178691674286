import react from 'react'
import { humanize } from '../../../../_modules/humanize'

export const nodewiseData = () => [
  {
    title: humanize("node_name"),
    dataIndex: 'node_name',
    key: 'node_name',
    align: "center",
    width: 45
  },
  {
    title: humanize("manpower_planned"),
    dataIndex: 'manpower_count',
    key: 'manpower_count',
    align: "center",
    width: 45
  },
  {
    title: humanize("actual_arrived"),
    dataIndex: 'actual_arrived',
    key: 'actual_arrived',
    align: "center",
    width: 45
  },
  {
    title: humanize("arrived_late_percentage"),
    dataIndex: 'arrived_late',
    key: 'arrived_late',
    align: "center",
    width: 45
  },
  {
    title: humanize("logged_out_count"),
    dataIndex: 'logged_out',
    key: 'logged_out',
    align: "center",
    width: 45
  },
  {
    title: humanize("completed_shift_hours"),
    dataIndex: 'completed_shift_hours',
    key: 'completed_shift_hours',
    align: "center",
    width: 45
  },
  {
    title: humanize("completed_valid_shift_hours"),
    dataIndex: 'completed_valid_shift_hours',
    key: 'completed_valid_shift_hours',
    align: "center",
    width: 45
  },
]
export const daywiseData = () => [
  {
    title: humanize('date'),
    dataIndex: 'display_date',
    key: 'display_date',
    align: 'center',
    // render: (record) => <div>{handleDateFormat(record)}</div>
  },
  {
    title: humanize('day'),
    dataIndex: 'day',
    key: 'day',
    align: 'center',
  },
  {
    title: 'Manpower Planned',
    dataIndex: 'manpower_count',
    key: 'manpower_count',
    align: 'center',
  },
  {
    title: humanize('actual_arrived'),
    dataIndex: 'actual_arrived',
    key: 'actual_arrived',
    align: 'center',
  },
  {
    title: humanize('arrived_late'),
    dataIndex: 'arrived_late',
    key: 'arrived_late',
    align: 'center',
  },
  {
    title: humanize('logged_out'),
    dataIndex: 'logged_out',
    key: 'logged_out',
    align: 'center',
  },
  {
    title: humanize('completed_shift_hours'),
    dataIndex: 'completed_shift_hours',
    key: 'completed_shift_hours',
    align: 'center',
  },
  {
    title: humanize('completed_valid_shift_hours'),
    dataIndex: 'completed_valid_shift_hours',
    key: 'completed_valid_shift_hours',
    align: 'center',
  }
]

export const reportsNestedUserWiseColumns = [
  {
    title: humanize('display_date'),
    dataIndex: 'display_date',
    key: 'display_date',
    align: 'center',
  },
  {
    title: humanize('day'),
    dataIndex: 'day',
    key: 'day',
    align: 'center',
  },
  {
    title: humanize('log_in_time'),
    dataIndex: 'log_in_time',
    key: 'log_in_time',
    align: 'center',
  },
  {
    title: humanize('log_out_time'),
    dataIndex: 'log_out_time',
    key: 'log_out_time',
    align: 'center',
  },
  {
    title: humanize('active_hours'),
    dataIndex: 'active_hours',
    key: 'active_hours',
    align: 'center',
  },
  {
    title: humanize('valid_active_hours'),
    dataIndex: 'valid_active_hours',
    key: 'valid_active_hours',
    align: 'center',
  }
]

export const reportsNestedDayWiseColumns = [
  {
    title: humanize('name'),
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: humanize('roles'),
    dataIndex: 'roles',
    key: 'roles',
    align: 'center',
  },
  {
    title: humanize('shift_timing'),
    dataIndex: 'shift_timing',
    key: 'shift_timing',
    align: 'center',
  },
  {
    title: humanize('shift_hrs'),
    dataIndex: 'shift_hrs',
    key: 'shift_hrs',
    align: 'center',
  },
  {
    title: humanize('log_in_time'),
    dataIndex: 'log_in_time',
    key: 'log_in_time',
    align: 'center',
  },
  {
    title: humanize('log_out_time'),
    dataIndex: 'log_out_time',
    key: 'log_out_time',
    align: 'center',
  },
  {
    title: humanize('active_hours'),
    dataIndex: 'active_hours',
    key: 'active_hours',
    align: 'center',
  },
  {
    title: humanize('valid_active_hours'),
    dataIndex: 'valid_active_hours',
    key: 'valid_active_hours',
    align: 'center',
  }
]

export const reportsAttnUserColumns = [
  {
    title: humanize('name'),
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: humanize('roles'),
    dataIndex: 'roles',
    key: 'roles',
    align: 'center',
  },
  {
    title: humanize('shift_timing'),
    dataIndex: 'shift_timing',
    key: 'shift_timing',
    align: 'center',
  },
  {
    title: humanize('shift_hrs'),
    dataIndex: 'shift_hrs',
    key: 'shift_hrs',
    align: 'center',
  },
  {
    title: humanize('month'),
    dataIndex: 'month',
    key: 'month',
    align: 'center',
  },
  {
    title: humanize('attendance'),
    dataIndex: 'attendance',
    key: 'attendance',
    align: 'center',
  }
]