import moment from "moment"
import { handleDateFormat } from "../../../../_modules/date"
import { humanize } from "../../../../_modules/humanize"
import { getStaticTime } from "../../../../_modules/time"
import { Tag } from 'antd'

export const allocationList = () => [
    {
        title: humanize(''),
        dataIndex: '',
        key: '',
        align: 'center',
        width: 120,
        render: (record) =>
            <div className="frz-dis-flex frz-hrz-center frz-vt-center">
                <div className={`${'frz-dis-flex frz-rm-10'} 
                 ${record.status == "ready" && 'frz-ready-clr'}
                 ${record.status == "not_ready" && 'frz-not-ready-clr'}
                 ${record.status == "allocated" && 'frz-allocated-clr'}
                 `
                } />
                <div className="frz-img-card" style={{ width: 50 }}>
                    <img src={record.image_url} style={{ width: 50, height: 50 }} />
                </div>
            </div>
    },
    {
        title: 'SKU Name',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 120,
        render: (record) => <div className="frz-dis-flex frz-hrz-center frz-vt-center">
            {/* <img src={record.image_url} style={{ width: 50, height: 50 }} /> */}
            <div>
                {record.product_name}
            </div>
        </div>
    },
    {
        title: humanize('sku_code'),
        dataIndex: 'fraazo_sku',
        key: 'fraazo_sku',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('available_qty'),
        dataIndex: 'sto_available_stock',
        key: 'sto_available_stock',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('current_stock'),
        dataIndex: 'current_stock',
        key: 'current_stock',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('expected_transfer_qty'),
        dataIndex: 'expected_transfer_qty',
        key: 'expected_transfer_qty',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('allocated_qty'),
        dataIndex: 'allocated_qty',
        key: 'allocated_qty',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('yet_to_receive'),
        dataIndex: 'yet_to_receive',
        key: 'yet_to_receive',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
]

export const allocationDetailList = () => [
    {
        title: humanize(''),
        dataIndex: 'image_url',
        key: 'image_url',
        align: 'center',
        width: 120,
        render: (record) => <div className="frz-img-card" style={{ width: 50 }}><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: 'SKU Name',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 120,
        render: (record) => <div className="frz-dis-flex frz-hrz-center frz-vt-center">
            {/* <img src={record.image_url} style={{ width: 50, height: 50 }} /> */}
            <div>
                {record.product_name}
            </div>
        </div>
    },
    {
        title: humanize('current_stock'),
        dataIndex: 'current_stock',
        key: 'current_stock',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    }
]

export const allocationDetailNestedList = () => [
    {
        title: humanize('destination_node_name'),
        dataIndex: 'destination_node_name',
        key: 'destination_node_name',
        align: 'center',
        width: 120
    },
    {
        title: humanize('destination_node_code'),
        dataIndex: 'destination_node_code',
        key: 'destination_node_code',
        align: 'center',
        width: 120
    },
    {
        title: humanize('expected_transfer_qty'),
        dataIndex: 'expected_transfer_qty',
        key: 'expected_transfer_qty',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    },
    {
        title: humanize('allocated_qty'),
        dataIndex: 'allocated_qty',
        key: 'allocated_qty',
        align: 'center',
        width: 120,
        // render: (record) => <div><img src={record} style={{ width: 50, height: 50 }} /></div>
    }
]
