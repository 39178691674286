import { Http } from '../_modules/http'

export class WastageClosingService {

  http = new Http()

  get_dashboard_data(node_code) {
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/dashboard_data?node_code=" 
                + node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_product_data_create_partial_wastage(node_code){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/products/index_partial_wastage?node_code=" 
                + node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_product_data_create_wastage(node_code){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/products?node_code=" 
                + node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_product_data_create_closing(node_code){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/products?node_code=" 
                + node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_adhoc_wastage(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/create_partial_wastage" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_wastage_data(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items/index_wastage?node_code=" 
                + payload.node_code + '&is_partial_wastage=' + payload.is_partial_wastage
                + '&is_daily_wastage=' + payload.is_daily_wastage + '&by_date=' + payload.by_date
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_closing_data(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items?node_code=" 
                + payload.node_code + '&by_date=' + payload.by_date + '&product_category=' + payload.product_category
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_wc_create_wastage(node_code){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/wastage_items?node_code=" 
                + node_code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_wc_create_closing(node_code, product_category){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/closing_items?node_code=" 
                + node_code + '&product_category=' + product_category
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  validate_wastage(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items/bulk_validate_wastage" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  validate_closing(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items/bulk_validate" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }
  
  submit_create_wastage(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/create_wastage" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  submit_create_closing(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  edit_closing(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items/"+ payload.id 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers);
  }

  city_head_request(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/approval_dashboard/requests" 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_base_download(urlParam){
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/stock_losses/' + urlParam
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_job_download(id){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/get_job_details?id=' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  send_email(urlParam){
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/stock_losses/' + urlParam + '&email_sheet=true'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_module_access(node_code){
    const url = process.env.REACT_APP_AWS3_URL + "/api/project_modules?node_code=" + node_code;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_reasons(){
    const url = process.env.REACT_APP_AWS3_URL + '/v2/admin_dashboard_v2/reasons'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_partial_data(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_loss_items/partial_closing?node_code=" 
                + payload.node_code + '&by_date=' + payload.by_date
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  get_partial_product_data(procurement_type){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/index_products?by_procurement_type=" 
                + procurement_type
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  create_partial_closing(payload){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_v2/stock_losses/partial_closing"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  get_check_done(node_code, date){
    const url = process.env.REACT_APP_AWS3_URL + "/v2/admin_dashboard_v2/stock_losses/check_done?node_code=" 
    + node_code + '&date=' + date
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
}

