import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './_routes';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Spin } from "antd";
const Root = ({ store }) => {

  const [isDarkMode, setIsDarkMode] = React.useState();
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  // Avoid theme change flicker
  if (status === "loading") {
    return (
      <div className="spin-center first-spin">
        <Spin tip="...Loading" />
      </div>  
    )
  }

  return (
    <Provider store={store}>
      <Router>
        <Routes toggleTheme={toggleTheme} currentTheme={currentTheme} isDarkMode={isDarkMode} />
      </Router>
    </Provider>
  )
}
Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
