import React, {useRef, useState, useEffect} from "react";
import { Spin } from 'antd';
import TableHeaders from './table_headers';
import TableList from './table_list';
import ClosingEditModal from '../../common/wc_edit_modal';
import {openNotificationWithIcon} from '../../../../_modules/notification';

function ClosingTable(props){
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(props.data)

  useEffect(() => {
    setTableData(props.data)
  }, [props.data])

  function editClosing(data){
    modalRef.current.showModal('Edit Closing', data)
  }
  
  function modalSubmit(values, id){
    const payload = {
      id: id,
      body: {
        node_code: props.currentNode,
        quantity: values.closing
      }
    }
    props.wc_service.edit_closing(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      modalRef.current.handleCancel()
      props.getClosingData()
    },
    (err) => {
      console.log(err)
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
    })
  }

  function filterData(e){
    let filterData = props.data.filter(x => x.name.toLowerCase().includes(e.target.value.toLowerCase()))
    setTableData(filterData)
  }

  return(
    <div>
      {props.data ? 
        <div>
          {props.data.length > 0 ? 
            <div>
              <TableHeaders filterData={filterData}/>
              {tableData.length > 0 ?
                <TableList data={tableData} currentDate={props.currentDate} editClosing={editClosing}/>
              : 
                <div className="horizontal-vertical-center frz-font-20">
                  <b>No SKU's Available</b>
                </div>
              }
              <ClosingEditModal modalSubmit={modalSubmit} ref={modalRef} type={'closing'} lbp={'Closing Qty'}/>
            </div>
          :
            <div className="horizontal-vertical-center frz-font-20">
              <b>No SKU's Available</b>
            </div>
          }
        </div>
      : 
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ClosingTable