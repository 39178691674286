import { humanize } from "../../../../_modules/humanize"

export const STOShipmentTableColumns = [
  {
    title: humanize('#_shipment'),
    dataIndex: "id",
    key: "id",
    align: "center",
    width: 170
  },
  {
    title: humanize('source_node'),
    dataIndex: "source_node",
    key: "source_node",
    align: "center",
    width: 170
  },
  {
    title: humanize('total_stos'),
    dataIndex: "total_stos",
    key: "total_stos",
    align: "center",
    width: 170
  },
  {
    title: humanize('status'),
    dataIndex: "status",
    key: "status",
    align: "center",
    width: 170
  },
  {
    title: humanize('expected_delivery_datetime'),
    dataIndex: "expected_delivery_datetime",
    key: "expected_delivery_datetime",
    align: "center",
    width: 170
  },
  {
    title: humanize('vehicle_numbers'),
    dataIndex: "vehicle_numbers",
    key: "vehicle_numbers",
    align: "center",
    width: 170
  }
]

export const STOShipmentColumns = [
  // {
  //   title: "Source Name",
  //   dataIndex: "source_name",
  //   key: "source_name",
  //   align: "center",
  // },
  {
    title: "Destination",
    dataIndex: "destination",
    key: "destination",
    align: "center",
  },
  {
    title: "Order Number",
    dataIndex: "order_number",
    key: "order_number",
    align: "center",
  },
  {
    title: "Transfer Date",
    dataIndex: "transfer_date",
    key: "transfer_date",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
  }
]