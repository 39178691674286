import React from "react";

function ListingHeader(props){
  return(
    <div className="wcl-listing-header wcl-top-noborder">
      <div className="wcl-40">Product</div>
      <div className="wcl-10 text-center">{props.wcScreen === 'create_wastage' ? 'Recorded Wastage' : 'Wastage'}</div>
      <div className="wcl-15 text-center">{props.wcScreen === 'create_wastage' ? 'Curent Wastage' : 'Closing'}</div>
      {props.wcScreen === 'create_wastage' ? 
        <div className="wcl-20 text-center">Reason</div>
      : null}
      <div className="wcl-10 text-center">Units</div>
      <div className="wcl-5"></div>
    </div>
  )
}

export default ListingHeader