import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Spin } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import { CrateService } from '../../../_services/crate';

const crate_service = new CrateService()

const AddNewCrate = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('Add New Crate')
  const [crateData, setCrateData] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal() {
      setVisible(true)
      setData(true)
      getCrate()
    },

    handleCancel,

    stopLoading() {
      setLoading(false)
    }

  }));

  function getCrate(){
    crate_service.get_crate_types().subscribe((r) => {
      setCrateData(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function handleCancel() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values)
  }


  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {

              });
          }}
          onCancel={handleCancel}>
            <div>
              {crateData ?
                <Form
                  form={form}
                  {...formItemLayout}
                  layout="horizontal"
                  name="form">

                  <FRZSelect DropDownData={crateData} name="crate_type_id" isRequired={true}
                    label="Crate Type" placeholder="Select Crate Type" value="id" option="name"
                    isShowSearch={true} width={200} />
                  
                  <FRZSelect DropDownData={props.nodes} name="node_id" isRequired={true}
                    label="CC / DS Name" placeholder="Select CC / DS Name" value="id" option="name"
                    isShowSearch={true} width={"100%"} />
                  
                  <FRZRadioButton name="is_active" label="Is Active" isRequired={true}/>
        
                </Form>
              :
                <div className="spin-center">
                  <Spin tip="...Loading" />
                </div>
              }  
          </div>  
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})
export default AddNewCrate