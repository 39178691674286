import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Button } from 'antd';
import { formItemLayout } from '../../_modules/controllayout';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import { openNotificationWithIcon } from '../../_modules/notification';
import FRZInput from '../../_controls/FRZInput';

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false,
  secondary: false
}

const VerifyOTP = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)

  useEffect(() => {
    return () => {
      handleCancelModal()
    };
  }, []);

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title, secondary) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true, secondary: secondary }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setModalState(initialModal)
  }

  const handleOnSubmit = (values) => {
    setModalState(prevState => ({ ...prevState, loading: true }))
    const payload = {
      primary_mobile_otp: values.primary_mobile_otp.toString(),
      primary_mobile_number: modalState.data.mobile_number
    }
    if (modalState.secondary) {
      payload.primary_mobile_number = parseInt(modalState.secondary)
      payload.secondary_mobile_number = modalState.data.mobile_number
      payload.secondary_mobile_otp = modalState.values.secondary_mobile_otp.toString()
    }
    props.attendance_service.verify_otp(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'OTP Verified Successfully')
      props.addUser(modalState.data)
      handleCancelModal()
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          width={'50%'}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={modalState.loading} onClick={() => {
              form
                .validateFields()
                .then(values => {
                  handleOnSubmit(values)
                })
                .catch(info => {
                  console.log('Validate Failed:', info);
                });
            }}>
              Submit
            </Button>
          ]}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput isRequired={true} label={`OTP For ` + modalState.data.mobile_number} name="primary_mobile_otp" width={200}
              placeholder="Enter OTP" />

            {modalState.secondary ?
              <FRZInput isRequired={true} label={`OTP For ` + modalState.secondary} name="secondary_mobile_otp" width={200}
                placeholder="Enter OTP" />
              : null}
          </Form>
        </Modal>
        : null}
    </div >

  )
})

export default VerifyOTP
