import React, { useState, useEffect, useRef } from "react";
import {calculateTime} from '../../common/calculate_time';
import {timeFormat} from '../../common/time_format';
import { Button, Spin, Tabs } from 'antd';
import email from '../../../../static/images/email.svg';
import print from '../../../../static/images/print.svg';
import download from '../../../../static/images/download.svg';
import {openNotificationWithIcon} from '../../../../_modules/notification';
import TimeScreen from './timescreen';
import ClosingTable from './table';
import CreateClosingModal from './create_closing_modal';

const { TabPane } = Tabs;
var interval

function Closing(props){
  const modalRef = useRef(null);
  const [timer, setTimer] = useState(false)
  const [allowFinalClosing, setAllowFinalClosing] = useState(false)
  const [allowRequest, setAllowRequest] = useState(false)
  const [loading, setLoading] = useState(false)
  const [closingData, setClosingData] = useState(false)
  const [showTimeScreen, setShowTimeScreen] = useState(false)
  
  var expiryTimestamp
  var date = new Date()

  useEffect(() => {
    if(props.data.current_transaction_count !== props.data.transaction_limit){
      setTime()
      setFetchClosing(props.currentMainTab)
    }else{
      setTime()
      getClosingData()
    }
  },[])

  function setTime() {
    var startTime = timeFormat(props.data.cutoff_starts_in)
    var endTime = timeFormat(props.data.cutoff_ends_in)
    var approvalEndTime = timeFormat(props.data.approval_ends_in)
    if(new Date(startTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = startTime
      getTimer()
    }
    else if(new Date(endTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = endTime
      setAllowFinalClosing(true)
      getTimer()
    }else if(new Date(approvalEndTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = approvalEndTime
      setAllowRequest(true)
      setAllowFinalClosing(false)
      getTimer()
    }else{
      setAllowRequest(false)
      setShowTimeScreen(true)
    }
  }

  function getTimer() {
    interval = setInterval(() => {
      setTimer(calculateTime(expiryTimestamp))
    }, 1000)
  }

  useEffect(() => {
    if (timer === '00:00:00') {
      clearInterval(interval)
      setTime()
    }
  }, [timer])

  useEffect(() => {
    if(props.data.current_transaction_count === props.data.transaction_limit || props.currentDate)
      getClosingData()
  },[props.currentDate])

  function getClosingData(type){
    setClosingData(false)
    const payload = {
      node_code: props.currentNode,
      by_date: props.currentDate ?? '',
      product_category: type ?? props.currentMainTab
    }
    props.wc_service.get_closing_data(payload).subscribe((r) => {
      setClosingData(r.response.data)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error', 'Backend API Error')
      setClosingData([])
    })
  }

  function createWC() {
    modalRef.current.showModal('Create Closing', true)
  }

  function setFetchClosing(e){
    if(e === 'packaging' && props.allowPackagingClosing){
      getClosingData(e)
    }else if(e === 'crates' && props.allowCratesClosing){
      getClosingData(e)
    }else if(e === 'node_procurement' && props.data.current_transaction_count === props.data.transaction_limit){
      getClosingData(e)
    }else if(props.currentDate){
      getClosingData(e)
    }
  }

  return(
    <div>
      <div className="wastage-btn-container closing-btn-container">
        <Spin size="small" spinning={props.emailLoading === 'email_closing_sheet'}>
          <div className="email-print-export" 
            onClick={() => props.sendEmail(`${'closing_items_pdf_report?node_code='+props.currentNode+'&product_category='+props.currentMainTab}`, 'email_closing_sheet')}>
            <img src={email} width={18} height={16} /> Email Sheet
          </div>
        </Spin>
        {props.jobLoading === 'print_closing' ? 
          <Spin size="small">
            <div className="email-print-export">
              <img src={print} width={18} height={16} /> Print Sheet
            </div>
          </Spin>
        : 
          <div className="email-print-export" 
            onClick={() => props.getJobDownloads(`closing_items_pdf_report?node_code=${props.currentNode}&product_category=${props.currentMainTab}`, 'print_closing')}>
            <img src={print} width={18} height={16} /> Print Sheet
          </div>
        }
        <Spin size="small" spinning={props.exportLoading === 'csv_closing_sheet'}>
          <div className="email-print-export"
            onClick={() => props.getJobDownloads(`export_closing_report?node_code=${props.currentNode}&product_category=${props.currentMainTab}` +
            `&date=${props.currentDate ?? ''}`, 'csv_closing_sheet')}>
            <img src={download} width={18} height={16} /> Export to CSV
          </div>
        </Spin>
      </div>
      <div className="wc-main-screen-container">
        <Tabs type="card" className="wcl-main-tabs" onChange={(e) =>  {props.setCurrentMainTab(e); setFetchClosing(e)}}
              activeKey={props.currentMainTab}>
          <TabPane tab="Products" key="node_procurement"></TabPane>
          <TabPane tab="Package" key="packaging"></TabPane>
          <TabPane tab="Crates" key="crates"></TabPane>
        </Tabs>
        {(props.currentMainTab === 'crates' && !props.allowCratesClosing && !props.currentDate) ||
         (props.currentMainTab === 'packaging' && !props.allowPackagingClosing && !props.currentDate) || 
         (props.currentMainTab === 'node_procurement' && props.data.current_transaction_count !== props.data.transaction_limit && !props.currentDate) ?
          <TimeScreen allowFinalClosing={allowFinalClosing} allowRequest={allowRequest} timer={timer}
            data={props.data} setWCScreen={props.setWCScreen} showTimeScreen={showTimeScreen}
            wc_service={props.wc_service} currentNode={props.currentNode} createWC={createWC}
            getJobDownloads={props.getJobDownloads} jobLoading={props.jobLoading}
            currentMainTab={props.currentMainTab} allowCratesClosing={props.allowCratesClosing}
            allowPackagingClosing={props.allowPackagingClosing}/>
        :
          <ClosingTable data={closingData} currentDate={props.currentDate} currentNode={props.currentNode}
            wc_service={props.wc_service} getClosingData={getClosingData} />
        }
      </div>
      <CreateClosingModal ref={modalRef} printImg={print} jobLoading={props.jobLoading} 
                          getJobDownloads={props.getJobDownloads} currentMainTab={props.currentMainTab}
                          setWCScreen={props.setWCScreen} currentNode={props.currentNode}/>
    </div>

  )
}

export default Closing