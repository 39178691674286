import { Tag } from "antd"
import moment from "moment"

export const poColumns = [
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center'
  },
  {
    title: 'Supplier Name',
    dataIndex: 'source_name',
    key: 'source_name',
    align: 'center',
    width: 130
  },
  {
    title: 'Collection Center',
    dataIndex: 'collection_center',
    key: 'collection_center',
    align: 'center',
    width: 130
  },
  {
    title: 'City',
    dataIndex: 'city_name',
    key: 'city_name',
    align: 'center',
    width: 130
  },
  {
    title: 'Order Placed At',
    dataIndex: 'ordered_at',
    key: 'ordered_at',
    align: 'center',
    width: 130
  },
  {
    title: 'Delivery Date',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    align: 'center',
    width: 130
  },
  // {
  //   title: "Is Advance",
  //   dataIndex: "",
  //   key: "",
  //   align: "center",
  //   render: (record) => {
  //     return record.is_advance !== null ? record.is_advance.toString() : ''
  //   }
  // },
  // {
  //   title: "Is Local Purchase",
  //   dataIndex: "",
  //   key: "",
  //   align: "center",
  //   render: (record) => {
  //     return record.is_local_purchase !== null ? record.is_local_purchase.toString() : ''
  //   }
  // },
  // {
  //   title: 'Tax Type',
  //   dataIndex: 'tax_type',
  //   key: 'tax_type',
  //   align: 'center',
  //   width: 130
  // },
  // {
  //   title: 'Basic Amount',
  //   dataIndex: 'basic_amount',
  //   key: 'basic_amount',
  //   align: 'center',
  //   width: 130
  // },
  {
    title: 'GRN done',
    dataIndex: 'grn_done',
    key: 'grn_done',
    align: 'center',
    width: 130,
    render: (record) => {
      return (
        <div>
          {record ? "True" : "False"}
        </div>
      )
    }

  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
    align: 'center',
    width: 130
  },
]

export const retailWastageColumns = [
  {
    title: "Node",
    dataIndex: "node",
    key: "node",
    align: "center",
  },
  {
    title: "Created By",
    dataIndex: 'created_by',
    key: 'created_by',
    align: "center",
  },
  {
    title: "Authenticated By",
    dataIndex: "authenticated_by",
    key: "authenticated_by",
    align: "center",
  },
]

export const productColumns = [
  {
    title: "Product",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.name + " - (" + record.sku + ")"
    }
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
    align: "center",
  },
  {
    title: "Basic Unit Price",
    dataIndex: "basic_unit_price",
    key: "basic_unit_price",
    align: "center",
  },
  {
    title: 'Tax Percentage',
    dataIndex: 'tax_percentage',
    key: 'tax_percentage',
    align: 'center',
  },
  {
    title: 'Cess Percentage',
    dataIndex: 'cess_percentage',
    key: 'cess_percentage',
    align: 'center',
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
    align: "center",
  },
  {
    title: "Basic Amount",
    dataIndex: "basic_amount",
    key: "basic_amount",
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "center",
  },
]

export const productGrnColumns = [
  {
    title: "Product",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.name + " - (" + record.sku + ")"
    }
  },
  {
    title: "PO Qty",
    dataIndex: "po_qty",
    key: "po_qty",
    align: "center",
  },
  {
    title: "Grn Qty",
    dataIndex: "grn_qty",
    key: "grn_qty",
    align: "center",
  },
  {
    title: "Excess Qty",
    dataIndex: "excess_qty",
    key: "excess_qty",
    align: "center",
  },
  {
    title: "Grading Qty",
    dataIndex: "graded_quantity",
    key: "graded_quantity",
    align: "center",
  },
  {
    title: "Allocated Qty",
    dataIndex: "allocated_qty",
    key: "allocated_qty",
    align: "center",
  },
  {
    title: "Pack Size",
    dataIndex: "pack_size",
    key: "pack_size",
    align: "center",
  },
  {
    title: "Packed Qty",
    dataIndex: "packed_qty",
    key: "packed_qty",
    align: "center",
  },
  {
    title: "Basic Unit Price",
    dataIndex: "basic_unit_price",
    key: "basic_unit_price",
    align: "center",
  },
  {
    title: 'Tax Percentage',
    dataIndex: 'tax_percentage',
    key: 'tax_percentage',
    align: 'center',
  },
  {
    title: 'Cess Percentage',
    dataIndex: 'cess_percentage',
    key: 'cess_percentage',
    align: 'center',
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
    align: "center",
  },
  {
    title: "Grading Loss",
    dataIndex: "grading_loss",
    key: "grading_loss",
    align: "center",
  },
  {
    title: "Basic Amount",
    dataIndex: "product_basic_amount",
    key: "product_basic_amount",
    align: "center",
  },
  {
    title: "Total Amt",
    dataIndex: "product_total_amount",
    key: "product_total_amount",
    align: "center",
  },
]
export const productRetailWastageColumns = [

  {
    title: "Product",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      console.log(record)
      return record.product_name + " - (" + record.sku + ")"
    }
  },

  {
    title: "Wastage Qty",
    dataIndex: "wastage_qty",
    key: "wastage_qty",
    align: "center",
  },
  {
    title: "Is Approved",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_approved !== null ? record.is_approved.toString() : ''
    }
  },
  {
    title: "Rejection Season",
    dataIndex: "rejection_reason",
    key: "rejection_reason",
    align: 'center',
  },
  {
    title: "Updated At",
    dataIndex: '',
    key: '',
    render: (record) => {
      return moment(record.updated_at).format("YYYY-MM-DD") + " at " + moment(record.updated_at).format("HH:mm")
    }
  },

]
export const paymentColumns = [
  {
    title: 'Order No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.order_number
    }
  },
  {
    title: 'Order Date',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.order_date
    }
  },
  {
    title: 'Delivery Date',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.delivery_date
    }
  },
  {
    title: 'Supplier Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.name
    }
  },
  {
    title: 'Collection Center',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.cc_name
    }
  },
  {
    title: 'Total Amt',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.total_amount
    }
  },
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'due_date',
    align: 'center'
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    align: 'center'
  },
  {
    title: 'Payment Method',
    dataIndex: 'mode_of_payment',
    key: 'mode_of_payment',
    align: 'center'
  },
  {
    title: 'Final Amt',
    dataIndex: 'final_amount',
    key: 'final_amount',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejection_reason',
    key: 'rejection_reason',
    align: 'center'
  },
  {
    title: 'Txn Detail',
    dataIndex: 'transaction_detail',
    key: 'transaction_detail',
    align: 'center'
  },
]

export const nodeListingColumns = [
  {
    title: "Center Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
  {
    title: "Node Type",
    dataIndex: "node_type",
    key: "node_type",
    align: "center",
  },
  {
    title: "Mobile Number",
    dataIndex: "POC_contact",
    key: "POC_contact",
    align: "center",
  },
  {
    title: "Is Hub",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_hub !== null ? record.is_hub.toString() : ''
    }
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active !== null ? record.is_active.toString() : ''
    }
  },
  {
    title: "City",
    dataIndex: "city_name",
    key: "city_name",
    align: "center",
  },
]

export const CCWHIncentoryColumns = [
  {
    title: "Node Name",
    dataIndex: "node_name",
    key: "node_name",
    align: "center",
  },
  {
    title: "Product Name",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.product_name + " - (" + record.sku + ")"
    }
  },
  {
    title: "Fraazo Sku",
    dataIndex: "sku",
    key: "sku",
    align: "center",
  },
  {
    title: "Inventory Unit",
    dataIndex: "inventory_unit",
    key: "inventory_unit",
    align: "center",
  },
  {
    title: "Current Stock",
    dataIndex: "current_stock",
    key: "current_stock",
    align: "center",
  },
  {
    title: "Transit Stock",
    dataIndex: "transit_stock",
    key: "transit_stock",
    align: "center",
  }, {
    title: "Allocated Stock",
    dataIndex: "ytd_qty",
    key: "ytd_qty",
    align: "center",
  },
]


export const poPaymentDetailColumn = [
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'due_date',
    align: 'center'
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    align: 'center'
  },
  {
    title: 'Payment Method',
    dataIndex: 'mode_of_payment',
    key: 'mode_of_payment',
    align: 'center'
  },
  {
    title: 'Final Amt',
    dataIndex: 'final_amount',
    key: 'final_amount',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejection_reason',
    key: 'rejection_reason',
    align: 'center'
  },
  {
    title: 'Txn Detail',
    dataIndex: 'transaction_detail',
    key: 'transaction_detail',
    align: 'center'
  },
]
export const CCWHIncentoryViewDetailsColumns = [
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    align: 'center'
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    align: 'center',
    width: 180
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type',
    key: 'transaction_type',
    align: 'center',
    width: 200,
    render: (record) => {
      let color = record == 'credit' ? 'green' : 'red';
      return (
        <Tag color={color}>
          {record}
        </Tag>
      );
    }
  },
  {
    title: 'Previous Stock',
    dataIndex: 'previous_stock',
    key: 'previous_stock',
    align: 'center'
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
    render: (text, record, index) => {
      let color = record.transaction_type === 'credit' ? 'green' : 'red';
      return (
        <Tag color={color}>
          {record.qty}
        </Tag>
      );
    }
  },
  {
    title: 'Current Stock',
    dataIndex: 'current_stock',
    key: 'current_stock',
    align: 'center'
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type',
    key: 'order_type',
    align: 'center'
  },
  {
    title: 'Loss Type ',
    dataIndex: 'loss_type',
    key: 'loss_type',
    align: 'center'
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    align: 'center'
  },
  {
    title: 'Created By  ',
    dataIndex: 'created_by',
    key: 'created_by',
    align: 'center'
  },
]
export const onBoardedColumns =[
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center'
  },
  {
    title: 'Name',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    align: 'center',
  },
  {
    title: 'Type',
    dataIndex: 'supplier_type',
    key: 'supplier_type',
    align: 'center'
  },
  {
    title: 'City',
    dataIndex: 'city_name',
    key: 'city_name',
    align: 'center'
  },
  {
    title: 'CC',
    dataIndex: 'node_name',
    key: 'node_name',
    align: 'center',
    width:250
  }
]
export const topFarmersColumns = [
  {
    title: 'Name',
    dataIndex: 'farmer_name',
    key: 'farmer_name',
    align: 'center'
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    align: 'center'
  },
  {
    title: 'City',
    dataIndex: 'city_name',
    key: 'city_name',
    align: 'center'
  },
  {
    title: 'CC',
    dataIndex: 'node_name',
    key: 'node_name',
    align: 'center'
  }
]
export const tonnageColumns = (type) => {
  const symbol = type === 'value' ? '(₹)' : '(tons)' 
  return [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      align: 'center'
    },
    {
      title: `Farmer ${symbol}`,
      dataIndex: 'farmer_tonnage',
      key: 'farmer_tonnage',
      align: 'center'
    },
    {
      title: 'Farmer Tonnage %',
      dataIndex: 'farmer_tonnage_percentage',
      key: 'farmer_tonnage_percentage',
      align: 'center'
    },
    {
      title: `Vendor ${symbol}`,
      dataIndex: 'vendor_tonnage',
      key: 'vendor_tonnage',
      align: 'center'
    },
    {
      title: 'Vendor Tonnage %',
      dataIndex: 'vendor_tonnage_percentage',
      key: 'vendor_tonnage_percentage',
      align: 'center'
    },
    {
      title: `Total ${symbol}`,
      dataIndex: 'total_tonnage',
      key: 'total_tonnage',
      align: 'center'
    },
    {
      title: 'Total Tonnage %',
      dataIndex: 'total_tonnage_percentage',
      key: 'total_tonnage_percentage',
      align: 'center'
    }
  ]
}