import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Input, InputNumber, Pagination, Row, Spin, DatePicker } from "antd";
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch";
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";
import MainTable from "../../../components/antd/table";
import { WorkBenchColumns } from "../../../components/antd/columns/operations/workbench";
import { useSelector } from 'react-redux'
import moment from 'moment';
import FrzDynamicDownload from "../../../_controls/FRZDynamicDownload";

const ops_service = new OperationsService()

const fnvData = [{ name: "FnV", value: true }, { name: "Non-FnV", value: false }]
const inStockData = [{ name: "In stock", value: true }, { name: "Out of stock", value: false }]
const editedOrdersData = [{ name: "Edited orders", value: true }, { name: "Non-edited orders", value: false }]
const activeProductsData = [{ name: "Active", value: true }, { name: "Inactive", value: false }]
const activeNodeProductsData = [{ name: "Active", value: true }, { name: "Inactive", value: false }]

function WorkBench() {
  // const [renderData, setRenderData] = useState(false)
  const [listingData, setListingData] = useState(false)
  const [columns, setColumns] = useState([])
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [products, setProducts] = useState(false)
  const [dsDisable, setDsDisable] = useState(true)
  const [darkstore, setDarkstore] = useState([])
  const [state, setState] = useState({
    valueByCity: undefined, valueByDarkStore: undefined,
    valueByDate: moment(), date: moment(), valueBySku: undefined,
    valueByFnv: undefined,
    valueByStock: undefined,
    valueByEditedOrder: undefined,
    valueByActiveProduct: undefined,
    valueByActiveNodeProduct: undefined
  });
  const { valueByCity, valueByDate, date, valueByDarkStore, valueBySku,
    valueByFnv,
    valueByStock,
    valueByEditedOrder,
    valueByActiveProduct,
    valueByActiveNodeProduct } = state
  const cityData = useSelector(state => state.auth.city);
  const darkstoreData = useSelector(state => state.auth.darkstore);


  // useEffect(() => {
  //   if (!renderData) {
  //     setRenderData(true)
  //     getListingData()
  //     getProducts()
  //   }
  // })

  useEffect(() => {
    if (current) {
      getListingData()
    }
  }, [current])


  useEffect(() => {
    getColumns()
    getProducts()
  }, [])

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
    setCurrent(false)
  }

  const getProducts = () => {
    ops_service.get_product_list().subscribe((r) => {
      let tempData = r.response.data.map(v => ({ ...v, name_sku: v.name + " - (" + v.sku + ")" })
      )
      setProducts(tempData)

    },
      (err) => {
        console.log(err)
      })
  }


  function getColumns() {
    let tempCol = []
    WorkBenchColumns.map(data => {
      tempCol.push(data)
    })
    setColumns(tempCol)
  }

  useEffect(() => {
    if (valueByCity) {
      let cityId = cityData.find((x) => x.code === valueByCity).id
      let filteredDs = darkstoreData.filter((e) => e.city_id === cityId)
      setDarkstore(filteredDs)
      setDsDisable(false)
      // setDarkstore
    }
    if (((valueByDarkStore || valueBySku) && (valueByCity && valueByDate))) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [state])

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    if (type == "valueByCity") {
      setState(prevState => ({ ...prevState, valueByDarkStore: undefined }))
    }
    setCurrent(false)
  }

  const getListingData = () => {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current ? current : 1,
      date: valueByDate ? moment(valueByDate).format("YYYY-MM-DD") : '',
      city: valueByCity ? valueByCity : '',
      darkstore: valueByDarkStore ? valueByDarkStore : '',
      sku: valueBySku ? valueBySku : '',
      fnv: valueByFnv !== undefined ? valueByFnv : '',
      stock: valueByStock !== undefined ? valueByStock : '',
      edited: valueByEditedOrder !== undefined ? valueByEditedOrder : '',
      activeProduct: valueByActiveProduct !== undefined ? valueByActiveProduct : '',
      activeNodeProduct: valueByActiveNodeProduct !== undefined ? valueByActiveNodeProduct : '',
    }
    ops_service.get_work_bench(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setListingData(r.response.data)
    },
      (err) => {

      })
  }

  function reset() {
    setState({
      valueByDate: moment(), date: moment()
    })
    setListingData(false)
    setDisable(true)
    setCurrent(false)
    // setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    // setListingData([]);
    // setRenderData(false);
    // setRenderData(false)
  }

  return (
    <div className="layer">
      {products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} disabled className="frz-w-200 frz-m-10" placeholder="Delivery Date" />

                <FrzDynamicSearch DropDownData={cityData}
                  placeholder="Select city" value="code" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByCity')}
                  type="valueByCity" margin={10} defValue={valueByCity} />

                <FrzDynamicSearch DropDownData={darkstore}
                  placeholder="Select darkstore" value="code" option="name"
                  isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'valueByDarkStore')}
                  type="valueByDarkStore" margin={10} defValue={valueByDarkStore} selectDisabled={dsDisable} />

                <FrzDynamicSearch DropDownData={products}
                  placeholder="Select SKU" value="sku" option="name"
                  isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'valueBySku')}
                  type="valueBySku" margin={10} defValue={valueBySku} />

                <FrzDynamicSearch DropDownData={fnvData}
                  placeholder="Fnv / Non FnV SKU" value="value" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByFnv')}
                  type="valueByFnv" margin={10} defValue={valueByFnv} />

                <FrzDynamicSearch DropDownData={inStockData}
                  placeholder="In stock" value="value" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByStock')}
                  type="valueByStock" margin={10} defValue={valueByStock} />


                <FrzDynamicSearch DropDownData={editedOrdersData}
                  placeholder="Edited Order" value="value" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByEditedOrder')}
                  type="valueByEditedOrder" margin={10} defValue={valueByEditedOrder} />

                <FrzDynamicSearch DropDownData={activeProductsData}
                  placeholder="Active product" value="value" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByActiveProduct')}
                  type="valueByActiveProduct" margin={10} defValue={valueByActiveProduct} />

                <FrzDynamicSearch DropDownData={activeNodeProductsData}
                  placeholder="Active node product" value="value" option="name"
                  isShowSearch={true} width={180} setOnChange={(e) => onChange(e, 'valueByActiveNodeProduct')}
                  type="valueByActiveNodeProduct" margin={10} defValue={valueByActiveNodeProduct} />

                <Button disabled={disable}
                  className="frz-w-100px frz-m-10" type="primary" onClick={getListingData}>Search</Button>

                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>

          <Row>
            <Card>
              <Col span={24}>
                <FrzDynamicDownload isDisabled={disable} name={'Workbench'} fileName={'workbench'} url={'admin_dashboard/node_wise_product_fulfilment_report'}
                  payload={'?by_date=' + `${valueByDate ?? ''}` + '&by_city=' + `${valueByCity ?? ''}`
                    + '&node_code=' + `${valueByDarkStore ?? ''}` + '&by_sku=' + `${valueBySku}` + "&fnv_category=" + valueByFnv + "&in_stock=" + valueByStock + "&edited_orders=" + valueByEditedOrder + "&active_products=" + valueByActiveProduct +
                    "&active_node_products=" + valueByActiveNodeProduct}
                  type={'job'} reportFunc={'zapper_report_without_global_city'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
              </Col>
            </Card>
          </Row>

          {listingData ?
            listingData.length > 0 ?
              <Row>
                <Col span={24}>
                  <MainTable dataSource={listingData} columns={columns} />
                  <Pagination className="frz-tm-10 frz-bm-10" current={current ? current : 1}
                    pageSize={50} total={state.total} onChange={pagination}
                    showSizeChanger={false} />
                </Col>
              </Row>
              : (disable || spinEnable ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">No Data Available</div>)
                :
                <div className="no-data">No Data Available On Selected Filters</div>)
            :
            <div className="no-data">Please Select Filters</div>}
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </div>
  );
}
export default WorkBench;