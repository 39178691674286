import { ajax } from "rxjs/ajax";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

export class Http {

  send_request(request) {
    return ajax(request).pipe(
      catchError((err) => {
        console.log("Handling error locally and rethrowing it...", err);
        if(err.status === 401){
          localStorage.clear()
          window.location.reload()
        }
        return throwError(err);
      }),
      map((res) => {
        if (res.status === 200 || res.status === 201) {
          return res;
        }
        throw new Error(res);
      })
    );
  }

  get(url, headers = {}) {
    let request = {
      url: url,
      method: "GET",
      headers: headers,
    };

    return this.send_request(request);
  }

  post(url, data = {}, headers = {}) {
    let request = {
      url: url,
      method: "POST",
      body: data,
      headers: headers,
    };
    return this.send_request(request);
  }

  put(url, data = {}, headers = {}) {
    let request = {
      url: url,
      method: "PUT",
      body: data,
      headers: headers,
    };
    return this.send_request(request);
  }

  patch(url, data = {}, headers = {}) {
    let request = {
      url: url,
      method: "PATCH",
      body: data,
      headers: headers,
    };
    return this.send_request(request);
  }

  delete(url, data = {}, headers = {}) {
    let request = {
      url: url,
      method: "DELETE",
      body: data,
      headers: headers,
    };
    return this.send_request(request);
  }
}
