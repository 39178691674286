import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Select, Input, Pagination } from 'antd';
import { OperationsService } from "../../../_services/operations";
import MainTable from '../../../components/antd/table';
import { CCWHIncentoryColumns } from "../../../components/antd/columns/operations/category.js";
import { ReloadOutlined } from '@ant-design/icons';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import ViewDetailsModal from '../../../components/operations/inventorymanagement/ViewDetailsModal';

const operation_service = new OperationsService();
const { Option } = Select;

const inventoryData = [
  { name: "Available Inventory", value: "current_stock" },
  { name: "Available Intransit", value: "transit_stock" }]

const CCWHInventory = () => {
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodeListing, setNodeLisiting] = useState([])
  const [state, setState] = useState({ nodeId: undefined, valueByName: undefined, valueByCategory: [], valueBySubCategory: [], invType: undefined });
  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [subCatData, setSubCatData] = useState([])

  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [showViewDetails,setShowViewDetails]=useState(false)
  const [curr,setCurr] = useState(null);
  const { nodeId, valueByName, valueByCategory, valueBySubCategory, invType } = state
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      if (nodeId) {
        getListing()
      }
      getNodeListing()
      getProductCategories()
      getProductSubCategories()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getProductCategories() {
    operation_service.getProductCategories().subscribe(
      (r) => {
        setProductCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductSubCategories() {
    operation_service.getProductSubCategories().subscribe(
      (r) => {
        setSubCatData(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1))
        setProductSubCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function onChangeDropdown(value, type) {
    // console.log(values)
    setState(prevState => ({ ...prevState, [type]: value }))
    if (type === 'valueByCategory') {
      let newSubCat = []

      if (value.length > 0) {
        productSubCategories.map(data => {
          if (value == data.parent_code) {
            newSubCat.push(data)
          }
        })
      } else {
        newSubCat = productSubCategories
      }

      setSubCatData(newSubCat)
      setState(prevState => ({ ...prevState, valueBySubCategory: [] }))
    }
  }

  const getNodeListing = () => {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodeLisiting(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      nodeId: nodeId === undefined ? "" : nodeId,
      valueByName: valueByName === undefined ? "" : valueByName,
      page: current,
      invType: invType ? invType : "",
      valueByCategory: valueByCategory ? valueByCategory : "",
      valueBySubCategory: valueBySubCategory ? valueBySubCategory : ""
    }
    operation_service.get_cc_wh_inventory(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getColumns() {
    CCWHIncentoryColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Action",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => (setShowViewDetails(!showViewDetails),setCurr(record))}>View Details</Button>
        );
      },
    });
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function reset() {
    setState({ nodeId: undefined })
    setDisable(true)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    if (type == "nodeId") {
      setDisable(false)
    }
  }

  return (
    <div className="layer">
      {nodeListing ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <FrzDynamicSearch DropDownData={nodeListing}
                  placeholder="Node Name" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="nodeId" margin={10} defValue={nodeId} />

                <Input
                  placeholder="Product Name"
                  onChange={(e) => onChange(e.target.value, "valueByName")}
                  value={valueByName}
                  className="frz-w-200 frz-m-10" />


                <Select className="frz-w-200 frz-m-10 frz-multiple-mode"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch={true}
                  value={valueByCategory}
                  placeholder="Select Category" onChange={(e) => onChangeDropdown(e, 'valueByCategory')}>
                  {productCategories.map(data => {
                    return (
                      <Option value={data.code} key={data.code}>{data.name}</Option>
                    )
                  })}
                </Select>

                <Select className="frz-w-200 frz-m-10 frz-multiple-mode"
                  value={valueBySubCategory}
                  placeholder="Select Sub-Category" onChange={(e) => onChangeDropdown(e, 'valueBySubCategory')}>
                  {subCatData.map(data => {
                    return (
                      <Option value={data.code} key={data.code}>{data.name}</Option>
                    )
                  })}
                </Select>

                <FrzDynamicSearch DropDownData={inventoryData}
                  placeholder="Select Available Inventory" value="value" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="invType" margin={10} defValue={invType} />

                <Button disabled={disable} onClick={getListing}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />


                <span className="fl-right">
                  <div className="frz-dis-flex">
                    <div className="frz-dis-flex frz-vt-center">
                      <FrzDynamicUpload name={'Inventory Correction'} url={'admin_dashboard/procurement/upload_inventory_correction_data'} uploadFunc={'upload_zapper_put'}
                        callBack={getListing} />
                    </div>

                    <FrzDynamicDownload name={'Inventory'} fileName={'Inventory'} url={'admin_dashboard/procurement/get_inventory_report'}
                      payload={'?node_id=' + `${nodeId ?? ''}` + '&by_name=' + `${valueByName ?? ''}`
                        + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}`}
                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  </div>
                </span>
              </Col>
            </Card>
          </Row>
          {
            listingData.length > 0 ?
              <Row>
                <Col span={24}>
                  <MainTable dataSource={listingData} columns={columns} rowKey='id' />
                  <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                    pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
                </Col>
              </Row>
              : (disable || spinEnable ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  !nodeId && !listingData.length ?
                    <div className="no-data">Please select a node</div>
                    :
                    <div className="no-data">No Data Available</div>)
                : listingData ?
                  <div className="no-data">No Data Available On Selected Filters</div>
                  : <div className="no-data">Please select a node</div>)
          }
        </div >
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
      {showViewDetails ? <ViewDetailsModal node={nodeId} data={curr} show={showViewDetails} hide={setShowViewDetails}/>:null}
    </div >
  )
}

export default CCWHInventory