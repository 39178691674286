import React from 'react'
import { Form, Select } from 'antd'
import { tailLayout } from '../_modules/controllayout';

const FrzDynamicSearch = ({
  name,
  label,
  isRequired,
  placeholder,
  DropDownData,
  option,
  value,
  mode,
  filterOption,
  isNone,
  isShowSearch,
  width,
  isDataKey,
  setOnChange,
  isDisabled,
  selectDisabled,
  type,
  marginLeft,
  marginRight,
  margin,
  defValue,
  isCustomOption,
  customKey,
  customValue,
  customOption,
  breakOption,
  customClass,
  allowClear
}) => {
  const { Option } = Select;

  const onChange = (e) => {
    if (setOnChange) {
      setOnChange(e, type)
    }
  }

  return (
    // <Form.Item
    //   {...tailLayout}
    //   name={name}
    //   label={label}
    //   rules={isRequired ? [{
    //     required: true,
    //     message: `Please input the value of ${label}!`,
    //   }
    //   ] : []}>
    <Select
      onChange={onChange} value={defValue}
      className={customClass ? customClass : ""}
      style={{ width: width, marginLeft: marginLeft, marginRight: marginRight, margin: margin }}
      showSearch={isShowSearch}
      disabled={selectDisabled ? selectDisabled : false}
      // filterOption={filterOption ? (input, option) =>
      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear={allowClear}
      mode={mode === undefined ? false : mode}
      placeholder={placeholder ? placeholder : label}>
      {isCustomOption ? <Option key={customKey} value={customValue}>{customOption}</Option> : null}
      {DropDownData?.map((data, index) => {
        return (
          <Option className={`${breakOption ? "frz-line-break" : ""}`} key={index} disabled={isDisabled ? isDisabled : false} value={value ? data[value] : data}>
            {value ? data[option] : data}
          </Option>
        )
      })}
    </Select>
    // </Form.Item>
  )
}

export default FrzDynamicSearch
