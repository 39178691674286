import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Collapse, Progress, Button, Tooltip } from 'antd'
import { faChevronUp, faChevronDown, faPencilAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalVeichleNo from './modalveichleno';
import { OperationsService } from '../../../_services/operations'
import BucketContainer from './bucketcontainers';

const { Panel } = Collapse;

const ops_service = new OperationsService()

const TransitCard = (props) => {
  const { transitBucketsData, getTransitBucketsData, nodeID, valueByNode, onChange,
    nodes, disable, spinEnable, handleCleanUp } = props


  return (
    <div className="frz-w-100">
      <BucketContainer transitBucketsData={transitBucketsData}
        getTransitBucketsData={getTransitBucketsData} nodeID={nodeID} spinEnable={spinEnable}
        valueByNode={valueByNode} onChange={onChange} nodes={nodes} disable={disable}
        handleCleanUp={handleCleanUp}
      />
    </div>
  )
}

export default TransitCard