import { Http } from '../_modules/http'

export class WastageService {

  http = new Http()

  get_crate_closing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'crate_dashboard/node_closing?node_code=' + `${payload.nodeCode}`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  post_crate_closing(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "crate_dashboard/node_closing";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }

  get_wastage_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/stock_loss?node_code=' + `${payload.nodeCode}`
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  revert_wastage(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/stock_loss";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }

  get_wastage_product_list(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/loss_items?id=' + `${payload.nodeId}`
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_wastage(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/create_daily_wastage";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }

  wastage_get_all_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/get_all_products?node_code=" + payload.nodeCode
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  stock_loss_approvals(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/stock_loss_approvals?created_by=' + payload.created_by
      + '&by_node=' + payload.by_node
      + "&page=" + payload.page
      + '&by_is_approved=' + payload.by_is_approved
      + '&by_product=' + payload.by_product
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }


  get_all_products(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/get_all_products?node_id=" + payload.nodeId
      + '&admin_id=' + JSON.parse(localStorage.getItem('auth')).id
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }



}
