import React, { useEffect, useState } from 'react'
import { Radio, Input, Card, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons';

const SidePanel = ({ nodeData, handleNodeType, nodeType, activeItem, handleActiveItem, listingData }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (nodeData.length) {
      setSearchTerm("")
      setSearchResults(nodeData)
    }
  }, [nodeData])

  useEffect(() => {
    if (nodeData) {
      const results = nodeData.filter(data =>
        data.name.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (nodeData.length) {
      if (!activeItem) {
        handleActiveItem(nodeData[0].id)
      }
    }
  }, [nodeData, nodeType])


  const handleOnItemClick = (id) => {
    handleActiveItem(id)
  }

  const onChangeNodeType = (value) => {
    handleNodeType(value)
  }

  return (
    <div>
      <Card className="frz-setting-card">
        {/* cc wh button */}
        <div>
          <Radio.Group disabled={!listingData} className="frz-setting-radio-button" buttonStyle="solid"
            onChange={(e) => onChangeNodeType(e.target.value)}
            value={nodeType} style={{ marginBottom: 8 }}>
            <Radio.Button value='CC'>CC</Radio.Button>
            <Radio.Button value="DS">DS</Radio.Button>
            <Radio.Button value="WH">WH</Radio.Button>
          </Radio.Group>
        </div>
        {/* serach */}
        <Input size="middle" placeholder="Search" prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleChange}
        />
      </Card >
      <Card className="frz-setting-card-p0 frz-setting-card-height frz-ovflw-scroll">
        {searchResults && activeItem ?
          searchResults.length ?
            <div>
              {searchResults.map((data, index) => {
                return (
                  <div key={index} className={`${"frz-sidepanel-items frz-sp-bw frz-pointer"} ${activeItem == data.id ? "frz-sp-active-item" : ""}`}
                    onClick={() => handleOnItemClick(data.id)}>
                    {data.name}
                  </div>
                )
              })}
            </div>
            :
            <div className="no-data">No Data Available</div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
      </Card>
    </div >
  )
}

export default SidePanel