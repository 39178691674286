import { Modal, Radio, Spin } from "antd"
import { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../../../_modules/notification";
import { OperationsService } from "../../../_services/operations";
import ListingCrate from "./listing_crate";
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
const ops_service = new OperationsService()
const CrateMgmtModal =({hide,show})=>{
    const [radioType,setRadioType]=useState(null);
    const [spinEnable, setSpinEnable]=useState(false)
    const [node,setNode]=useState(null);
    const [nodeListing,setNodeListing]=useState(null)
    const [nodeId,setNodeId]=useState(null);
    const [nodeCode,setNodeCode]=useState(null)
    const [nodeType,setNodeType]=useState(null)
    useEffect(()=>{
        if(nodeType){
          getNodes()
        }
    },[nodeType])
    const getNodes=()=>{
        const payload={
          nodeType:nodeType,
        }
        ops_service.get_node_listing(payload).subscribe((r) => {
          // console.log(r.response.data)
          setNodeListing(r.response.data)
          setSpinEnable(false)
        },
        (err) => {
          console.log(err)
          setSpinEnable(false)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    }
    const handleOk = () => {
        hide(false);
      };
    const handleCancel = () => {
        hide(false);
      };
    const onChange=(e)=>{
      setNode(true)
      setNodeId(e)
      for(let i=0;i<nodeListing.length;i++){
        if(nodeListing[i].id===e){
          setNodeCode(nodeListing[i].code)
          return
        }
      }
    }
    return(
        <>
        <Modal footer={false}  className='crate-management-modal' title="Crate Management Modal" visible={show}  onOk={handleOk} onCancel={handleCancel}>
        {/* <div className='frz-bm-10'> */}
        <div className='crate-radio frz-bm-10'><Radio.Group disabled={nodeType} onChange={(e)=>{setNodeType(e.target.value)}} buttonStyle="solid">
          <Radio.Button value="CC">CC</Radio.Button>
          <Radio.Button value="WH">WH</Radio.Button>
        </Radio.Group></div>
        {/* </div> */}
        {nodeType ?<div className='frz-bm-10'><FrzDynamicSearch DropDownData={nodeListing?nodeListing:null}
                    placeholder="Select Source Node" value="id" option="name"
                    isShowSearch={true} width={268} setOnChange={onChange}
                    type="nodeId"  defValue={nodeId} isDisabled={node} />
        </div>:null}
        {node ?<div className='crate-radio'><Radio.Group onChange={(e)=>setRadioType(e.target.value)} buttonStyle="solid">
          <Radio.Button value="new_crate">Introduce New Crate</Radio.Button>
          <Radio.Button value="discard_crate">Discard Crate</Radio.Button>
        </Radio.Group></div>:<p className='horizontal-vertical-center frz-font-20'>Please Select A Node {!nodeType?"Type":''}</p>}
        <div>
          {radioType ?<ListingCrate hide={hide} crateType={radioType} nodeCode={nodeCode} nodeId={nodeId} /> :(spinEnable?<div className="spin-center">
          <Spin tip="...Loading" />
          </div>:(nodeId && !radioType ?<p className='horizontal-vertical-center frz-font-20'>Please Select a Crate</p>:null))}
        </div>
      </Modal></>
    )
}
export default CrateMgmtModal