import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination, Input, DatePicker } from 'antd';
import { OperationsService } from "../../../_services/operations";
import { ReloadOutlined } from '@ant-design/icons';
import SupplierCard from '../../../components/operations/supplier/SupplierCard';
import SupplierDetailModal from '../../../components/operations/supplier/SupplierDetailModal';
import MapTracking from '../../../components/operations/supplier/MapTracking';
import SupplierEditModal from '../../../components/operations/supplier/SupplierEditModal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const operation_service = new OperationsService();

const defaultState = { valueByName: "", supplierMap: false }

const Suppliers = () => {

  const modalRef = useRef(null);
  const mapRef = useRef(null);
  const editModalRef = useRef(null)

  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [editMode, setEditMode] = useState(undefined)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [supplierid, setSupplierid] = useState(false)
  const [operations, setOperations] = useState(false)
  const [downloadOperations, setDownloadOperations] = useState(false)
  const [supplierCategory, setSupplierCategory] = useState(false)
  const [state, setState] = useState(defaultState);
  const [nodes, setNodes] = useState(false)
  const { valueByName, supplierMap } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      fetchMap()
    }
  })

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'procurement', 'procurement_admin', 'category_admin', 'category'].includes(data)) {
        setOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin'].includes(data)) {
        setDownloadOperations(true)
      }
    })
    getSupplierCategory()
    getNodes()
  }, [])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      valueByName: valueByName,
    }
    operation_service.get_supplier(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getNodes() {
    const payload = {
      nodeType: ''
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getSupplierCategory(){
    operation_service.get_supplier_category().subscribe((r) => {
      setSupplierCategory(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getSupplierDetail(data) {
    setSupplierid(data.id)
    mapRef.current.onActiveSupplier(data)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function showEditModal(data, type) {
    setEditMode(type)
    editModalRef.current.showEditModal(type, data)
  }

  function reset() {
    setState(defaultState)
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function fetchMap() {
    setState(prevState => ({ ...prevState, spinEnableMap: true }))
    operation_service.get_map_supplier().subscribe((r) => {
      setState(prevState => ({
        ...prevState,
        supplierMap: r.response.data,
        spinEnableMap: false
      }))
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function modalSubmit(payloadData) {
    const bankPayload = payloadData.supplier_bank_details_attributes

    delete payloadData.bank_name
    delete payloadData.bank_account_no
    delete payloadData.beneficiary_name
    delete payloadData.ifsc
    delete payloadData.supplier_bank_details_attributes

    const payload = {
      supplier: payloadData,
      category_parameters_id: payloadData.supplier_type === 'supplier' ? payloadData.category_parameters_id : [],
    }
    delete payload.supplier.category_parameters_id

    if (editMode === 'Edit Suppliers Information') {
      operation_service.update_supplier(payload).subscribe((r) => {
        if (payloadData.is_bank_details) {
          operation_service.update_supplier_bank(bankPayload).subscribe((r) => {
            openNotificationWithIcon('success', 'Edited Successfully')
            getListing()
            editModalRef.current.handleCancel()
          },(err) => {
            editModalRef.current.stopLoading()
            editModalRef.current.handleCancel()
            openNotificationWithIcon('error', err?.response?.errors?.[0])
          })
        }else{
          openNotificationWithIcon('success', 'Edited Successfully')
          getListing()
          editModalRef.current.handleCancel()
        }
      },
        (err) => {
          console.log(err)
          editModalRef.current.stopLoading()
          editModalRef.current.handleCancel()
          openNotificationWithIcon('error', err?.response?.errors?.[0])
        })
    }
  }

  return (
    <div className="layer">
      <Row>
        <Card>
          <Col span={24}>
            <Input
              placeholder="Name"
              onChange={(e) => onChange(e.target.value, "valueByName")}
              value={valueByName}
              className="frz-w-200 frz-m-10" />
            <Button disabled={disable} onClick={getListing}
              className="frz-w-100px frz-m-10" type="primary">Search</Button>
            <Button disabled={disable} onClick={reset} type="primary"
              className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
            {downloadOperations ?
              <span className="fl-right">
                <FrzDynamicDownload name={'Supplier'} fileName={'Supplier'} url={`admin_dashboard/procurement/supplier_report`}
                  payload={'?'} type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
              </span>
              : null}
          </Col>
        </Card>
      </Row>
      {listingData && supplierMap && supplierCategory && nodes ?
        <div>
          {listingData.length > 0 ?
            <>
              <Row>
                <Col span={8}>
                  <SupplierCard supplierData={listingData} supplierid={supplierid} getSupplierDetail={getSupplierDetail}
                    showModal={showModal} showEditModal={showEditModal} operations={operations} />
                  <Pagination className="frz-tm-10 frz-bm-10" current={current}
                    pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
                </Col>

                <Col span={16} className="frz-tp-10">
                  {state.supplierMap ?
                    <MapTracking supplierMap={supplierMap} supplierid={supplierid} ref={mapRef} />
                    :
                    <div className="spin-center"><Spin tip="...Loading" /></div>
                  }
                </Col>
              </Row>
            </>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <SupplierDetailModal ref={modalRef} listingData={listingData} />
          <SupplierEditModal ref={editModalRef} modalSubmit={modalSubmit} supplierCategory={supplierCategory} nodes={nodes}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default Suppliers