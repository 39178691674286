import { Col, InputNumber, Row } from "antd"

const EditStoNormal =({editNormalData,errorEnable,handleEditTQ})=>{
    return(
        <>
        <div>
                    {editNormalData?.length>0 ?<div><div className="table-headers-sto" >
                        <Row>
                            <Col span={8}>Product</Col>
                            <Col span={3} className="frz-text-center">Current Stock</Col>
                            <Col span={3} className="frz-text-center">Available Qty</Col>
                            <Col span={4}>Expected Transfer QTY</Col>
                            <Col span={4}>Transfer QTY</Col>
                            <Col span={2} className="frz-text-center">Units</Col>
                            <Col span={3}></Col>
                        </Row>
                    </div>
                <div className="table-details-sto">
                        {editNormalData?.map((item)=>{
                        return(<Row key={item?.id}>
                            <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p></div></Col>
                            <Col span={3} className="frz-text-center">{item?.current_stock}</Col>
                            <Col span={3} className="frz-text-center">{item?.available_qty}</Col>
                            <Col span={4}>
                                <InputNumber disabled={true} value={item?.expected_transfer_qty}    status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                            </Col>
                            <Col span={4}>
                                <InputNumber onChange={(value)=>handleEditTQ(value,item?.sku,item?.inventory_unit)} value={item?.transfer_qty}  className={(errorEnable && item?.error && !item?.transfer_qty) || item.isDecimal ?'wcl-input-error':''} status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                                {errorEnable && item?.error && !item?.transfer_qty ? <div className='wcl-input-error-msg'>Please enter a valid number</div>:null}
                                {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                            </Col>
                            <Col span={2} className="frz-text-center">{item?.inventory_unit}
                            {item?.unit_changed?<span style={{position: 'absolute',left:15,top:-29,background:'cyan',borderBottomLeftRadius:'4px',borderTopRightRadius:'4px',fontSize:'12px',padding:'0px 10px'}}>Unit Changed</span>:null}
                            </Col>
                        </Row>)})}
                </div></div>:<p className='sto-no-data'>No Sku's Found</p>}
            </div></>
    )
}

const EditStoCrate =({editCrateData,errorEnable,handleEditCrateTQ})=>{
    return(
        <>
        <div>
                {editCrateData?.length>0?
                <div>

                <div className="crate-title-container">
                    <Row>
                        <Col span={8} className='frz-align-left'>Crate Type</Col>
                        <Col span={3}>Available Qty</Col>
                        <Col span={3}>Transfer Crate Count</Col>
                    </Row>
                </div>
                <div className="crate-details-container">
                    {editCrateData?.map((item,i)=>
                     {
                        return(
                            <Row key={i}>
                                {/* <Col span={3}> <img src={item.image_url} width="50px" height='50px' alt={item.image_url}/></Col>
                                <Col span={3}>{item.name} ({item?.sku}) </Col> */}
                                
                                <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                                <Col span={3}>{item?.available_qty}</Col>
                                <Col span={3}>
                                            <InputNumber onChange={(value)=>handleEditCrateTQ(value,item?.sku)} value={item?.transfer_qty}  className={((errorEnable && item?.error) || item.isDecimal) &&  !item?.transfer_qty?'wcl-input-error':''} status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                                            {errorEnable && item?.error && !item?.transfer_qty? <p className='wcl-input-error-msg'>Please enter a valid number</p>:null}
                                            {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                                </Col>
                            </Row>
                        )
                })}
                </div>
                </div>:<p className='sto-no-data'>No Crate's Found</p>}
                </div></>
    )
}

const EditStoPackage =({editPackageData,errorEnable,handleEditPackageTQ})=>{
    return(
        <>
        <div>
                {editPackageData?.length>0?
                <div>

                <div className="crate-title-container">
                    <Row>
                        <Col span={8} className='frz-align-left'>Package Type</Col>
                        <Col span={3}>Available Qty</Col>
                        <Col span={3}>Transfer Package Count</Col>
                    </Row>
                </div>
                <div className="crate-details-container">
                    {editPackageData?.map((item,i)=>
                     {
                        return(
                            <Row key={i}>
                                {/* <Col span={3}> <img src={item.image_url} width="50px" height='50px' alt={item.image_url}/></Col>
                                <Col span={3}>{item.name} ({item?.sku}) </Col> */}
                                
                                <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                                <Col span={3}>{item?.available_qty}</Col>
                                <Col span={3}>
                                            <InputNumber onChange={(value)=>handleEditPackageTQ(value,item?.sku)} value={item?.transfer_qty}  className={((errorEnable && item?.error) || item.isDecimal) &&  !item?.transfer_qty?'wcl-input-error':''} status='error'  style={{width:'150px'}} min={0} placeholder="Transfer Qty"/>
                                            {errorEnable && item?.error && !item?.transfer_qty? <p className='wcl-input-error-msg'>Please enter a valid number</p>:null}
                                            {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                                </Col>
                            </Row>
                        )
                })}
                </div>
                </div>:<p className='sto-no-data'>No Packages's Found</p>}
                </div></>
    )
}

export {EditStoNormal,EditStoCrate,EditStoPackage};