import { CheckCircleOutlined } from "@ant-design/icons"
import { Col, InputNumber, Row } from "antd"

const InwardStoNormal =({inwardNormal,handleReceivedRejQTY,handleApprove})=>{
    return(
        <>
        <div>
                {inwardNormal?.length>0?<>
                <div className="table-headers-sto">
                <Row>
                    <Col span={8}>SKU</Col>
                    <Col span={2} className="frz-text-center">Inventory Unit</Col>
                    <Col span={3} className="frz-text-center">Expected Transfer QTY</Col>
                    <Col span={2} className="frz-text-center">Transfer Qty</Col>
                    <Col span={3} className="frz-text-center">Received QTY</Col>
                    <Col span={3} className="frz-text-center">Rejected Qty</Col>
                    <Col span={3} className="frz-text-center">Action</Col> 
                </Row>
                </div>
                <div className="table-details-sto">
                        {inwardNormal?.length > 0 ? inwardNormal?.map((item,i)=>{
                        return(<Row key={i}>
                            <Col span={8} className="frz-text-center"><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt='prod_img' width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                            <Col span={2} className="frz-text-center">{item?.inventory_unit}</Col>
                            <Col span={3} className="frz-text-center">
                                {item?.expected_transfer_qty}
                            </Col>
                            <Col span={2} className="frz-text-center">
                                {item?.transfer_unit_qty}
                            </Col>
                            <Col span={3} className="frz-text-center">
                                <InputNumber value={item?.qty}  style={{width:'130px'}} className={ item?.errorMsg || item.error || item.isDecimal ?'wcl-input-error':''} min={0} placeholder="Received Qty" onChange={(value)=>handleReceivedRejQTY('receive',value,item?.sku,'normal',item?.inventory_unit)}/>
                                { item?.errorMsg? <p className='wcl-input-error-msg' >{item?.errorMsg==='Less'?'Total Qty is less than the transfer Qty':'Total Received is greater than the transfer Qty'}</p>:null}
                                {item?.error ?<div className='wcl-input-error-msg'>Missing QTY</div>:null}
                                {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}    
                            </Col>
                            <Col span={3} className="frz-text-center">
                                <InputNumber value={item?.rejected_qty} className={ item?.errorMsg ||item.error || item.isDecimal ?'wcl-input-error':''} style={{width:'130px'}} min={0} placeholder="Rejected Qty" onChange={(value)=>handleReceivedRejQTY('rej',value,item?.sku,'normal',item?.inventory_unit)}/>
                                {item?.error ?<div className='wcl-input-error-msg'>Missing QTY</div>:null}
                                {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                            </Col>
                          <Col span={3} className="frz-text-center">
                          {item?.totalvalue  ?<div className='tabs-to-center frz-text-center'>
                              <div className="frz-box-btn">
                                <div className={item?.isApproved ? "frz-circle-button-approve-active" : "frz-circle-button-approve"} onClick={()=>handleApprove(item?.sku,'normal')}>
                                  <CheckCircleOutlined />
                                </div>
                              </div>
                            </div>:null}
                          </Col>
                        </Row>)}):null}
                </div>
                </>:<p className='sto-no-data'>No SKU's available</p>}
                
            </div>
            </>
    )
}
const InwardStoCrate=({inwardCrate,handleApprove,handleReceivedRejQTY})=>{
    return(
        <>
        <div>
                    {inwardCrate?.length>0 ?<div>
                        <div className="table-headers-sto">
                            <Row>
                                <Col span={8} className='frz-align-left'>Crate Type</Col>
                                <Col span={3} className="frz-text-center">Transfer Qty</Col>
                                <Col span={3} className="frz-text-center">Received QTY</Col>
                                <Col span={3} className="frz-text-center">Action</Col>
                            </Row>
                        </div>
                        <div className="table-details-sto">
                            {inwardCrate ?inwardCrate?.map((item,i)=>
                                {
                                return(
                                    <Row key={i}>
                                        <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt={item?.image_url} width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                                        <Col span={3} className="frz-text-center">
                                        {item?.transfer_unit_qty}
                                        </Col>
                                        <Col span={3} className="frz-text-center">
                                            <InputNumber value={item?.qty}  style={{width:'130px'}} min={0} className={ item?.errorMsg||item.error || item.isDecimal ?'wcl-input-error':''} placeholder="Received Qty" onChange={(value)=>handleReceivedRejQTY('receive',value,item?.sku,'crate')}/>
                                            { item?.errorMsg   ? <p className='wcl-input-error-msg'>{item?.errorMsg  ==='Less'?'Total Qty is less than the transfer Qty':'Total Received is greater than the transfer Qty'}</p>:null}
                                            {item?.error  ?<div className='wcl-input-error-msg'>Missing QTY</div>:null}
                                            {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                                        </Col>
                                        <Col span={3} className="frz-text-center">
                                        {item?.totalvalue && !item?.isDecimal ?<div className='tabs-to-center frz-text-center'>
                                            <div className="frz-box-btn">
                                                <div className={item?.isApproved ? "frz-circle-button-approve-active" : "frz-circle-button-approve"} onClick={()=>handleApprove(item?.sku,'crate')}>
                                                <CheckCircleOutlined />
                                                </div>
                                            </div>
                                        </div>:null}
                                        </Col>
                                    </Row>
                                )
                            }):null}
                        </div>            
                </div>:<p className='sto-no-data'>No Crate's Found</p>}
                </div></>
    )
}
const InwardStoPackage=({inwardPackageData,handleApprove,handleReceivedRejQTY})=>{
    return(
        <>
        <div>
                    {inwardPackageData?.length>0 ?<div>
                        <div className="table-headers-sto">
                            <Row>
                                <Col span={8} className='frz-align-left'>Package Type</Col>
                                <Col span={3} className="frz-text-center">Transfer Qty</Col>
                                <Col span={3} className="frz-text-center">Received QTY</Col>
                                <Col span={3} className="frz-text-center">Action</Col>
                            </Row>
                        </div>
                        <div className="table-details-sto">
                            {inwardPackageData ?inwardPackageData?.map((item,i)=>
                                {
                                return(
                                    <Row key={i}>
                                        <Col span={8}><div className="frz-dis-flex product-img-container"><img src={item?.image_url} alt={item?.image_url} width='50px' height='50px' /> <p className='product-name-sto'>{item?.name} ( {item?.sku} )</p> </div></Col>
                                        <Col span={3} className="frz-text-center">
                                        {item?.transfer_unit_qty}
                                        </Col>
                                        <Col span={3} className="frz-text-center">
                                            <InputNumber value={item?.qty}  style={{width:'130px'}} min={0} className={ item?.errorMsg||item.error || item.isDecimal ?'wcl-input-error':''} placeholder="Received Qty" onChange={(value)=>handleReceivedRejQTY('receive',value,item?.sku,'package')}/>
                                            { item?.errorMsg   ? <p className='wcl-input-error-msg'>{item?.errorMsg  ==='Less'?'Total Qty is less than the transfer Qty':'Total Received is greater than the transfer Qty'}</p>:null}
                                            {item?.error  ?<div className='wcl-input-error-msg'>Missing QTY</div>:null}
                                            {item?.isDecimal ? <div className='wcl-input-error-msg'>Please enter a integer values</div>:null}
                                        </Col>
                                        <Col span={3} className="frz-text-center">
                                        {item?.totalvalue && !item?.isDecimal ?<div className='tabs-to-center frz-text-center'>
                                            <div className="frz-box-btn">
                                                <div className={item?.isApproved ? "frz-circle-button-approve-active" : "frz-circle-button-approve"} onClick={()=>handleApprove(item?.sku,'package')}>
                                                <CheckCircleOutlined />
                                                </div>
                                            </div>
                                        </div>:null}
                                        </Col>
                                    </Row>
                                )
                            }):null}
                        </div>            
                </div>:<p className='sto-no-data'>No Packages's Found</p>}
                </div></>
    )
}
export {InwardStoNormal, InwardStoCrate,InwardStoPackage}