import React,{ useEffect, useState} from "react";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { Select,Input, Button, DatePicker, Dropdown, Radio, Menu,Tooltip, Spin} from "antd"
import { OperationsService } from "../../../_services/operations";
import { openNotificationWithIcon } from "../../../_modules/notification";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import FrzDynamicDownload from "../../../_controls/FRZDynamicDownload";
import FrzDynamicSearch from "../../../_controls/FrzDynamicSearch";
import FrzDynamicUpload from "../../../_controls/FRZDynamicUpload";
import ModalUpdateSTO from "./ModalUpdateSTO";
import { setSTO } from "../../../_actions";
const { Option } =Select;
const { Search } = Input;
const ops_service = new OperationsService()
const dateFormat = 'YYYY/MM/DD';
let timer;
const pageIndex={
  'sto_outward':'out',
  'sto_inward':'in',
}
const FilterTab =({setScreen,setPayload,handleStoModal,node,page,setPage,setApiError,setNode,curr,setSpinEnable,stoData,setStoData,showCreate,setShowCreate,showStoShipment,setShowStoShipment})=>{
    const [status] = useState([{ id: 'dispatched', name: 'Dispatched' }, { id: 'delivered', name: 'Delivered' },
  { id: 'cancelled', name: 'Cancelled' }, { id: "material_allocated", name: "Material Allocated" },
  { id: "assigned", name: "Assigned" }, { id: "shipment_assigned", name: "Shipment Assigned" },
  { id: "ready_to_dispatch", name: "Ready To Dispatch" }])
    const [nodes,setNodes]=useState(false)
    const [destinationNodes,setDestinationNodes]=useState(false)
    const [flag,setFlag]=useState(false)
    const [state, setState] = useState({
        valueByNode: undefined, valueByDestination: undefined,showDateValue:false ,valueByStatus: undefined,start_date:undefined,end_date:undefined,
        valueByDate: undefined, date: undefined, valueByOrderNumber: undefined,city_type:undefined,inter_city:undefined,date_type:undefined,by_source_node_type:undefined,by_destination_node_type:undefined,valueByDestinationNode:undefined,per_page:20,
        valueByProductId:undefined,valueByProductName:undefined
      });
    const [prodSpin,setProdSpin]=useState(false);
    const [productList,setProductList]=useState([])
    const [updateStoModal, setUpdateStoModal] = useState(false)
    const { valueByNode, valueByDestination,start_date,end_date,showDateValue,valueByProductName ,inter_city,valueByStatus,valueByDestinationNode, valueByDate, date, valueByOrderNumber,city_type,date_type,by_source_node_type ,by_destination_node_type,per_page,valueByProductId} = state
    const [disable, setDisable] = useState(true)
    const [reset,setReset]=useState(false)
    const [sourcenode,setSourcenode] = useState(undefined)
    const [destinationnode,setDestinationnode]=useState(undefined)
    const [loading,setLoading] = useState(false)
    const [call,setCall]=useState(false)
    const dispatch = useDispatch();
    const [isSearched,setIsSearched]=useState(false)
    const STO = useSelector(state => state.auth.sto);
    useEffect(()=>{
      if(STO?.curr===curr){
        setPage(1)
        setState((prev)=>({...prev,...STO}))
        if(STO?.destinationNT){
            setDestinationnode(STO.destinationNT)
        }
        if(STO?.sourceNT){
          setSourcenode(STO.sourceNT)
        }
        setDisable(false)
        setCall(true)
      }
      else{
        getSto()
      }
    },[])
    useEffect(()=>{
      if(call){
        getSto()
      }
    },[call])
    function getDestinationNodes(){
      const payloadDestNode = {
        nodeType:destinationnode ? destinationnode: curr==='sto_inward'?`["CC","WH"]`:`["CC","WH","DS"]`,
        no_admin:true
      }
      ops_service.get_node_listing(payloadDestNode).subscribe((r) => {
        setDestinationNodes(r.response.data)
        setSpinEnable(false)
      },
        (err) => {
          console.log(err)
        })
    }
    useEffect(()=>{
      if(reset){
        getSto()
      }
    },[reset])
    useEffect(()=>{
        getNodes();
    },[sourcenode])
    useEffect(() => {
        getDestinationNodes()
    },[destinationnode])
    function getNodes() {
        setSpinEnable(true)
        const payload = {
          nodeType: sourcenode ? sourcenode:`["CC","WH","DS"]`
        }
        ops_service.get_node_listing(payload).subscribe((r) => {
          setNodes(r.response.data)
          setSpinEnable(false)
        },
          (err) => {
            console.log(err)
          setSpinEnable(false)
            setApiError(true)
          })
      }
      const handleSearch=()=>{
        setReset(false)
        store();
        if(page>1){
          setPage(1)
        }else{
          getSto()
        }
      }
    const onChange=(value, type)=>
    {
      setIsSearched(true)
      if(value){
        setDisable(false)
      }
      // if(type!=='valueByOrderNumber')setPage(1)
      if(type==='by_source_node_type'){
        setSourcenode(value)
        setState((prev)=>({...prev,valueByNode:undefined}))
      }
      if(type==='by_destination_node_type'){
        setDestinationnode(value)
        setState((prev)=>({...prev,valueByDestinationNode:undefined}))
      }
        setState((prev)=>(
          {...prev,[type]:value}
        ))
    }
    useEffect(()=>{
      if(flag){
        getSto()
      }
    },[page])
    function getSto() {
        setApiError(false)
        setLoading(true)
        setIsSearched(false)
        setFlag(true)
        setSpinEnable(true)
        setStoData([])
        const payload = {
          sto_type: pageIndex[curr]?pageIndex[curr]:'',
          inter_city:inter_city ? inter_city:'',
          by_source_city:curr==='sto_outward' ?localStorage.getItem('city'):'',
          by_destination_city: curr==='sto_inward' ? localStorage.getItem('city'):(valueByDestination?valueByDestination:''),
          by_source: valueByNode && curr!=='sto_inward' ? valueByNode:'',
          by_destination: Number(valueByDestinationNode) ? Number(valueByDestinationNode) :'',
          by_procurement_type:localStorage.getItem('procurementtype')?localStorage.getItem('procurementtype'):'',
          date_type:start_date && end_date && date_type ?  date_type:'',
          start_date: start_date ? start_date : '',
          end_date: end_date ? end_date : '',
          by_destination_node_type:by_destination_node_type?by_destination_node_type:'',
          by_source_node_type :by_source_node_type?by_source_node_type:'',
          by_order_number: valueByOrderNumber ? valueByOrderNumber : '',
          page:page?(isSearched?1:page):1,
          per_page:per_page?per_page:'',
          by_status:curr==='sto_inward' ?(valueByStatus?valueByStatus:`["dispatched","delivered"]`):(valueByStatus?valueByStatus:''),
          by_product:valueByProductId ?? ''
        }
        ops_service.get_sto_list(payload).subscribe((r) => {
          setSpinEnable(false)
            setStoData(r.response)
            setLoading(false)
        },
          (err) => {
            console.log(err)
            setApiError(true)
            setSpinEnable(false)
            setLoading(false)
          })
      }
      const handleCreateSto=()=>{
        let procurementType=localStorage.getItem('procurementtype');
        if(!procurementType){
          openNotificationWithIcon('error','Please select a procurement type on top right')
        }
        if(procurementType) {
          setShowCreate(true);
          setScreen('CreateSTO')
        }
      }
      const resetFields=()=>{
        dispatch(setSTO({},'STO'))
        setState({
          valueByNode: undefined,start_date:undefined, end_date:undefined,showDateValue:false,valueByDestination:undefined, valueByStatus: undefined,by_source_node_type:undefined,by_destination_node_type:undefined,
          valueByDate: undefined, date: undefined, valueByOrderNumber: undefined,city_type:undefined,date_type:undefined,destination_node:undefined,valueByDestinationNode:undefined,page:1,per_page:20,
          valueByProductId:undefined,valueByProductName:undefined
        })
        setSourcenode(undefined)
        setDestinationnode(undefined)
        setApiError(false)
        setDisable(true)
        setIsSearched(false)
        if(page>1){
          setPage(1)
        }else{
          setFlag(true)
          setReset(true)
          // setCall(true)
        }
      }
      const store=()=>{
        dispatch(setSTO({...state,curr:curr,destinationNT:destinationnode,sourceNT:sourcenode,page:1},'STO'))
      }
      useEffect(()=>{
        curr==='sto_outward' && setPayload(state) 

      },[state])
      function onChangeRangePicker(value, dateString) {
        setIsSearched(true)
        setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
      }
      const debounce = (cb, d) => {
        return function (...args) {
          setProdSpin(true)
          setProductList([])
            clearInterval(timer);
          timer = setTimeout(() => {
            cb(...args);
          }, d);
        };
      };
      const handleChange=debounce((e)=>{
          let value=e;
          if(value.length>=3){
            const payload = { by_name:value,procurement_type:localStorage.getItem('procurementtype')}
            ops_service.get_sku_list(payload).subscribe((r) => {
            setProdSpin(false)
            setProductList(r.response.data)
          },
            (err) => {
              setProdSpin(false)
              console.log(err)
            }) 
          }
      },1000)
    return(
        <>
        <div className="frz-dis-flex frz-justify-sb sto-filter-container">
           <div className="frz-dis-flex left-sto-filter-container">
           <div className='intra-city-inter-city'>
                          <Radio.Group value={inter_city} buttonStyle="solid"  onChange={(value)=>onChange(value.target.value,'inter_city')}>
                              <Tooltip title='Within a City'> 
                              <Radio.Button  value="false">Intra City</Radio.Button>  
                              </Tooltip>
                              <Tooltip title='City To City'> 
                              <Radio.Button  value="true">Inter City</Radio.Button>
                              </Tooltip>
                          </Radio.Group>
                  </div>
             { curr !=='sto_inward' ?<div>
               <Select placeholder="Source Node Type" value={sourcenode}  onChange={(value)=>onChange(value,'by_source_node_type')}>
                 <Option value={'CC'}>CC</Option>
                 <Option value={'WH'}>WH</Option>
                 <Option value={'DS'}>DS</Option>
               </Select>
             </div>:null}
                  {curr!=='sto_inward'?
                    <FrzDynamicSearch DropDownData={nodes?nodes:null}
                    placeholder="Source Node" value="id" option="name"
                    isShowSearch={true}  setOnChange={onChange}
                    type="valueByNode"  defValue={valueByNode} />:null
                  }
                  <div>
                    <Select placeholder="Destination Node Type" value={destinationnode} onChange={(value)=>onChange(value,'by_destination_node_type')}>
                      <Option value={'CC'}>CC</Option>
                      <Option value={'WH'}>WH</Option>
                      {curr==='sto_outward' ?<Option value={'DS'}>DS</Option>:null}
                    </Select>
                  </div>
                  {((curr==='sto_outward' || curr==='sto_inward')) || curr==='sto_inward' ?<FrzDynamicSearch DropDownData={destinationNodes?destinationNodes:null}
                    placeholder="Destination Node" value="id" option="name"
                    isShowSearch={true}  setOnChange={onChange}
                    type="valueByDestinationNode"  defValue={valueByDestinationNode} />:null}
                <div>
                      <Select placeholder="Date Type" value={date_type} onChange={(value)=>onChange(value,'date_type')}>
                        <Option value='delivery'>Delivery Date</Option>
                        <Option value='dispatch'>Dispatched Date</Option>
                        <Option value='inward'>Inward Date</Option>
                      </Select>
                    </div>
                  { date_type?<div>
                      <DatePicker.RangePicker value={showDateValue ? [moment(start_date, dateFormat),
                moment(end_date, dateFormat)] : ''} format={dateFormat}
                allowClear={false} onChange={onChangeRangePicker} />
                  </div>:null}
                  <div className="frz-rm-10">
                  <FrzDynamicSearch DropDownData={curr==='sto_outward'?status:[{ id: 'dispatched', name: 'Dispatched' }, { id: 'delivered', name: 'Delivered' }]}
                  placeholder="Select Status" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'valueByStatus')}
                  type="valueByStatus"  defValue={valueByStatus} />
                  </div>

                  <div className="filter-search-bar frz-dis-flex">
                      <Input placeholder="Search with STO No"  value={valueByOrderNumber} onChange={(e)=>onChange(e?.target?.value,'valueByOrderNumber')}></Input>
                  </div>
                  <div className='search-bar-sku sku-main-box'>
                        <Select placeholder={'Please Search Product'} showSearch 
                          showArrow={false} value={valueByProductName} filterOption={false} className="search-sku-container"
                          onSearch={handleChange} style={{width:"300px"}} onChange={(e,f) => {onChange(e,'valueByProductId');onChange(f.name+"  -  ("+f.sku+")",'valueByProductName')}}
                          notFoundContent={null}>
                          {productList?.length>0 ? productList?.map(data => {
                            return <Option  value={data.id} sku={data.sku} name={data.name}  key={data.id}>
                              <div className="frz-dis-flex frz-justify-sb">
                                <div><img src={data.image_url} alt={data.image_url} width='30px' heigth='30px'/>  {data.name} - ({data.sku})</div> 
                                </div>
                              </Option>
                          }):(prodSpin ?<div className="spin-center add-sku-loader"><Spin tip="...Loading" /></div>:null)}
                        </Select>
                  </div>
                  <div><Button type='primary'  disabled={disable} loading={loading} onClick={handleSearch}>Search</Button></div>
                  <div> <Button disabled={disable} onClick={resetFields} type="primary"
                   ghost shape="circle" icon={<ReloadOutlined />} /></div>
           </div>
           <div className="frz-dis-flex filter-right-container"> 
           {curr==='sto_outward'? <div>
                      {!localStorage.getItem('procurementtype') || !localStorage.getItem('city')?<>
                      <Tooltip title={`please select a ${!localStorage.getItem('procurementtype')?"procurement type":'city'}  on top`}>
                        <Button disabled={true}>Create STO</Button>
                      </Tooltip>
                      </>:
                        <Button type='primary'  onClick={handleCreateSto}>Create STO</Button>}
                  </div>:null}

                  {curr==='sto_outward' ? <div >
                      {!localStorage.getItem('city')?<Tooltip title={`please select a city from top right`}>
                        <Button disabled={true}>Upload STO</Button>
                      </Tooltip>:
                      <Button type='primary'ghost disabled={localStorage.getItem('city')?false:true} onClick={() => handleStoModal(true)}>Upload STO</Button>}
                  </div>:null}
                  {
                    curr==='sto_outward' ?<><Button onClick={() => setUpdateStoModal(true)} className="frz-w-100px" type="primary">
                    Update STO
                  </Button></>:null
                  }
              <Dropdown  className='export-csv-drop' overlay={<Menu>
               {curr==='sto_outward' ? <FrzDynamicDownload isDisabled={curr==='sto_inward'?'true':''} name={'Order Sheet'} fileName={'Order Sheet'} url={'admin_dashboard/procurement/sto_report'}
                    payload={'?by_source=' + (valueByNode??'') + '&sto_type=' + (pageIndex[curr]??'') 
                      + '&by_destination=' +(valueByDestinationNode?valueByDestinationNode:'')
                      + '&start_date=' + (start_date ? start_date : '') + '&end_date=' + (end_date ?? '') + "&by_order_number=" + (valueByOrderNumber??'')
                      + '&by_source_city='+(curr==='sto_outward' ?localStorage.getItem('city'):'') 
                      + '&inter_city=' + (inter_city??'')
                      + '&by_destination_city=' +(curr==='sto_inward' ? localStorage.getItem('city'):(valueByDestination?valueByDestination:''))
                      + '&date_type='+(start_date && end_date && date_type ?  date_type:'')
                      + '&by_status=' + (valueByStatus?valueByStatus:'')
                      + '&by_source_node_type=' + (by_source_node_type?by_source_node_type:'')
                      + '&by_destination_node_type=' + (by_destination_node_type?by_destination_node_type:'')
                      + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&order_sheet=true'}
                    type={'job'} reportFunc={'zapper_report_without_global_city'} jobReportFunc={'zapper_job_report'} base={'zapper'} />:null}
                  <FrzDynamicDownload name={'STO'} fileName={'STO'} url={'admin_dashboard/procurement/sto_report'}
                    payload={'?by_source=' + (valueByNode??'') + '&sto_type=' + (pageIndex[curr]??'') 
                    + '&by_destination=' + (valueByDestinationNode?valueByDestinationNode:'')
                    + '&start_date=' + (start_date ? start_date : '') + '&end_date=' + (end_date ?? '') + "&by_order_number=" + (valueByOrderNumber??'')
                    + '&by_source_city='+(curr==='sto_outward' ?localStorage.getItem('city'):'') 
                    + '&inter_city=' + (inter_city??'')
                    + '&by_destination_city=' +(curr==='sto_inward' ? localStorage.getItem('city'):(valueByDestination?valueByDestination:''))
                    + '&date_type='+(start_date && end_date && date_type ?  date_type:'')
                    + '&by_status=' + (valueByStatus?valueByStatus:'')
                    + '&by_source_node_type=' + (by_source_node_type?by_source_node_type:'')
                    + '&by_destination_node_type=' + (by_destination_node_type?by_destination_node_type:'')
                      + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&operations=true'}
                    type={'job'} reportFunc={'zapper_report_without_global_city'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  {curr==='sto_outward' ?<FrzDynamicDownload   name={'Shortfall'} fileName={'Shortfall'} url={`admin_dashboard/procurement/shortfall_report`}
                    payload={'?by_source=' + (valueByNode??'') + '&sto_type=' + (pageIndex[curr]??'') 
                    + '&by_destination=' + (valueByDestinationNode?valueByDestinationNode:'')
                    + '&start_date=' + (start_date ? start_date : '') + '&end_date=' + (end_date ?? '') + "&by_order_number=" + (valueByOrderNumber??'')
                    + '&by_source_city='+(curr==='sto_outward' ?localStorage.getItem('city'):'') 
                    + '&inter_city=' + (inter_city??'')
                    + '&by_destination_city=' +(curr==='sto_inward' ? localStorage.getItem('city'):(valueByDestination?valueByDestination:''))
                    + '&date_type='+(start_date && end_date && date_type ?  date_type:'')
                    + '&by_status=' + (valueByStatus?valueByStatus:'')
                    + '&by_source_node_type=' + (by_source_node_type?by_source_node_type:'')
                    + '&by_destination_node_type=' + (by_destination_node_type?by_destination_node_type:'')}
                    type={'job'}
                    reportFunc={'zapper_report_without_global_city'} jobReportFunc={'zapper_job_report'} base={'zapper'} />:null}
                                      </Menu>} placement={'topRight'} arrow={{ pointAtCenter: true }}>
                      <div className="export-csv-btn"><Button>
                          <span className="filter-download-btn"><DownloadOutlined  /></span>
                          <span>Export to CSV</span>
                      </Button></div>
                  </Dropdown>
                    
           </div>
        </div>
        {updateStoModal ?
            <ModalUpdateSTO updateStoModal={updateStoModal} setUpdateStoModal={(value) => setUpdateStoModal(value)} getSto={getSto} />
            : null}
        </>
    )
}


export default FilterTab
