import React from 'react'
import { Form, DatePicker } from 'antd'
import { tailLayout } from '../_modules/controllayout'
const FRZDatepicker = ({ name, label, isRequired, placeholder, isDisabled, disabledDate, width, setOnChange }) => {


    const onChange = (e) => {
        if (setOnChange) {
            setOnChange(e)
        }
    }


    return (
        <Form.Item
            {...tailLayout}
            name={name}
            label={label}
            rules={isRequired ? [
                {
                    required: true,
                    message: `Please input the value of ${label}!`,
                },
            ] : []}
        >
            <DatePicker placeholder={placeholder} format={'YYYY/MM/DD'} disabled={isDisabled}
                style={{ width: width ? width : '200px' }} disabledDate={disabledDate} onChange={onChange} />
        </Form.Item>
    )
}

export default FRZDatepicker
