import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Spin, Button, Card, Row, Col, InputNumber, Pagination, Popconfirm, Table, DatePicker, Select } from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import MainTable from '../../../components/antd/table';
import { poColumns } from '../../../components/antd/columns/operations/category';
import PoModal from '../../../components/operations/category/po_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import NestedPoTable from '../../../components/operations/category/nested_po';
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ops_service = new OperationsService()
const {Option}= Select;
let timer;
function PoList() {
  const modalRef = useRef(null);
  const [poData, setPoData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [products, setProducts] = useState(false)
  const [operations, setOperations] = useState(false)
  const [fetchColm, setFetchColm] = useState(false)
  const [status] = useState([{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }])
  const [state, setState] = useState({
    valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
    start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined,
    valueByProductCode: undefined
  });
  const [prodSpin,setProdSpin]=useState(false)
  const { valueByNode, valueBySupplier, valueByOrder, start_date, end_date,
    showDateValue, valueByStatus, valueByProductCode } = state
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getPo()
    }
  })

  useEffect(() => {
    getNodes()
    getSuppliers()
    // getProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'category', 'category_admin', 'cc_admin', 'procurement_admin', 'procurement'].includes(data)) {
        setOperations(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false)
      getColumns()
    }
  })

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`,
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getSuppliers() {
    const payload = {
      no_city: ''
    }
    ops_service.get_suppliers(payload).subscribe((r) => {
      setSuppliers(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  // function getProducts() {
  //   ops_service.get_product_list().subscribe((r) => {
  //     let tempData = r.response.data.map(v => ({ ...v, name_sku: v.name + " - (" + v.sku + ")" }))
  //     setProducts(tempData)
  //   },
  //     (err) => {
  //       console.log(err)
  //     })
  // }

  function getPo() {
    setSpinEnable(true)
    setPoData([])
    const payload = {
      page: current,
      by_node: !valueByNode ? '' : valueByNode,
      by_supplier: !valueBySupplier ? '' : valueBySupplier,
      by_order_number: !valueByOrder ? '' : valueByOrder,
      start_date: start_date,
      end_date: end_date,
      by_status: valueByStatus === undefined ? '' : valueByStatus,
      admin_id: JSON.parse(localStorage.getItem('auth')).id,
      by_product: valueByProductCode ?? ''
    }

    ops_service.get_po_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setPoData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    poColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit/Status',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {record.is_active ?
              <Popconfirm
                title="Are you sure you want to cancel this po?"
                onConfirm={() => cancelPO(record.id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
                disabled={!operations}
              >
                <Button type="primary" ghost disabled={!operations}>
                  Cancel
                </Button>
              </Popconfirm>
              : <div className='color-red'>CANCELLED</div>
            }
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    if (type === 'Create Po' && localStorage.getItem('city') === '') {
      openNotificationWithIcon('error', 'Please select a city on top right')
      return
    }
    if ((localStorage.getItem('procurementtype') === null || localStorage.getItem('procurementtype') === '')) {
      openNotificationWithIcon('error', 'Please select a procurement type on top right')
      return
    }
    modalRef.current.showModal(type, data)
  }

  function cancelPO(id) {
    setSpinEnable(true)
    setPoData([])
    ops_service.cancel_po(id).subscribe((r) => {
      openNotificationWithIcon('success', 'PO Cancelled Successfully')
      getPo()
    },
      (err) => {
        console.log(err)
        getPo()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
      start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined, valueByProductCode: undefined
    })
    setDisable(true)
    setCurrent(1)
    setPoData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setPoData(false)
    setRenderData(false)
  }

  function modalSubmitPo(payloadData) {
    const payload = {
      ...payloadData, created_by: JSON.parse(localStorage.getItem('auth')).id,
      by_city: localStorage.getItem('city') ?? '',
      procurement_type: localStorage.getItem('procurementtype')
    }
    ops_service.create_po(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      getPo()
      modalRef.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function expandedRow(record) {
    return <NestedPoTable data={record} products={products} />
  }

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current) {
    return current && (current < moment().subtract(91, "day") || current > moment().add(10, "day"));
  }
  const debounce = (cb, d) => {
    return function (...args) {
      setProdSpin(true)
      setProducts([])
        clearInterval(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, d);
    };
  };
  const handleChange=debounce((e)=>{
      let value=e;
      if(value.length>=3){
        const payload = { by_name:value,procurement_type:localStorage.getItem('procurementtype')}
        ops_service.get_sku_list(payload).subscribe((r) => {
        setProdSpin(false)
        setProducts(r.response.data)
      },
        (err) => {
          setProdSpin(false)
          console.log(err)
        }) 
      }
  },1000)
  return (
    <div className="layer">
      {poData && nodes && suppliers ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                  value={showDateValue ? [moment(start_date, dateFormat),
                  moment(end_date, dateFormat)] : ''} format={dateFormat}
                  allowClear={false} disabledDate={disabledDate} />

                <FrzDynamicSearch DropDownData={nodes}
                  placeholder="Select Nodes" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueByNode" margin={10} defValue={valueByNode} />

                <FrzDynamicSearch DropDownData={suppliers}
                  placeholder="Select Suppliers" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueBySupplier" margin={10} defValue={valueBySupplier} />

                <FrzDynamicSearch DropDownData={status}
                  placeholder="Select Status" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueByStatus" margin={10} defValue={valueByStatus} />
                <span className='search-bar-sku' style={{width:'300px'}}>
                        <Select placeholder={'Please Search Product'} showSearch 
                          showArrow={false} value={valueByProductCode} filterOption={false} className="search-sku-container"
                          onSearch={handleChange} onChange={(e) => onChange(e,'valueByProductCode')}
                          notFoundContent={null}>
                          {products?.length>0 ? products?.map(data => {
                            return <Option  value={data.sku}  key={data.id}>
                              <div className="frz-dis-flex frz-justify-sb">
                                <div><img src={data.image_url} alt={data.image_url} width='30px' heigth='30px'/>  {data.name} - ({data.sku})</div> 
                                </div>
                              </Option>
                          }):(prodSpin ?<div className="spin-center add-sku-loader"><Spin tip="...Loading" /></div>:null)}
                        </Select>
                  </span>

                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "valueByOrder")}
                  value={valueByOrder}
                  className="frz-width-150p frz-m-10" />

                <Button disabled={disable} onClick={getPo}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Po')} className="frz-w-100px frz-m-10" type="primary"
                  disabled={!operations}>
                  Create Po
                </Button>
                <div className="frz-dis-upload dynamic-upload">
                  <FrzDynamicUpload name={'Update Po'} url={'admin_dashboard/procurement/upload_po_data?created_by='
                    + `${JSON.parse(localStorage.getItem('auth')).id}`}
                    uploadFunc={'upload_zapper_put'} callBack={getPo} disabled={!operations} />
                  <FrzDynamicUpload checkPoType={true} name={'Create Po'} url={'admin_dashboard/procurement/upload_po_sheet?created_by='
                    + `${JSON.parse(localStorage.getItem('auth')).id}` + '&procurement_type=' + localStorage.getItem('procurementtype')}
                    uploadFunc={'upload_zapper_put'} callBack={getPo} disabled={!operations} />
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Po'} fileName={'Po'} url={'admin_dashboard/procurement/purchase_order_report'}
                    payload={'?by_node=' + `${valueByNode ?? ''}` + '&by_supplier=' + `${valueBySupplier ?? ''}`
                      + '&by_order_number=' + `${valueByOrder ?? ''}` + '&start_date=' + `${start_date}`
                      + '&end_date=' + `${end_date}` + '&by_status=' + `${valueByStatus === undefined ? '' : valueByStatus}`
                      + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&hide='
                      + `${JSON.parse(localStorage.getItem('roles')).includes('coldex')}`}
                    type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  <FrzDynamicDownload name={'Forecast vs Po'} fileName={'Forecast vs Po'}
                    url={'admin_dashboard/procurement/forecasting_vs_po'}
                    payload={'?start_date=' + `${start_date}` + '&end_date=' + `${end_date}`} type={'job'}
                    reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                </span>
              </Col>
            </Card>
          </Row>
          {poData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={poData} columns={columns} expandedRow={expandedRow} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <PoModal modalSubmit={modalSubmitPo} ref={modalRef} nodes={nodes} suppliers={suppliers} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default PoList