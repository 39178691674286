import { Http } from '../_modules/http'

export class SupplierService {

  http = new Http()

  get_stats(payload){
    const url = process.env.REACT_APP_AWS3_URL +
      '/v2/stats_dashboard/suppliers?start_date=' + payload.start_date 
      + "&end_date=" + payload.end_date + "&by_supplier_type="+payload.by_supplier_type
      + "&by_holding_start="+ payload.by_holding_start + "&by_holding_end=" + payload.by_holding_end 
      + "&by_credit_cycle=" + payload.by_credit_cycle 
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&by_node=' + `[${payload.by_node}]`
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
      let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_onboarded(payload){
    const url = process.env.REACT_APP_AWS3_URL +'/v2/stats_dashboard/suppliers/onboarded?start_date='+ payload.start_date 
    + "&end_date=" + payload.end_date + "&by_supplier_type="+payload.by_supplier_type
    + "&by_holding_start="+ payload.by_holding_start + "&by_holding_end=" + payload.by_holding_end 
    + "&by_credit_cycle=" + payload.by_credit_cycle 
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&by_node=' + `[${payload.by_node}]`
    + '&range_type='+ payload.range_type
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_recently_onboarded(payload){
    const url = process.env.REACT_APP_AWS3_URL +'/v2/stats_dashboard/suppliers/recently_onboarded_suppliers?page='+payload.page
    + "&by_node=" + (payload.by_node?`[${payload.by_node}]`:`[]`)
    + "&by_supplier_type=" + payload.by_supplier_type
    + "&name_search="+payload.name_search
    + "&end_date=" + payload.end_date
    + '&start_date='+ payload.start_date 
    + "&by_holding_start="+ payload.by_holding_start + "&by_holding_end=" + payload.by_holding_end 
    + "&by_credit_cycle=" + payload.by_credit_cycle 
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    + '&per_page='+ payload.per_page

    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_sku_tag_wise(payload){
    const url = process.env.REACT_APP_AWS3_URL +'/v2/stats_dashboard/suppliers/sku_tag_wise_count?start_date='+ payload.start_date 
    + "&end_date=" + payload.end_date + "&by_supplier_type="+payload.by_supplier_type
    + "&by_holding_start="+ payload.by_holding_start 
    + "&by_holding_end=" + payload.by_holding_end 
    + "&by_credit_cycle=" + payload.by_credit_cycle 
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&by_node=' + `[${payload.by_node}]`
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_farmers_rating(payload){
    const url = process.env.REACT_APP_AWS3_URL +'/v2/stats_dashboard/suppliers/top_farmers_rating?page='+payload.page
    + "&by_node=" + (payload.by_node?`[${payload.by_node}]`:`[]`)
    + "&by_supplier_type=" + payload.by_supplier_type
    + "&name_search="+payload.name_search
    + '&by_rating_order='+payload.by_rating_order
    + '&by_rating='+payload.by_rating
    + '&start_date='+ payload.start_date 
    + "&end_date=" + payload.end_date
    + "&by_credit_cycle=" + payload.by_credit_cycle
    + '&by_city=' + `${localStorage.getItem('city')}`
    + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`
    + "&by_holding_start="+ payload.by_holding_start 
    + "&by_holding_end=" + payload.by_holding_end
    + '&per_page='+ payload.per_page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  get_tonnage(payload,type){
    const url = process.env.REACT_APP_AWS3_URL +`/v2/stats_dashboard/suppliers/${type}?page=`+payload.page
      + '&start_date=' + payload.start_date 
      + "&end_date=" + payload.end_date + "&by_supplier_type="+payload.by_supplier_type
      + "&by_holding_start="+ payload.by_holding_start + "&by_holding_end=" + payload.by_holding_end 
      + '&by_city=' + `${localStorage.getItem('city')}`
      + '&by_node=' + `[${payload.by_node}]`
      + '&per_page='+ payload.per_page
      + "&by_credit_cycle=" + payload.by_credit_cycle 
      + '&by_procurement_type=' + `${localStorage.getItem('procurementtype')}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}
