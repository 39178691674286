export const forcastListingColumns = [
  {
    title: "Name",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.name + " - (" + record.sku + ")"
    }
  },
  {
    title: "Node Name",
    dataIndex: "node_name",
    key: "node_name",
    align: "center",
  },
  {
    title: "City Name",
    dataIndex: "city_name",
    key: "city_name",
    align: "center",
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    align: "center",
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
    align: "center",
  },
  {
    title: "Forecast Qty",
    dataIndex: "forecast_qty",
    key: "forecast_qty",
    align: "center",
  },
  {
    title: "Target Price",
    dataIndex: "target_price",
    key: "target_price",
    align: "center",
  },
  {
    title: "Delivery Date",
    dataIndex: "delivery_date",
    key: "delivery_date",
    align: "center",
  }
]