import React from "react";
import { DoubleRightOutlined } from '@ant-design/icons'

function UomMessage(props){
  return(
    <div>
      <div className="wcl-listing-header wcl-top-noborder">
        <div className="wcl-50">Product</div>
        <div className="wcl-20 text-center">Old Unit</div>
        <div className="wcl-10"></div>
        <div className="wcl-20 text-center">New Unit</div>
      </div>
      {props.data.map((item, index) => {
        return(
          <div key={index} className="wcl-listing-table">
            <div className="wcl-50 wcl-align-center">
              <img src={item.image_url} className="wcl-listing-img" />
              <div>{item.name} - ({item.sku})</div>
            </div>
            <div className="wcl-20 wcl-align-center">
              {item.old_unit}
            </div>
            <div className="wcl-10 wcl-align-center">
              <DoubleRightOutlined className="color-green"/>
            </div>
            <div className="wcl-20 wcl-align-center frz-font-16">
              {item.new_unit}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default UomMessage