import React, { useEffect, useState } from "react";
import {Col, Pagination, Row, Spin, Table, Card } from "antd";
import { UploadService } from "../../../_services/uploads";
import { uploadLogsColumns } from "../../../components/antd/columns/operations/upload_logs";

const upload_service = new UploadService()

function UploadLogs() {
  const [renderData, setRenderData] = useState(false)
  const [listingData, setListingData] = useState(false)
  const [columns] = useState(uploadLogsColumns)
  const [disable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [spinEnable, setSpinEnable] = useState(false)
  const [state, setState] = useState({});

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
    }
  })

  const getListing = () => {
    const paylaod = {
      page: current
    }
    upload_service.get_upload_logs(paylaod).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setListingData(r.response.data)
    }, ((err) => {
      console.log("err", err);
    }))
  }
  
  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  return (
    <div className="layer">
      {listingData ?
        <div>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card>
                  <Table dataSource={listingData} columns={columns} 
                        pagination={false} scroll={{ y: 'calc(100vh - 189px)' }}
                        rowKey={'id'}/>
                </Card>
                <Pagination className="frz-tm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default UploadLogs;