import PoList from '../pages/operations/category/create_po';
import GrnList from '../pages/operations/grn/grnlist';
import Wastage from '../pages/operations/wastage/wastage';
import STOList from '../pages/operations/sto/stolist';
import CCWHInventory from '../pages/operations/category/cc_wh_inventory';
import Suppliers from '../pages/operations/supplier/suppliers';
import CCWH from '../pages/operations/category/cc_wh';
import DownloadListOperations from '../pages/operations/downloads/list';
import AdminList from '../pages/admin/admin';
import Forcasting from '../pages/operations/forcasting/list';
import Management from '../pages/operations/management/management';
import NodeUser from '../pages/operations/nodeuser/nodeuser';
import RetailWastage from '../pages/operations/retailwastage/retailwastage';
import CrateManagement from '../pages/operations/crate';
import WorkBench from '../pages/operations/workbench/workbench';
import UploadLogs from '../pages/upload_dashboard/upload_logs';
import Uploads from '../pages/upload_dashboard/uploads';
import STOShipment from '../pages/operations/stoshipment/sto_shipment';
import ApprovalSettings from '../pages/operations/approvalsettings';
import ApprovalPending from '../pages/operations/approvalpending';
import WastageClosing from '../pages/wastage_closing';
import SupplierManagement from '../pages/procurementDashboard/supplier';
import FulfilmentComponent from '../pages/procurementDashboard/fulfilment';
import StoList from '../pages/sto_list/index';
import Attendance from '../pages/attendance';
import STOAllocation from '../pages/sto_allocation/sto_allocation';
import AttendanceReport from '../pages/attendance/attendancereport';

const routes = {
  wastage: {
    name: 'Wastage',
    key: 'wastage_parent',
    children: [{
      name: 'Wastage',
      path: "/wastage",
      key: "wastage",
      component: Wastage
    },
      // {
      //   name: "Retail Wastage",
      //   path: "/retail_wastage",
      //   key: "retail_wastage",
      //   component: RetailWastage
      // }
    ]
  },


  ccwhinventory: {
    name: 'Inventory Mgmnt',
    key: 'inv_mgmt',
    children: [{
      name: 'Inventory List',
      path: '/cc_wh',
      key: 'cc_wh',
      component: CCWHInventory
    },
    {
      name: "Wastage Closing",
      path: "/wastage_closing",
      key: "wastage_closing",
      component: WastageClosing
    }]
  },

  grnlist: {
    name: 'Grn',
    path: '/grn_list',
    key: 'grn_list',
    component: GrnList
  },

  stolist: {
    name: 'STO',
    path: '/sto',
    key: 'sto',
    component: StoList
    // key: 'sto_list_parent',
    // children: [
    // //   {
    // //   name: 'STO',
    // //   path: '/sto_list',
    // //   key: 'sto_list',
    // //   component: STOList
    // // },
    // { 
    //   name: 'STO',
    // },
    // {
    //   name: 'STO Crate Management',
    //   path: '/sto_crate_management',
    //   key: 'sto_crate_management',
    //   component: Management
    // },
    // {
    //   name: 'STO Shipment',
    //   path: '/sto_shipment',
    //   key: 'sto_shipment',
    //   component: STOShipment
    // }
    // ],
  },

  stoallocation: {
    name: 'STO Allocation',
    path: '/sto_allocation',
    key: 'sto_allocation',
    component: STOAllocation
  },

  nodeuser: {
    name: 'Node User',
    path: '/users_list',
    key: 'users_list',
    component: NodeUser
  },

  workbench: {
    name: 'Work Bench',
    path: '/work_bench',
    key: 'work_bench',
    component: WorkBench
  },

  crate: {
    name: 'Crate Mgmt',
    path: '/crate_management',
    key: 'crate_management',
    component: CrateManagement
  },

  polist: {
    name: 'Po',
    path: '/po_list',
    key: 'po_list',
    component: PoList
  },

  supplier: {
    name: 'Suppliers',
    path: '/supplier',
    key: 'supplier',
    component: Suppliers
  },

  ccwh: {
    name: 'CC WH',
    path: '/ccwh',
    key: 'ccwh',
    component: CCWH
  },

  downloads: {
    name: 'Downloads',
    path: '/downloads',
    key: 'downloads',
    component: DownloadListOperations
  },

  // attendance: {
  //   name: 'Attendance',
  //   path: '/attendance',
  //   key: 'attendance',
  //   component: Attendance
  // },

  attendance: {
    name: 'Attendance',
    key: 'attendance',
    children: [{
      name: 'Attendance',
      path: '/attendance',
      key: 'attendance',
      component: Attendance
    },
    {
      name: 'Attendance Reports',
      path: '/attendance_reports',
      key: 'attendance_reports',
      component: AttendanceReport
    }]
  },

  forcasting: {
    name: 'Forecasting',
    path: '/forecasting',
    key: 'forecasting',
    component: Forcasting
  },

  uploadDashboard: {
    name: 'Upload Dashboard',
    key: 'upload_dashbaord',
    children: [{
      name: 'Uploads',
      path: '/uploads',
      key: 'uploads',
      component: Uploads
    },
    {
      name: 'Upload Logs',
      path: '/upload_logs',
      key: 'upload_logs',
      component: UploadLogs
    }]
  },

  approval_settings: {
    name: 'Approval Settings',
    path: '/approval_settings',
    key: 'approval_settings',
    component: ApprovalSettings
    // children: [{
    //   name: 'Approval Settings',
    //   path: '/approval_settings',
    //   key: 'approval_settings',
    //   component: ApprovalSettings
    // },
    // {
    //   name: 'Pending Approval',
    //   path: '/approval_pending',
    //   key: 'approval_pending',
    //   component: ApprovalPending
    // }
    // ],
  },

  approval_pending: {
    name: 'Pending Approval',
    path: '/approval_pending',
    key: 'approval_pending',
    component: ApprovalPending
  },

  // stoShipment: {
  //   name: 'STO Shipment',
  //   path: '/sto_shipment',
  //   key: 'sto_shipment',
  //   component: STOShipment
  // }
  procurementDashboard: {
    name: 'Procurement Dashboard',
    key: 'procurement_dashboard',
    children: [
      {
        name: 'Supplier',
        path: '/supplier_managememt',
        key: 'supplier_managememt',
        component: SupplierManagement
      },
      // {
      //   name: 'Fulfilment',
      //   path: '/fulfilment',
      //   key: 'fulfilment',
      //   component: FulfilmentComponent
      // }
    ]
  },
}

export default routes