import React from "react";
import { InputNumber, Select } from "antd";

function TableList(props) {

  return (
    <div className="wcl-listing-table-container-modal">
      {props.data.map((item, index) => {
        return (
          <div key={index} className="wcl-align-center wcl-sp-b wcl-listing-table frz-p-relative">
            <div className="wcl-align-center wcl-40">
              <img src={item.image_url} className="wcl-listing-img" />
              <div>{item.name} - ({item.sku})</div>
            </div>
            <div className="wcl-align-center wcl-10 text-center wcl-input">
              <div className="p-relative">
                <InputNumber min={0} size="large" onChange={(e) => props.onChangeWastage(e, item, 'wastage')}
                  className={props.enableError && (item.wastage_quantity === null || item.errors) ? 'wcl-input-error' : ''}
                  disabled={props.buttonLoading} value={item.wastage_quantity} />
                {props.enableError && item.errors ?
                  <div className="wcl-input-error-msg">
                    {item.errors}
                  </div>
                : null}
              </div>
            </div>
            <div className="wcl-align-center wcl-40 text-center wcl-input frz-hrz-center">
              <div className="p-relative">
                <Select placeholder="Select Reason" disabled={props.buttonLoading} value={item.reason_id}
                  onChange={(e) => props.onChangeWastage(e, item, 'reason')}
                  className={props.enableError && item.wastage_quantity > 0 && (item.reason_id === null || item.errors) ? 
                    'wcl-select-error frz-w-200px' : 'frz-w-200px'}>
                  {props.reasons.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.name}
                      </Select.Option>
                    )
                  })}
                </Select>
                {props.enableError && item.wastage_quantity > 0 && item.reason_id === null ?
                  <div className="wcl-input-error-msg">
                    Please select a reason
                  </div>
                : null}
              </div>
            </div>
            <div className="wcl-align-center wcl-10 text-center frz-p-relative">
              {item.unit_changed ? <div className="wcl-unit-changed top-minus-23">Unit Changed</div> : null}
              {item.unit}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TableList