import React from "react";
import {Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

function TableHeaders(props){
  return(
    <div className="wcl-listing-header-filter">
      <div className="wcl-40">Product 
        <Input placeholder="Search Product" prefix={<SearchOutlined />} className="frz-w-300px frz-lm-20"
               onChange={props.filterData}/></div>
      <div className="text-center wcl-15">Category</div>
      <div className="text-center wcl-10">Wastage</div>
      <div className="text-center wcl-15">Wastage %</div>
      <div className="text-center wcl-15">Reason</div>
      <div className="text-center wcl-5"></div>
    </div>
  )
}

export default TableHeaders