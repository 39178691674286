import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Upload, Popconfirm, Spin } from 'antd'
import { UploadService } from '../../../_services/uploads';
import { openNotificationWithIcon } from '../../../_modules/notification';

const upload_service = new UploadService()

const PDFUpload = forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState(false)
    const [fileList, setFileList] = useState([])

    useImperativeHandle(ref, () => ({

        afterUpload(id) {
            setLoading(true)
            const payload = {
                id: id,
                signed_closing_image_base: uploadFile
            }
            upload_service[props.uploadFunc](payload, props.url).subscribe((r) => {
                setUploadFile(false)
                setLoading(false)
                setFileList([])
                openNotificationWithIcon('success', props.name + ' Submitted Succesfully')
                openNotificationWithIcon('success', 'Uploaded File Succesfully')
                props.setWCScreen('wc_main')
            }, (err) => {
                setLoading(false)
                props.setWCScreen('wc_main')
                openNotificationWithIcon('success', props.name + ' Submitted Succesfully')
                openNotificationWithIcon('error', err?.response?.errors[0] ?? 'Upload Failed Please Check Internal File Format')
            })
        },

        cancelUpload() {
            setLoading(false)
        }
    
      }));

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadprops = {
        onRemove: file => {
            setUploadFile(false)
            setFileList([])
        },
        beforeUpload: file => {
            setFileList([file])
            getBase64(file, actionUrl => 
                setUploadFile(actionUrl)
            )
            return false
        },
        fileList,
    };

    return (
        <>
            <Spin spinning={loading}>
                <Upload {...uploadprops}>
                    {fileList.length === 0 ?
                        <Button type="primary" className={`${props.disabled ? '' : "cl-black-button"}`} 
                                disabled={props.disabled}>Upload {props.name} PDF/Image</Button>
                    : null}
                </Upload>
                {fileList.length > 0 ?
                    <Popconfirm
                        title="Are you sure you want submit and all values entered are correct?"
                        onConfirm={() => {props.submitWC(); setLoading(true)}}
                        okText="Yes"
                        cancelText="No"
                        placement="leftTop">
                        <Button
                            type="primary"
                            // onClick={afterUpload}
                            disabled={fileList.length === 0}
                            loading={loading}
                            className={`${`frz-lm-10`} ${props.disabled ? '' : "cl-black-button"}`}
                        >
                            Submit {props.name}
                        </Button>
                    </Popconfirm>
                : null}
            </Spin>
        </>
    );
})

export default PDFUpload;
