import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, InputNumber, Pagination, Switch, Table, DatePicker,
  Checkbox, Popconfirm, Select
} from 'antd';
import { ReloadOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import MainTable from '../../../components/antd/table';
import { poColumns } from '../../../components/antd/columns/operations/category';
import AddProductModal from '../../../components/operations/category/product_modal';
import UploadBills from '../../../components/operations/category/upload_bill';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import ViewDetails from '../../../components/operations/category/ViewDetails';
import FrzDynamicSearch from '../../../_controls/FrzDynamicSearch';
import NestedGrnTable from '../../../components/operations/category/nested_grn';
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const {Option}=Select;
const ops_service = new OperationsService()
let timer;
function GrnList() {
  const modalRef = useRef(null);
  const detailModalRef = useRef(null);

  const [grnData, setGrnData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [payIds, setPayIds] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [products, setProducts] = useState(false)
  const [status] = useState([{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }])
  const [payments] = useState([{ id: 'online', name: 'Online' }, { id: 'cash', name: 'Cash' }])
  const [paymentLoaders, setPaymentLoaders] = useState(false)
  const [operations, setOperations] = useState(false)
  const [showPay, setShowPay] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  const [fetchColm, setFetchColm] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [prodSpin,setProdSpin]=useState(false)
  const [state, setState] = useState({
    valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
    start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined,
    valueByPayments: undefined, valueByProductCode: undefined,
    valueByDueDate: undefined, date: ''
  });
  const { valueByNode, valueBySupplier, valueByOrder, start_date, end_date,
    showDateValue, valueByStatus, valueByPayments, valueByProductCode, valueByDueDate, date } = state
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getGrn()
    }
  })

  useEffect(() => {
    getNodes()
    getSuppliers()
    // getProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer', 'super_admin', 'procurement_admin', 'procurement', 'cc_admin', 'coldex', 'warehouse_admin', 'category', 'category_admin'].includes(data)) {
        setOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer', 'super_admin', 'admin', 'procurement_admin'].includes(data)) {
        setShowPay(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer', 'super_admin', 'admin'].includes(data)) {
        setAllowEdit(true)
      }
    })
    if (JSON.parse(localStorage.getItem('auth')).uid === 'ruby.suresh@fraazo.com') {
      setAllowEdit(true)
    }
    if (process.env.REACT_APP_AWS_TOKEN === '12345') {
      setAllowEdit(true)
    }
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if (fetchColm) {
      setGrnData(fetchColm)
      setFetchColm(false)
      getColumns()
    }
  })

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getSuppliers() {
    ops_service.get_suppliers().subscribe((r) => {
      setSuppliers(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  // function getProducts() {
  //   ops_service.get_product_list().subscribe((r) => {
  //     let tempData = r.response.data.map(v => ({ ...v, name_sku: v.name + " - (" + v.sku + ")" })
  //     )
  //     setProducts(tempData)
  //   },
  //     (err) => {
  //       console.log(err)
  //     })
  // }

  function getGrn(onEdit) {
    setSpinEnable(true)
    setGrnData([])
    if (payIds.length > 0 || onEdit) {
      setPayIds([])
      setColumns([])
    }
    const payload = {
      page: current,
      by_node: !valueByNode ? '' : valueByNode,
      by_supplier: !valueBySupplier ? '' : valueBySupplier,
      by_order_number: !valueByOrder ? '' : valueByOrder,
      start_date: start_date,
      end_date: end_date,
      by_status: valueByStatus === undefined ? '' : valueByStatus,
      admin_id: JSON.parse(localStorage.getItem('auth')).id,
      by_product: valueByProductCode ?? '',
      due_date: !valueByDueDate ? '' : valueByDueDate,
    }

    // JSON.parse(localStorage.getItem('roles')).forEach(data => {
    //   if(['category','coldex'].includes(data)){
    //     payload.admin_id = JSON.parse(localStorage.getItem('auth')).id
    //   }
    // })

    ops_service.get_grn_list(payload).subscribe((r) => {
      if (valueByNode) {
        setStatsData(r.response.meta.stats)
      }
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      if (payIds.length > 0 || onEdit) {
        setFetchColm(r.response.data)
      } else {
        setGrnData(r.response.data)
      }
    },
      (err) => {
        console.log(err)
      })
  }

  function showDetailModal(data, type) {
    detailModalRef.current.showDetailModal(type, data)
  }

  const handleOnViewDetails = (values, type) => {
    showDetailModal(values, type)
  }

  function getColumns() {
    poColumns.map(data => {
      columns.push(data)
    })
    columns.unshift(
      {
        title: 'Pay',
        dataIndex: '',
        key: '',
        render: (record) => {
          return (record.is_active && record.can_initiate_payment ?
            <div>
              <Checkbox onChange={(e) => onChangeCheckbox(e.target.checked, record)} disabled={!showPay || !record.is_verified} />
              {!record.is_verified ?
                <div className='frz-grn-bankverification'>
                  Bank Account Not Verified
                </div>
                : null}
            </div>
            : null
          )
        }
      },
    )
    columns.push({
      title: 'Payments Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      align: 'center',
    },
      // {
      //   title: 'Allow Edit',
      //   dataIndex: '',
      //   key: '',
      //   align: 'center',
      //   render: (record) => {
      //     return (record.is_active ?
      //       <div>
      //         <Switch checked={record.is_editable} onChange={(e) => onChangeEdit(e, record.id)} disabled={!allowEdit} />
      //       </div>
      //       : null
      //     )
      //   }
      // },
      {
        title: 'Upload Bills',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (record.is_active ?
            <div>
              <Button onClick={() => uploadBills(record)} type="primary"
                disabled={record.payment_status === 'paid'}>
                Upload Bills
              </Button>
            </div>
            : null
          )
        }
      },
      {
        title: 'View Details',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Button title="View Details" disabled={!record.payment_status}
            onClick={() => handleOnViewDetails(record, "View Details")}>
            View Details
          </Button>
        }
      },
      {
        title: 'Downloads',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div>
              {record.invoice_url || record.grn_image_url ?
                <Popconfirm placement="leftTop" title='Select Download Document Type?'
                  onConfirm={() => downloadURL(record.invoice_url, 'Invoice')}
                  onCancel={() => downloadURL(record.grn_image_url, 'GRN')}
                  okText="Invoice" cancelText="GRN">
                  <Button type="primary" icon={<DownloadOutlined />}></Button>
                </Popconfirm>
                : null
              }
            </div>
          )

        }
      },
      {
        title: 'Downloads receipt',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div>
              {/* https://opsstaging.api.fraazo.com/api/v1/admin_dashboard/procurement/generate_grn_receipt?grn_id=581 */}
              <FrzDynamicDownload name={'Receipt'} fileName={'GRN'} url={'/api/v1/admin_dashboard/procurement/generate_grn_receipt?po_id=' + record.id}
                payload={""}
                type={'job'} reportFunc={'download_sto'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
            </div>
          )

        }
      })
    setColumns(columns)
  }

  function downloadURL(url, type) {
    if (url) {
      window.open(url, '_blank');
    } else {
      openNotificationWithIcon('error', 'No Documents Found For ' + type)
    }
  }

  function onChangeCheckbox(value, record) {
    if (value === true) {
      payIds.push(record.id);
      setPayIds(payIds)
    } else {
      const index = payIds.indexOf(record.id);
      if (index > -1) {
        payIds.splice(index, 1);
      }
      setPayIds(payIds)
    }
  }

  function createPayments() {
    if (payIds.length === 0 || !valueByPayments) {
      openNotificationWithIcon('error', "Please Select PO's & Payment Method")
    } else {
      setPaymentLoaders(true)
      const payload = {
        po_ids: payIds,
        admin_id: JSON.parse(localStorage.getItem('auth')).id,
        mode_of_payment: valueByPayments
      }
      ops_service.create_payments(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Payment Created Successfull')
        setState(prevState => ({ ...prevState, valueByPayments: undefined }))
        getGrn()
        setPaymentLoaders(false)
      },
        (err) => {
          console.log(err)
          setPaymentLoaders(false)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    }
  }

  function onChangeEdit(value, id) {
    setSpinEnable(true)
    setGrnData([])
    const payload = {
      id: id,
      is_editable: value
    }
    ops_service.allow_edit_grn(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Update Successfull')
      getGrn(true)
    },
      (err) => {
        console.log(err)
        getGrn(true)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    if (type !== 'valueByPayments') {
      setDisable(false)
      setCurrent(1)
    }
  }

  function reset() {
    setState({
      valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
      start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined,
      valueByPayments: undefined, valueByProductCode: undefined
    })
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function expandedRow(record) {
    return <NestedGrnTable data={record} products={products} />
  }

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current) {
    return current && (current < moment().subtract(91, "day") || current > moment().add(10, "day"));
  }

  function uploadBills(data) {
    modalRef.current.showModal('Upload Bills', data)
  }

  function modalSubmitBills(payloadData) {
    const payload = { ...payloadData }
    ops_service.upload_bills(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Uploaded Successfully')
      getGrn()
      modalRef.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDueDate: dateString }))
    setDisable(false)
    setCurrent(1)
  }
  const debounce = (cb, d) => {
    return function (...args) {
      setProdSpin(true)
      setProducts([])
        clearInterval(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, d);
    };
  };
  const handleChange=debounce((e)=>{
      let value=e;
      if(value.length>=3){
        const payload = { by_name:value,procurement_type:localStorage.getItem('procurementtype')}
        ops_service.get_sku_list(payload).subscribe((r) => {
        setProdSpin(false)
        setProducts(r.response.data)
      },
        (err) => {
          setProdSpin(false)
          console.log(err)
        }) 
      }
  },1000)

  return (
    <div className="layer">
      {grnData && nodes && suppliers  ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                  value={showDateValue ? [moment(start_date, dateFormat),
                  moment(end_date, dateFormat)] : ''} format={dateFormat}
                  allowClear={false} disabledDate={disabledDate} />

                <FrzDynamicSearch DropDownData={nodes}
                  placeholder="Select Nodes" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueByNode" margin={10} defValue={valueByNode} />

                <FrzDynamicSearch DropDownData={suppliers}
                  placeholder="Select Suppliers" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueBySupplier" margin={10} defValue={valueBySupplier} />

                <FrzDynamicSearch DropDownData={status}
                  placeholder="Select Status" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueByStatus" margin={10} defValue={valueByStatus} />

                {/* <FrzDynamicSearch DropDownData={products}
                  placeholder="Select Product" value="sku" option="name_sku"
                  isShowSearch={true} width={300} setOnChange={onChange}
                  type="valueByProductCode" margin={10} defValue={valueByProductCode}
                  breakOption={true} /> */}
                  <span className='search-bar-sku' style={{width:'300px'}}>
                        <Select placeholder={'Please Search Product'} showSearch 
                          showArrow={false} value={valueByProductCode} filterOption={false} className="search-sku-container"
                          onSearch={handleChange} onChange={(e) => onChange(e,'valueByProductCode')}
                          notFoundContent={null}>
                          {products?.length>0 ? products?.map(data => {
                            return <Option  value={data.sku}  key={data.id}>
                              <div className="frz-dis-flex frz-justify-sb">
                                <div><img src={data.image_url} alt={data.image_url} width='30px' heigth='30px'/>  {data.name} - ({data.sku})</div> 
                                </div>
                              </Option>
                          }):(prodSpin ?<div className="spin-center add-sku-loader"><Spin tip="...Loading" /></div>:null)}
                        </Select>
                  </span>

                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "valueByOrder")}
                  value={valueByOrder}
                  className="frz-width-150p frz-m-10" />

                <DatePicker value={date} onChange={onChangeDate} className="frz-w-200 frz-m-10"
                  placeholder="Due Date" disabledDate={disabledDate} />

                <Button disabled={disable} onClick={getGrn}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <FrzDynamicSearch DropDownData={payments}
                  placeholder="Select Payment" value="id" option="name"
                  isShowSearch={true} width={200} setOnChange={onChange}
                  type="valueByPayments" margin={10} defValue={valueByPayments} />

                <Button onClick={createPayments} className="frz-dwn" type="primary" loading={paymentLoaders}>
                  Create Payments
                </Button>
                <span className="fl-right">
                  <div className="frz-dis-flex frz-vt-center">
                    {valueByNode && Object.keys(statsData).length ?
                      <div>
                        <div>
                          Pending Count: {statsData.pending_count}
                        </div>
                        <div>
                          GRN Count: {statsData.grn_count}
                        </div>
                      </div>
                      : null}
                    <FrzDynamicDownload name={'GRN'} fileName={'GRN'} url={'admin_dashboard/procurement/grn_report'}
                      payload={'?by_node=' + `${valueByNode ?? ''}` + '&by_supplier=' + `${valueBySupplier ?? ''}`
                        + '&by_order_number=' + `${valueByOrder ?? ''}` + '&start_date=' + `${start_date}`
                        + '&end_date=' + `${end_date}` + '&by_status=' + `${valueByStatus === undefined ? '' : valueByStatus}`
                        + '&due_date=' + `${valueByDueDate ?? ''}`
                        + '&admin_id=' + `${JSON.parse(localStorage.getItem('auth')).id}` + '&hide='
                        + `${JSON.parse(localStorage.getItem('roles')).includes('coldex')}`}
                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  </div>
                </span>
              </Col>
            </Card>
          </Row>
          {grnData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={grnData} columns={columns} expandedRow={expandedRow} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <UploadBills modalSubmit={modalSubmitBills} ref={modalRef} />
          <ViewDetails ref={detailModalRef} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default GrnList