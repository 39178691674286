import React, {useState} from 'react';
import CircularProgress from './circularProgress';
import { ReactComponent as Polygon } from '../../../static/images/polygon.svg'
import { ReactComponent as PolygonDown } from '../../../static/images/polygon_down.svg'
import { Col, Tooltip } from "antd"

const StatusData = (props) => {
  const {statusData, item, i} = props
  const [displayCount, setDisplayCount] = useState(0)
  
  setInterval(() => {
    setDisplayCount(val => val < statusData[item]?.count ? val+1 : val)
  }, 200)

  return (
    <Col key={i} className="gutter-row" span={6}>
    <div className="spend-mgnt-status-bar">
        <div>
            <div style={{ position: 'relative' }}>
                {/* < StatePercentage /> */}
                {/* <div role={"progressbar"} aria-valuenow={"65"} aria-valuemin={"0"} aria-valuemax={"100"}></div> */}
                <CircularProgress
                    size={70}
                    strokeWidth={6}
                    percentage={80}
                    color="#EFB041"
                    text={statusData[item].percentage ?? 0} />
            </div>
        </div>
        <div className="frz-vertical-center frz-lm-5 frz-fw-bold frz-font-16 frz-hrz-center frz-ml-16p frz-flex-d-c">
            <div className='frz-text-trans-capital supplier-font'>
                {item.toLowerCase()}
            </div>
            <div>
                <span className="supplier-count-fort">{displayCount}</span>
                <span className='frz-lm-5'>
                    <Tooltip title={`${statusData[item]?.sign_count}`}>
                        {statusData[item]?.sign_count ? <Polygon /> : <PolygonDown />}
                    </Tooltip>
                </span>
            </div>
        </div>
    </div>
</Col>
  )
}

export default StatusData;