import React, { useEffect, useState } from "react";

const CircularProgress = ({ size, strokeWidth, percentage, color, text}) => {
  const [progress, setProgress] = useState(0);
  const [displayText, setDisplayText] = useState(0)
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  setInterval(() => {
    setDisplayText(val => val < text ? val+1 : val)
  }, 200)

  return (
    <svg width={size} height={size} viewBox={viewBox} className="svg">
      <circle
        className="circle"
        fill="none"
        stroke="white"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      {/* <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash]}
        strokeLinecap="round"
        style={`animation: 'clock-animation 10s linear infinite'
        @keyframes clock-animation {
          0% {
            stroke-dashoffset: 471;
          }
          80% {
            stroke-dashoffset: 0;
          }
        }`
      }
      /> */}
      <text
        fontSize="20px"
        x="50%"
        y="50%"
        dy="5px"
        textAnchor="middle"
      >
        {`${displayText}%`}
      </text>
    </svg>
  );
};

export default CircularProgress;
