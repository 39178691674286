import React, { useState, useEffect, useRef } from "react";
import {calculateTime} from '../../common/calculate_time';
import {timeFormat} from '../../common/time_format';
import { Button, Spin, Tabs } from 'antd';
import email from '../../../../static/images/email.svg';
import print from '../../../../static/images/print.svg';
import download from '../../../../static/images/download.svg';
import PartialClosingModal from './partial_closing_modal/modal';
import PartialTable from './table';
import {openNotificationWithIcon} from '../../../../_modules/notification';
import TimeScreen from './timescreen';
import FrzDynamicUpload from "../../../../_controls/FRZDynamicUpload";

const { TabPane } = Tabs;
var interval

function PartialClosing(props){
  const modalRef = useRef(null);
  const [timer, setTimer] = useState(false)
  const [allowPartialClosing, setAllowPartialClosing] = useState(false)
  const [allowRequest, setAllowRequest] = useState(false)
  const [productData, setProductData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [partialData, setPartialData] = useState(false)
  const [showTimeScreen, setShowTimeScreen] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  
  var expiryTimestamp
  var date = new Date()

  useEffect(() => {
    if(!props.data.current_transaction_count){
      setTime()
      setPartialData([])
    }else{
      setTime()
      getPartialData()
    }
  },[])

  function setTime() {
    var startTime = timeFormat(props.data.cutoff_starts_in)
    var endTime = timeFormat(props.data.approval_ends_in)
    var approvalEndTime = timeFormat(props.data.approval_ends_in)
    if(new Date(startTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = startTime
      getTimer()
    }
    else if(new Date(endTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = endTime
      setAllowPartialClosing(true)
      getTimer()
    }else if(new Date(approvalEndTime).getTime() >= (new Date().getTime() + 1000)){
      expiryTimestamp = approvalEndTime
      setAllowRequest(true)
      setAllowPartialClosing(false)
      getTimer()
    }else{
      setAllowRequest(false)
      setShowTimeScreen(true)
    }
  }

  function getTimer() {
    interval = setInterval(() => {
      setTimer(calculateTime(expiryTimestamp))
    }, 1000)
  }

  useEffect(() => {
    if (timer === '00:00:00') {
      clearInterval(interval)
      setTime()
    }
  }, [timer])

  function addPartial(){
    setLoading('partial')
    props.wc_service.get_partial_product_data(props.currentProcType).subscribe((r) => {
      setLoading(false)
      let newRes = r.response.data.map(x => ({...x, closing_quantity: null, errors: null}))
      setProductData(newRes)
      modalRef.current.showModal('Create Partial Closing', newRes)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error', 'Backend API Error')
      setLoading(false)
    })
  }

  useEffect(() => {
    props.currentDate && getPartialData()
  },[props.currentDate])

  function getPartialData(){
    setPartialData(false)
    const payload = {
      node_code: props.currentNode,
      by_date: props.currentDate ?? ''
    }
    props.wc_service.get_partial_data(payload).subscribe((r) => {
      setPartialData(r.response.data)
      setAllowEdit(r.response.meta.can_edit)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error', 'Backend API Error')
      setPartialData([])
    })
  }

  return(
    <div>
      {timer ? 
        <div className="wastage-timer">
          {allowPartialClosing ? 'Time left to complete inventory correction ' : 
           showTimeScreen ? "Time exhuasted for today's inventory correction" : 
           'Inventory correction starts in '}
          <span className="time frz-lp-10">{timer}</span>
        </div>
      : null}
      <div className="wc-main-screen-container">
        <div className="frz-partial-add-button wcl-display-right">
          <FrzDynamicUpload name={'Inventory Correction'} uploadFunc={'upload_wc_sheet'} modType={'wcl'} m0={true}
            url={`/v2/admin_dashboard_v2/stock_losses/upload_inventory_correction?node_code=${props.currentNode}`}
            disabled={!allowPartialClosing || props.currentProcType !== 'fmcg'}
          />
          <Button type="primary" className={`${allowPartialClosing ? "add-wastage" : ""} ${"frz-lm-10"}`} onClick={addPartial}
                  loading={loading === 'partial'} disabled={!allowPartialClosing}>
            Add SKU
          </Button>
        </div>
        <div>
          <PartialTable data={partialData} wc_service={props.wc_service} allowEdit={allowEdit}
                      currentDate={props.currentDate} currentNode={props.currentNode} getPartialData={getPartialData}/>
        </div>
      </div>
      <PartialClosingModal productData={productData} wc_service={props.wc_service}
        ref={modalRef} currentNode={props.currentNode} getPartialData={getPartialData}/>
    </div>

  )
}

export default PartialClosing