import { Http } from '../_modules/http'

export class UploadService {

  http = new Http()

  upload_zapper_put(payload, minUrl){
    const url = process.env.REACT_APP_AWS_URL + minUrl
    let headers = { ...JSON.parse(localStorage.getItem('auth'))}
    return this.http.put(url, payload, headers);
  }

  upload_zapper_post(payload, minUrl){
    const url = process.env.REACT_APP_AWS_URL + minUrl
    let headers = { ...JSON.parse(localStorage.getItem('auth'))}
    return this.http.post(url, payload, headers);
  }

  get_upload_logs(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/upload_logs?page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers);
  }

  upload_wc_sheet(payload, minUrl){
    const url = process.env.REACT_APP_AWS3_URL + minUrl
    let headers = { ...JSON.parse(localStorage.getItem('auth'))}
    return this.http.put(url, payload, headers);
  }

  upload_zapper_putv2(payload, minUrl){
    const url = process.env.REACT_APP_AWS3_URL + minUrl
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  inward_challan_upload(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/signed_delivery_challan"
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

}
